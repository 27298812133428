import React from "react";
import { useDispatch } from "react-redux";
import ScreenMain from "./ActionMagnetic/ScreenMain";
import ScreenResults from "./ActionMagnetic/ScreenResults";
import prepareAction from "./ActionMagnetic/prepareAction";
import onSubmit from "./ActionMagnetic/onSubmit";
import quizShouldSkip from "./Common/quizShouldSkip";
import scrollToAction from "./Common/scrollToAction";
import {
  wfSagaSubmitAction,
  WF_SAGA_SET_ENTITY_GUID_DONE,
} from "redux/workflow/action";
import { Field, useForm } from "react-final-form";
import { required } from "common/components/rules";
import AbstractAction from "./AbstractAction";

const ActionMagnetic = ({ action, state, config, ...rest }) => {
  const dispatch = useDispatch();
  const form = useForm();

  const handleSubmit = () => {
    scrollToAction(action.id);
    dispatch(wfSagaSubmitAction(action.guid));
  };

  const { screen, trial, points } = state;
  React.useEffect(() => {
    form.change(`action-${action.id}`, {
      screen: screen,
      trial: trial,
      points: points.map(p => ({
        correct: p.correct,
        answers: p.answers,
        proposals: p.proposals,
      })),
    });
  }, [screen, trial, points, action.id, form]);

  const Screen = screen === "info" ? ScreenMain : ScreenResults;

  const handleValidate = React.useCallback(
    v => {
      if (config.required && (screen === "info" || screen === "retry")) {
        return required();
      }
    },
    [config.required, screen]
  );

  return (
    <>
      <Screen
        action={action}
        onSubmit={handleSubmit}
        state={state}
        config={config}
        {...rest}
      />
      <Field
        name={`action-${action.id}`}
        component="input"
        type="hidden"
        validate={handleValidate}
      />
    </>
  );
};

ActionMagnetic.initialState = {
  points: [],
  proposals: [],
  trial: 0,
  screen: "info",
  clearOn: WF_SAGA_SET_ENTITY_GUID_DONE,
  actionType: "MAGNETIC",
};

ActionMagnetic.prepareAction = prepareAction;
ActionMagnetic.onSubmit = onSubmit;
ActionMagnetic.shouldSkip = quizShouldSkip;
ActionMagnetic.skipValue = ({ state: { screen, trial, points } }) => ({
  screen: screen,
  trial: trial,
  points: points.map(p => ({
    correct: p.correct,
    answers: p.answers,
    proposals: p.proposals,
  })),
});
ActionMagnetic.classNames = ["qrockme-action-magnetic"];

Object.assign(ActionMagnetic, AbstractAction);
export default ActionMagnetic;
