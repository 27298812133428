import React from "react";
import jsQR from "jsqr";

const constraints = {
  audio: false,
  video: { facingMode: "environment" },
};

function drawLine(canvas, begin, end, color) {
  canvas.beginPath();
  canvas.moveTo(begin.x, begin.y);
  canvas.lineTo(end.x, end.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
}

const CatchQrCode = ({ onDecode, onPlaying, delay }) => {
  const [scanned, setScanned] = React.useState();
  const camera = React.useRef();
  const canvas = React.useRef();
  const animationFrame = React.useRef();
  const firstFrame = React.useRef(true);
  const startScanningAt = React.useRef(Date.now() + (delay || 0));

  const tick = React.useCallback(() => {
    const video = camera.current;
    const ctx = canvas && canvas.current && canvas.current.getContext("2d");
    if (video && ctx && video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.current.hidden = false;
      canvas.current.height = video.videoHeight;
      canvas.current.width = video.videoWidth;
      ctx.drawImage(video, 0, 0, canvas.current.width, canvas.current.height);
      const imageData = ctx.getImageData(
        0,
        0,
        canvas.current.width,
        canvas.current.height
      );
      let code;
      if (startScanningAt.current < Date.now()) {
        code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "attemptBoth",
        });
      }
      if (code && code.data) {
        drawLine(
          ctx,
          code.location.topLeftCorner,
          code.location.topRightCorner,
          "#FF3B58"
        );
        drawLine(
          ctx,
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          "#FF3B58"
        );
        drawLine(
          ctx,
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          "#FF3B58"
        );
        drawLine(
          ctx,
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          "#FF3B58"
        );
        setScanned(code.data);
      } else {
        if (firstFrame.current && onPlaying) {
          firstFrame.current = false;
          onPlaying();
        }
        animationFrame.current = requestAnimationFrame(tick);
      }
    }
  }, [onPlaying, startScanningAt]);

  React.useEffect(() => {
    if (scanned) {
      onDecode(scanned);
    }
  }, [onDecode, scanned]);

  React.useEffect(() => {
    let stream;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(s => {
        stream = s;
        camera.current.srcObject = stream;
        camera.current.play().then(() => {
          animationFrame.current = requestAnimationFrame(tick);
        });
      })
      .catch(e => {
        console.error("Unable to get video stream", e);
      });
    return () => {
      if (stream) {
        stream.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
    };
  }, [tick]);

  return (
    <>
      <video playsInline style={{ width: 0, height: 0 }} ref={camera} />
      <canvas style={{ width: "100%" }} hidden ref={canvas} />
    </>
  );
};

export default CatchQrCode;
