export const SET_TITLE = "SET_TITLE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LANG_CURRENT = "SET_LANG_CURRENT";
export const SET_LANG_CHOICE = "SET_LANG_CHOICE";
export const SET_LANG_AVAILABLE = "SET_LANG_AVAILABLE";

export const setTitle = title => {
  return {
    type: SET_TITLE,
    payload: title,
  };
};

export const setToken = token => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setLangCurrent = value => {
  return {
    type: SET_LANG_CURRENT,
    payload: value,
  };
};

export const setLangChoice = value => {
  return {
    type: SET_LANG_CHOICE,
    payload: value,
  };
};

export const setLangAvailable = value => {
  return {
    type: SET_LANG_AVAILABLE,
    payload: value,
  };
};
