import React from "react";
import ActionsIntl from "./Actions.i";
import useFormatMessage, { withIntl } from "hooks/useFormatMessage";
import InputAdapter from "common/components/formAdapters/InputAdapter";
import TextareaAdapter from "common/components/formAdapters/TextareaAdapter";
import MaskedInputAdapter from "common/components/formAdapters/MaskedInputAdapter";
import PhoneNumberAdapter from "common/components/formAdapters/PhoneNumberAdapter";
import { makeStyles } from "@material-ui/core";
import { FormField } from "./Actions.s";
import { Field } from "react-final-form";
import {
  required,
  noValidation,
  email,
  emailRequired,
} from "common/components/rules";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const useStyles = makeStyles({
  arialInput: {
    "& input": {
      "font-family": "Arial",
    },
    "& textarea": {
      "font-family": "Arial",
    },
  },
  dateInput: {
    "-webkit-appearance": "none",
    height: 43,
  },
});

let ActionWriteText = ({ action, config, data }) => {
  const classes = useStyles();
  const t = useFormatMessage();
  let fieldProps = {
    component: InputAdapter,
    placeholder: config.placeholder || t(ActionsIntl.TextareaPlaceholder),
    initialValue: data.result,
  };
  switch (config.textType) {
    case "multiple":
      fieldProps = {
        ...fieldProps,
        component: TextareaAdapter,
        placeholder: config.placeholder || t(ActionsIntl.TextareaPlaceholder),
        rows: 10,
      };
      break;
    case "number":
      fieldProps = {
        ...fieldProps,
        component: InputAdapter,
        type: "number",
        placeholder: config.placeholder || t(ActionsIntl.NumberPlaceholder),
      };
      break;
    case "date":
      fieldProps = {
        ...fieldProps,
        component: InputAdapter,
        className: classes.dateInput,
        placeholder: config.placeholder || t(ActionsIntl.DatePlaceholder),
        type: "date",
        initialValue: data.result ? data.result.substr(0, 10) : undefined,
      };
      break;
    case "email":
      fieldProps = {
        ...fieldProps,
        component: MaskedInputAdapter,
        placeholder: config.placeholder || t(ActionsIntl.EmailPlaceholder),
        validate: config.required ? emailRequired : email,
      };
      break;
    case "phone":
      fieldProps = {
        ...fieldProps,
        component: PhoneNumberAdapter,
        placeholder: config.placeholder || t(ActionsIntl.PhonePlaceholder),
      };
      break;
    default:
  }

  return (
    <FormField className={classes.arialInput}>
      <Title config={config} data={data} />
      <Field
        parse={value => value}
        name={`action-${action.id}`}
        validate={config.required ? required : noValidation}
        {...fieldProps}
      />
    </FormField>
  );
};

ActionWriteText = withIntl(ActionWriteText);
ActionWriteText.classNames = ["qrockme-action-writetext"];

Object.assign(ActionWriteText, AbstractAction);
export default ActionWriteText;
