import React from "react";
import { makeStyles } from "@material-ui/core";
import nl2br from "react-nl2br";
import clsx from "clsx";
import useCancelableRequest from "common/components/useCancelableRequest";

const useStyles = makeStyles({
  button: {
    height: "auto",
    display: "flex",
    alignItems: "stretch",
    fontFamily: "inherit",
    cursor: "pointer",
    padding: 0,
  },
  buttonText: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonImage: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  buttonIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0 16px",
  },
});

function createEmptyNode(className) {
  return (
    <div
      className={clsx(
        className,
        "qrockme-button-image qrockme-button-image-empty"
      )}
    ></div>
  );
}

const NextButton = ({
  image,
  title,
  desc,
  additionals,
  invert,
  className,
  disabled,
  onClick,
  icon,
}) => {
  additionals = additionals || [];
  invert = Boolean(invert);
  disabled = Boolean(disabled);
  const classes = useStyles();
  const [result] = useCancelableRequest(image);
  const Icon = icon;

  const leftNode = React.useMemo(() => {
    if (Icon && !image) {
      return createEmptyNode(classes.buttonImage);
    }
    if (!image) {
      return null;
    }
    return (
      <div
        className={clsx(classes.buttonImage, "qrockme-button-image")}
        style={{
          backgroundImage: `url('${result}')`,
        }}
      ></div>
    );
  }, [image, result, classes, Icon]);

  const rightNode = React.useMemo(() => {
    if (Icon) {
      return (
        <div
          className={clsx(
            classes.buttonIcon,
            "qrockme-button-image qrockme-button-icon"
          )}
        >
          <Icon />
        </div>
      );
    }
    if (!image) return null;
    return createEmptyNode(classes.buttonImage);
  }, [image, classes, Icon]);

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div className={className}>
      <button
        type="button"
        className={clsx(classes.button, "qrockme-button")}
        disabled={disabled}
        onClick={handleClick}
      >
        {invert ? rightNode : leftNode}
        {
          <div className={clsx(classes.buttonText, "qrockme-button-text")}>
            {title && (
              <div className="qrockme-button-title">{nl2br(title)}</div>
            )}
            {desc && <div className="qrockme-button-desc">{nl2br(desc)}</div>}
            {additionals.length > 0 &&
              additionals.map(({ content }, idx) => {
                if (!content) return null;
                const className = [
                  "qrockme-button-add",
                  `qrockme-button-add-${idx + 1}`,
                ].join(" ");
                return (
                  <div className={className} key={idx}>
                    {nl2br(content)}
                  </div>
                );
              })}
          </div>
        }
        {invert ? leftNode : rightNode}
      </button>
    </div>
  );
};

export default NextButton;
