import standardizeValue from "./standardizeValue";

const rules = {
  eq: {
    validate: (answer, { value }) => answer === standardizeValue(value),
    toString: ({ value }) => `= ${value}`,
  },
  contain: {
    validate: (answer, { value }) =>
      answer.indexOf(standardizeValue(value)) > -1,
    toString: ({ value }) => `= *${value}*`,
  },
  ncontain: {
    validate: (answer, { value }) =>
      answer.indexOf(standardizeValue(value)) === -1,
    toString: ({ value }) => `<> *${value}*`,
  },
};

export default rules;
