import React from "react";
import { Grid, Button, makeStyles, Collapse } from "@material-ui/core";
import {
  PersonAdd as Icon,
  VerifiedUser as LoggedIcon,
} from "@material-ui/icons";
import ActionsIntl from "./Actions.i";
import useFormatMessage from "hooks/useFormatMessage";
import InputAdapter from "common/components/formAdapters/InputAdapter";
import MaskedInputAdapter from "common/components/formAdapters/MaskedInputAdapter";
import { FormField, ImageContainer, ImageLoggedContainer } from "./Actions.s";
import { Field, useField } from "react-final-form";
import { required, emailRequired } from "common/components/rules";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const useStyles = makeStyles({
  emailButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "bold",
    marginTop: -16,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  emailButtonContainer: {
    textAlign: "right",
  },
});

const ActionRegister = ({ action, config, data }) => {
  const classes = useStyles();
  const email = config.email || "required";
  const [showEmail, setShowEmail] = React.useState(email !== "disabled");
  const t = useFormatMessage();
  const noEmailField = useField(`action-${action.id}[noemail]`);
  const noEmailFieldChange = noEmailField.input.onChange;

  React.useEffect(() => {
    noEmailFieldChange(!showEmail);
  }, [noEmailFieldChange, showEmail]);

  return (
    <>
      <Title config={config} data={data} />
      {config.userId && (
        <ImageLoggedContainer>
          <LoggedIcon style={{ fontSize: 70 }} />
        </ImageLoggedContainer>
      )}
      {!config.userId && (
        <>
          <ImageContainer className="qrockme-user-icon">
            <Icon style={{ fontSize: 70 }} />
          </ImageContainer>
          <Grid spacing={2} container>
            <Grid xs={6} item>
              <FormField>
                <Field
                  name={`action-${action.id}[firstname]`}
                  placeholder={t(ActionsIntl.FirstnamePlaceholder)}
                  validate={required}
                  component={InputAdapter}
                />
              </FormField>
            </Grid>
            <Grid xs={6} item>
              <FormField>
                <Field
                  name={`action-${action.id}[lastname]`}
                  placeholder={t(ActionsIntl.LastnamePlaceholder)}
                  validate={required}
                  component={InputAdapter}
                />
              </FormField>
            </Grid>
          </Grid>
          <Collapse in={showEmail} unmountOnExit>
            <FormField>
              <Field
                name={`action-${action.id}[email]`}
                placeholder={t(ActionsIntl.RegisterEmailPlaceholder)}
                validate={emailRequired}
                component={MaskedInputAdapter}
              />
            </FormField>
          </Collapse>
          {email === "optional" && (
            <div className={classes.emailButtonContainer}>
              <Button
                variant="text"
                className={classes.emailButton}
                onMouseDown={() => setShowEmail(s => !s)}
              >
                {showEmail
                  ? t(ActionsIntl.RegisterDontHaveEmail)
                  : t(ActionsIntl.RegisterHaveEmail)}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

ActionRegister.prepareAction = function (action, state, { user, actions }) {
  if (
    user.id &&
    actions.length === 2 &&
    actions.some(
      a => a.component && a.component.shouldSkip && a.component.shouldSkip(a)
    )
  ) {
    return null;
  }
  return { ...action, config: { ...action.config, userId: user.id } };
};

ActionRegister.classNames = ["qrockme-action-register"];

Object.assign(ActionRegister, AbstractAction);
export default ActionRegister;
