import React from "react";
import { useActionImage } from "common/actions";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";

const ActionLink = ({ config, data }) => {
  const image = useActionImage(config, data);
  const showTitle = config.showTitle === undefined ? true : config.showTitle; // On old or never edited LINK showTitle is undefined
  const title = showTitle ? config.title : undefined;
  return (
    <MenuButton
      title={title}
      image={image}
      href={config.link}
      target="_blank"
    />
  );
};

ActionLink.classNames = ["qrockme-menu-link"];

Object.assign(ActionLink, AbstractAction);

export default ActionLink;
