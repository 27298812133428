import React from "react";
import { makeStyles } from "@material-ui/core";
import { MoreVert as HandleIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    color: "#565656",
    background: "#E3E3E3",
    borderRadius: 4,
    flexShrink: 0,
    margin: 2,
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
    },
  },
  icon: {
    fontSize: 22,
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
  },
  label: {
    padding: 10,
    flexGrow: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

const BarProposal = React.forwardRef(({ label, className, style }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className, "action-proposalbar-proposal")}
      style={style}
    >
      <div className={clsx(classes.icon, "action-proposal-handle")}>
        <HandleIcon fontSize="inherit" color="inherit" />
      </div>
      <div className={clsx(classes.label, "action-proposal-label")}>
        {label}
      </div>
    </div>
  );
});

export default BarProposal;
