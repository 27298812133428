import { put, call, select } from "redux-saga/effects";
import { setToken } from "redux/app/action";
import { setUserData } from "auth/store/actions/user.actions";
import { selectToken, selectLangCurrent } from "redux/app/selector";
import jwtService from "services/jwtService";

export default function* apiCall(fn, ...args) {
  const token = yield select(selectToken);
  const lang = yield select(selectLangCurrent);
  const result = yield call(fn, ...args, token, lang);
  if (result.token) {
    // JWT token update
    if (result.token.token) {
      yield put(setToken(result.token.token));
    }

    // SSO Login
    if (result.token.user) {
      yield put(setUserData(result.token.user));
      jwtService.setSession(result.token.token, result.token.user);
    }
  }
  return result;
}
