import makeBasicAPIActions from "common/utils/makeBasicAPIActions";
import { request as apiRequest } from "common/utils/authRequest";
import { clearUploadPic, removeUploadedPic, initUploadedPic } from "./action";

const upload = makeBasicAPIActions(
  "UPLOAD_PIC",
  (request, success, failure) => (name, file, id) => {
    return async (dispatch, getState) => {
      dispatch(request({ name, file, id }));
      let response;
      const token = getState().app.token;
      const formData = new FormData();
      formData.append("image", file);
      try {
        response = await apiRequest({
          endpoint: `upload`,
          method: "POST",
          data: formData,
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response) {
          try {
            const resp = await response;
            dispatch(success({ name, url: resp.url, id }));
            return Promise.resolve({ name, url: resp.url, id });
          } catch (error) {
            dispatch(failure({ name, id, error }));
            return Promise.reject();
          }
        } else {
          dispatch(failure("Something went wrong"));
          return Promise.reject();
        }
      } catch (error) {
        dispatch(failure({ name, id, error }));
        return Promise.reject();
      }
    };
  }
);

export default {
  upload,
  clearUploadPic,
  removeUploadedPic,
  initUploadedPic,
};
