import axios from "axios";
import { rootApi } from "config/api";

export const request = async options => {
  const { root, endpoint, method, token, lang, query, ...restOptions } =
    options;
  const url = `${root || rootApi}${endpoint}`;
  let authorization = {};
  if (token) {
    authorization = {
      Authorization: `Bearer ${token}`,
    };
  }
  return await axios({
    method: method || "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authorization,
    },
    params: { ...query, lang },
    url: url,
    ...restOptions,
  })
    .then(response => {
      if (response.status >= 400) {
        return Promise.reject(response);
      }
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
