import initialState from "./initialState";
import { shuffle } from "lodash";
import produce from "immer";
import { prepareChildren } from "scenes/actions/Common/utils";

export default function ({ config, children, data, ...rest }, state) {
  if (!state && data && data.result_obj) {
    state = produce(initialState, draft => {
      draft.answers = data.result_obj;
    });
  } else {
    state = state || { ...initialState };
  }

  if (!state.proposals) {
    state = produce(state, draft => {
      if (config.randomSurvey) {
        draft.proposals = shuffle(config.proposals);
      } else {
        draft.proposals = config.proposals;
      }
    });
  }

  const values = state.proposals
    .map(p => (state.answers.includes(p.guid) ? p.valueSimple : undefined))
    .filter(Boolean);

  return {
    config,
    data,
    ...rest,
    children: prepareChildren(children, { onlyScreens: [...values, "info"] }),
    state,
  };
}
