import * as Api from "api";
import apiCall from "./apiCall";
import { replace } from "connected-react-router";
import { put, call, select } from "redux-saga/effects";
import { setTitle } from "redux/app/action";
import {
  WF_SAGA_LOAD_CARDCHOICES_DONE,
  WF_SAGA_LOAD_CARDCHOICES_FAILED,
} from "redux/workflow/action";
import { selectCurrent } from "redux/workflow/selector";

export default function* loadCardChoices({ payload: guid }) {
  try {
    const current = yield select(selectCurrent);

    let r = {};
    if (guid) {
      r = yield call(
        apiCall,
        Api.fetchCard,
        current.entity,
        current.project,
        guid,
        {}
      );
    } else {
      // Si on est en début de projet on affiche les cartes de départ
      r = yield call(
        apiCall,
        Api.fetchProject,
        current.entity,
        current.project
      );
      yield put(setTitle(r.parentTitle));
    }

    if (r.cards.length === 1) {
      // Une seule carte on y passe directement
      yield put(replace("/_", { guid: r.cards[0].guid }));
    }

    yield put({
      type: WF_SAGA_LOAD_CARDCHOICES_DONE,
      payload: {
        nextCards: [],
        cards: r.cards,
        card: undefined,
      },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_LOAD_CARDCHOICES_FAILED, message: e.message });
  }
}
