import React from "react";
import PropTypes from "prop-types";
import { wfSagaSetActionState } from "redux/workflow/action";
import { useDispatch } from "react-redux";
import MainScreen from "../../Common/MainScreen";
import Proposal from "../Proposal";
import produce from "immer";
import useByGuid from "scenes/actions/Common/useByGuid";

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();

  const handleProposalClick = guid => () => {
    const index = state.answers.findIndex(v => v === guid);
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          draft.proposals = state.proposals;
          if (index > -1) {
            draft.answers.splice(index, 1);
          } else {
            if (config.multiple) {
              draft.answers.push(guid);
            } else {
              draft.answers = [guid];
            }
          }
        })
      )
    );
  };

  const handleSubmitClick = ev => {
    setSubmitting(true);
    setTimeout(onSubmit, 500);
    return ev.preventDefault();
  };

  const proposalsByGuid = useByGuid(config.proposals);
  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      onSubmit={handleSubmitClick}
    >
      {state.proposals.map(p => {
        const checked = state.answers.indexOf(p.guid) > -1;
        return (
          <Proposal
            key={p.guid}
            checked={checked}
            disabled={submitting}
            image={proposalsByGuid[p.guid].image}
            label={proposalsByGuid[p.guid].label}
            onClick={handleProposalClick(p.guid)}
          />
        );
      })}
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
