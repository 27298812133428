import React from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton, Chip, makeStyles } from "@material-ui/core";
import {
  Search as ZoomIcon,
  Close as CloseIcon,
  MoreVert as HandleIcon,
} from "@material-ui/icons";
import nl2br from "react-nl2br";
import clsx from "clsx";
import ImageLoader from "common/components/ImageLoader";

const useStyles = makeStyles({
  proposal: {
    width: "100%",
    border: "1px solid #C7C7C7",
    background: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    padding: 0,
    cursor: "grab",
    overflow: "hidden",
    "&.action-proposal-disabled": { cursor: "auto" },
    "&:focus": {
      outline: "none",
    },
    "& .action-proposal-handle": {
      display: "flex",
      alignItems: "center",
    },
    "& .action-proposal-label": {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      marginLeft: 8,
      marginRight: 8,
      flexGrow: 1,
    },
    "& .action-proposal-image": {
      marginLeft: 8,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      "& img": {
        borderRadius: 6,
        maxWidth: "100%",
        maxHeight: "100%",
      },
    },
    "& .action-proposal-correction": {
      marginRight: 8,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
  },
  image: {
    position: "relative",
  },
  zoomBtn: {
    backgroundColor: "transparent",
    borderRadius: 0,
    color: "#aeaeae",
    position: "absolute",
    right: 0,
    top: 0,
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiIconButton-label": {
      display: "block",
      position: "absolute",
      height: 40,
      width: 40,
      right: -20,
      top: -20,
      borderRadius: 40,
      border: "2px solid #C7C7C7",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      bottom: 2,
      left: 2,
      fontSize: 17,
    },
  },
  zoomImg: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  zoomHeader: {
    textAlign: "right",
  },
  zoomPaper: { margin: 4 },
});

const Proposal = ({
  image,
  label,
  highlighted,
  disabled,
  showSolution,
  solution,
  className,
}) => {
  const [zoom, setZoom] = React.useState();
  const classes = useStyles();

  const handleZoomClick = image => e => {
    setZoom(image);
    e.stopPropagation();
    return e.preventDefault();
  };

  const handleZoomClose = () => {
    setZoom(undefined);
  };

  return (
    <>
      <div
        className={clsx({
          [classes.proposal]: true,
          "action-proposal": true,
          "action-proposal-checked": disabled || highlighted,
          "action-proposal-disabled": disabled,
          [className]: Boolean(className),
        })}
      >
        {!disabled && (
          <div className="action-proposal-handle">
            <HandleIcon />
          </div>
        )}
        <div className="action-proposal-label">{nl2br(label)}</div>
        {image && (
          <div className={clsx(classes.image, "action-proposal-image")}>
            <IconButton
              size="small"
              className={classes.zoomBtn}
              onClick={handleZoomClick(image)}
            >
              <ZoomIcon color="inherit" />
            </IconButton>
            <ImageLoader alt={label} src={image} />
          </div>
        )}
        {solution !== undefined && showSolution && (
          <div className="action-proposal-correction">
            <Chip
              size="small"
              className="action-proposal-chip"
              label={solution}
            />
          </div>
        )}
      </div>
      <Dialog
        open={Boolean(zoom)}
        onClose={handleZoomClose}
        classes={{ paper: classes.zoomPaper }}
      >
        <div className={classes.zoomHeader}>
          <IconButton onClick={handleZoomClose} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <img src={zoom} className={classes.zoomImg} alt="" />
        </div>
      </Dialog>
    </>
  );
};

Proposal.propTypes = {
  image: PropTypes.string,
  correct: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Proposal;
