import React from "react";
import { makeStyles } from "@material-ui/core";
import { useDrag } from "react-dnd";
import Spot from "./Spot";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    cursor: "grab",
  },
});

const DraggableSpot = ({ index, dndGroup, onDragging, onDrop, style }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (onDrop) {
        const didDrop = monitor.didDrop();
        onDrop(item, didDrop);
      }
    },
  });
  const classes = useStyles();

  React.useEffect(() => {
    if (onDragging) {
      onDragging(isDragging);
    }
  }, [onDragging, isDragging]);

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <Spot
      ref={drag}
      className={clsx(classes.root, { dragging: isDragging })}
      style={style}
    />
  );
};

export default DraggableSpot;
