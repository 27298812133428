import styled from "styled-components/macro";

export const Title = styled.div`
  font-size: ${props => props.theme.fontSizes.title};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  text-align: left;
  justify-content: start;
  align-items: center;
  max-width: 100%;
  margin-left: 5%;
  margin-right: 5%;
`;

export const BackBtnContainer = styled.div`
  display: flex;
  text-align: left;
  justify-content: start;
  max-width: 100%;
`;

export const LogoWrapper = styled.div`
  text-align: ${props => props.align || ""};
  flex-grow: 1;
  height: 100%;
`;

export const LogoImg = styled.img`
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const LogoContainer = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;
