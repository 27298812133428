import styled from "styled-components/macro";
import medias from "medias";

export const AppContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: scroll;
  ${"/* autoprefixer: off */"}
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-left: 0;
  color: ${props => props.theme.colors.darkGray};
  ${medias.sm`
      justify-content: start;
   `}
  ${medias.md`
      justify-content: start;
   `}
   ${medias.lg`
      justify-content: start;
   `}
`;
