import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { wfSagaSetActionState } from "redux/workflow/action";
import useInView from "react-cool-inview";
import { Preview } from "react-dnd-multi-backend";
import Board from "./Board";
import MainScreen from "../Common/MainScreen";
import ProposalsBar from "../Common/ProposalsBar";
import BarProposal from "../Common/BarProposal";
import populateLabels from "./populateLabels";
import produce from "immer";
import { Portal } from "@material-ui/core";

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [dragging, setDragging] = React.useState();
  const [over, setOver] = React.useState(false);
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const labels = config.proposals;
  const pointsWithLabels = React.useMemo(
    () => populateLabels(state.points, labels),
    [labels, state.points]
  );

  const proposals = React.useMemo(
    () =>
      state.proposals.map(guid => ({
        guid,
        label: labels[guid],
      })),
    [state.proposals, labels]
  );

  const handlePointDrop = (pointIndex, { guid, from }) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          const proposalIndex = draft.proposals.indexOf(guid);
          if (proposalIndex > -1) {
            draft.proposals.splice(proposalIndex, 1);
          }
          if (from !== undefined) {
            draft.points[from].answers = draft.points[pointIndex].answers;
          } else {
            draft.proposals.push(...draft.points[pointIndex].answers);
          }
          draft.points[pointIndex].answers = [guid];
        })
      )
    );
  };

  const handleBarDrop = ({ guid, from, index }) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          if (from !== undefined) {
            draft.points[from].answers = [];
            if (index !== undefined) {
              draft.proposals.splice(index, 0, guid);
            } else {
              draft.proposals.push(guid);
            }
          }
        })
      )
    );
  };

  const handleDragging = React.useCallback(
    dragging => setDragging(dragging),
    []
  );

  const handleOver = React.useCallback(() => {
    setOver(true);
  }, []);

  const handleLeave = React.useCallback(() => {
    setOver(false);
  }, []);

  const generatePreview =
    actionGuid =>
    ({ itemType, item, style }) => {
      if (actionGuid !== itemType) return;
      const className = over ? "action-proposalbar-proposal-over" : "";
      return (
        <Portal>
          <div
            style={{
              ...style,
              zIndex: 1001,
              width: item.width + 5,
            }}
          >
            <BarProposal label={item.label} className={className} />
          </div>
        </Portal>
      );
    };

  const fulfilled = state.points.every(p => p.answers.length > 0);
  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      dragging={dragging}
    >
      <Board
        ref={ref}
        dndGroup={action.guid}
        src={config.image}
        alt={config.title}
        isMainScreen={true}
        points={pointsWithLabels}
        onDrop={handlePointDrop}
        onLoad={() => setLoaded(true)}
        onDragging={handleDragging}
        onOver={handleOver}
        onLeave={handleLeave}
      />
      <ProposalsBar
        dndGroup={action.guid}
        sticky={inView && loaded}
        proposals={proposals}
        onSubmit={fulfilled ? onSubmit : undefined}
        onDrop={handleBarDrop}
        onDragging={handleDragging}
      />
      <Preview>{generatePreview(action.guid)}</Preview>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
