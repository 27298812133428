import React from "react";
import { makeStyles } from "@material-ui/core";
import ReactIframeResizer from "react-iframe-resizer-super";
import InnerHTML from "dangerously-set-html-content";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const useStyles = makeStyles({
  responsive169: {
    overflow: "hidden",
    paddingBottom: "56.25%",
    position: "relative",
    height: 0,
    "& iframe": {
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      position: "absolute",
    },
  },
  fullheight: {
    "& iframe": {
      display: "block",
      height: "70vh",
      width: "100%",
    },
  },
  slider: {
    margin: "0 20px 30px 20px",
  },
});

const regexYoutube = /^https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})/m;
const regexYoutube2 = /^https:\/\/youtu.be\/([\w-]{11})/m;
function codeYoutube(url) {
  let matches = regexYoutube.exec(url);
  if (!matches) {
    matches = regexYoutube2.exec(url);
  }
  if (matches && matches[1]) {
    return `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/${matches[1]}"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>`;
  }
  return;
}

const regexDaily = /^https:\/\/www\.dailymotion\.com\/video\/([a-zA-Z0-9]+)/m;
function codeDaily(url) {
  const matches = regexDaily.exec(url);
  if (matches && matches[1]) {
    return `<iframe
        frameborder="0"
        width="640"
        height="360"
        src="https://www.dailymotion.com/embed/video/${matches[1]}"
        allowfullscreen
        allow="autoplay"
      ></iframe>`;
  }
  return;
}

const regexVimeo = /^https:\/\/vimeo\.com\/([0-9]+)/m;
function codeVimeo(url) {
  const matches = regexVimeo.exec(url);
  if (matches && matches[1]) {
    return `<iframe
        src="https://player.vimeo.com/video/${matches[1]}"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>`;
  }
  return;
}

function codeDefault(url) {
  return `<iframe width="100%" src="${url}" frameborder="0" allowfullscreen></iframe>`;
}

function prepare(config, format, classes) {
  let url, code, classe;

  const isUrl = config.code.trim().startsWith("http");

  if (isUrl) {
    if (format === "auto") {
      url = config.code;
    } else {
      code =
        codeYoutube(config.code) ||
        codeDaily(config.code) ||
        codeVimeo(config.code) ||
        codeDefault(config.code);
    }
  } else {
    code = config.code;
  }

  switch (format) {
    case "16:9":
      classe = classes.responsive169;
      break;
    case "fullheight":
      classe = classes.fullheight;
      break;
    default:
  }
  classe += " " + format;

  return { url, code, classe };
}

const ActionEmbedded = ({ config, data }) => {
  const format = config.format || "16:9";
  const classes = useStyles();

  const iframeResizerOptions = { checkOrigin: false };
  const { url, code, classe } = prepare(config, format, classes);

  return (
    <>
      <Title config={config} data={data} />
      {url && format === "auto" && (
        <ReactIframeResizer
          iframeResizerOptions={iframeResizerOptions}
          key={url}
          src={url}
        />
      )}
      {code && (
        <div className={classe}>
          <InnerHTML html={code} />
        </div>
      )}
    </>
  );
};

ActionEmbedded.classNames = ["qrockme-action-embedded"];

Object.assign(ActionEmbedded, AbstractAction);
export default ActionEmbedded;
