import React, { useRef } from "react";
import { DeleteForever as DeleteIcon } from "@material-ui/icons";

import DrawingCanvas from "./DrawingCanvas";
import { DrawingInputContainer, DrawingClearContainer } from "./DrawingInput.s";

const DrawingInput = ({ onChange, value, name, initialBindedImage }) => {
  const ref = useRef();

  const handleClearClick = () => {
    // was this action binded to a drawing?
    if (initialBindedImage !== null) {
      // if so, we need to restore the initial binded image
      onChange(initialBindedImage);
      return;
    }

    ref.current.clear();
    onChange(null);
  };

  const handleOnDrawingEnd = () => {
    onChange(ref.current.getCanvas().toDataURL());
  };

  const handleOnReadyToLoadData = (width) => {
    ref.current.fromDataURL(value, { width, height: 150 });
  };

  return (
    <>
      <DrawingInputContainer
        id="action-drawing-canvas"
        className="action-drawing-canvas"
      >
        <DrawingCanvas
          ref={ref}
          onDrawingEnd={handleOnDrawingEnd}
          onReadyToLoadData={handleOnReadyToLoadData}
        />
        <input type="hidden" name={name} value={value} />
        <DrawingClearContainer
          className="action-drawing-delete-icon"
          onClick={handleClearClick}
        >
          <DeleteIcon />
        </DrawingClearContainer>
      </DrawingInputContainer>
    </>
  );
};

export default DrawingInput;
