import React from "react";

const defaultProps = ["image"];

export function useActionImage(
  config: Record<string, any>,
  data: Record<string, any>,
  ...props: string[]
): string | void {
  props = props && props.length ? props : defaultProps;
  // Not sure memo work here because of props array
  return React.useMemo(() => {
    for (const prop of props) {
      if (data[prop] && data[prop][0] && data[prop][0].url) {
        return data[prop][0].url;
      } else if (config[prop]) {
        return config[prop];
      }
    }
    return;
  }, [config, data, props]);
}
