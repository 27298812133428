import React from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  progress: {
    width: "100% !important",
    height: "100% !important",
  },
  disableStrokeAnimation: {
    transition: "none",
  },
});

const CircularCountDown = React.memo(
  ({ max, value, invert, label, plural }) => {
    const classes = useStyles();
    const percent = (value / max) * 100 * (invert ? -1 : 1);
    const style = value === 0 || value === max ? { transition: "none" } : {};

    return (
      <Box className="action-countdown-timer">
        <Box position="relative" width="100%" paddingBottom="100%">
          <Box position="absolute" width="100%" height="100%">
            <CircularProgress
              variant="determinate"
              className={clsx(classes.progress)}
              classes={{ circle: "action-countdown-track" }}
              value={100}
            />
          </Box>
          <Box position="absolute" width="100%" height="100%">
            <CircularProgress
              variant="determinate"
              className={clsx(classes.progress)}
              style={style}
              classes={{
                circle: clsx(
                  "action-countdown-circle",
                  classes.disableStrokeAnimation
                ),
              }}
              value={percent}
            />
          </Box>
          <Box
            position="absolute"
            alignItems="center"
            justifyContent="center"
            display="flex"
            width="100%"
            height="100%"
          >
            <div className="action-countdown-value">{value}</div>
          </Box>
        </Box>
        {label && (
          <div className="action-countdown-label">
            {value > 1 ? plural || label : label}
          </div>
        )}
      </Box>
    );
  }
);

export default CircularCountDown;
