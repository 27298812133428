import { LOCATION_CHANGE } from "connected-react-router";
import { takeLatest, takeEvery } from "redux-saga/effects";
import setEntityGuid from "./sagas/setEntityGuid";
import loadEntity from "./sagas/loadEntity";
import startProject from "./sagas/startProject";
import loadCard from "./sagas/loadCard";
import loadCardChoices from "./sagas/loadCardChoices";
import submitCard from "./sagas/submitCard";
import skipCard from "./sagas/skipCard";
import startOrResume from "./sagas/startOrResume";
import reloadProjects from "./sagas/reloadProjects";
import prepareActions from "./sagas/prepareActions";
import checkCardSkip from "./sagas/checkCardSkip";
import setActionState from "./sagas/setActionState";
import submitAction from "./sagas/submitAction";
import updateAction from "./sagas/updateAction";
import prepareUpdatedAction from "./sagas/prepareUpdatedAction";
import clearStates from "./sagas/clearStates";
import gotoCard from "./sagas/gotoCard";
import gotoSearchResult from "./sagas/gotoSearchResult";
import {
  WF_SAGA_LOAD_CARD_DONE,
  WF_SAGA_SET_ENTITY_GUID,
  WF_SAGA_SET_ENTITY_GUID_DONE,
  WF_SAGA_START_OR_RESUME,
  WF_SAGA_LOAD_ENTITY,
  WF_SAGA_START_PROJECT,
  WF_SAGA_LOAD_CARD,
  WF_SAGA_SUBMIT_CARD,
  WF_SAGA_SKIP_CARD,
  WF_SAGA_LOAD_CARDCHOICES,
  WF_SAGA_PREPARE_ACTIONS_DONE,
  WF_SAGA_SET_ACTION_STATE,
  WF_SAGA_SUBMIT_ACTION,
  WF_SAGA_SET_ACTION_STATE_DONE,
  WF_SAGA_UPDATE_ACTION,
  WF_SAGA_UPDATE_ACTION_DONE,
  WF_SAGA_GOTO_CARD,
  WF_SAGA_GOTO_SEARCHRESULT,
} from "./action";

function* onLoadCardDone(...args) {
  yield clearStates(...args);
  yield prepareActions(...args);
}

export default function* rootSaga() {
  yield takeEvery(WF_SAGA_SET_ACTION_STATE_DONE, prepareActions);
  yield takeEvery(WF_SAGA_LOAD_CARD_DONE, onLoadCardDone);
  yield takeEvery(WF_SAGA_PREPARE_ACTIONS_DONE, checkCardSkip);
  yield takeEvery(WF_SAGA_SET_ENTITY_GUID, setEntityGuid);
  yield takeEvery(WF_SAGA_SET_ENTITY_GUID_DONE, clearStates);
  yield takeEvery(WF_SAGA_LOAD_CARD, clearStates);
  yield takeEvery(WF_SAGA_LOAD_ENTITY, loadEntity);
  yield takeEvery(WF_SAGA_START_PROJECT, startProject);
  yield takeLatest(WF_SAGA_LOAD_CARD, loadCard);
  yield takeLatest(WF_SAGA_LOAD_CARDCHOICES, loadCardChoices);
  yield takeEvery(WF_SAGA_SUBMIT_CARD, submitCard);
  yield takeEvery(WF_SAGA_SKIP_CARD, skipCard);
  yield takeEvery(WF_SAGA_START_OR_RESUME, startOrResume);
  yield takeLatest(LOCATION_CHANGE, reloadProjects);
  yield takeEvery(WF_SAGA_SET_ACTION_STATE, setActionState);
  yield takeEvery(WF_SAGA_SUBMIT_ACTION, submitAction);
  yield takeLatest(WF_SAGA_UPDATE_ACTION, updateAction);
  yield takeEvery(WF_SAGA_UPDATE_ACTION_DONE, prepareUpdatedAction);
  yield takeEvery(WF_SAGA_GOTO_CARD, gotoCard);
  yield takeEvery(WF_SAGA_GOTO_SEARCHRESULT, gotoSearchResult);
}
