export default function (action) {
  const screenChildren = (action.children[action.state.screen] || []).filter(
    action => action.type !== "CHECKPOINT"
  );
  if (screenChildren.length === 1 && action.state.screen !== "info") {
    const child = screenChildren[0];
    if (child.component && child.component.shouldSkip) {
      return child.component.shouldSkip(child);
    }
  }
}
