export function isValidURL(url: string) {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

// load an image and convert it to a base64 string
export function convertImageToBase64(imgUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.style.visibility = "hidden";
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("No context");
        return;
      }
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };
    image.src = imgUrl;
  });
}
