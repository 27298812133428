import styled from 'styled-components/macro';
import T from 'prop-types';

const Button = styled.button`
  cursor: pointer;
  &:disabled {
    background: ${props => props.theme.colors.grey};
    color: #565656;
    cursor: default;
  }
  &:focus {
    outline: none;
  }
`;

Button.propTypes = {
  size: T.oneOf(['small', 'medium', 'large']),
};

Button.defaultProps = {
  size: 'medium',
  color: 'white',
};

export default Button;
