import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "common/components/Loader";
import FlipCard from "common/components/FlipCard";
import { CardWrapper, CardsContainer } from "./Projects.s";
import { selectEntity, selectCurrent } from "redux/workflow/selector";
import { push } from "connected-react-router";
import { wfSagaStartProject, wfLoadEntity } from "redux/workflow/action";
import { selectLangChoice } from "redux/app/selector";

import Pwa from "./Pwa";

const Projects = () => {
  const dispatch = useDispatch();
  const { loading, projects } = useSelector(selectEntity);
  const current = useSelector(selectCurrent);
  const currentLangChoice = useSelector(selectLangChoice);
  const currentLangRef = React.useRef(currentLangChoice);
  const [flippedId, setFlippedId] = useState(0);

  React.useEffect(() => {
    if (!current.entity) {
      // Retour home si pas d'entité en cours
      dispatch(push("/"));
    }
  }, [dispatch, current.entity]); // Reload si l'utilisateur choisi une langue

  React.useEffect(() => {
    if (currentLangRef.current !== currentLangChoice && current.entity) {
      currentLangRef.current = currentLangChoice;
      dispatch(wfLoadEntity(current.entity));
    }
  }, [dispatch, current.entity, currentLangChoice]); // Reload si l'utilisateur choisi une langue

  const handleFlipButtonClick = (e, flippedId) => {
    e.preventDefault();
    e.stopPropagation();
    setFlippedId(flippedId);
  };

  const handleCardClick = project => {
    dispatch(wfSagaStartProject(project));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="qrockme-column">
      <Pwa />
      <CardsContainer className={"items" + projects.length}>
        {projects.map(project => (
          <CardWrapper key={project.guid} className="qrockme-card">
            <FlipCard
              data={project}
              isFlipped={flippedId === project.guid}
              onFlipButtonClick={handleFlipButtonClick}
              onCardClick={handleCardClick}
            />
          </CardWrapper>
        ))}
      </CardsContainer>
      <br />
    </div>
  );
};

export default Projects;
