import styled from 'styled-components/macro';
import T from 'prop-types';

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

Card.propTypes = {
  background: T.string,
};

export default Card;
