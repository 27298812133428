export default function (config, state) {
  const proposals = state.proposals.map(p => ({
    ...p,
    correct: p.guid === p.answer,
  }));
  const correctCount = proposals.reduce(
    (prev, cur) => (cur.correct ? prev + 1 : prev),
    0
  );
  const correct = correctCount === Object.values(config.proposals).length;
  return { correct, proposals, correctCount };
}
