import { defineMessages } from "react-intl";

export default defineMessages({
  Require: {
    id: "Validation.Require",
    defaultMessage: "Requis",
  },
  Email: {
    id: "Validation.Email",
    defaultMessage: "Email au format utilisateur@domaine.tld",
  },
});
