import styled from "styled-components/macro";
import loginBackground from "common/loginBackground.svg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  height: 97px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  width: 177px;
  background: url(${loginBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  padding: 0;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 16px 0;
`;

export const FormField = styled.div`
  max-width: 350px;
  margin: 0 auto 16px auto;
  margin-bottom: 16px;
`;

export const LoginError = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  background-color: #ffe1e4;
  font-size: 12px;
  color: #a6a5a5;
`;
