import styled from "styled-components/macro";
import Button from "common/components/Button";

export const StyledButton = styled(Button)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  cursor: pointer;
`;
