import React from "react";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import AddAPhotoIcon from "common/icons/AddAPhoto";
import CancelIcon from "common/icons/Cancel";
import Loader from "common/components/Loader";
import Card from "common/components/Card";
import { useSelector, useDispatch } from "react-redux";
import { picUploadOperations } from "./redux";
import useTheme from "hooks/useTheme";
import T from "prop-types";
import uuidv4 from "uuid/v4";
import ImageBlobReduce from "image-blob-reduce";
import Pica from "pica";
import {
  Container,
  CardWrapper,
  CardContent,
  LoaderWrapper,
  DeleteWrapper,
  PdfFileName,
} from "./MediaUpload.s";
import ImageLoader from "../ImageLoader";

function comparer(otherArray) {
  return current => {
    return (
      otherArray.filter(other => {
        return other === current;
      }).length === 0
    );
  };
}

const resizeTypes = {
  "image/png": "png",
  "image/jpg": "jpeg",
  "image/jpeg": "jpeg",
};

const pica = Pica({ features: ["js", "wasm"] });

function preProcess(file) {
  if (resizeTypes[file.type]) {
    return ImageBlobReduce({ pica })
      .toBlob(file, {
        max: 1000,
        pica: { unsharpAmount: 100, unsharpRadius: 1, unsharpThreshold: 1 },
      })
      .then(blob => new File([blob], file.name));
  }
  return file;
}

const MediaUpload = ({ name, small, onChange, value }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const imagesState = useSelector(state => state.uploadPics.images);
  const images = imagesState[name] || {};

  // Required to init pics when value contain data from model
  React.useEffect(() => {
    if (value) {
      dispatch(picUploadOperations.initUploadedPic(name, value));
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, name]);

  React.useEffect(() => {
    const uploads = [];
    Object.keys(images).forEach(key => {
      if (images[key] && images[key].upload) {
        uploads.push(images[key].upload);
      }
    });
    const oldUpload = value && Array.isArray(value) ? value : [];
    const onlyInA = uploads.filter(comparer(oldUpload));
    const onlyInB = oldUpload.filter(comparer(uploads));

    const result = onlyInA.concat(onlyInB);
    if (result.length > 0 && uploads.length > 0) {
      onChange(uploads);
    }
  }, [images, onChange, value, dispatch]);

  const localImages = React.useMemo(() => {
    const localImages = [];
    Object.keys(images).forEach(key => {
      if (images[key] && images[key].local) {
        localImages.push({ id: key, file: images[key].local });
      }
    });
    return localImages;
  }, [images]);

  React.useEffect(() => {
    return function cleanup() {
      dispatch(picUploadOperations.clearUploadPic(name));
    };
  }, [dispatch, name]);

  const handleAddPhoto = () => {
    document.getElementById(name).click();
  };

  const handleSelectedImage = async e => {
    if (e.target.files) {
      const localImages = await Promise.all(
        [...e.target.files].map(async file => ({
          file: await preProcess(file),
          id: uuidv4(),
        }))
      );
      localImages.map(localImage =>
        dispatch(
          picUploadOperations.upload(name, localImage.file, localImage.id)
        )
      );
    }
  };

  const handleDeleteClick = imageIdToDelete => {
    dispatch(picUploadOperations.removeUploadedPic(name, imageIdToDelete));
    if (Object.keys(images).length === 1) onChange([]);
  };

  const style = {};
  if (localImages.length === 0) {
    style.height = small ? "180px" : "270px";
    style.cursor = "pointer";
  } else {
    style.background = "none";
    style.justifyContent = "left";
  }

  const containerProps = {};
  if (localImages.length === 0) {
    containerProps.role = "button";
    containerProps["aria-label"] = "Prendre une photo";
  }

  return (
    <Container
      className="image-frame"
      style={style}
      onClick={localImages.length ? () => {} : handleAddPhoto}
      {...containerProps}
    >
      {localImages.length === 0 && <AddAPhotoIcon style={{ fontSize: 81 }} />}
      {Boolean(localImages.length) && (
        <CardWrapper
          onClick={handleAddPhoto}
          style={{ cursor: "pointer" }}
          key="first-button"
        >
          <Container className="image-frame">
            <AddAPhotoIcon style={{ fontSize: 21 }} />
          </Container>
        </CardWrapper>
      )}
      {localImages.map(image => {
        const url = images[image.id].upload?.url || "";
        const filename = images[image.id].upload?.filename || "";
        const loading = images[image.id]?.isLoading;
        return (
          <CardWrapper key={image.id}>
            <Container className="image-frame">
              <Card shadow="none" className="image-frame" background="#d8d8d8">
                <CardContent>
                  {loading && (
                    <LoaderWrapper>
                      <Loader fullPage color="white" />
                    </LoaderWrapper>
                  )}
                  {!loading && (
                    <DeleteWrapper>
                      <CancelIcon
                        onClick={() => handleDeleteClick(image.id)}
                        color={theme.colors.white}
                      />
                    </DeleteWrapper>
                  )}
                  {url.toLowerCase().endsWith(".pdf") ? (
                    <>
                      <div>
                        <PdfIcon />
                      </div>
                      <PdfFileName>{filename}</PdfFileName>
                    </>
                  ) : (
                    <ImageLoader
                      className="image-frame"
                      height="100%"
                      width="100%"
                      alt=""
                      src={url}
                      cover
                    />
                  )}
                </CardContent>
              </Card>
            </Container>
          </CardWrapper>
        );
      })}
      <input
        accept="image/*,application/pdf"
        type="file"
        multiple
        id={name}
        key={localImages.length}
        style={{ display: "none" }}
        onChange={handleSelectedImage}
      />
    </Container>
  );
};

MediaUpload.propTypes = {
  small: T.bool,
};

MediaUpload.defaultProps = {
  small: false,
};

export default MediaUpload;
