import React from "react";
import { useSelector } from "react-redux";
import Home from "@material-ui/icons/Home";
import Person from "@material-ui/icons/Person";
import Language from "@material-ui/icons/Language";
import useReactRouter from "use-react-router";
import IconCnx from "common/icons/IconCnx";
import {
  Container,
  GroupContainer,
  ContainerTemp,
  GroupContainerTemp,
  StyledMenuItem,
  NavButton,
  MenuContainer,
} from "./DefaultMenu.s";
import BackButton from "./BackButton";
import { makeStyles } from "@material-ui/core";
import { selectLangAvailable } from "redux/app/selector";
import LangMenu from "./actions/ActionButton/LangMenu";
import { NotificationsIcon } from "common/components/Notifications";
import clsx from "clsx";
import { selectCurrent } from "redux/workflow/selector";

const useStyle = makeStyles(theme => {
  return {
    root: {
      [theme.breakpoints.down("sm") + " and (orientation: landscape)"]: {
        display: "none",
      },
    },
    noClick: {
      cursor: "default !important",
    },
  };
});

const DefaultMenu = () => {
  const { history, location } = useReactRouter();
  const user = useSelector(({ auth }) => auth.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyle();

  const langAvailable = useSelector(selectLangAvailable);
  const current = useSelector(selectCurrent);

  const handleClickLanguageIcon = React.useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const doNav = path => {
    if (location.pathname === "/_") {
      history.replace(path, { resume: true });
    } else {
      history.push(path);
    }
  };

  return (
    <MenuContainer className={classes.root}>
      <Container className="qrockme-bottomnav">
        <GroupContainerTemp>
          <ContainerTemp>
            <BackButton
              className={clsx("bottomnav-item bottomnav-item-back", {
                [classes.noClick]: location.pathname !== "/_",
              })}
            />
          </ContainerTemp>
          <ContainerTemp>
            {current.hasNotification && location.pathname === "/_" && (
              <NavButton
                className={clsx("bottomnav-item bottomnav-item-notifications", {
                  [classes.noClick]: location.pathname !== "/_",
                })}
                onClick={() => {
                  if (location.pathname === "/_") {
                    history.push("/notifications");
                  }
                }}
              >
                <NotificationsIcon />
              </NavButton>
            )}
          </ContainerTemp>
        </GroupContainerTemp>
        <GroupContainer>
          <NavButton
            className="bottomnav-item bottomnav-item-home"
            onClick={() => doNav("/projects")}
          >
            <Home fontSize="inherit" />
          </NavButton>
        </GroupContainer>
        <GroupContainer>
          <StyledMenuItem className="bottomnav-item bottomnav-item-lang">
            {langAvailable && Object.keys(langAvailable).length > 1 && (
              <>
                <Language
                  style={{ cursor: "pointer" }}
                  fontSize="inherit"
                  onClick={handleClickLanguageIcon}
                />
                <LangMenu anchorEl={anchorEl} onClose={() => setAnchorEl()} />
              </>
            )}
          </StyledMenuItem>
          {user.id ? (
            <StyledMenuItem
              className="bottomnav-item bottomnav-item-user"
              onClick={() => doNav("/user")}
            >
              <IconCnx style={{ width: 19, height: 17 }} fontSize="inherit" />
            </StyledMenuItem>
          ) : (
            <StyledMenuItem
              className="bottomnav-item bottomnav-item-user"
              onClick={() => doNav("/account")}
            >
              <Person fontSize="inherit" />
            </StyledMenuItem>
          )}
        </GroupContainer>
      </Container>
    </MenuContainer>
  );
};

export default DefaultMenu;
