import React from "react";
import T from "prop-types";
import Toggle from "react-toggle";

import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";
import {
  FieldError,
  FormInputContainer,
  TextError,
  ToggleContainer,
} from "./FormAdapters.s";
import useFormatMessage from "hooks/useFormatMessage";
import { LabelWrapper } from "./ToggleAdapter.style";

const ToggleAdapter = ({
  input: { onChange, value },
  label,
  meta,
  ...rest
}) => {
  const theme = useTheme();
  const t = useFormatMessage();

  const translate = (value) => {
    if (typeof value === "string") {
      return value;
    }
    return t(value);
  };

  return (
    <FormInputContainer>
      <ToggleContainer>
        <LabelWrapper className="action-toggle-label">
          {rest.image}
          {label}
        </LabelWrapper>
        <Toggle
          checked={!!value}
          icons={false}
          onChange={(e) => onChange(e.target.checked)}
          {...rest}
        />
      </ToggleContainer>
      {meta.touched && meta.error && (
        <FieldError>
          <InfoIcon color={theme.colors.grey} />
          <TextError>{translate(meta.error)}</TextError>
        </FieldError>
      )}
    </FormInputContainer>
  );
};

ToggleAdapter.propTypes = {
  input: T.any,
  meta: T.any,
  onError: T.func,
};

ToggleAdapter.defaultProps = {
  input: null,
  meta: null,
  onError: null,
};

export default ToggleAdapter;
