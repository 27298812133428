import { defineMessages } from "react-intl";

export default defineMessages({
  HomeScreen: {
    id: "PwaDialogIOS.HomeScreen",
    defaultMessage: "+ Sur l'écran d'accueil",
  },
  Text: {
    id: "PwaDialogIOS.Text",
    defaultMessage:
      "Pour un meilleur confort d'utilisation, ajoutez cette application à votre écran d'accueil",
  },
});
