import styled from "styled-components/macro";

export const MenuContainer = styled.div`
  z-index: 1100;
`;

export const Container = styled.div`
  font-size: 22px;
  display: flex;
`;

export const GroupContainer = styled.div`
  width: 33.333%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const GroupContainerTemp = styled.div`
  width: 33.333%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ContainerTemp = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const StyledMenuItem = styled.div`
  display: flex;
  text-decoration: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: initial;
  touch-action: initial;
  cursor: pointer;
`;

export const NavButton = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;
