import ActionMissingWords from "../ActionMissingWords";
import { shuffle } from "lodash";
import extractWords from "./extractWords";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

export default function ({ config, children, data, ...rest }, state, { lang }) {
  if (!state || state.lang !== lang) {
    const words = extractWords(config.html);
    const supwords = (config.supwords || []).map(v => ({
      ...v,
      rawlabel: v.label,
    }));
    state = {
      ...ActionMissingWords.initialState,
      answers: words.reduce(
        (prev, { guid }) => ({ ...prev, [guid]: null }),
        {}
      ),
      words: shuffle([...words, ...supwords]),
      lang,
    };
    if (data?.result_obj?.screen) {
      state = {
        ...state,
        ...data?.result_obj,
        correctCount: Object.values(data?.result_obj.answers).reduce(
          (prev, a) => {
            return prev + (a.correct ? 1 : 0);
          },
          0
        ),
      };
    }
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...ActionMissingWords.initialState,
        words: state.words,
        answers: state.answers,
        trial: state.trial,
        lang: state.lang,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
