import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import humanize from "humanize-duration";
import nl2br from "react-nl2br";
import clsx from "clsx";

export const NotificationsListItem = React.memo(
  ({
    guid,
    title,
    desc,
    dest,
    read,
    time,
    titleImage,
    additionals,
    lang,
    onClick,
  }) => {
    const imageUrl =
      titleImage && process.env.REACT_APP_STATIC_SERVER
        ? titleImage.replace(
            "https://s3.eu-west-3.amazonaws.com/qrock.me/",
            process.env.REACT_APP_STATIC_SERVER
          )
        : titleImage;

    const secondary = React.useMemo(() => {
      return (
        <>
          <div className="notifications-list-desc">{nl2br(desc)}</div>
          {Boolean(additionals) &&
            additionals.map(({ content }, idx) => (
              <div
                className={`notifications-list-add notifications-list-add${
                  idx + 1
                }`}
              >
                {content}
              </div>
            ))}
          <div className="notifications-list-time">
            {humanize(Date.now() - time, {
              language: lang,
              units: ["d", "h", "m", "s"],
              round: true,
              largest: 1,
            })}
          </div>
        </>
      );
    }, [desc, time, lang, additionals]);
    return (
      <ListItem
        className={clsx({
          "notifications-list-item": true,
          "notifications-list-item-unread": !read,
        })}
        key={guid}
        onClick={() => onClick(guid, dest)}
        disableGutters
        button
      >
        <ListItemAvatar className="notifications-list-image">
          {titleImage ? (
            <Avatar alt={title} src={imageUrl} variant="square" />
          ) : (
            <div></div>
          )}
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: "notifications-list-title" }}
          primary={title}
          secondary={secondary}
        />
      </ListItem>
    );
  }
);
