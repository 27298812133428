const AbstractAction = {};

AbstractAction.getClasses = function (action) {
  const classes = this.classNames ? [...this.classNames] : [];
  if (action.cssClass && classes[0]) {
    // Only derivate main classe
    classes.push(`${classes[0]}-${action.menuClass}`);
  }
  return classes;
};

AbstractAction.isActive = function () {
  return false;
};

export default AbstractAction;
