import React from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { withIntl } from "hooks/useFormatMessage";
import CommonIntl from "common/CommonTrad.i";
import Text from "common/components/Text";
import { Box } from "@rebass/grid";
import { useDispatch } from "react-redux";
import * as authActions from "auth/store/actions";
import { utilsLocalStorage } from "utils";
import { AccountCircle, Close as CloseIcon } from "@material-ui/icons";
import {
  Container,
  ImageContainer,
  ButtonContainer,
  StyledButton,
  TagContainer,
  TagsContainer,
  CloseContainer,
} from "./User.s";
import { IconButton } from "@material-ui/core";

function User(props) {
  const dispatch = useDispatch();
  const user = utilsLocalStorage.getUser();
  const tags = user.tags;
  const history = useHistory();
  const location = useLocation();

  const displayTags =
    tags &&
    tags.map(tag => (
      <TagContainer className="qrockme-user-tag" key={tag.id}>
        {tag.name}
      </TagContainer>
    ));

  const handleLogOutClick = () => {
    dispatch(authActions.logoutUser()).then(({ redirect }) => {
      if (redirect) {
        window.location.replace(redirect);
      } else {
        history.replace({ pathname: "/account" });
      }
    });
  };

  return (
    <Container className="qrockme-column">
      <CloseContainer>
        <IconButton
          onClick={() => {
            if (location.state?.resume) {
              history.replace("/_?resume=1");
            } else {
              history.replace("/_");
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </CloseContainer>
      <Box m={3}>
        <ImageContainer className="qrockme-user-icon">
          <AccountCircle style={{ fontSize: 70 }} />
        </ImageContainer>
        <Text mt={32} textAlign="center" color="#565656">
          {user.firstname}&nbsp;{user.lastname}
        </Text>
      </Box>
      <Box mt={32}>
        <TagsContainer>{displayTags}</TagsContainer>
      </Box>
      <ButtonContainer>
        <StyledButton className="qrockme-button" onClick={handleLogOutClick}>
          <FormattedMessage {...CommonIntl.LogOut} />
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
}
export default withIntl(withRouter(User));
