import ActionCheckPoint from "./ActionCheckPoint";
import ActionCountDown from "./ActionCountDown";
import ActionDynList from "./ActionDynList";
import ActionNext from "./ActionNext";
import ActionText from "./ActionText";
import ActionWriteText from "./ActionWriteText";
import ActionTakePics from "./ActionTakePics";
import ActionToggle from "./ActionToggle";
import ActionLink from "./ActionLink";
import ActionMultiMedia from "./ActionMultiMedia";
import ActionEmbedded from "./ActionEmbedded";
import ActionQcmQcu from "./ActionQcmQcu";
import ActionSort from "./ActionSort";
import ActionNumber from "./ActionNumber";
import ActionQText from "./ActionQText";
import ActionMagnetic from "./ActionMagnetic";
import ActionHotspot from "./ActionHotspot";
import ActionMatching from "./ActionMatching";
import ActionMenu from "./ActionMenu";
import ActionMissingWords from "./ActionMissingWords";
import ActionPopup from "./ActionPopup";
import ActionRegister from "./ActionRegister";
import ActionScan from "./ActionScan";
import NullAction from "./NullAction";
import ActionRank from "./ActionRank";
import ActionAccordion from "./ActionAccordion";
import ActionTakeDrawing from "./ActionTakeDrawing";
import ActionSearch from "./ActionSearch";
import ActionButton from "./ActionButton";
import ActionLogin from "./ActionLogin";
import ActionFavorites from "./ActionFavorites";
import ActionHistory from "./ActionHistory";

export const componentsMap = {
  CHECKPOINT: ActionCheckPoint,
  COUNTDOWN: ActionCountDown,
  DYNLIST: ActionDynList,
  NEXT: ActionNext,
  TEXT: ActionText,
  WRITE_TEXT: ActionWriteText,
  TAKE_PICS: ActionTakePics,
  TOGGLE: ActionToggle,
  RANK: ActionRank,
  LINK: ActionLink,
  "MULTI-MEDIA": ActionMultiMedia,
  EMBEDDED: ActionEmbedded,
  QCMQCU: ActionQcmQcu,
  SORT: ActionSort,
  NUMBER: ActionNumber,
  Q_TEXT: ActionQText,
  MAGNETIC: ActionMagnetic,
  HOTSPOT: ActionHotspot,
  MATCHING: ActionMatching,
  MENU: ActionMenu,
  MISSING_WORDS: ActionMissingWords,
  SCAN: ActionScan,
  POPUP: ActionPopup,
  ACCORDION: ActionAccordion,
  SEARCH: ActionSearch,
  REGISTER: ActionRegister,
  UNREGISTER: NullAction,
  TAKE_DRAWING: ActionTakeDrawing,
  BUTTON: ActionButton,
  LOGIN: ActionLogin,
  FAVORITES: ActionFavorites,
  HISTORY: ActionHistory,
};
