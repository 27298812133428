import React from "react";
import { useDispatch } from "react-redux";
import { wfSagaSetEntityGuid, wfSetQuery } from "redux/workflow/action";
import Loader from "common/components/Loader";
import qs from "qs";

export default ({ match, location }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    dispatch(wfSetQuery(query));
    dispatch(wfSagaSetEntityGuid(match.params.entityGUID, false));
  }, [dispatch, match.params.entityGUID, location]);
  return <Loader />;
};
