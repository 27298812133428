import Quiz from "./ActionQcmQcu/Quiz";
import Survey from "./ActionQcmQcu/Survey";

const ActionQcmQcu = {};

ActionQcmQcu.getComponent = action => {
  if (action.config.quiz === false) {
    return Survey;
  } else {
    return Quiz;
  }
};

export default ActionQcmQcu;
