import React from "react";
import { Search as SearchIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";
import { SearchField } from "common/components/Search";
import { useActionImage } from "common/actions";

const ActionSearch = ({ config, data, menuPosition }) => {
  const nav = useHistory();

  const title = config.showTitle
    ? config.title || config.nextCardName
    : undefined;
  const image = useActionImage(config, data, "titleImage");

  const handleClick = React.useCallback(() => {
    nav.push("/search", { ...config, titleImage: undefined });
  }, [nav, config]);

  if (menuPosition === "left" || menuPosition === "right") {
    return (
      <SearchField
        image={image}
        placeholder={config.placeholder}
        onClick={handleClick}
      />
    );
  }

  return (
    <MenuButton
      title={title}
      image={image}
      icon={!image ? SearchIcon : undefined}
      onClick={handleClick}
    />
  );
};

ActionSearch.classNames = ["qrockme-menu-search"];

Object.assign(ActionSearch, AbstractAction);

export default ActionSearch;
