import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../reducer/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import history from "common/history";
import createSagaMiddleware from "redux-saga";
import rootSaga from "store/rootSaga";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "app", "workflow", "prefs"],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk, logger)
  )
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default { store, persistor };
