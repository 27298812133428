import { createSelector } from "reselect";

export const makeSelectPrefsList = () => {
  const selectPrefsList = createSelector(
    [
      state => state.prefs,
      (_, nsKey, listKey) => ({
        nsKey,
        listKey,
      }),
    ],
    (prefs, { nsKey, listKey }) => {
      return prefs[nsKey]?.[listKey] || {};
    }
  );
  return selectPrefsList;
};

export const makeSelectPrefsValue = () => {
  const selectPrefsList = createSelector(
    [
      state => state.prefs,
      (_, nsKey, listKey) => ({
        nsKey,
        listKey,
      }),
    ],
    (prefs, { nsKey, listKey }) => {
      return prefs[nsKey]?.[listKey];
    }
  );
  return selectPrefsList;
};
