import React from "react";
import { useDispatch } from "react-redux";
import ScreenMain from "./ActionNumber/ScreenMain";
import ScreenResults from "./ActionNumber/ScreenResults";
import prepareAction from "./ActionNumber/prepareAction";
import onSubmit from "./ActionNumber/onSubmit";
import quizShouldSkip from "./Common/quizShouldSkip";
import {
  wfSagaSubmitAction,
  WF_SAGA_SET_ENTITY_GUID_DONE,
} from "redux/workflow/action";
import { Field, useForm } from "react-final-form";
import { required } from "common/components/rules";
import AbstractAction from "./AbstractAction";

const ActionNumber = ({ action, state, config, ...rest }) => {
  const dispatch = useDispatch();
  const form = useForm();

  const handleSubmit = () => {
    dispatch(wfSagaSubmitAction(action.guid));
  };

  const { screen, trial, answer } = state;
  React.useEffect(() => {
    form.change(`action-${action.id}`, { screen, trial, answer });
  }, [screen, trial, answer, action.id, form]);

  const Screen = screen === "info" ? ScreenMain : ScreenResults;

  const handleValidate = React.useCallback(
    v => {
      if (config.required && (screen === "info" || screen === "retry")) {
        return required();
      }
    },
    [config.required, screen]
  );

  return (
    <>
      <Screen
        action={action}
        onSubmit={handleSubmit}
        state={state}
        config={config}
        {...rest}
      />
      <Field
        name={`action-${action.id}`}
        component="input"
        type="hidden"
        validate={handleValidate}
      />
    </>
  );
};

ActionNumber.initialState = {
  answer: "",
  trial: 0,
  screen: "info",
  clearOn: WF_SAGA_SET_ENTITY_GUID_DONE,
  actionType: "NUMBER",
};

ActionNumber.prepareAction = prepareAction;
ActionNumber.onSubmit = onSubmit;
ActionNumber.shouldSkip = quizShouldSkip;
ActionNumber.skipValue = ({ state: { screen, trial, answer } }) => ({
  screen,
  trial,
  answer,
});
ActionNumber.classNames = ["qrockme-action-number"];

Object.assign(ActionNumber, AbstractAction);
export default ActionNumber;
