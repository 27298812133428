import React from "react";
import { makeStyles, Popover } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setLangChoice, setLangCurrent } from "redux/app/action";
import clsx from "clsx";
import { LangList } from "common/actions";

const useStyles = makeStyles({
  root: { overflow: "visible" },
  button: { padding: "4px 8px" },
  arrow: {
    position: "absolute",
    marginLeft: -6,
    width: 12,
    height: 6,
    overflow: "hidden",
    "&:after": {
      content: '""',
      position: "absolute",
      width: 6,
      height: 6,
      left: "50%",
      transform: "translate(-50%,-50%) rotate(45deg)",
    },
  },
  arrowUp: {
    transform: "rotate(180deg)",
    top: -6,
  },
});

const LangMenu = ({ anchorEl, direction = "up", onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSelect = React.useCallback(
    lang => {
      onClose();
      dispatch(setLangChoice(lang));
      dispatch(setLangCurrent(lang));
    },
    [onClose, dispatch]
  );

  const { anchorOrigin, transformOrigin } = React.useMemo(() => {
    return direction === "up"
      ? {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      : {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        };
  }, [direction]);

  const open = Boolean(anchorEl);
  return (
    <Popover
      classes={{ paper: clsx(classes.root, "lang-menu") }}
      elevation={0}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disablePortal
      disableAutoFocus
    >
      {direction === "down" && (
        <i
          className={clsx(classes.arrow, classes.arrowUp, "lang-menu-arrow")}
        />
      )}
      <LangList onSelect={handleSelect} />
      {direction === "up" && (
        <i className={clsx(classes.arrow, "lang-menu-arrow")} />
      )}
    </Popover>
  );
};

export default LangMenu;
