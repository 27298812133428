import React from "react";
import { Drawer, IconButton, Button, makeStyles } from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons";
import ActionsRenderer from "./actions/ActionsRenderer";
import { useMenu } from "./useMenu";
import Image from "common/components/Image";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  breakpoint: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  breakpointSecondary: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  button: {
    padding: 0,
    minWidth: "auto",
    flexShrink: 0,
    flexGrow: 0,
    "& .MuiButton-label": {
      height: "100%",
    },
  },
}));

const DrawerMenu = ({ position }) => {
  const classes = useStyles();
  const {
    actions,
    hasOtherSide,
    className,
    titleImage,
    currentOpenMenu,
    openMenu,
    closeMenu,
  } = useMenu(position);

  const buttonClasses = className
    .split(" ")
    .map(name => `${name}drawer-button`);
  const breakpointClass = clsx({
    [classes.breakpoint]:
      position === "left" || (position === "right" && !hasOtherSide),
    [classes.breakpointSecondary]: position === "right" && hasOtherSide,
  });

  const button = React.useMemo(() => {
    if (!titleImage) {
      return (
        <IconButton
          className={breakpointClass + " " + buttonClasses.join(" ")}
          onClick={() => openMenu(position)}
        >
          <MenuIcon />
        </IconButton>
      );
    }
    return (
      <Button
        className={clsx(breakpointClass, classes.button, buttonClasses)}
        onClick={() => openMenu(position)}
      >
        <Image src={titleImage} alt="" responsive />
      </Button>
    );
  }, [
    titleImage,
    buttonClasses,
    breakpointClass,
    classes.button,
    position,
    openMenu,
  ]);

  if (!actions) {
    if (hasOtherSide) {
      return (
        <div
          className={clsx(classes.breakpoint, "menudrawer-placeholder")}
        ></div>
      );
    }
    return null;
  }

  return (
    <>
      {button}
      <Drawer
        anchor={position}
        open={currentOpenMenu === position}
        onClose={() => closeMenu()}
        className={breakpointClass}
        classes={{
          paper: `qrockme-menu-vertical qrockme-menu-${position} qrockme-menu-drawer ${className}`,
        }}
      >
        <div
          className={`qrockme-menu-vertical-close qrockme-menu-${position}-close`}
        >
          <IconButton onClick={() => openMenu()} size="small" color="inherit">
            <CloseIcon />
          </IconButton>
        </div>
        <ActionsRenderer actions={actions} menuPosition={position} />
      </Drawer>
    </>
  );
};

export default DrawerMenu;
