import { request as apiRequest } from "common/utils/authRequest";

export function fetchEntity(guid, scan, token, lang) {
  let endpoint = `processes/${guid}`;
  if (scan) {
    endpoint += "?scan=1";
  }
  return apiRequest({
    endpoint,
    method: "POST",
    token,
    lang,
  });
}

export function fetchProject(entityGuid, projectGuid, token, lang) {
  return apiRequest({
    endpoint: `processes/${entityGuid}/${projectGuid}`,
    method: "POST",
    token,
    lang,
  });
}

export function fetchCard(
  entityGuid,
  projectGuid,
  cardGuid,
  query,
  session,
  token,
  lang
) {
  return apiRequest({
    endpoint: `processes/${entityGuid}/${projectGuid}/${cardGuid}`,
    method: "POST",
    token,
    lang,
    query,
    data: { session },
  });
}

export function postCardData(
  entityGuid,
  projectGuid,
  cardGuid,
  parentCardGuid,
  sessionProject,
  sessionCard,
  data,
  query,
  session,
  token,
  lang
) {
  return apiRequest({
    endpoint: "useractions",
    method: "POST",
    token,
    lang,
    data: JSON.stringify({
      actions: data,
      entity_guid: entityGuid,
      project_guid: projectGuid,
      card_guid: cardGuid,
      card_guid_parent: parentCardGuid,
      session_project: sessionProject,
      session_card: sessionCard,
      session,
      query,
    }),
  });
}

export function updateActionData(
  entityGuid,
  projectGuid,
  cardGuid,
  actionGuid,
  data,
  session,
  token,
  lang
) {
  return apiRequest({
    endpoint: `processes/${entityGuid}/${projectGuid}/${cardGuid}/${actionGuid}`,
    method: "POST",
    token,
    lang,
    data: {
      session,
      actions: { [actionGuid]: data },
    },
  });
}

export function fetchSearchIndex(entityGuid, projectGuid, token, lang) {
  return apiRequest({
    endpoint: `search/${entityGuid}/${projectGuid}`,
    method: "GET",
    token,
    lang,
  });
}

export function ssoConnect(guid, secret, token, lang) {
  return apiRequest({
    endpoint: `auth/sso/connect`,
    method: "POST",
    lang,
    data: { guid, secret },
  });
}
