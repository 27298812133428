import React from "react";
import { ContentRenders } from "./Actions.s";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const ActionText = ({ config, data }) => {
  let html = config.text || config.html || "";
  if (html === "<p><br></p>") {
    html = "";
  } else {
    const proxyUrl = process.env.REACT_APP_STATIC_SERVER;
    html = html
      .replace(
        /https:\/\/s3\.eu-west-3\.amazonaws\.com\/qrock\.me\//gm,
        proxyUrl
      )
      .replace(/ ([!:;?.])/gm, "&nbsp;$1"); // Prevent symbol to go on a new line alone
  }

  return (
    <>
      <Title config={config} data={data} />
      {html && (
        <ContentRenders
          className="action-content"
          dangerouslySetInnerHTML={{ __html: `${html}` }}
        />
      )}
    </>
  );
};

ActionText.classNames = ["qrockme-action-text"];

Object.assign(ActionText, AbstractAction);
export default ActionText;
