import React from "react";
import { Field } from "react-final-form";

import { required, noValidation } from "common/components/rules";
import { FormField } from "./Actions.s";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";
import DrawingInputAdapter from "common/components/formAdapters/DrawingInputAdapter";

const ActionTakeDrawing = ({ action, config, data, actionCount }) => {
  return (
    <FormField key={`action-${action.id}`}>
      <Title config={config} data={data} />
      <Field
        className="action-field"
        name={`action-${action.id}`}
        component={DrawingInputAdapter}
        validate={config.required ? required : noValidation}
        initialValue={data.result}
        binded={!!data.binding}
      />
    </FormField>
  );
};

ActionTakeDrawing.classNames = ["qrockme-action-drawing"];

Object.assign(ActionTakeDrawing, AbstractAction);
export default ActionTakeDrawing;
