import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    background: "#FFFFFF",
    border: "3px solid #1670FF",
    borderRadius: "50%",
    transition: "background-color 0.5s ease, color 0.5s ease",
  },
  dot: {
    width: 8,
    height: 8,
    background: "#1670FF",
    borderRadius: "50%",
  },
});

const Spot = React.forwardRef(({ className, style }, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      className={clsx(classes.root, "action-spot", className)}
      style={style}
    >
      <div className={clsx(classes.dot, "action-spot-dot")}></div>
    </div>
  );
});

export default Spot;
