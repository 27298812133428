import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { wfSagaSetEntityGuid } from "redux/workflow/action";
import CatchQrCode from "./CatchQrCode";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  back: {
    textAlign: "right",
  },
  scan: {
    margin: 16,
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
});

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleDecode = React.useCallback(
    data => {
      const entityGUID = data.substr(data.lastIndexOf("/") + 1);
      dispatch(wfSagaSetEntityGuid(entityGUID));
    },
    [dispatch]
  );

  return (
    <>
      <div className={classes.back}>
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.scan}>
        <CatchQrCode onDecode={handleDecode} />
      </div>
    </>
  );
};
