import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { withIntl } from "hooks/useFormatMessage";
import { useHistory, useLocation } from "react-router";
import { FavoritesList } from "common/components/Favorites";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
  },
});

function Favorites() {
  const { state } = useLocation();

  const nav = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "favorites-header")}>
        <div className={classes.title}>Liste des favoris</div>
        <IconButton size="small" onClick={() => nav.goBack()}>
          <CloseIcon />
        </IconButton>
      </div>
      <FavoritesList category={state.showCategory} sort={state.sort} />
    </div>
  );
}

export default withIntl(Favorites);
