import React from "react";
import Action from "./Action";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Action as ActionType } from "types";
import { scroller, Events } from "react-scroll";
import { useLocation } from "react-router";

const useStyles = makeStyles({
  groupLabel: {
    textAlign: "left",
    width: "100%",
  },
});

interface Props {
  actions: ActionType[];
}

interface LocationState {
  scrollTo?: string | number;
}

const ActionsRenderer: React.FC<Props> = ({ actions, ...others }) => {
  const { state } = useLocation<LocationState>();

  React.useEffect(() => {
    if (state?.scrollTo) {
      Events.scrollEvent.register("end", to => {
        const container = document.getElementById("qrockme-scene");
        const scrollPosition = container
          ? Math.abs(
              container.scrollHeight -
                container.clientHeight -
                container.scrollTop
            )
          : 0;
        if (to && scrollPosition > 1) {
          scroller.scrollTo(to, {
            containerId: "qrockme-scene",
            duration: 50,
            offset: 0,
            delay: 50,
            smooth: "easeInOutQuart",
          });
        }
      });
      scroller.scrollTo(`action-title-${state?.scrollTo}`, {
        containerId: "qrockme-scene",
        duration: 300,
        offset: 0,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      return () => {
        Events.scrollEvent.remove("end");
      };
    }
  }, [state?.scrollTo]);

  const classes = useStyles();
  let prevGroup: string | undefined;
  return (
    <>
      {actions.map(action => {
        if (!action.component) {
          return null;
        }
        let groupLabel;
        if (prevGroup !== action.data.group) {
          prevGroup = groupLabel = action.data.group;
        }
        return (
          <React.Fragment key={action.key}>
            {groupLabel && (
              <div className={clsx(classes.groupLabel, "action-group-label")}>
                {groupLabel}
              </div>
            )}
            <div
              className={action.type === "POPUP" ? undefined : action.className}
            >
              <Action
                {...others}
                {...action}
                action={action.action}
                config={action.config}
                component={action.component}
                actionCount={actions.length}
              />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ActionsRenderer;
