import React from "react";
import { Action as ActionType } from "types";

interface Props {
  action: ActionType;
  component: any;
  config: { [k: string]: any };
}

const Action: React.FC<Props> = ({ action, config, component, ...others }) => {
  const ComponentProp = component;
  return (
    <ComponentProp
      action={action}
      config={config}
      data={action.data}
      {...others}
    />
  );
};

export default Action;
