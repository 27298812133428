import React from "react";
import isVideo from "is-video";
import isImage from "is-image";
import isAudio from "libs/isAudio";
import { makeStyles } from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import clsx from "clsx";
import nl2br from "react-nl2br";
import ImageLoader from "common/components/ImageLoader";

const useStyles = makeStyles({
  responsive: {
    maxWidth: "100%",
    height: "auto",
  },
  items: {
    textAlign: "left",
  },
  item: {
    display: "flex",
    "& .action-media-ligne-media": {
      flexShrink: 0,
    },
    "&. .action-media-ligne-text": {
      flexGrow: 1,
    },
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
});

const MediasLine = ({ config, medias }) => {
  const classes = useStyles();
  if (!medias.length) return null;
  return (
    <div className={clsx(classes.items, "action-media-ligne-items")}>
      {medias.map((media, index) => {
        let { title, filename, desc, additionals } = media;
        const url = process.env.REACT_APP_STATIC_SERVER
          ? media.url.replace(
              "https://s3.eu-west-3.amazonaws.com/qrock.me/",
              process.env.REACT_APP_STATIC_SERVER
            )
          : media.url;
        let type;
        let ContainerTag = "div";
        const containerProps = {};
        if (isVideo(url)) {
          type = "video";
        } else if (isImage(url)) {
          type = "image";
        } else if (isAudio(url)) {
          type = "audio";
        } else if (url.toLowerCase().endsWith(".pdf")) {
          type = "pdf";
          title = title || filename;
          ContainerTag = "a";
          containerProps.href = url;
          containerProps.target = "_blank";
          containerProps.rel = "noopener noreferrer";
        }
        return (
          <ContainerTag
            key={url}
            className={clsx(
              { [classes.link]: type === "pdf" },
              classes.item,
              "action-media-ligne-item",
              `action-media-ligne-${type}-item`
            )}
            {...containerProps}
          >
            <div className="action-media-ligne-media">
              {type === "image" && (
                <ImageLoader
                  className={classes.responsive}
                  alt={title || config.title + " #" + (index + 1)}
                  src={url}
                />
              )}
              {type === "video" && (
                <video
                  className={classes.responsive}
                  title={title + " #" + (index + 1)}
                  controls
                >
                  <source
                    src={url}
                    style={{ display: "block", lineHeight: "0" }}
                  />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              )}
              {type === "audio" && (
                <audio title={title + " #" + (index + 1)} controls>
                  <source src={url} />
                  Sorry, your browser doesn't support embedded audios.
                </audio>
              )}
              {type === "pdf" && (
                <DownloadIcon fontSize="inherit" color="inherit" />
              )}
            </div>
            <div className="action-media-ligne-text">
              <div>
                <span className="action-media-ligne-title">{nl2br(title)}</span>
                <span className="action-media-ligne-desc">{nl2br(desc)}</span>
                {additionals &&
                  additionals.map(({ content }, idx) => {
                    const classname =
                      "action-media-add action-media-add-" +
                      (idx + 1);
                    return <div className={classname}>{nl2br(content)}</div>;
                  })}
              </div>
            </div>
          </ContainerTag>
        );
      })}
    </div>
  );
};

export default MediasLine;
