import React from "react";
import ActionsRenderer from "../ActionsRenderer";
import { isOnlyCheckPoints } from "./utils";

const SubScreens = ({ actions, screens, parent, onChange }) => {
  if (screens === undefined) {
    screens = [];
  } else if (!Array.isArray(screens)) {
    screens = [screens];
  }

  const count = React.useMemo(() => {
    return screens.reduce((prev, key) => prev + (actions[key] || []).length, 0);
  }, [actions, screens]);

  React.useEffect(() => {
    if (onChange) {
      onChange(count);
    }
  }, [count, onChange]);

  if (!count) return null;
  return screens.map(key => {
    const screenActions = actions[key] || [];
    if (!screenActions.length) return null;
    if (isOnlyCheckPoints(screenActions)) {
      return <ActionsRenderer key={key} actions={screenActions} />;
    }
    const classes = ["action-screen"];
    if (["success", "fail", "retry"].includes(key)) {
      classes.push("action-screen-results");
    }
    classes.push(`action-screen-${key}`);
    return (
      <div className={classes.join(" ")} key={key}>
        <ActionsRenderer actions={screenActions} parent={parent} />
      </div>
    );
  });
};

export default React.memo(SubScreens);
