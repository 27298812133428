import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrent } from "redux/workflow/selector";
import { makeSelectPrefsList } from "redux/prefs/selector";
import { prefsAdd, prefsRemove } from "redux/prefs/action";

export function useFavorites(category) {
  const { project, card } = useSelector(selectCurrent);
  const selectPrefsList = React.useMemo(makeSelectPrefsList, []);
  const favorites = useSelector(state =>
    selectPrefsList(state, project, `favorites_${category}`)
  );
  const dispatch = useDispatch();

  const add = React.useCallback(
    cardGuid => {
      cardGuid = cardGuid || card;
      dispatch(prefsAdd(project, `favorites_${category}`, cardGuid));
    },
    [card, category, project, dispatch]
  );
  const remove = React.useCallback(
    cardGuid => {
      cardGuid = cardGuid || card;
      dispatch(prefsRemove(project, `favorites_${category}`, cardGuid));
    },
    [card, category, project, dispatch]
  );

  const isCurrentCardFavorite = Boolean(favorites[card]);

  return { favorites, isCurrentCardFavorite, add, remove };
}
