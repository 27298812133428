import { put, select } from "redux-saga/effects";
import {
  WF_SAGA_SET_ACTION_STATE_DONE,
  WF_SAGA_SET_ACTION_STATE_FAILED,
} from "../action";
import { selectCurrent } from "../selector";

export default function* setActionState({ payload: { guid, state } }) {
  try {
    const { project: projectGuid, card: cardGuid } = yield select(
      selectCurrent
    );
    yield put({
      type: WF_SAGA_SET_ACTION_STATE_DONE,
      payload: {
        projectGuid,
        cardGuid,
        actionGuid: guid,
        state,
      },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_SET_ACTION_STATE_FAILED, message: e.message });
  }
}
