import React from "react";
import axios from "axios";
import { makeStyles, Button as MuiButton } from "@material-ui/core";
import { Info as LoginErrorIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { selectUser } from "redux/auth/selector";
import { setUserData } from "auth/store/actions/user.actions";
import { LOGIN_SUCCESS, LOGIN_ERROR } from "auth/store/actions/login.actions";
import { setToken } from "redux/app/action";
import IconCnx from "common/icons/IconCnx";
import Title from "./Common/Title";
import AbstractAction from "./AbstractAction";
import loginBackground from "common/loginBackground.svg";
import useFormatMessage from "hooks/useFormatMessage";
import ConnexionIntl from "scenes/login/Login.i";
import Lock from "common/icons/Lock";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import clsx from "clsx";
import Button from "common/components/Button";
import jwtService from "services/jwtService";
import { wfSagaLoadCard, wfSetLoading } from "redux/workflow/action";
import { selectCurrent, selectEntity } from "redux/workflow/selector";
import SubScreens from "./Common/SubScreens";

const useStyles = makeStyles({
  form: {
    marginTop: "1em",
  },
  input: {
    "&::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "& input": {
      padding: 0,
    },
    margin: "0.5em 0",
  },
  forgotContainer: {
    textAlign: "right",
  },
  removeFieldsetBorders: {
    "& fieldset": {
      border: "none",
    },
  },
  icon: {
    height: 97,
    margin: "1em auto 1em auto",
    width: 177,
    background: `url(${loginBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FF3041",
  },
  link: {
    fontSize: 13,
    color: "#919090",
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  hint: {
    fontSize: 13,
    color: "#919090",
    marginBottom: 12,
  },
  error: {
    color: "red",
  },
});

const ActionLogin = ({ config, data, children }) => {
  const user = useSelector(selectUser);
  const { card: currentCard } = useSelector(selectCurrent);
  const { company } = useSelector(selectEntity);
  const [sso, setSso] = React.useState(
    company.sso === 2 || config.mode === "sso"
  );
  const [ssoError, setSsoError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState();
  const dispatch = useDispatch();
  const t = useFormatMessage();
  const connected = user && user.is_visitor === 0;

  const classes = useStyles();

  const handleEmailChange = React.useCallback(e => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = React.useCallback(e => {
    setPassword(e.target.value);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setSsoError(false);
    if (sso) {
      axios
        .post(`${process.env.REACT_APP_ROOT_API}auth/sso`, {
          email: email,
          target: "card",
        })
        .then(response => {
          window.location.replace(response.data.redirect);
        })
        .catch(() => {
          setSsoError(true);
        });
      return;
    }
    setError();
    jwtService
      .signInWithEmailAndPassword(email.trim(), password.trim())
      .then(({ user, token }) => {
        dispatch(setToken(token));
        dispatch(setUserData(user));
        dispatch({
          type: LOGIN_SUCCESS,
        });
        dispatch(wfSetLoading(true));
        dispatch(wfSagaLoadCard(currentCard));
      })
      .catch(error => {
        setError(
          error.response && error.response.data.error === "E_LOCKED"
            ? ConnexionIntl.AccountLocked
            : ConnexionIntl.LoginFailed
        );
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  }, [email, password, sso, currentCard, dispatch]);

  const disableSubmit = React.useMemo(() => {
    return sso
      ? email.trim().length === 0
      : email.trim().length === 0 || password.trim().length === 0;
  }, [sso, email, password]);

  const hasSubScreenActions = (children["connected"] || []).length > 0;

  return (
    <>
      {connected && (
        <>
          {!hasSubScreenActions && (
            <div className={clsx(classes.icon, "action-login-icon")}>
              <IconCnx style={{ fontSize: 70 }} />
            </div>
          )}
          {hasSubScreenActions && (
            <SubScreens actions={children} screens="connected" />
          )}
        </>
      )}
      {!connected && (
        <>
          <Title config={config} data={data} />
          <div className={clsx(classes.form, "action-login-form")}>
            <div className={clsx(classes.icon, "action-login-icon")}>
              <Lock style={{ fontSize: 70 }} />
            </div>
            {error && (
              <div className="action-login-error">
                <LoginErrorIcon style={{ marginRight: 8 }} />
                {t(error)}
              </div>
            )}
            <MaskedInput
              mask={emailMask}
              autoComplete="username"
              onChange={handleEmailChange}
              className={clsx("qrockme-input", classes.input)}
              placeholder={t(ConnexionIntl.EmailPlaceholder)}
            />
            {!sso && (
              <>
                <input
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  className={clsx("qrockme-input", classes.input)}
                  placeholder={t(ConnexionIntl.PwdPlaceholder)}
                />
                <div className={classes.forgotContainer}>
                  <MuiButton
                    className={classes.link}
                    variant="text"
                    href={`${process.env.REACT_APP_ROOT_ADMIN}auth/forgot-password?back=1`}
                  >
                    <FormattedMessage {...ConnexionIntl.Forgot} />
                  </MuiButton>
                </div>
              </>
            )}
            {sso && (
              <div
                className={clsx(classes.hint, "action-sso-hint", {
                  [classes.error]: ssoError,
                  "action-sso-error": ssoError,
                })}
              >
                {ssoError ? (
                  <FormattedMessage {...ConnexionIntl.AuthSsoError} />
                ) : (
                  <FormattedMessage {...ConnexionIntl.AuthSsoHint} />
                )}
              </div>
            )}
            <div>
              <Button
                type="button"
                className="qrockme-button"
                disabled={disableSubmit}
                onClick={handleSubmit}
              >
                <FormattedMessage {...ConnexionIntl.Connexion} />
              </Button>
              {company.sso === 1 && (
                <div>
                  <MuiButton
                    className={classes.link}
                    variant="text"
                    onClick={() => setSso(s => !s)}
                  >
                    {sso ? (
                      <FormattedMessage {...ConnexionIntl.AuthPasswordLink} />
                    ) : (
                      <FormattedMessage {...ConnexionIntl.AuthSsoLink} />
                    )}
                  </MuiButton>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

ActionLogin.classNames = ["qrockme-action-login"];

Object.assign(ActionLogin, AbstractAction);

ActionLogin.shouldSkip = function (action, { user }) {
  if (!user.id) return;
  const screenChildren = (action.children["connected"] || []).filter(
    action => action.type !== "CHECKPOINT"
  );
  if (screenChildren.length === 1) {
    const child = screenChildren[0];
    if (child.component && child.component.shouldSkip) {
      return child.component.shouldSkip(child);
    }
  }
};

export default ActionLogin;
