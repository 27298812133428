import React from "react";
import {
  Home as HomeIcon,
  Language as LangIcon,
  PlayArrow as ArrowIcon,
} from "@material-ui/icons";
import ScanIcon from "common/icons/QrCode";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";
import { animateScroll } from "react-scroll";
import { setLangChoice, setLangCurrent } from "redux/app/action";
import { NotificationsIcon } from "common/components/Notifications";

const BackIcon = (props: any) => (
  <ArrowIcon {...props} style={{ transform: "rotate(180deg)" }} />
);
const UpIcon = (props: any) => (
  <ArrowIcon
    {...props}
    style={{ transform: "rotate(-90deg) translate(-1px)" }}
  />
);
const DownIcon = (props: any) => (
  <ArrowIcon
    {...props}
    style={{ transform: "rotate(90deg) translate(-1px)" }}
  />
);

type Types =
  | "home"
  | "lang"
  | "scan"
  | "notifications"
  | "back"
  | "up"
  | "down";
type Handler = (payload?: any) => void;
type Return = { icon: React.ElementType; handler: Handler };

export function useActionButton(
  type: Types,
  fullScroll: boolean = false
): Return {
  const nav = useHistory();
  const dispatch = useDispatch();

  const homeHandler = React.useCallback(() => nav.push("/projects"), [nav]);
  const langHandler = React.useCallback(
    lang => {
      dispatch(setLangChoice(lang));
      dispatch(setLangCurrent(lang));
    },
    [dispatch]
  );
  const scanHandler = React.useCallback(() => nav.push("/scan"), [nav]);
  const notificationsHandler = React.useCallback(
    () => nav.push("/notifications"),
    [nav]
  );
  const backHandler = React.useCallback(() => dispatch(goBack()), [dispatch]);
  const upHandler = React.useCallback(() => {
    if (fullScroll) {
      animateScroll.scrollToTop({
        containerId: "qrockme-scene",
      });
    } else {
      const container = document.getElementById("qrockme-scene");
      if (container) {
        animateScroll.scrollMore(container.clientHeight * 0.9 * -1, {
          containerId: "qrockme-scene",
        });
      }
    }
  }, [fullScroll]);
  const downHandler = React.useCallback(() => {
    if (fullScroll) {
      animateScroll.scrollToBottom({
        containerId: "qrockme-scene",
      });
    } else {
      const container = document.getElementById("qrockme-scene");
      if (container) {
        animateScroll.scrollMore(container.clientHeight * 0.9, {
          containerId: "qrockme-scene",
        });
      }
    }
  }, [fullScroll]);

  const data: Record<Types, Return> = {
    home: { icon: HomeIcon, handler: homeHandler },
    lang: { icon: LangIcon, handler: langHandler },
    scan: { icon: ScanIcon, handler: scanHandler },
    notifications: {
      icon: NotificationsIcon,
      handler: notificationsHandler,
    },
    back: { icon: BackIcon, handler: backHandler },
    up: { icon: UpIcon, handler: upHandler },
    down: { icon: DownIcon, handler: downHandler },
  };

  return data[type];
}
