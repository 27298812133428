import React from "react";
import T from "prop-types";
import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";
import MediaUpload from "common/components/MediaUpload";
import {
  FieldError,
  FormInputContainer,
  TextError,
  PicsContainer,
} from "./FormAdapters.s";

const PicsAdapter = ({
  input: { onChange, value, name },
  label,
  meta,
  small,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <FormInputContainer>
      {meta.touched && meta.error && (
        <FieldError>
          <InfoIcon color={theme.colors.grey} />
          <TextError>{meta.error}</TextError>
        </FieldError>
      )}
      <PicsContainer {...rest}>
        <MediaUpload
          small={small}
          name={name}
          value={value}
          onChange={onChange}
        />
      </PicsContainer>
    </FormInputContainer>
  );
};

PicsAdapter.propTypes = {
  input: T.any,
  meta: T.any,
};

PicsAdapter.defaultProps = {
  input: null,
  meta: null,
};

export default PicsAdapter;
