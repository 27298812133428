import { push } from "connected-react-router";
import { put, select } from "redux-saga/effects";
import { setTitle } from "redux/app/action";
import { selectWorkflow, selectCurrent } from "redux/workflow/selector";
import {
  wfSagaSkipCard,
  wfSetLoading,
  WF_SAGA_CHECK_CARD_SKIP_DONE,
  WF_SAGA_CHECK_CARD_SKIP_FAILED,
} from "redux/workflow/action";
import { selectUser } from "redux/auth/selector";
import { prefsAdd } from "redux/prefs/action";

const challenges = [
  "QCMQCU",
  "SORT",
  "MAGNETIC",
  "HOTSPOT",
  "MATCHING",
  "MISSING_WORDS",
  "SCAN",
  "NUMBER",
  "Q_TEXT",
];

export default function* checkCardSkip() {
  try {
    const user = yield select(selectUser);
    const { skippedCount, actions } = yield select(selectWorkflow);
    const { project: projectGuid, card: cardGuid } = yield select(
      selectCurrent
    );
    let skip;
    const actionsWithoutCheckpoints = actions.filter(
      action => action.type !== "CHECKPOINT"
    );
    if (actionsWithoutCheckpoints.length === 0) {
      // Si aucune action on passe
      skip = { history: false };
    } else if (actionsWithoutCheckpoints.length === 1) {
      // On verifie si cette action accepte d'être passée si elle est seule
      const action = actionsWithoutCheckpoints[0];
      if (action.component && action.component.shouldSkip) {
        skip = action.component.shouldSkip(action, { user });
        if (skip) {
          skip.actionGuid = action.guid;
        }
      }
    } else if (
      actionsWithoutCheckpoints.some(action => {
        if (challenges.includes(action.type) && action.config.quiz !== false) {
          skip = action.component.shouldSkip(action, { user });
          if (skip) {
            skip.actionGuid = action.guid;
            return true;
          }
        }
        return false;
      })
    ) {
    }
    if (skip) {
      if (skippedCount > 5) {
        // Probablement une boucle un infinie on retourne sur home
        yield put(wfSetLoading());
        yield put(setTitle(""));
        yield put(push("/"));
        return;
      } else {
        const values = actions.reduce((prev, cur) => {
          const skipValue = cur.component?.skipValue
            ? cur.component?.skipValue(cur)
            : undefined;
          if (skipValue !== undefined) {
            prev.push({
              action_id: cur.action.id,
              value: JSON.stringify(skipValue),
            });
          }
          if (cur.children && typeof cur.children === "object") {
            Object.values(cur.children)
              .flat()
              .forEach(action => {
                const skipValue = action.component?.skipValue
                  ? action.component?.skipValue(cur)
                  : undefined;
                if (skipValue !== undefined) {
                  prev.push({
                    action_id: action.action.id,
                    value: JSON.stringify(skipValue),
                  });
                }
              });
          }
          return prev;
        }, []);
        yield put(wfSagaSkipCard({ ...skip, projectGuid, cardGuid, values }));
      }
    } else {
      yield put(prefsAdd(projectGuid, `history`, cardGuid));
    }
    yield put({
      type: WF_SAGA_CHECK_CARD_SKIP_DONE,
      payload: { skip },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_CHECK_CARD_SKIP_FAILED, message: e.message });
  }
}
