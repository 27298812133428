import {
  SET_TITLE,
  SET_TOKEN,
  SET_LANG_CURRENT,
  SET_LANG_CHOICE,
  SET_LANG_AVAILABLE,
} from "./action";

const INITIAL_STATE = {
  title: "",
  token: undefined,
  langCurrent: undefined,
  langChoice: undefined,
  langAvailable: {},
};
const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_LANG_CURRENT:
      return {
        ...state,
        langCurrent: action.payload,
      };
    case SET_LANG_CHOICE:
      return {
        ...state,
        langChoice: action.payload,
      };
    case SET_LANG_AVAILABLE:
        return {
          ...state,
          langAvailable: action.payload,
        };
    default:
      return state;
  }
};

export default appReducer;
