import {
  WF_SET_ENTITY,
  WF_SET_CURRENT,
  WF_UPDATE_CURRENT,
  WF_SET_QUERY,
  WF_SET_LOADING,
  WF_SET_ACTION_STATE,
  WF_HISTORY_SET,
  WF_HISTORY_SKIP,
  WF_HISTORY_TRAVELING,
  WF_HISTORY_REMOVE_FUTURE,
  WF_ERROR_CLEAR,
  WF_RESUMING,
  WF_PWA_DECLINED,
  WF_MENU_OPEN,
  WF_SAGA_LOAD_ENTITY,
  WF_SAGA_LOAD_ENTITY_DONE,
  WF_SAGA_START_PROJECT,
  WF_SAGA_START_PROJECT_DONE,
  WF_SAGA_LOAD_CARD_DONE,
  WF_SAGA_PREPARE_ACTIONS_DONE,
  WF_SAGA_LOAD_CARDCHOICES_DONE,
  WF_SAGA_SUBMIT_CARD,
  WF_SAGA_SUBMIT_CARD_ABORT,
  WF_SAGA_START_OR_RESUME,
  WF_SAGA_SET_ENTITY_GUID_DONE,
  WF_SAGA_SET_ENTITY_GUID_FAILED,
  WF_SAGA_CHECK_CARD_SKIP_DONE,
  WF_SAGA_SET_ACTION_STATE_DONE,
  WF_SAGA_SUBMIT_ACTION_DONE,
  WF_SAGA_CLEAR_STATES_DONE,
  WF_SAGA_START_OR_RESUME_DONE,
  WF_SAGA_UPDATE_ACTION_DONE,
  WF_SAGA_PREPARE_UPDATED_ACTION_DONE,
  WF_SAGA_GOTO_SEARCHRESULT_DONE,
} from "./action";
import { LOCATION_CHANGE } from "connected-react-router";
import uuidv4 from "uuid/v4";
import produce from "immer";

const INITIAL_STATE = {
  entity: { loading: true, projects: [] },
  workflow: {
    loading: true,
    cards: [],
    card: undefined,
    actions: [],
    nextCards: [],
    skippedCount: 0,
    menus: {},
  },
  current: {
    entity: undefined,
    project: undefined,
    card: undefined,
    session: {},
  },
  history: [],
  searchHistory: {},
  states: {},
  menusState: {},
  historyTraveling: false,
  resuming: false,
  pwaDeclined: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE: // Lors de la navigation on met a jour l'historique
      const { action, location } = payload;
      if (location.pathname.startsWith("/_")) {
        // On suit uniquement les routes du le workflow
        if (state.historyTraveling) {
          return {
            ...state,
            historyTraveling: false,
            current: { ...state.current, session: location.state.session },
          };
        }
        if (
          location.state &&
          (location.state.guid || location.state.guid === "")
        ) {
          if (action === "PUSH" || action === "REPLACE") {
            return {
              ...state,
              history: [...state.history, location.state],
              current: { ...state.current, session: location.state.session },
            };
          }
        }
      }
      return state;
    case WF_SET_ENTITY:
      return {
        ...state,
        title: payload,
      };
    case WF_SET_CURRENT:
      return {
        ...state,
        current: payload,
      };
    case WF_UPDATE_CURRENT:
      return {
        ...state,
        current: { ...state.current, ...payload },
      };
    case WF_SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case WF_HISTORY_REMOVE_FUTURE:
      const i = state.history.findIndex(
        v =>
          v.guid === payload.guid && v.session?.hash === payload.session?.hash
      );
      if (i === -1) {
        return state;
      }
      return {
        ...state,
        history: state.history.slice(0, i + 1),
      };
    case WF_HISTORY_SET:
      return {
        ...state,
        history: payload,
      };
    case WF_HISTORY_SKIP:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          skippedCount: state.workflow.skippedCount + 1,
        },
        history: [
          ...state.history.slice(0, state.history.length - 1),
          { ...state.history[state.history.length - 1], skipped: true },
        ],
      };
    case WF_HISTORY_TRAVELING:
      return {
        ...state,
        historyTraveling: payload,
      };
    case WF_RESUMING:
      return {
        ...state,
        resuming: payload,
      };
    case WF_PWA_DECLINED:
      return {
        ...state,
        pwaDeclined: payload,
      };
    case WF_MENU_OPEN:
      return {
        ...state,
        menusState: { ...state.menusState, open: payload },
      };
    case WF_SAGA_START_OR_RESUME_DONE:
      return {
        ...state,
        resuming: false,
      };
    case WF_ERROR_CLEAR:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          errors: undefined,
          errorsData: undefined,
          loading: false,
        },
      };
    case WF_SAGA_LOAD_ENTITY:
      return {
        ...state,
        entity: { ...INITIAL_STATE.entity },
        workflow: {
          ...INITIAL_STATE.workflow,
          menus: { bottom: null, top: null },
        },
        menusState: { ...INITIAL_STATE.menusState },
        projects: {},
      };
    case WF_SAGA_LOAD_ENTITY_DONE:
      if (payload.projects.length === 1) {
        return {
          ...state,
          entity: { loading: false, ...payload },
        };
      }
      // Show menus on project selection page
      return {
        ...state,
        entity: { loading: false, ...payload },
        workflow: { ...state.workflow, menus: {} },
      };
    case WF_SAGA_START_PROJECT:
      return {
        ...state,
        workflow: {
          ...INITIAL_STATE.workflow,
          menus: { bottom: null, top: null }, // hide menu until start is done
        },
      };
    case WF_SAGA_START_PROJECT_DONE:
      return {
        ...state,
        workflow: { ...state.workflow, starting: true },
        current: {
          ...state.current,
          sessionProject: uuidv4(),
        },
      };
    case WF_SAGA_PREPARE_ACTIONS_DONE:
      const menus = { ...state.workflow.menus, ...payload.menus };
      if (menus.top === null) {
        delete menus.top;
      }
      if (menus.bottom === null) {
        delete menus.bottom;
      }
      return {
        ...state,
        workflow: {
          ...state.workflow,
          starting: false,
          actions: payload.actions,
          menus,
        },
      };
    case WF_SAGA_PREPARE_UPDATED_ACTION_DONE:
      const idx = state.workflow.actions.findIndex(
        a => a.guid === payload.action.guid
      );
      if (idx < 0) {
        return state;
      }
      return {
        ...state,
        workflow: {
          ...state.workflow,
          actions: produce(state.workflow.actions, draft => {
            draft[idx] = payload.action;
          }),
        },
      };
    case WF_SAGA_LOAD_CARD_DONE:
      return {
        ...state,
        workflow: {
          loading: true,
          skippedCount: 0,
          ...payload,
          menus: state.workflow.menus,
          starting: state.workflow.starting,
        },
        current: {
          ...state.current,
          card: payload.card ? payload.card.guid : undefined,
          session: payload.session || {},
          sessionCard: uuidv4(),
        },
      };
    case WF_SAGA_LOAD_CARDCHOICES_DONE:
      return {
        ...state,
        workflow: { loading: false, skippedCount: 0, ...payload },
        current: {
          ...state.current,
          card: payload.card ? payload.card.guid : undefined,
        },
      };
    case WF_SAGA_START_OR_RESUME:
    case WF_SAGA_SUBMIT_CARD:
      if (payload?.replace) {
        return {
          ...state,
          workflow: { ...state.workflow, errors: undefined, loading: true },
          history: [...state.history.slice(0, state.history.length - 1)],
        };
      } else {
        return {
          ...state,
          workflow: { ...state.workflow, errors: undefined, loading: true },
        };
      }

    case WF_SAGA_SUBMIT_CARD_ABORT:
      const { errors, errorsData } = payload;
      return {
        ...state,
        workflow: { ...state.workflow, errors, errorsData },
      };
    case WF_SAGA_SET_ENTITY_GUID_FAILED: // Reset
      return { ...INITIAL_STATE };
    case WF_SAGA_SET_ENTITY_GUID_DONE:
      return {
        ...state,
        entity: { ...INITIAL_STATE.entity, loading: false },
        pwaDeclined: false,
      };
    case WF_SET_LOADING:
      return {
        ...state,
        workflow: { ...state.workflow, loading: payload },
      };
    case WF_SAGA_CHECK_CARD_SKIP_DONE:
      return {
        ...state,
        workflow: { ...state.workflow, loading: Boolean(payload.skip) },
      };
    case WF_SET_ACTION_STATE:
    case WF_SAGA_SET_ACTION_STATE_DONE:
    case WF_SAGA_SUBMIT_ACTION_DONE:
      const { projectGuid, cardGuid, actionGuid, state: newstate } = payload;
      if (newstate === undefined) return state;
      const states = produce(state.states || {}, draft => {
        if (!draft[projectGuid]) {
          draft[projectGuid] = {};
        }
        if (!draft[projectGuid][cardGuid]) {
          draft[projectGuid][cardGuid] = {};
        }
        draft[projectGuid][cardGuid][actionGuid] =
          typeof newstate === "function"
            ? newstate(draft[projectGuid][cardGuid][actionGuid] || {})
            : newstate;
      });
      return { ...state, states };
    case WF_SAGA_CLEAR_STATES_DONE:
      return { ...state, states: payload };
    case WF_SAGA_UPDATE_ACTION_DONE:
      if (!payload.session) return state;
      return {
        ...state,
        current: produce(state.current, draft => {
          draft.session = payload.session;
        }),
        history: produce(state.history, draft => {
          draft[draft.length - 1].session = payload.session;
        }),
      };
    case WF_SAGA_GOTO_SEARCHRESULT_DONE:
      if (payload.fromHistory) return state; // Do not update order in this case
      const project = state.current.project;
      return produce(state, draft => {
        if (!draft.searchHistory) {
          draft.searchHistory = {};
        }
        if (!draft.searchHistory[project]) {
          draft.searchHistory[project] = [payload.id];
        } else {
          draft.searchHistory[project] = [
            ...new Set([payload.id, ...draft.searchHistory[project]]),
          ];
        }
        // Do not keep more than 100 in history
        if (draft.searchHistory[project].length > 150) {
          draft.searchHistory[project].splice(100, 50);
        }
      });
    default:
      return state;
  }
};

export default reducer;
