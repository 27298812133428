import React from "react";
import T from "prop-types";
import { useHistory } from "react-router";
import { withIntl } from "hooks/useFormatMessage";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { selectWorkflow } from "redux/workflow/selector";
import { wfErrorClear } from "redux/workflow/action";
import CommonIntl from "common/CommonTrad.i";
import ErrorTrad from "common/ErrorTrad.i";
import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";
import {
  LoaderContainer,
  StyledButton,
  AlertError,
  TextError,
  Errors,
} from "./Loader.s";

const Loader = ({ fullPage, size }) => {
  const dispatch = useDispatch();
  const { errors, errorsData } = useSelector(selectWorkflow);
  const theme = useTheme();
  const history = useHistory();

  const isUserExistsError = errors && errors.indexOf("UserExists") > -1;

  const handleClick = () => {
    dispatch(wfErrorClear());
    if (isUserExistsError) {
      history.push("/account", { email: errorsData["UserExists"] });
    }
  };

  const buttonText = isUserExistsError
    ? CommonIntl.ToLogin
    : CommonIntl.Validate;

  return (
    <LoaderContainer className="qrockme-loader" fullPage={fullPage}>
      {errors && errors.length > 0 ? (
        <Errors>
          {errors.map(value => (
            <AlertError key={value}>
              <InfoIcon color={theme.colors.grey} />
              <TextError>
                {ErrorTrad[value] ? (
                  <FormattedMessage {...ErrorTrad[value]} />
                ) : (
                  "Erreur inconnue"
                )}
              </TextError>
            </AlertError>
          ))}
          <StyledButton className="qrockme-button" onClick={handleClick}>
            <FormattedMessage {...buttonText} />
          </StyledButton>
        </Errors>
      ) : (
        <FontAwesomeIcon spin size={size} icon={faCircleNotch} />
      )}
    </LoaderContainer>
  );
};

Loader.propTypes = {
  fullPage: T.bool,
  size: T.string,
};

Loader.defaultProps = {
  fullPage: false,
  size: "3x",
};

export default withIntl(Loader);
