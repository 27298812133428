import React from "react";
import PropTypes from "prop-types";
import Proposal from "../Proposal";
import ResultsScreen from "../../Common/ResultsScreen";
import clsx from "clsx";
import useByGuid from "scenes/actions/Common/useByGuid";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";
  const headerCount = (state.correct || []).reduce((prev, cur) => {
    if (state.answers.indexOf(cur) > -1) {
      return prev + 1;
    } else {
      return prev;
    }
  }, 0);
  const headerTotal = showSolution ? state.correct.length : undefined;

  const proposalsByGuid = useByGuid(config.proposals);

  // Update to localized data
  const proposals = state.proposals.map(p => ({
    ...p,
    label: proposalsByGuid[p.guid].label,
    image: undefined,
  }));

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={headerCount}
      headerTotal={headerTotal}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      {proposals.map(p => {
        const checked = state.answers.indexOf(p.guid) > -1;
        let correct;
        if (showSolution && state.correct.indexOf(p.guid) > -1) {
          correct = true; // This proposal is correct
        } else if (showSolution && state.wrong.indexOf(p.guid) > -1) {
          correct = false; // This proposal is wrong
        }
        return (
          <Proposal
            key={p.guid}
            checked={checked}
            disabled={true}
            label={p.label}
            correct={correct}
            className={clsx({
              "action-proposal-correct":
                showSolution && correct !== undefined && correct,
              "action-proposal-wrong":
                showSolution && correct !== undefined && !correct,
            })}
          />
        );
      })}
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
