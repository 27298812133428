import React from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "redux/theme/selector";
import clsx from "clsx";

import {
  Title,
  LogoWrapper,
  LogoImg,
  LogoContainer,
  TitleContainer,
} from "./TitleBar.s";
import { selectEntity, selectWorkflow } from "redux/workflow/selector";
import { makeStyles } from "@material-ui/core";
import DrawerMenu from "./DrawerMenu";

const useStyle = makeStyles(theme => {
  return {
    root: {
      display: "flex",
      [theme.breakpoints.down("sm") + " and (orientation: landscape)"]: {
        display: "none",
      },
    },
    titlebar: {
      flexGrow: 1,
    },
  };
});

function logoUrl(url) {
  if (!url) return url;
  if (!process.env.REACT_APP_STATIC_SERVER) return url;
  return url.replace(
    "https://s3.eu-west-3.amazonaws.com/qrock.me/",
    process.env.REACT_APP_STATIC_SERVER
  );
}

const TitleBar = () => {
  const title = useSelector(state => state.app.title);
  let { logo, logoLeft, logoRight } = useSelector(selectTheme);
  logo = logoUrl(logo);
  logoLeft = logoUrl(logoLeft);
  logoRight = logoUrl(logoRight);
  const entity = useSelector(selectEntity);
  const { loading } = useSelector(selectWorkflow);
  const classes = useStyle();

  if (entity.loading) {
    return null;
  }

  let logoPosition;
  if (logoLeft && !logo && !logoRight) {
    logoPosition = "title-logo-left-only";
  }
  if (logoRight && !logo && !logoLeft) {
    logoPosition = "title-logo-right-only";
  }

  // Setting key on img prevent react to show old image until new one is loaded
  return (
    <div className={classes.root}>
      <DrawerMenu position="left" />
      <div className={clsx(classes.titlebar, "qrockme-titlebar", logoPosition)}>
        <LogoContainer className="title-logo">
          {logoLeft && (
            <LogoWrapper className="title-logo-left" align="left">
              <LogoImg
                className="title-logo-img"
                key={logoLeft}
                src={logoLeft}
                align="left"
              />
            </LogoWrapper>
          )}
          {logo && (
            <LogoWrapper className="title-logo-center">
              <LogoImg className="title-logo-img" key={logo} src={logo} />
            </LogoWrapper>
          )}
          {logoRight && (
            <LogoWrapper className="title-logo-right" align="right">
              <LogoImg
                className="title-logo-img"
                key={logoRight}
                src={logoRight}
                align="right"
              />
            </LogoWrapper>
          )}
        </LogoContainer>
        {title && (
          <TitleContainer className="title">
            <Title>{!loading && title}</Title>
          </TitleContainer>
        )}
      </div>
      <DrawerMenu position="right" />
    </div>
  );
};

export default TitleBar;
