import React from "react";
import Loader from "common/components/Loader";

const PdfViewerLazy = props => {
  const Component = React.lazy(() => import("./PdfViewer"));

  return (
    <React.Suspense fallback={<Loader />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default PdfViewerLazy;
