import styled from "styled-components";

export const FieldError = styled.div`
  background: rgba(
    ${(props) => props.theme.colors.rgb.primary.red},
    ${(props) => props.theme.colors.rgb.primary.green},
    ${(props) => props.theme.colors.rgb.primary.blue},
    0.14
  );
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const ToggleContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormInputContainer = styled.div`
  width: 100%;

  .react-toggle .react-toggle-thumb {
    height: 24px;
    width: 24px;
    border: none;
  }

  .react-toggle .react-toggle-track {
    background: white;
    border: 1px solid lightGray;
  }

  .react-toggle--focus .react-toggle-track {
    border: 1px solid lightGray;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    border: 1px solid lightGray;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: white;
    border: 1px solid lightGray;
  }

  .react-toggle:hover:not(.react-toggle--checked) .react-toggle-track {
    background: white;
    border: 1px solid lightGray;
  }

  .react-toggle--checked .react-toggle-track {
    border: 1px solid lightGray;
  }

  .react-toggle--checked .react-toggle-thumb {
    box-shadow: 0 0 4px 0 rgba(129, 129, 129, 0.5),
      inset 0 1px 4px 0 rgba(255, 255, 255, 0.5);
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-thumb {
    box-shadow: 0 0 4px 0 rgba(129, 129, 129, 0.5),
      inset 0 1px 4px 0 rgba(255, 255, 255, 0.5);
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 4px 0 rgba(129, 129, 129, 0.5),
      inset 0 1px 4px 0 rgba(255, 255, 255, 0.5);
  }
`;

export const TextError = styled.span`
  margin-left: 12px;
  font-weight: 400;
`;

export const PicsContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const DrawingContainer = styled.div`
  height: 100%;
  width: 100%;
`;
