import * as Api from "api";
import apiCall from "./apiCall";
import { call, select } from "redux-saga/effects";
import {
  selectCurrent,
  selectHistory,
  selectQuery,
} from "redux/workflow/selector";

export default function* sendValues(
  entityGuid,
  projectGuid,
  cardGuid,
  sessionProject,
  sessionCard,
  values
) {
  const history = yield select(selectHistory);
  const query = yield select(selectQuery);
  const current = yield select(selectCurrent);
  const prevGuid = history[history.length - 2]
    ? history[history.length - 2].guid || cardGuid
    : cardGuid;
  const result = yield call(
    apiCall,
    Api.postCardData,
    entityGuid,
    projectGuid,
    cardGuid,
    prevGuid,
    sessionProject,
    sessionCard,
    values,
    query,
    current.session
  );
  return result;
}
