import React from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { wfGotoSearchResult } from "redux/workflow/action";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";
import scrollToAction from "./Common/scrollToAction";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import {
  useSearch,
  SearchResults,
  SearchField,
} from "common/components/Search";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  popper: {
    zIndex: 2000,
  },
  results: {
    overflowX: "hidden",
  },
});

const ActionSearch = ({ config, data, action }) => {
  const anchorEl = React.useRef();
  const [text, setText] = React.useState("");
  const [showResults, setShowResults] = React.useState(false);
  const { hint, history, results, search } = useSearch(config);
  const dispatch = useDispatch();
  const nav = useHistory();
  const classes = useStyles();

  const handleChange = React.useCallback(
    value => {
      setText(value);
      search(value);
    },
    [search]
  );

  const handleResultClick = React.useCallback(
    ({ id, card, action }, fromHistory) => {
      dispatch(wfGotoSearchResult({ id, card, action }, Boolean(fromHistory)));
    },
    [dispatch]
  );

  const handleFocus = React.useCallback(() => {
    if (isMobile) {
      nav.push("/search", { ...config, titleImage: undefined });
    } else {
      scrollToAction(action.id, -10);
      setShowResults(true);
    }
  }, [action.id, nav, config]);

  const handleBlur = React.useCallback(() => {
    setTimeout(() => setShowResults(false), 200);
  }, []);

  const showTitle = config.showTitle === undefined ? true : config.showTitle;
  return (
    <>
      <Title config={config} data={data} />
      <SearchField
        ref={anchorEl}
        image={showTitle ? undefined : config.titleImage}
        placeholder={config.placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={text}
      />
      <Collapse in={showResults}>
        {text.trim().length === 0 && history.length > 0 && (
          <div className={clsx(classes.results, "action-search-results")}>
            <SearchResults
              results={history}
              onClick={handleResultClick}
              history
            />
          </div>
        )}
        {text.trim().length > 0 && (
          <div className={clsx(classes.results, "action-search-results")}>
            {hint && <div className="search-hint">{hint}</div>}
            <SearchResults results={results} onClick={handleResultClick} />
          </div>
        )}
      </Collapse>
    </>
  );
};

ActionSearch.classNames = ["qrockme-action-search"];

Object.assign(ActionSearch, AbstractAction);
export default ActionSearch;
