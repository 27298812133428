import React from "react";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import { Box } from "@rebass/grid";
import { useDispatch } from "react-redux";
import useFormatMessage, { withIntl } from "hooks/useFormatMessage";
import { themeClearProject } from "redux/theme/action";
import { Container } from "./Home.s";
import ConnexionIntl from "./Home.i";
import { useLocation } from "react-router";

function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const t = useFormatMessage();

  React.useEffect(() => {
    dispatch(themeClearProject(""));
  }, [dispatch]);

  return (
    <Container>
      <Box m={3}>
        <Heading textAlign="center">{t(ConnexionIntl.Title)}</Heading>
      </Box>
      <Box m={3}>
        <Text textAlign="center">
          {location.state && location.state.error
            ? t(ConnexionIntl.SubTitleError)
            : t(ConnexionIntl.SubTitle)}
        </Text>
      </Box>
    </Container>
  );
}

export default withIntl(Home);
