import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useForm } from "react-final-form";
import { wfSubmitCard } from "redux/workflow/action";
import Default from "./ActionNext/Default";
import Line from "./ActionNext/Line";
import Card from "./ActionNext/Card";
import CardLarge from "./ActionNext/CardLarge";
import Image from "./ActionNext/Image";
import AbstractAction from "./AbstractAction";
import { isStyleVariant } from "./Common/utils";

const ActionNext = ({
  action,
  config,
  parent,
  onClick,
  className,
  ...rest
}) => {
  const dispatch = useDispatch();
  const form = useForm();
  const { invalid } = form.getState();

  const handleClick = action => () => {
    if (onClick) {
      onClick(action);
    } else if (action.config.ignoreFormValidation) {
      dispatch(
        wfSubmitCard(
          [{ action_id: action.id, value: JSON.stringify(action.data) }],
          action.config.nextCard,
          Boolean(action.config.nextCard)
        )
      );
    } else {
      const data = { ...action.data };
      if (parent?.data?.records) {
        data.siblings = parent?.data?.records;
      }
      if (action.config.modelNav) {
        form.change("replace", true);
      }
      form.change("clickedCategory", action.config.nextCard);
      form.change(`action-${action.id}`, data);
      form.submit();
    }
  };

  const disabled = invalid && !config.ignoreFormValidation;

  const Component = React.useMemo(() => {
    if (isStyleVariant(action.cssClass, "cardlarge")) {
      return CardLarge;
    } else if (isStyleVariant(action.cssClass, "card")) {
      return Card;
    } else if (isStyleVariant(action.cssClass, "line")) {
      return Line;
    } else if (isStyleVariant(action.cssClass, "image")) {
      return Image;
    } else {
      return Default;
    }
  }, [action.cssClass]);

  return (
    <Component
      {...rest}
      parent={parent}
      config={config}
      action={action}
      disabled={disabled}
      onClick={handleClick(action)}
    />
  );
};

ActionNext.propTypes = {
  config: PropTypes.object.isRequired,
};

ActionNext.shouldSkip = function (action) {
  return { next: action.config.nextCard, history: false };
};
ActionNext.classNames = ["qrockme-action-next"];

Object.assign(ActionNext, AbstractAction);

ActionNext.getClasses = function (action) {
  const classes = AbstractAction.getClasses.apply(this, [action]);
  const userClass = classes.pop();
  if (action.data?.modelNav) {
    classes.push("qrockme-action-next-nav");
    if (action.data?.modelNav === "next") {
      classes.push("qrockme-action-next-nav-next");
    } else if (action.data?.modelNav === "prev") {
      classes.push("qrockme-action-next-nav-prev");
    }
  }
  if (isStyleVariant(action.cssClass, "cardlarge")) {
    classes.push("qrockme-action-next-cardlarge");
  } else if (isStyleVariant(action.cssClass, "card")) {
    classes.push("qrockme-action-next-card");
  } else if (isStyleVariant(action.cssClass, "line")) {
    classes.push("qrockme-action-next-line");
  } else if (isStyleVariant(action.cssClass, "image")) {
    classes.push("qrockme-action-next-image");
  }
  classes.push(userClass); // Keep user class at end of array
  return classes;
};

export default ActionNext;
