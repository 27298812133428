import React from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { selectEntity, selectQuery } from "redux/workflow/selector";
import qs from "qs";
import PwaDialogIOS from "./PwaDialogIOS";

const Pwa = () => {
  const { guid, description, name, pwa, icon } = useSelector(selectEntity);
  const query = useSelector(selectQuery);

  if (!pwa) return null;

  const icons = [
    {
      src: icon || `${window.location.origin}/assets/images/logo-new-256.png`,
      sizes: "256x256",
      type: "image/png",
    },
  ];

  const urlqs = qs.stringify({ __pwa: 1, ...query });
  const url = `${window.location.origin}/entity/${guid}?${urlqs}`;
  const manifest = {
    name,
    short_name: name,
    description: description || "",
    start_url: url,
    background_color: "#FFFFFF",
    theme_color: "#FF9E00",
    display: "fullscreen",
    icons,
  };
  const manifestURL = URL.createObjectURL(
    new Blob([JSON.stringify(manifest)], { type: "application/json" })
  );

  return (
    <>
      <Helmet>
        <link rel="manifest" id="pwa-manifest" href={manifestURL} />
        <link
          rel="apple-touch-icon"
          sizes="256x256"
          href={
            icon || `${window.location.origin}/assets/images/logo-new-256.png`
          }
        />
      </Helmet>
      <PwaDialogIOS
        name={name}
        icon={icon || "/assets/images/logo-new-256.png"}
      />
    </>
  );
};

export default Pwa;
