import ActionSort from "../ActionSort";
import checkResults from "./checkResults";
import { shuffle, keyBy } from "lodash";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

export default function ({ config, children, data, ...rest }, state) {
  if (!state) {
    state = state || { ...ActionSort.initialState };
    if (data?.result_obj?.screen) {
      const proposals = keyBy(config.proposals, "guid");
      const answers = data?.result_obj?.answers
        .map(v => {
          return proposals[v] ? { ...proposals[v] } : null;
        })
        .filter(Boolean);
      state = {
        ...state,
        ...data.result_obj,
        ...checkResults(config, answers),
      };
    }
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...ActionSort.initialState,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  if (!state.proposals) {
    const draws = [
      shuffle(config.proposals),
      shuffle(config.proposals),
      shuffle(config.proposals),
      shuffle(config.proposals),
      shuffle(config.proposals),
    ]
      .map(draw => {
        const { correctCount } = checkResults(config, draw);
        return { draw, correctCount };
      })
      .sort((a, b) => a.correctCount - b.correctCount);
    state = produce(state, draft => {
      draft.proposals = draws[0].draw;
    });
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
