import ActionHotspot from "../ActionHotspot";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

export default function ({ config, children, data, ...rest }, state) {
  if (!state && data?.result_obj?.screen) {
    state = {
      ...ActionHotspot.initialState,
      ...data.result_obj,
      correctCount: (data?.result_obj?.zones || []).reduce((prev, zone) => {
        return prev + (zone.correct ? 1 : 0);
      }, 0),
    };
  } else if (!state) {
    state = { ...ActionHotspot.initialState, zones: config.zones };
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...ActionHotspot.initialState,
        zones: state.zones,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
