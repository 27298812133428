import { put, select } from "redux-saga/effects";
import {
  WF_SAGA_SUBMIT_ACTION_DONE,
  WF_SAGA_SUBMIT_ACTION_FAILED,
} from "../action";
import { selectCurrent, selectAction, selectActionState } from "../selector";
import { wfSagaSetActionState } from "../action";

export default function* submitAction({ payload: { guid } }) {
  try {
    const { project: projectGuid, card: cardGuid } = yield select(
      selectCurrent
    );
    const action = yield select(selectAction(guid));
    const payload = {
      projectGuid,
      cardGuid,
      actionGuid: guid,
    };
    if (action.component && action.component.onSubmit) {
      const actionState = yield select(
        selectActionState(projectGuid, cardGuid, guid)
      );
      const { state } = action.component.onSubmit(action, actionState);
      if (state) {
        yield put(wfSagaSetActionState(guid, state));
      }
    }
    yield put({
      type: WF_SAGA_SUBMIT_ACTION_DONE,
      payload,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: WF_SAGA_SUBMIT_ACTION_FAILED, message: e.message });
  }
}
