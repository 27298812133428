import React from "react";
import { Favorite as FavoriteIcon } from "@material-ui/icons";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";
import NextButton from "common/components/NextButton";
import { useActionImage } from "common/actions";
import { FavoritesList, useFavorites } from "common/components/Favorites";
import SubScreens from "./Common/SubScreens";
import clsx from "clsx";

const ActionFavorites = ({ config, data, children }) => {
  const image = useActionImage(config, data, "titleImage");
  const { isCurrentCardFavorite, add, remove } = useFavorites(
    config.type === "list" ? config.showCategory : config.addCategory
  );
  const hasSubScreenActions = (children["empty"] || []).length > 0;

  return (
    <>
      {config.type === "add" && (
        <>
          <NextButton
            title={config.title}
            image={image}
            icon={FavoriteIcon}
            className={clsx({
              "action-favorites-active": isCurrentCardFavorite,
            })}
            onClick={() => (isCurrentCardFavorite ? remove() : add())}
          />
        </>
      )}
      {config.type === "list" && (
        <>
          <Title config={config} data={data} />
          <FavoritesList category={config.showCategory} sort={config.sort}>
            {hasSubScreenActions && (
              <SubScreens actions={children} screens="empty" />
            )}
          </FavoritesList>
        </>
      )}
    </>
  );
};

ActionFavorites.classNames = ["qrockme-action-favorites"];

Object.assign(ActionFavorites, AbstractAction);
export default ActionFavorites;
