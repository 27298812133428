import React from "react";
import PropTypes from "prop-types";
import Proposal from "./Proposal";
import ResultsScreen from "../Common/ResultsScreen";
import useByGuid from "../Common/useByGuid";
import clsx from "clsx";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";
  const headerCount = state.correctCount;
  const headerTotal = state.proposals.length;

  // Update to localized data
  const proposalsByGuid = useByGuid(config.proposals);
  const proposals = (state.solution || state.proposals).map(p => ({
    ...p,
    label: proposalsByGuid[p.guid].label,
    image: undefined,
  }));

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={headerCount}
      headerTotal={headerTotal}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <div className="action-proposals">
        {proposals.map(p => (
          <Proposal
            key={p.guid}
            disabled={true}
            showSolution={showSolution}
            {...p}
            className={clsx({
              "action-proposal-correct": p.correct,
              "action-proposal-wrong": !p.correct,
            })}
          />
        ))}
      </div>
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
