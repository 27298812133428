import React from "react";
import PropTypes from "prop-types";
import Proposal from "./Proposal";
import rules from "./rules";
import ResultsScreen from "../Common/ResultsScreen";
import clsx from "clsx";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <div className="action-proposals">
        <Proposal
          disabled={true}
          checked={true}
          label={state.answer}
          correct={state.correct}
          className={clsx({
            "action-proposal-correct": showSolution && state.correct,
          })}
        />
        {showSolution && !state.correct && (
          <>
            <br />
            {state.proposals.map((r, i) => (
              <Proposal
                key={i}
                disabled={true}
                checked={false}
                label={rules[r.operator].toString(r)}
                correct={true}
                className={clsx({
                  "action-proposal-correct": true,
                })}
              />
            ))}
          </>
        )}
      </div>
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
