import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Notifications as NotificationsIcon,
} from "@material-ui/icons";
import NotificationsIntl from "./Notifications.i";
import useFormatMessage, { withIntl } from "hooks/useFormatMessage";
import { useHistory } from "react-router";
import { NotificationsList } from "common/components/Notifications";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
  },
});

function Notifications() {
  const t = useFormatMessage();
  const nav = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "notifications-header")}>
        <IconButton size="small" onClick={() => nav.goBack()}>
          <BackIcon color="inherit" />
        </IconButton>
        <div className={classes.title}>{t(NotificationsIntl.Title)}</div>
        <NotificationsIcon color="inherit" />
      </div>
      <NotificationsList />
    </div>
  );
}

export default withIntl(Notifications);
