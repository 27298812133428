import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  NavigateNext as ArrowIcon,
  NavigateBefore as PrevIcon,
} from "@material-ui/icons";
import nl2br from "react-nl2br";
import clsx from "clsx";
import useCancelableRequest from "common/components/useCancelableRequest";
import { useActionNext } from "common/actions";

const useStyles = makeStyles({
  root: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  text: {
    flexGrow: 1,
    textAlign: "left",
  },
  image: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    marginRight: 8,
  },
  imageEmpty: {
    border: "none",
    boxShadow: "none !important",
  },
});

const Line = ({ config, data, disabled, onClick }) => {
  const classes = useStyles();
  const { title, image, desc, additionals } = useActionNext(config, data);
  const [result] = useCancelableRequest(image);
  const invert = data?.modelNav === "prev";

  const imageNode = React.useMemo(() => {
    if (!image) return null;
    return (
      <div
        className={clsx(classes.image, "qrockme-line-image")}
        style={{
          backgroundImage: `url('${result}')`,
        }}
      ></div>
    );
  }, [classes, image, result]);

  const arrowNode = (
    <div className={clsx(classes.arrow, "qrockme-line-arrow")}>
      {invert ? <PrevIcon /> : <ArrowIcon />}
    </div>
  );

  return (
    <div
      className={clsx(classes.root, "qrockme-line", {
        "qrockme-line-disabled": disabled,
      })}
      onClick={onClick}
    >
      {invert ? arrowNode : imageNode}
      <div className={clsx(classes.text, "qrockme-line-text")}>
        {title && <div className="qrockme-line-title">{nl2br(title)}</div>}
        {desc && <div className="qrockme-line-desc">{nl2br(desc)}</div>}
        {additionals.length > 0 &&
          additionals.map(({ content }, idx) => {
            if (!content) return null;
            const className = [
              "qrockme-line-add",
              `qrockme-line-add-${idx + 1}`,
            ].join(" ");
            return (
              <div className={className} key={idx}>
                {nl2br(content)}
              </div>
            );
          })}
      </div>
      {invert ? imageNode : arrowNode}
    </div>
  );
};

export default Line;
