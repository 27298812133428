import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "auth/store/actions";
import { bindActionCreators } from "redux";
import * as Actions from "storeLogin/actions";
import jwtService from "services/jwtService";
import SplashScreen from "../splashScreen/SplashScreen";
class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  constructor(props) {
    super(props);
    this._isMounted = false;
  }
  async componentDidMount() {
    this._isMounted = true;
    this._isMounted && (await Promise.all([this.jwtCheck()]));
    this._isMounted && this.setState({ waitAuthCheck: false });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  jwtCheck = () =>
    new Promise(resolve => {
      //console.log('jwtCheck: ');

      jwtService.on("onAutoLogin", () => {
        //console.log('Logging in with JWT');
        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then(user => {
            //console.log('signInWithToken : user: ', user);
            this.props.setUserData(user);
            resolve();
            //console.log('Logged in with JWT');
          })
          .catch(error => {
            this.props.showMessage({ message: error });
            //console.log('error: ', error);
            resolve();
          });
      });
      jwtService.on("NetworkError404", message => {
        //console.log('NetworkError404: ', message);
        // gestion 404 TODO
      });
      jwtService.on("onAutoLogout", message => {
        if (message) {
          this.props.showMessage({ message });
        }
        this.props.logout();
        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });
      jwtService.init();
      return Promise.resolve();
    });

  render() {
    // return <React.Fragment children={this.props.children} />;
    return this.state.waitAuthCheck ? (
      <SplashScreen />
    ) : (
      <React.Fragment children={this.props.children} />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
