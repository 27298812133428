import styled from "styled-components/macro";
import Button from "common/components/Button";
import loginBackground from "common/loginBackground.svg";
import loggedBackground from "common/loggedBackground.svg";

//min-height: calc(${props => props.vh}px - (90px + 44px + 16px));
export const Container = styled.div`
  flex-grow: 1;
`;

export const FormField = styled.div`
  margin-bottom: 16px;
`;

export const FormContainer = styled.form`
  width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px auto;
`;

export const StyledButton = styled(Button)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  cursor: pointer;
`;

export const ContentRenders = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 1.1876em;
  p {
    margin: 0;
    padding: 0;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  .react-card-flip {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px 4px;
    width: 100%;
    height: 100%;
  }
`;

export const ResultContainer = styled.div`
  margin: 16px;
`;

export const BottomSpacer = styled.div`
  height: 90px;
`;

export const ImageContainer = styled.div`
  height: 97px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  width: 177px;
  background: url(${loginBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageLoggedContainer = styled.div`
  color: #69a533;
  height: 97px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  width: 177px;
  background: url(${loggedBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;
