import React from "react";
import { useDispatch } from "react-redux";
import ScreenMain from "./ActionMatching/ScreenMain";
import ScreenResults from "./ActionMatching/ScreenResults";
import prepareAction from "./ActionMatching/prepareAction";
import onSubmit from "./ActionMatching/onSubmit";
import quizShouldSkip from "./Common/quizShouldSkip";
import scrollToAction from "./Common/scrollToAction";
import {
  wfSagaSubmitAction,
  WF_SAGA_SET_ENTITY_GUID_DONE,
} from "redux/workflow/action";
import { Field, useForm } from "react-final-form";
import { required } from "common/components/rules";
import AbstractAction from "./AbstractAction";

const ActionMatching = ({ action, state, config, ...rest }) => {
  const dispatch = useDispatch();
  const form = useForm();

  const handleSubmit = () => {
    scrollToAction(action.id);
    dispatch(wfSagaSubmitAction(action.guid));
  };

  const { screen, trial, proposals } = state;
  React.useEffect(() => {
    form.change(`action-${action.id}`, {
      screen: screen,
      trial: trial,
      proposals: proposals.map(p => ({
        correct: p.correct,
        answer: p.answer,
        guid: p.guid,
      })),
    });
  }, [screen, trial, proposals, action.id, form]);

  const Screen = screen === "info" ? ScreenMain : ScreenResults;

  const handleValidate = React.useCallback(
    v => {
      if (config.required && (screen === "info" || screen === "retry")) {
        return required();
      }
    },
    [config.required, screen]
  );

  return (
    <>
      <Screen
        action={action}
        onSubmit={handleSubmit}
        state={state}
        config={config}
        {...rest}
      />
      <Field
        name={`action-${action.id}`}
        component="input"
        type="hidden"
        validate={handleValidate}
      />
    </>
  );
};

ActionMatching.initialState = {
  values: [],
  proposals: [],
  trial: 0,
  screen: "info",
  clearOn: WF_SAGA_SET_ENTITY_GUID_DONE,
  actionType: "MATCHING",
};

ActionMatching.prepareAction = prepareAction;
ActionMatching.onSubmit = onSubmit;
ActionMatching.shouldSkip = quizShouldSkip;
ActionMatching.skipValue = ({ state: { screen, trial, proposals } }) => ({
  screen,
  trial,
  proposals,
});
ActionMatching.classNames = ["qrockme-action-matching"];

Object.assign(ActionMatching, AbstractAction);
export default ActionMatching;
