import { Index } from "lunr";

interface Filters {
  card: boolean;
  actionContent: boolean;
  actionTitle: boolean;
  exclude: string[];
}

interface ParsedId {
  type: "card" | "action";
  card: string;
  action?: string;
}

function parseId(id: string): ParsedId {
  const [card, action] = id.split("|");
  return {
    type: action ? "action" : "card",
    card,
    action,
  };
}

const filterResults =
  (filters: Filters) =>
  (result: Index.Result): boolean => {
    const { type, card } = parseId(result.ref);
    if (!filters.card && type === "card") return false;
    if (!filters.actionContent && !filters.actionTitle && type === "action")
      return false;
    if (filters.exclude.length && filters.exclude.includes(card)) return false;
    if (!filters.actionTitle || !filters.actionContent) {
      let title = false;
      let content = false;
      Object.values(result.matchData.metadata).forEach(fields => {
        if (fields.title) {
          title = true;
        }
        if (fields.content) {
          content = true;
        }
      });
      if (!filters.actionTitle && !content) return false;
      if (!filters.actionContent && !title) return false;
    }
    return true;
  };

export default filterResults;
