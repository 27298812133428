import {
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  FastForward as ResumeIcon,
} from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { withIntl } from "hooks/useFormatMessage";
import ResumeIntl from "./Resume.i";
import { wfSagaSetEntityGuid, wfStartResuming } from "redux/workflow/action";
import {
  selectCurrent,
  selectEntity,
  selectWorkflow,
} from "redux/workflow/selector";
import Loader from "common/components/Loader";
import { StyledButton } from "./Resume.s";
import clsx from "clsx";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const Resume = ({ match }) => {
  const guid = match.params.entityGUID;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const workflow = useSelector(selectWorkflow);
  const entity = useSelector(selectEntity);
  const current = useSelector(selectCurrent);
  const project = entity.projects.find(p => p.guid === current.project);
  const classes = useStyles();

  const cancelResume = React.useCallback(() => {
    setLoading(true);
    dispatch(wfSagaSetEntityGuid(guid, true));
  }, [dispatch, guid]);

  React.useEffect(() => {
    if (!project || !workflow.card) {
      cancelResume();
    }
  }, [project, workflow.card, cancelResume]);

  const handleHomeClick = () => {
    cancelResume();
  };

  const handleResumeClick = () => {
    setLoading(true);
    dispatch(wfStartResuming());
    history.push("/_");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Dialog open={true} classes={{ paper: "qrockme-action-popup" }}>
      <DialogContent className="action-screen">
        <div className="qrockme-action qrockme-action-text qrockme-action-text-__default">
          <Typography component="h2" variant="h6">
            <FormattedMessage {...ResumeIntl.Title} />
          </Typography>
          <p>{project.name}</p>
          <p>{workflow.card.name}</p>
        </div>
        <p className={clsx(classes.actions, "qrockme-action-next")}>
          <StyledButton
            color="primary"
            className="qrockme-button"
            onClick={handleHomeClick}
          >
            <HomeIcon />
          </StyledButton>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <StyledButton
            color="primary"
            className="qrockme-button"
            onClick={handleResumeClick}
          >
            <ResumeIcon />
          </StyledButton>
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default withIntl(Resume);
