import React from "react";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import ImageLoader from "common/components/ImageLoader";
import clsx from "clsx";

const useStyles = makeStyles({
  input: {
    "&::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "& input": {
      padding: 0,
    },
  },
  removeFieldsetBorders: {
    "& fieldset": {
      border: "none",
    },
  },
  imageAdornment: {
    width: 24,
    height: 24,
    flexShrink: 0,
  },
});

type OnChangeHandler = (value: string) => void;
interface LocalProps {
  value: string;
  placeholder?: string;
  image?: string;
  onChange: OnChangeHandler;
}

type Props = LocalProps & TextFieldProps;

export const SearchField: React.ForwardRefExoticComponent<Props> =
  React.forwardRef(
    ({ value, title, image, placeholder, onChange, ...rest }, ref) => {
      const classes = useStyles();

      const handleClearClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
          onChange("");
          e.stopPropagation();
          e.preventDefault();
        },
        [onChange]
      );

      const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value || "";
          onChange(value);
        },
        [onChange]
      );

      const startAdornment = React.useMemo(
        () =>
          image ? (
            <InputAdornment position="start" className={classes.imageAdornment}>
              <ImageLoader alt="" src={image} invisible responsive />
            </InputAdornment>
          ) : (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        [image, classes.imageAdornment]
      );

      const endAdornment = React.useMemo(() => {
        if (!value || value.length === 0) return;
        return (
          <InputAdornment position="end">
            <IconButton onMouseDown={handleClearClick} size="small">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        );
      }, [value, handleClearClick]);

      return (
        <TextField
          ref={ref}
          className={clsx(classes.removeFieldsetBorders, "search-input")}
          variant="outlined"
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            className: clsx("qrockme-input", classes.input),
            startAdornment,
            endAdornment,
            ...rest.InputProps,
          }}
          value={value}
          fullWidth
          {...rest}
        />
      );
    }
  );
