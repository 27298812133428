import AbstractAction from "./AbstractAction";

const NullAction = () => {
  return null;
};

NullAction.prepareAction = function (action, state, { user, actions }) {
  if (
    actions.length === 2 &&
    actions.some(
      a => a.component && a.component.shouldSkip && a.component.shouldSkip(a)
    )
  ) {
    return null;
  }
  return action;
};

Object.assign(NullAction, AbstractAction);
export default NullAction;
