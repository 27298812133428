import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles, Portal } from "@material-ui/core";
import { wfSagaSetActionState } from "redux/workflow/action";
import useInView from "react-cool-inview";
import { Preview } from "react-dnd-multi-backend";
import MainScreen from "../Common/MainScreen";
import ProposalsBar from "../Common/ProposalsBar";
import BarProposal from "../Common/BarProposal";
import Board from "./Board";
import clsx from "clsx";
import produce from "immer";

const useStyles = makeStyles({
  help: {
    textAlign: "left",
  },
});

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [dragging, setDragging] = React.useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const handleDrop = React.useCallback(
    (to, { guid, from, label }) => {
      dispatch(
        wfSagaSetActionState(
          action.guid,
          produce(draft => {
            const index = draft.words.findIndex(w => w.guid === guid);
            if (index > -1) {
              draft.words.splice(index, 1);
            }
            if (from !== undefined) {
              draft.answers[from] = draft.answers[to];
            } else if (draft.answers[to]) {
              draft.words.push(draft.answers[to]);
            }
            draft.answers[to] = { guid, label };
          })
        )
      );
    },
    [dispatch, action.guid]
  );

  const handleBarDrop = ({ guid, label, from, index }) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          if (from !== undefined) {
            draft.answers[from] = null;
            if (index !== undefined) {
              draft.words.splice(index, 0, { guid, label });
            } else {
              draft.words.push({ guid, label });
            }
          }
        })
      )
    );
  };

  const handleDragging = React.useCallback(
    dragging => setDragging(dragging),
    []
  );

  const generatePreview =
    actionGuid =>
    ({ itemType, item, style }) => {
      if (actionGuid !== itemType) return;
      return (
        <Portal>
          <div
            style={{
              ...style,
              zIndex: 1001,
              width: item.width + 5,
              opacity: 0.5,
            }}
          >
            <BarProposal label={item.label} />
          </div>
        </Portal>
      );
    };

  const fulfilled = Object.values(state.answers).every(v => !!v);
  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      dragging={dragging}
    >
      <div className={clsx(classes.help, "action-help")}>
        <img alt="Aide" src="assets/images/help/missingwords.gif" />
      </div>
      <Board
        ref={ref}
        dndGroup={action.guid}
        html={config.html}
        answers={state.answers}
        onDrop={handleDrop}
        onDragging={handleDragging}
      />
      <ProposalsBar
        dndGroup={action.guid}
        sticky={inView}
        proposals={state.words}
        onSubmit={fulfilled ? onSubmit : undefined}
        onDrop={handleBarDrop}
        onDragging={handleDragging}
      />
      <Preview>{generatePreview(action.guid)}</Preview>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
