import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import ToggleAdapter from "common/components/formAdapters/ToggleAdapter";
import { Field, useField } from "react-final-form";
import { required, noValidation } from "common/components/rules";
import { FormField } from "./Actions.s";
import AbstractAction from "./AbstractAction";
import { prepareChildren } from "./Common/utils";
import { wfSagaSetActionState } from "redux/workflow/action";
import { useDispatch } from "react-redux";
import CollapseScreens from "./Common/CollapseScreens";
import { useActionImage } from "common/actions";
import { ImageLoader } from "common/components";

const useStyles = makeStyles({
  image: {
    width: 24,
    height: 24,
    marginRight: 4,
    flexShrink: 0,
    flexGrow: 0,
  },
});

const ActionToggle = ({ action, config, data, children }) => {
  const field = useField(`action-${action.id}`);
  const image = useActionImage(config, data, "titleImage");
  const classes = useStyles();
  const value = field.input.value;
  const dispatch = useDispatch();
  const defaultValue = data.result !== undefined ? data.result : false;

  const imageNode = image ? (
    <div className={clsx(classes.image, "action-title-image")}>
      <ImageLoader alt={config.title} src={image} invisible responsive />
    </div>
  ) : null;

  const screen = React.useMemo(() => {
    if (value === undefined || value === "") {
      return defaultValue ? "true" : "false";
    } else {
      return value ? "true" : "false";
    }
  }, [value, defaultValue]);

  React.useEffect(() => {
    dispatch(wfSagaSetActionState(action.guid, { screen }));
  }, [dispatch, screen, action.guid]);

  return (
    <>
      <FormField>
        <Field
          className="action-toggle"
          name={`action-${action.id}`}
          component={ToggleAdapter}
          label={config.title}
          image={imageNode}
          validate={config.required ? required : noValidation}
          defaultValue={defaultValue}
        />
      </FormField>
      <CollapseScreens actions={children} screens={screen} />
    </>
  );
};

ActionToggle.classNames = ["qrockme-action-toggle"];

ActionToggle.prepareAction = function ({ children, ...rest }, state) {
  return {
    ...rest,
    children: prepareChildren(children, {
      onlyScreens: ["info", state?.screen],
    }),
  };
};

Object.assign(ActionToggle, AbstractAction);
export default ActionToggle;
