import { createBrowserHistory, createMemoryHistory } from "history";
import { isPwaIOS } from "./detect";

let history;
if (isPwaIOS()) {
  const path =
    (window.location.pathname || "/") + (window.location.search || "");
  history = createMemoryHistory({
    initialEntries: [path],
  });
} else {
  history = createBrowserHistory();
}

export default history;
