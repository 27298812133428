import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";
import { useDrop, useDrag } from "react-dnd";
import nl2br from "react-nl2br";
import clsx from "clsx";
import BarProposal from "../Common/BarProposal";
import Result from "./Result";

const useStyles = makeStyles({
  root: { marginTop: 8, marginBottom: 8 },
  label: {
    background: "#FFFFFF",
    borderRadius: 8,
    border: "2px solid #C7C7C7",
    padding: "4px 0 ",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 40,
    position: "relative",
    zIndex: 1,
  },
  link: {
    color: "#a1a1a1",
    background: "#C7C7C7",
    padding: 4,
    height: 16,
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    top: -6,
    left: 6,
    zIndex: 2,
    "&.filled": {
      background: "#ff3041",
      color: "#FFFFFF",
    },
  },
  linkIcon: {
    marginTop: -8,
  },
  anwserWrapper: {
    display: "flex",
  },
  anwser: {
    position: "relative",
    flexGrow: 1,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    minHeight: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 4,
    top: -4,
    minWidth: 0,
    "&.action-matching-answer-filled": {
      paddingTop: 1,
    },
  },
  anwserLabel: {
    flexBasis: 0,
    margin: 0,
    flexGrow: 1,
    cursor: "grab",
    "& .action-proposal-label": {
      whiteSpace: "normal",
      paddingLeft: 0,
      paddingRight: 54,
    },
  },
});

const Proposal = ({
  index,
  onDrop,
  onDragging,
  label,
  value,
  answer,
  correct,
  dndGroup,
  showSolution,
}) => {
  const ref = React.useRef();
  const width = ref.current ? ref.current.offsetWidth : 0;
  const [{ isOver }, drop] = useDrop({
    accept: dndGroup || "__disabled-drop",
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const [, drag] = useDrag({
    item: {
      type: dndGroup || "__disabled-drag",
      guid: answer ? answer.guid : "",
      from: index,
      label: answer ? answer.value : "",
      width,
    },
    begin: () => {
      if (onDragging) {
        onDragging(true);
      }
    },
    end: () => {
      if (onDragging) {
        onDragging(false);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const classes = useStyles();

  drag(ref);
  return (
    <div ref={drop} className={classes.root}>
      <div className={clsx(classes.label, "action-matching-proposal")}>
        {nl2br(label)}
      </div>
      <div className={classes.anwserWrapper}>
        <div>
          <div
            className={clsx(classes.link, {
              filled: isOver || Boolean(answer),
            })}
          >
            <LinkIcon className={classes.linkIcon} color="inherit" />
          </div>
        </div>
        <div
          className={clsx(classes.anwser, "action-matching-answer", {
            "action-matching-answer-filled": Boolean(answer),
            "action-matching-answer-correct":
              correct !== undefined && Boolean(correct),
            "action-matching-answer-wrong":
              correct !== undefined && !Boolean(correct),
          })}
        >
          {answer && dndGroup && (
            <BarProposal
              ref={ref}
              label={nl2br(answer.value)}
              className={classes.anwserLabel}
            />
          )}
          {answer && !dndGroup && (
            <Result
              label={nl2br(answer.value)}
              solution={showSolution ? nl2br(value) : undefined}
              correct={correct}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Proposal;
