import ActionNumber from "../ActionNumber";
import checkResults from "./checkResults";

export default function (action, state) {
  if (state.screen === "retry") {
    // Submit on retry screen do a restart
    return {
      state: {
        ...ActionNumber.initialState,
        trial: state.trial,
      },
    };
  } else if (state.screen === "info") {
    // Submit on info screen, we need to check answers
    const { attempts, proposals } = action.config;
    const { trial, answer } = state;

    const { correct } = checkResults(action.config, answer);
    let newstate = { ...state, trial: trial + 1 };
    if (correct) {
      newstate = { ...newstate, screen: "success", correct, proposals };
    } else if (attempts === 0 || trial + 1 < attempts) {
      // Unlimited attempts or trial left
      newstate = { ...newstate, screen: "retry", correct };
    } else {
      newstate = { ...newstate, screen: "fail", correct, proposals };
    }

    let value;
    if (newstate.screen === "success" || newstate.screen === "fail") {
      value = {
        screen: newstate.screen,
        trial: newstate.trial,
        answer,
      };
    }
    return { state: newstate, value };
  }
}
