import { defineMessages } from "react-intl";

export default defineMessages({
  Connexion: {
    id: "Login.Connexion",
    defaultMessage: "Connexion",
  },
  EmailPlaceholder: {
    id: "Login.EmailPlaceholder",
    defaultMessage: "Email",
  },
  PwdPlaceholder: {
    id: "Login.PwdPlaceholder",
    defaultMessage: "Mot de passe",
  },
  Forgot: {
    id: "Login.Forgot",
    defaultMessage: "Mot de passe oublié ?",
  },
  LoginFailed: {
    id: "Login.LoginFailed",
    defaultMessage: "Account locked after several unsuccessful login attempts",
  },
  AccountLocked: {
    id: "Login.AccountLocked",
    defaultMessage:
      "Compte verrouillé suite à plusieurs tentatives infructueuses de connexion",
  },
  AuthPasswordLink: {
    id: "Login.AuthPasswordLink",
    defaultMessage: "Connexion classique",
  },
  AuthSsoLink: {
    id: "Login.AuthSsoLink",
    defaultMessage: "Connexion SSO",
  },
  AuthSsoHint: {
    id: "Login.AuthSsoHint",
    defaultMessage:
      "Saisissez l'adresse email que vous utilisez pour vous connecter au SSO de votre entreprise",
  },
  AuthSsoError: {
    id: "Login.AuthSsoError",
    defaultMessage:
      "Aucune organisation SSO n'a été trouvée pour votre compte. Veuillez contacter votre administrateur.",
  },
});
