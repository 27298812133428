import { put } from "redux-saga/effects";
import {
  wfGotoCard,
  WF_SAGA_GOTO_SEARCHRESULT_DONE,
  WF_SAGA_GOTO_SEARCHRESULT_FAILED,
} from "redux/workflow/action";

export default function* gotoSearchResult({ payload }) {
  try {
    const { card, id } = payload;
    const [, actionId] = id.split("|");
    yield put(wfGotoCard(card, actionId));
    yield put({
      type: WF_SAGA_GOTO_SEARCHRESULT_DONE,
      payload,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_GOTO_SEARCHRESULT_FAILED, message: e.message });
  }
}
