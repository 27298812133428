import React from "react";
import * as Api from "api";
import { Index } from "lunr";
import { useSelector } from "react-redux";
import { selectCurrent, selectSearchHistory } from "redux/workflow/selector";
import { selectLangCurrent, selectToken } from "redux/app/selector";
import useFormatMessage from "hooks/useFormatMessage";
import mapResults, { Result } from "./mapResults";
import filterResults from "./filterResults";
import SearchIntl from "./Search.i";
import unicodeNormalizer from "../rules/unicodeNormalizer";
import { SearchContext } from "./SearchContext";

interface Config {
  showHistory: boolean;
  historyCount: number;
  inCardTitle: boolean;
  inActionContent: boolean;
  inActionTitle: boolean;
  exclude: number[];
}

const maxResults = 100;
const minLength = 3;

export function useSearch(config: Config) {
  const { entity, project } = useSelector(selectCurrent);
  const token = useSelector(selectToken);
  const lang = useSelector(selectLangCurrent);
  const translate = useFormatMessage();
  const [hint, setHint] = React.useState(translate(SearchIntl.Loading));
  const [error, setError] = React.useState(false);
  const [results, setResults] = React.useState<Result[]>([]);
  const [delayed, setDelayed] = React.useState<string>();
  const { indexes, setIndex } = React.useContext(SearchContext);
  const searchIndex = React.useMemo(() => indexes[project], [indexes, project]);
  const searchHistory = useSelector(selectSearchHistory(project));
  React.useEffect(() => {
    if (!searchIndex && !error) {
      Api.fetchSearchIndex(entity, project, token, lang)
        .then(r => {
          setIndex(project, r);
          setHint("");
        })
        .catch(() => {
          setHint(translate(SearchIntl.LoadingError));
          setError(true);
        });
    }
  }, [entity, project, error, token, lang, searchIndex, setIndex, translate]);

  const { index, refs } = React.useMemo(() => {
    if (searchIndex?.index) {
      return {
        index: Index.load(searchIndex.index),
        refs: searchIndex.refs || {},
      };
    }
    return { index: undefined, refs: undefined };
  }, [searchIndex]);

  const history = React.useMemo(() => {
    if (config.showHistory && refs) {
      return mapResults(searchHistory.slice(0, config.historyCount), refs);
    } else {
      return [];
    }
  }, [searchHistory, refs, config.showHistory, config.historyCount]);

  const filters = React.useMemo(() => {
    return {
      card: config.inCardTitle,
      actionContent: config.inActionContent,
      actionTitle: config.inActionTitle,
      exclude: config.exclude.map(id => id.toString()),
    };
  }, [config]);

  const search = React.useCallback(
    terms => {
      let trimmed = terms.trim();
      if (trimmed.length >= minLength) {
        if (index && refs) {
          trimmed = unicodeNormalizer(trimmed);
          if (!trimmed.endsWith("*")) {
            trimmed += "*";
          }
          const results = index.search(trimmed).filter(filterResults(filters));
          const parsed = mapResults(
            results.map(({ ref }) => ref),
            refs
          );
          if (parsed.length === 0) {
            setHint(translate(SearchIntl.NoResults));
          } else if (parsed.length > maxResults) {
            setHint(translate(SearchIntl.ManyResults));
          } else {
            setHint("");
          }
          setResults(parsed.slice(0, maxResults));
        } else {
          setHint(translate(SearchIntl.Loading));
          setDelayed(terms);
        }
      } else {
        if (trimmed.length > 0 && trimmed.length < minLength) {
          setHint(translate(SearchIntl.MoreChars));
        }
        setResults([]);
      }
    },
    [filters, index, refs, translate]
  );

  React.useEffect(() => {
    if (searchIndex && delayed) {
      setDelayed(undefined);
      search(delayed);
    }
  }, [searchIndex, delayed, search]);

  return { hint, history, results, search };
}
