const UPLOAD_PIC_REQUEST = "UPLOAD_PIC_REQUEST";
const UPLOAD_PIC_SUCCESS = "UPLOAD_PIC_SUCCESS";
const UPLOAD_PIC_FAILURE = "UPLOAD_PIC_FAILURE";
const CLEAR_UPLOAD_PIC = "CLEAR_UPLOAD_PIC";
const REMOVE_UPLOADED_PIC = "REMOVE_UPLOADED_PIC";
const INIT_UPLOADED_PIC = "INIT_UPLOADED_PIC";

export default {
  UPLOAD_PIC_REQUEST,
  UPLOAD_PIC_SUCCESS,
  UPLOAD_PIC_FAILURE,
  CLEAR_UPLOAD_PIC,
  REMOVE_UPLOADED_PIC,
  INIT_UPLOADED_PIC,
};
