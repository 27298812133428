import React from "react";
import { Badge } from "@material-ui/core";
import { Notifications as Icon } from "@material-ui/icons";
import { makeSelectPrefsList } from "redux/prefs/selector";
import { useSelector } from "react-redux";
import { selectCurrent } from "redux/workflow/selector";

export const NotificationsIcon = () => {
  const { project } = useSelector(selectCurrent);
  const selectPrefsList = React.useMemo(makeSelectPrefsList, []);
  const notifications = useSelector(state =>
    selectPrefsList(state, project, "notifications")
  );

  const count = React.useMemo(() => {
    return Object.values(notifications).reduce((prev, n) => {
      if (!n.view) {
        return prev + 1;
      }
      return prev;
    }, 0);
  }, [notifications]);

  if (!project) return <Icon />;

  return (
    <Badge
      badgeContent={count}
      max={9}
      color="primary"
      classes={{ badge: "icon-badge" }}
    >
      <Icon />
    </Badge>
  );
};
