import { createSelector } from "reselect";
import defaultCss from "css/default";

function appendCss(currentCss, css) {
  css = css.trim();
  if (!css) {
    return currentCss;
  }
  let imports = "";
  if (css.startsWith("@import")) {
    const pos = css.indexOf("\n");
    if (pos > -1) {
      imports = css.substring(0, pos);
      css = css.substring(pos + 1);
    } else {
      imports = css;
      css = "";
    }
  }
  return imports + currentCss + "\n" + css;
}

// TODO: remove appendCss from selectors.
//       selector should be pure functions
export const selectTheme = createSelector(
  (state) => state.theme,
  (theme) => {
    const r = { css: "", logo: "", logoRight: "", logoLeft: "" };
    r.css = appendCss(r.css, defaultCss);
    r.css = appendCss(r.css, theme.globalCss);
    r.css = appendCss(r.css, theme.projectCss);

    if (theme.projectLogo || theme.projectLogoLeft || theme.projectLogoRight) {
      r.logo = theme.projectLogo;
      r.logoLeft = theme.projectLogoLeft;
      r.logoRight = theme.projectLogoRight;
    } else if (
      theme.globalLogo ||
      theme.globalLogoLeft ||
      theme.globalLogoRight
    ) {
      r.logo = theme.globalLogo;
      r.logoLeft = theme.globalLogoLeft;
      r.logoRight = theme.globalLogoRight;
    } else {
      r.logoRight = "assets/images/logo-qrockme.png";
    }
    return r;
  }
);
