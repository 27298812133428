import React from "react";
import PropTypes from "prop-types";
import Proposal from "./Proposal";
import ActionsIntl from "../Actions.i";
import useFormatMessage from "hooks/useFormatMessage";
import ResultsScreen from "../Common/ResultsScreen";
import clsx from "clsx";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  const t = useFormatMessage();
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";
  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <div className="action-proposals">
        <Proposal
          disabled={true}
          checked={true}
          label={state.correct ? state.name : t(ActionsIntl.ScanError)}
          correct={state.correct}
          className={clsx({
            "action-proposal-correct": showSolution && state.correct,
          })}
        />
      </div>
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
