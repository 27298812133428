import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Home.Title',
    defaultMessage: 'Scan',
  },
  SubTitle: {
    id: 'Home.SubTitle',
    defaultMessage: 'Veuillez scanner un Qr Code',
  },
  SubTitleError: {
    id: 'Home.SubTitleError',
    defaultMessage: "Ce QR Code n'existe pas, veuillez scanner un QR Code",
  }
});
