import React from 'react';
import Logo from 'common/Logo';

import { Container } from './SplashScreen.s';

function SplashScreen() {
  return (
    <Container>
      <Logo />
    </Container>
  );
}

export default React.memo(SplashScreen);
