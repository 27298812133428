import React from "react";
import {
  Backdrop,
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import FiltersIcon from "common/icons/Filter";
import ClearFiltersIcon from "common/icons/FilterClear";
import useResizeObserver from "use-resize-observer/polyfilled";
import SubScreens from "./Common/SubScreens";
import { useField } from "react-final-form";
import { useDispatch } from "react-redux";
import { wfUpdateAction } from "redux/workflow/action";
import produce from "immer";
import clsx from "clsx";
import { isEmpty } from "lodash";
import FilterChip from "./ActionDynList/FilterChip";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const FILTERS_PANEL_MIN_WIDTH = 600;

const useStyles = makeStyles({
  filtersWrapper: {
    display: "flex",
    "& .action-filters-panel": {
      width: 200,
      flexGrow: 0,
      flexShrink: 0,
      textAlign: "left",
      paddingRight: 10,
    },

    "& .action-screen": {
      flexGrow: 1,
    },
  },
  filtersModalButton: {
    marginTop: -5,
    marginBottom: 5,
  },
  filtersModal: {
    position: "absolute",
    left: 1,
    bottom: 0,
    right: 1,
    zIndex: 20000,
    outline: "none",
  },
  filtersResetButton: {
    marginTop: -100,
    left: 0,
  },
});

const ActionDynList = ({ config, data, children, guid, action }) => {
  const [showModal, setShowModal] = React.useState(false);
  const filters = config.filters;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { ref, width } = useResizeObserver();

  const initialValue = React.useMemo(() => {
    return {
      filters: filters.reduce((prev, cur) => {
        return { ...prev, [cur.field]: cur.value };
      }, {}),
    };
  }, [filters]);

  const field = useField(`action-${action.id}`, {
    initialValue,
  });
  const { onChange, value } = field.input;
  const filtersState = value.filters;

  const handleShowModalClick = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const handleClearFiltersClick = React.useCallback(() => {
    const filters = Object.keys(filtersState).reduce((prev, cur) => {
      return { ...prev, [cur]: [] };
    }, {});
    onChange({ filters });
    dispatch(wfUpdateAction(guid, { filters }));
  }, [onChange, dispatch, guid, filtersState]);

  const handleFilterClick = modelFieldGuid => e => {
    const { name, checked } = e.target;
    const updatedValue = produce(value, draft => {
      if (!draft.filters[modelFieldGuid]) {
        draft.filters[modelFieldGuid] = [];
      }
      if (checked) {
        draft.filters[modelFieldGuid].push(name);
      } else {
        draft.filters[modelFieldGuid] = draft.filters[modelFieldGuid].filter(
          v => v !== name
        );
      }
    });
    onChange(updatedValue);
    dispatch(wfUpdateAction(guid, updatedValue));
  };

  const isFiltered = React.useMemo(
    () => Object.values(filtersState).some(v => !isEmpty(v)),
    [filtersState]
  );

  const childrenCount = children.empty.length + children.content.length;
  if (!childrenCount && !Boolean(filters?.length)) return null;

  const showFiltersPanel =
    Boolean(filters?.length) && width > FILTERS_PANEL_MIN_WIDTH;
  const showFiltersButton = Boolean(filters?.length) && !showFiltersPanel;

  const filtersFragment = filters.map(f => (
    <React.Fragment key={f.field}>
      <h2 className="action-filters-title">{f.label}</h2>
      {f.config.map(v => {
        return typeof v === "object" ? (
          <FilterChip
            key={v.value}
            name={v.label}
            value={v.value}
            image={v.icon}
            operator={f.operator}
            onClick={handleFilterClick(f.field)}
            checked={Boolean((filtersState[f.field] || []).includes(v.value))}
          />
        ) : (
          <FilterChip
            key={v}
            name={v}
            value={v}
            operator={f.operator}
            onClick={handleFilterClick(f.field)}
            checked={Boolean((filtersState[f.field] || []).includes(v))}
          />
        );
      })}
    </React.Fragment>
  ));

  return (
    <div ref={ref}>
      {Boolean(width) && (
        <>
          <Title config={config} data={data}>
            {showFiltersButton && (
              <IconButton
                onClick={handleShowModalClick}
                className={clsx(
                  classes.filtersModalButton,
                  "qrockme-icon-button",
                  {
                    "qrockme-icon-button-primary": isFiltered,
                  }
                )}
              >
                <FiltersIcon />
              </IconButton>
            )}
          </Title>
          {showFiltersPanel ? (
            <div
              className={clsx(
                classes.filtersWrapper,
                "action-filters-container"
              )}
            >
              <div className="action-filters-panel action-filters">
                {filtersFragment}
              </div>
              <SubScreens
                actions={children}
                parent={action}
                screens={["empty", "content"]}
              />
            </div>
          ) : (
            <SubScreens
              actions={children}
              parent={action}
              screens={["empty", "content"]}
            />
          )}
          {showFiltersButton && (
            <Modal
              open={showModal}
              onClose={() => setShowModal(false)}
              className={clsx(classes.filtersModal, "qrockme-action-dynlist")}
              aria-label={`Filtrer ${config.title}`}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              closeAfterTransition
            >
              <Collapse in={showModal} className={classes.filtersModal} appear>
                <div className="action-filters-modal action-filters">
                  <div className="action-filters-modal-header">
                    <Box flexGrow={1} textAlign="left">
                      <Button
                        className="action-filters-modal-close-button"
                        onClick={() => setShowModal(false)}
                        variant="text"
                        size="small"
                      >
                        x fermer
                      </Button>
                    </Box>
                    {isFiltered && (
                      <IconButton
                        onClick={handleClearFiltersClick}
                        className={clsx("qrockme-icon-button")}
                      >
                        <ClearFiltersIcon />
                      </IconButton>
                    )}
                  </div>
                  <div className="action-filters-modal-content">
                    {filtersFragment}
                  </div>
                </div>
              </Collapse>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

ActionDynList.classNames = ["qrockme-action-dynlist"];

Object.assign(ActionDynList, AbstractAction);
export default ActionDynList;
