import { defineMessages } from "react-intl";

export default defineMessages({
  UserExists: {
    id: "ErrorTrad.UserExists",
    defaultMessage: "Vous avez déjà un compte",
  },
  Maxlicenses: {
    id: "ErrorTrad.Maxlicenses",
    defaultMessage:
      "Quota utilisateur atteint, veuillez contacter votre administrateur",
  },
});
