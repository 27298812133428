import { defineMessages } from "react-intl";

export default defineMessages({
  Cancel: {
    id: "CommonTrad.Cancel",
    defaultMessage: "Cancel",
  },
  ActivateNow: {
    id: "CommonTrad.ActivateNow",
    defaultMessage: "Activate now",
  },
  See: {
    id: "CommonTrad.See",
    defaultMessage: "See",
  },
  FieldRequired: {
    id: "CommonTrad.FieldRequired",
    defaultMessage: "This field is required",
  },
  Online: {
    id: "CommonTrad.Online",
    defaultMessage: "Online",
  },
  Offline: {
    id: "CommonTrad.Offline",
    defaultMessage: "Offline",
  },
  Status: {
    id: "CommonTrad.Status",
    defaultMessage: "Status",
  },
  Unknown: {
    id: "CommonTrad.Unknown",
    defaultMessage: "Unknown",
  },
  Create: {
    id: "CommonTrad.Create",
    defaultMessage: "Create",
  },
  Name: {
    id: "CommonTrad.Name",
    defaultMessage: "Name",
  },
  Type: {
    id: "CommonTrad.Type",
    defaultMessage: "Type",
  },
  User: {
    id: "CommonTrad.User",
    defaultMessage: "User",
  },
  Pwd: {
    id: "CommonTrad.Pwd",
    defaultMessage: "Password",
  },
  NamePlaceholder: {
    id: "CommonTrad.NamePlaceholder",
    defaultMessage: "Enter a name",
  },
  TypePlaceholder: {
    id: "CommonTrad.TypePlaceholder",
    defaultMessage: "Enter a type",
  },
  PwdPlaceholder: {
    id: "CommonTrad.PwdPlaceholder",
    defaultMessage: "Enter a password",
  },
  Yes: {
    id: "CommonTrad.Yes",
    defaultMessage: "Yes",
  },
  No: {
    id: "CommonTrad.No",
    defaultMessage: "No",
  },
  Validate: {
    id: "CommonTrad.Validate",
    defaultMessage: "Valider",
  },
  Retry: {
    id: "CommonTrad.Retry",
    defaultMessage: "Réessayer",
  },
  LogOut: {
    id: "CommonTrad.LogOut",
    defaultMessage: "Déconnexion",
  },
  ToLogin: {
    id: "CommonTrad.ToLogin",
    defaultMessage: "Se connecter",
  },
});
