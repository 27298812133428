import React from "react";
import { makeStyles } from "@material-ui/core";
import { PlayArrow as BackIcon } from "@material-ui/icons";
import { NavButton } from "./BackButton.s";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";
import { useLocation } from "react-router";

const useStyles = makeStyles({
  icon: {
    transform: "rotate(180deg)",
  },
});

const BackButton = ({ ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.pathname === "/_") {
      dispatch(goBack());
    }
  };

  return (
    <NavButton className="bottomnav-item" onClick={handleBackClick} {...rest}>
      <BackIcon className={classes.icon} fontSize="inherit" />
    </NavButton>
  );
};

export default BackButton;
