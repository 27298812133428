import produce from "immer";

export function rankProposals(proposals) {
  return proposals.reduce(
    produce((draft, cur) => {
      if (cur.value === "1") {
        draft.correct.push(cur.guid);
      } else if (cur.value === "-1") {
        draft.wrong.push(cur.guid);
      }
    }),
    {
      correct: [],
      wrong: [],
    }
  );
}

export default function checkResults(proposals, state, attempts, multiple) {
  const { answers, trial } = state;
  const { correct, wrong } = rankProposals(proposals);

  if (correct.length === 0 && wrong.length === 0) {
    return { screen: "neutral", correct, wrong };
  }

  if (multiple) {
    if (
      correct.every(v => answers.indexOf(v) > -1) && // All correct proposals are selected
      !wrong.some(v => answers.indexOf(v) > -1) // No wrong proposal selected
    ) {
      return { screen: "success", correct, wrong };
    } else if (attempts === 0 || trial + 1 < attempts) {
      // Unlimited attempts or trial left
      return { screen: "retry" };
    }
    return { screen: "fail", correct, wrong };
  } else {
    if (correct.indexOf(answers[0]) > -1) {
      return { screen: "success", correct, wrong };
    }
    if (answers.length === 0 || wrong.indexOf(answers[0]) > -1) {
      if (attempts === 0 || trial + 1 < attempts) {
        // Unlimited attempts or trial left
        return { screen: "retry" };
      } else {
        return { screen: "fail", correct, wrong };
      }
    }
    return { screen: "neutral", correct, wrong };
  }
}
