import React from "react";
import { makeStyles } from "@material-ui/core";
import { Check as CorrectIcon, Close as WrongIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    fontSize: "1.5rem",
  },
});

const CorrectionIcon = ({ className, style, correct }) => {
  const classes = useStyles();

  if (correct === undefined) return null;
  return (
    <span
      className={clsx(classes.root, "action-correction-icon", className)}
      style={style}
    >
      {correct ? (
        <CorrectIcon htmlColor="#AFD286" fontSize="inherit" />
      ) : (
        <WrongIcon htmlColor="#ff3041" fontSize="inherit" />
      )}
    </span>
  );
};

export default CorrectionIcon;
