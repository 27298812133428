import React from "react";
import { useDispatch } from "react-redux";
import ScreenMain from "./ActionQText/ScreenMain";
import ScreenResults from "./ActionQText/ScreenResults";
import prepareAction from "./ActionQText/prepareAction";
import onSubmit from "./ActionQText/onSubmit";
import quizShouldSkip from "./Common/quizShouldSkip";
import {
  wfSagaSubmitAction,
  WF_SAGA_SET_ENTITY_GUID_DONE,
} from "redux/workflow/action";
import { Field, useForm } from "react-final-form";
import { required } from "common/components/rules";
import AbstractAction from "./AbstractAction";

const ActionQText = ({ action, state, config, ...rest }) => {
  const dispatch = useDispatch();
  const form = useForm();

  const handleSubmit = () => {
    dispatch(wfSagaSubmitAction(action.guid));
  };

  const { screen, trial, answer } = state;
  React.useEffect(() => {
    form.change(`action-${action.id}`, { screen, trial, answer });
  }, [screen, trial, answer, action.id, form]);

  const Screen = screen === "info" ? ScreenMain : ScreenResults;

  const handleValidate = React.useCallback(
    v => {
      if (config.required && (screen === "info" || screen === "retry")) {
        return required();
      }
    },
    [config.required, screen]
  );

  return (
    <>
      <Screen
        action={action}
        onSubmit={handleSubmit}
        state={state}
        config={config}
        {...rest}
      />
      <Field
        name={`action-${action.id}`}
        component="input"
        type="hidden"
        validate={handleValidate}
      />
    </>
  );
};

ActionQText.initialState = {
  answer: "",
  trial: 0,
  screen: "info",
  clearOn: WF_SAGA_SET_ENTITY_GUID_DONE,
  actionType: "Q_TEXT",
};

ActionQText.prepareAction = prepareAction;
ActionQText.onSubmit = onSubmit;
ActionQText.shouldSkip = quizShouldSkip;
ActionQText.skipValue = ({ state: { screen, trial, answer } }) => ({
  screen,
  trial,
  answer,
});
ActionQText.classNames = ["qrockme-action-qtext"];

Object.assign(ActionQText, AbstractAction);
export default ActionQText;
