import React from "react";
import PropTypes from "prop-types";
import ResultsScreen from "../Common/ResultsScreen";
import populateLabels from "./populateLabels";
import Board from "./Board";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";

  const labels = config.proposals;
  const pointsWithLabels = React.useMemo(
    () => populateLabels(state.points, labels),
    [labels, state.points]
  );

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={state.correctCount}
      headerTotal={state.points.length}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <Board
        src={config.image}
        alt={config.title}
        points={pointsWithLabels}
        showSolution={showSolution}
      />
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
