import * as Api from "api";
import apiCall from "./apiCall";
import startWorkFlow from "./startWorkFlow";
import { push, replace } from "connected-react-router";
import { put, call, select } from "redux-saga/effects";
import {
  wfHistoryRemoveFuture,
  wfStartHistoryTraveling,
  WF_SAGA_START_OR_RESUME_DONE,
} from "redux/workflow/action";
import {
  selectHistory,
  selectCurrent,
  selectResuming,
} from "redux/workflow/selector";

export default function* startOrResume({ payload: { query } }) {
  const resuming = yield select(selectResuming);
  const history = yield select(selectHistory);
  const isResuming = Boolean(resuming || query?.u || query?.resume);
  if (history.length > 1 || (history.length && isResuming)) {
    const h = [...history]; // Prevent state mutating
    if (query?.u) {
      yield call(apiCall, Api.ssoConnect, query.u, query.s);
    }
    if (!resuming && !query?.u && !query?.resume) {
      // Retour arrière
      h.pop();
    }
    const prev = h.reverse().find(v => !v.skipped); // On trouve la dernière carte non passée
    if (prev) {
      yield put(wfHistoryRemoveFuture(prev)); // User can't go forward
      yield put(wfStartHistoryTraveling()); // Prevent push next nav to history stack
      if (query?.u || query?.resume) {
        yield put(replace("/_", prev));
      } else {
        yield put(push("/_", prev));
      }
    } else {
      // Si pas de carte trouvé il faut revenir à la liste des projets
      yield put(push("/projects"));
    }
  } else {
    // Il faut vérifier s'il y a un projet à démarrer
    const current = yield select(selectCurrent);
    if (current.projectData) {
      // Start workflow
      yield call(startWorkFlow);
    } else {
      yield put(push("/projects"));
    }
  }
  yield put({
    type: WF_SAGA_START_OR_RESUME_DONE,
  });
}
