import React from "react";
import { Hidden, makeStyles } from "@material-ui/core";
import useResizeObserver from "use-resize-observer/polyfilled";
import { Check as CorrectIcon, Close as WrongIcon } from "@material-ui/icons";
import { useDrop, useDrag } from "react-dnd";
import clsx from "clsx";

const useStyles = makeStyles({
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  root: {
    position: "absolute",
    color: "#FFFFFF",
    filter: "drop-shadow(0 2px 4px rgba(173, 173, 173, 0.8))",
    animation: "ease 0.3s $fadeIn",
    transition: "opacity 0.3s",
    "&.hidden": {
      opacity: 0,
    },
  },
  icon: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    borderRadius: "50%",
    height: 30,
    width: 30,
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    "-webkit-tap-highlight-color": "transparent",
  },
  label: {
    minHeight: 34,
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    userSelect: "none",
    "&.draggable": {
      cursor: "grab",
    },
  },
  arrow: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "17.3px 10px 0 10px",
    borderColor: "transparent transparent transparent transparent",
  },
  point: {
    position: "relative",
    minWidth: 30,
    minHeight: 43,
    "&.action-point-hover": {
      "& .draggables": {
        display: "none",
      },
      "& .magnetic-point-icon": {
        backgroundColor: "#FFF",
      },
    },
    "& .magnetic-point-icon": {
      width: 20,
      height: 20,
      border: "1px solid #FFF",
      borderRadius: "50%",
    },
  },
  draggables: {
    position: "relative",
  },
});

const maxOut = 15;

const PointIcon = () => {
  return <div className="magnetic-point-icon"></div>;
};

const Label = React.forwardRef(({ className, children, onClick }, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      className={clsx(classes.label, "action-point-label", className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

const DraggableLabel = ({
  guid,
  label,
  from,
  dndGroup,
  onDragging,
  onClick,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, guid, label, from },
    begin: () => {
      if (onDragging) {
        onDragging(true);
      }
    },
    end: () => {
      if (onDragging) {
        onDragging(false);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <Label
      ref={drag}
      className={clsx({
        draggable: true,
        dragging: isDragging,
      })}
      onClick={onClick}
    >
      {label}
    </Label>
  );
};

const Point = ({
  x,
  y,
  index,
  answers,
  proposals,
  active,
  visible,
  showSolution,
  dndGroup,
  boardWidth,
  onDrop,
  onClick,
  onDragging,
  onOver,
  onLeave,
  correct,
}) => {
  const dropRef = React.useRef();
  const [{ isOver }, drop] = useDrop({
    accept: dndGroup || "__disabled-drop",
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const { ref, width, height } = useResizeObserver();
  const classes = useStyles();

  React.useEffect(() => {
    if (onOver && isOver) {
      onOver();
    }
    if (onLeave && !isOver) {
      onLeave();
    }
  }, [onOver, onLeave, isOver]);

  const top = y - (height || 48);
  let left = x - (width || 30) / 2;
  let arrowOffset = -10;
  if (left < -maxOut) {
    arrowOffset = -10 + left + maxOut;
    left = -maxOut;
  } else if (left + width > boardWidth + maxOut) {
    const over = left + width - boardWidth;
    left -= over - maxOut;
    arrowOffset = -10 + over - maxOut;
  }

  let Icon = PointIcon;
  if (correct !== undefined && correct) {
    Icon = CorrectIcon;
  } else if (correct !== undefined && !correct) {
    Icon = WrongIcon;
  }

  if (dndGroup) {
    drop(dropRef);
  }

  return (
    <div
      ref={ref}
      style={{
        left,
        top,
        zIndex: active || answers.length === 0 ? 1 : undefined,
      }}
      className={clsx(classes.root, { hidden: !visible })}
    >
      <div
        ref={dropRef}
        className={clsx(classes.point, "action-point", {
          "action-point-hover": isOver,
          "action-point-correct": correct,
          "action-point-wrong": correct !== undefined && !correct,
        })}
      >
        <div
          className={clsx(classes.icon, "action-point-icon")}
          onClick={onClick}
        >
          <Icon fontSize="inherit" />
        </div>
        <div
          className={clsx(classes.arrow, "action-point-arrow")}
          style={{ transform: `translateX(${arrowOffset}px)` }}
        />
        <div className={clsx(classes.draggables)}>
          {dndGroup &&
            answers.map(a => (
              <React.Fragment key={a.guid}>
                <Hidden smDown={active} mdUp={true}>
                  <Label className="tiny" onClick={onClick}>
                    1
                  </Label>
                </Hidden>
                <Hidden smDown={!active}>
                  <DraggableLabel
                    key={a.guid}
                    label={a.label}
                    guid={a.guid}
                    dndGroup={dndGroup}
                    from={index}
                    onDragging={onDragging}
                    onClick={onClick}
                  />
                </Hidden>
              </React.Fragment>
            ))}
          {!dndGroup &&
            active &&
            answers.map(({ guid, label }) => (
              <Label key={guid} className="active" onClick={onClick}>
                <div>{label}</div>
                {!correct && showSolution && (
                  <div className="action-point-solution">
                    {proposals[0].label}
                  </div>
                )}
              </Label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Point;
