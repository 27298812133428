import React from "react";
import { makeStyles } from "@material-ui/core";
import nl2br from "react-nl2br";
import clsx from "clsx";
import useCancelableRequest from "common/components/useCancelableRequest";
import { useActionNext } from "common/actions";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
  },
  text: {
    flexGrow: 1,
    textAlign: "right",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
});

const CardLarge = ({ config, data, disabled, onClick }) => {
  const classes = useStyles();

  const { title, image, desc, additionals } = useActionNext(config, data);
  const [result] = useCancelableRequest(image);

  const style = {};
  if (image) {
    style.backgroundImage = `url('${result}')`;
  }

  const hasText = React.useMemo(
    () => Boolean(((title || "") + (desc || "")).trim()),
    [title, desc]
  );

  return (
    <div
      className={clsx(classes.root, "qrockme-cardlarge", {
        "qrockme-cardlarge-disabled": disabled,
      })}
      style={style}
      onClick={onClick}
    >
      {hasText && (
        <div className={clsx(classes.text, "qrockme-cardlarge-text")}>
          {title && (
            <div className="qrockme-cardlarge-title">{nl2br(title)}</div>
          )}
          {desc && <div className="qrockme-cardlarge-desc">{nl2br(desc)}</div>}
          {additionals.length > 0 &&
            additionals.map(({ content }, idx) => {
              if (!content) return null;
              const className = [
                "qrockme-cardlarge-add",
                `qrockme-cardlarge-add-${idx + 1}`,
              ].join(" ");
              return (
                <div className={className} key={idx}>
                  {nl2br(content)}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CardLarge;
