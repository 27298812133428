import React from "react";
import { makeStyles } from "@material-ui/core";
import DraggableSpot from "./DraggableSpot";
import Spot from "./Spot";

const useStyles = makeStyles({
  root: {
    height: 20,
    display: "flex",
    marginTop: 6,
    marginBottom: 6,
  },
  count: {
    fontSize: 14,
    paddingRight: 10,
    color: "#565656",
    display: "flex",
    alignItems: "center",
  },
  disabledSpot: {
    borderColor: "#B3B3B3",
    "& .action-spot-dot": {
      background: "#B3B3B3",
    },
  },
});

const Bucket = ({ count, dndGroup, onDragging }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.count}>{count}</span>
      {count > 0 ? (
        <DraggableSpot dndGroup={dndGroup} onDragging={onDragging} />
      ) : (
        <Spot className={classes.disabledSpot} />
      )}
    </div>
  );
};

export default Bucket;
