import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 31 31" {...rest}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g transform="translate(-169.000000, -11.000000)">
          <g id="Group-7">
            <g id="Group-2" transform="translate(36.297491, 0.000000)">
              <g id="Group-6" transform="translate(133.000000, 11.000000)">
                <g id="Group-5">
                  <path
                    d="M0.500250645,-1.58628666e-12 L13.5,-1.58539848e-12 L13.5,3.54545455 L4.04545455,3.54545455 L4.04545455,13 L0.5,13 L0.500250645,-1.58628666e-12 Z"
                    id="Combined-Shape"
                  />
                  <path
                    d="M17.5002506,18 L30.5,18 L30.5,21.5454545 L21.0454545,21.5454545 L21.0454545,31 L17.5,31 L17.5002506,18 Z"
                    id="Combined-Shape-Copy"
                    transform="translate(24.000000, 24.500000) scale(-1, 1) rotate(-90.000000) translate(-24.000000, -24.500000) "
                  />
                  <path
                    d="M21.0454545,13 L17.5,13 L17.5,-6.82121026e-13 L30.5,8.42703685e-12 L30.5,3.54545455 L21.0454545,3.54545455 L21.0454545,13 Z"
                    id="Combined-Shape-Copy-3"
                    transform="translate(24.000000, 6.500000) rotate(-270.000000) translate(-24.000000, -6.500000) "
                  />
                  <path
                    d="M0.5,18 L13.5,18 L13.5,21.5454545 L4.04545455,21.5454545 L4.04545455,31 L0.5,31 L0.5,18 Z"
                    id="Combined-Shape-Copy-2"
                    transform="translate(7.000000, 24.500000) scale(-1, 1) rotate(-180.000000) translate(-7.000000, -24.500000) "
                  />
                </g>
                <path
                  d="M13,7 L13,10.4935824 L22,10.4935824 L22,8.49358244 L23,8.49358244 L23,16.4935824 L22,16.4935824 L22,14.4935824 L19,14.4935824 L19,16.4935824 L20,16.4935824 L20,17.493 L13,17.4935824 L13,19.4935824 L22,19.4935824 L22,18.493 L23,18.4935824 L23,22.4935824 L22,22.4935824 L22,20.4935824 L20,20.4935824 L20,24 L19,24 L19,20.4935824 L17,20.4935824 L17,22.4935824 L16,22.4935824 L16,24 L13,24 L13,22.4935824 L14,22.4935824 L14,20.4935824 L10,20.4935824 L10,19.493 L12,19.4935824 L12,17.4935824 L9,17.4935824 L9,18.493 L8,18.493 L8,15.493 L9,15.493 L9,16.4935824 L10,16.4935824 L10,13.4935824 L14,13.4935824 L14,11.4935824 L10,11.4935824 L10,10.493 L12,10.4935824 L12,8.49358244 L11,8.49358244 L11,7 L13,7 Z M9,20.4935824 L9,22.4935824 L10,22.4935824 L10,24 L8,24 L8,20.493 L9,20.4935824 Z M18,14.4935824 L11,14.4935824 L11,16.4935824 L18,16.4935824 L18,14.4935824 Z M22,11.4935824 L20,11.4935824 L20,13.4935824 L22,13.4935824 L22,11.4935824 Z M19,11.4935824 L17,11.4935824 L17,13.4935824 L19,13.4935824 L19,11.4935824 Z M9,11.4935824 L9,13.4935824 L8,13.493 L8,11.493 L9,11.4935824 Z M9,8.49358244 L9,9.493 L8,9.493 L8,8.49358244 L9,8.49358244 Z M20,7 L20,8.49358244 L16,8.49358244 L16,7 L20,7 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
