import styled from 'styled-components/macro';
import Button from 'common/components/Button';
import loginBackground from 'common/loginBackground.svg';

/* eslint-disable import/prefer-default-export */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  height: 97px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  width: 177px;
  background: url(${loginBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin: 16px 0;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const ButtonLogOut = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TagsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 30vh;
  overflow-y: auto;
`;

export const TagContainer = styled.div`
  display: flex;
  height: 33px;
  padding: 0 16px;
  margin-right: 8px;
  margin-bottom:16px;
  line-height: 33px;
  border-radius: 16.5px;
  font-size: 14px;
  color: #ffffff;
  font-width: normal;
`;
