import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  VisibilityOff as HideIcon,
  Visibility as ShowIcon,
} from "@material-ui/icons";
import useResizeObserver from "use-resize-observer/polyfilled";
import useMath from "../Common/useMath";
import ImageLoader from "common/components/ImageLoader";
import Point from "./Point";
import clsx from "clsx";

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    margin: "auto",
    paddingBottom: 8,
    userSelect: "none",
    "& *": {
      userSelect: "none",
    },
  },
  image: {
    "-webkit-transform": "translateZ(0)",
    maxWidth: "100%",
  },
  controls: {
    display: "flex",
  },
  help: {
    flexGrow: 1,
    textAlign: "left",
  },
  showIcon: {
    color: "#ff3041",
  },
});

const Board = React.forwardRef(
  (
    {
      src,
      alt,
      isMainScreen,
      points,
      dndGroup,
      onDrop,
      onLoad,
      onDragging,
      showSolution,
      onOver,
      onLeave,
    },
    ref
  ) => {
    const containerRef = React.useRef();
    const { width, height } = useResizeObserver({
      ref: containerRef,
    });
    const math = useMath(width, height);
    const [loaded, setLoaded] = React.useState(false);
    const [visible, setVisible] = React.useState(true);
    const [active, setActive] = React.useState();
    const classes = useStyles();

    const pointsWithPositions = React.useMemo(() => {
      if (!loaded) return [];
      return points.map(p => {
        const { x, y } = math.toScreen(p.x, p.y);
        return {
          ...p,
          x,
          y,
        };
      });
    }, [loaded, math, points]);

    const handleLoad = () => {
      setLoaded(true);
      if (onLoad) {
        onLoad();
      }
    };

    const handleDrop = index => item => {
      onDrop(index, item);
      if (active !== undefined) {
        setActive(index);
      }
    };

    const handleClick = index => () => {
      if (index === active) {
        setActive();
      } else {
        setActive(index);
      }
    };

    return (
      <>
        <div
          ref={ref}
          className={clsx(classes.controls, "action-mangetic-controls")}
        >
          <div className={clsx(classes.help, "action-help")}>
            {isMainScreen && (
              <img alt="Aide" src="assets/images/help/magnetic.gif" />
            )}
          </div>
          <IconButton size="small" onClick={() => setVisible(s => !s)}>
            {visible ? <ShowIcon /> : <HideIcon className={classes.showIcon} />}
          </IconButton>
        </div>
        <div ref={containerRef} className={classes.imageContainer}>
          <ImageLoader
            className={classes.image}
            src={src}
            alt={alt}
            onLoad={handleLoad}
          />
          {pointsWithPositions.map((p, i) => (
            <Point
              key={i}
              index={i}
              {...p}
              containerRef={ref}
              dndGroup={dndGroup}
              showSolution={showSolution}
              active={i === active || (p.correct !== undefined && !p.correct)}
              visible={visible}
              boardWidth={width}
              onDrop={handleDrop(i)}
              onClick={handleClick(i)}
              onDragging={onDragging}
              onOver={onOver}
              onLeave={onLeave}
            />
          ))}
        </div>
      </>
    );
  }
);

export default Board;
