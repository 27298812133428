import React from "react";
import { Field, useField } from "react-final-form";
import prepareAction from "./Survey/prepareAction";
import ScreenMain from "./Survey/ScreenMain";
import CollapseScreens from "../Common/CollapseScreens";
import AbstractAction from "../AbstractAction";

const Survey = ({ action, state, config, children, ...rest }) => {
  const field = useField(`action-${action.id}`);
  const fieldValue = React.useMemo(
    () => (state.answers.length ? JSON.stringify(state.answers) : "[]"),
    [state]
  );
  const { onChange } = field.input;

  React.useEffect(() => {
    onChange(state.answers.length ? JSON.stringify(state.answers) : "[]");
  }, [state, onChange]);

  const screenInside = config.ssPosition === "inside";
  const values = state.proposals
    .map(p => (state.answers.includes(p.guid) ? p.valueSimple : undefined))
    .filter(Boolean);

  return (
    <>
      <ScreenMain
        action={action}
        state={state}
        config={config}
        actions={children}
        {...rest}
      >
        {screenInside && children}
      </ScreenMain>
      {!screenInside && <CollapseScreens actions={children} screens={values} />}
      <Field
        name={`action-${action.id}`}
        component="input"
        defaultValue={fieldValue}
        type="hidden"
        validate={config.required ? v => v && v === "[]" : undefined}
      />
    </>
  );
};

Survey.prepareAction = prepareAction;
Survey.onSubmit = function (action, state) {
  return { value: state.answers.length ? state.answers : undefined };
};
Survey.shouldSkip = function (action) {
  const screenChildren = (action.children[action.state.screen] || []).filter(
    action => action.type !== "CHECKPOINT"
  );
  if (
    screenChildren.length === 1 &&
    action.state.screen !== "info" &&
    !action.state.skipped
  ) {
    const child = screenChildren[0];
    if (child.component && child.component.shouldSkip) {
      const skip = child.component.shouldSkip(child);
      if (skip) {
        return {
          ...skip,
          history: true, // Keep card in history to allow go back
          state: { ...action.state, skipped: true, actionSubmit: true }, // Set skippped to no skip again if user go back
        };
      }
    }
  }
};
Survey.classNames = ["qrockme-action-qcmqcu", "qrockme-action-qcmqcusurvey"];

Object.assign(Survey, AbstractAction);
export default Survey;
