const regex = /<span data-missing-word="([a-z0-9-]*)">(.*?)<\/span>/gm;

export default function extractWords(value) {
  return [...value.matchAll(regex)].map(
    ({ 1: guid, 2: label, index, length }) => ({
      guid,
      label: label.replace("&nbsp;", "").replace(/<[^>]*>?/gm, ""), // Strip tags
      rawlabel: label,
      index,
      length,
    })
  );
}
