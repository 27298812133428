import React from "react";
import PropTypes from "prop-types";
import ResultsScreen from "../Common/ResultsScreen";
import Board from "./Board";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={state.correctCount}
      headerTotal={Object.keys(state.answers).length}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <Board
        html={config.html}
        answers={state.answers}
        showSolution={showSolution}
      />
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
