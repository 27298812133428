import React from "react";
import PropTypes from "prop-types";
import { TextField, makeStyles } from "@material-ui/core";
import ActionsIntl from "../Actions.i";
import useFormatMessage from "hooks/useFormatMessage";
import { useDispatch } from "react-redux";
import { wfSagaSetActionState } from "redux/workflow/action";
import MainScreen from "../Common/MainScreen";
import produce from "immer";
import clsx from "clsx";

const useStyles = makeStyles({
  input: {
    "&::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&[type=number]": { "-moz-appearance": "textfield" },
    textAlign: "center",
  },
  removeFieldsetBorders: {
    "& fieldset": {
      border: "none",
    },
  },
});

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = React.useCallback(
    e => {
      const value = e.target.value;
      dispatch(
        wfSagaSetActionState(
          action.guid,
          produce(draft => {
            draft.answer = value;
          })
        )
      );
    },
    [dispatch, action.guid]
  );

  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      onSubmit={onSubmit}
    >
      <TextField
        value={state.answer}
        className={classes.removeFieldsetBorders}
        variant="outlined"
        placeholder={t(ActionsIntl.AnswerPlaceholder)}
        inputProps={{ className: clsx("qrockme-input", classes.input) }}
        onChange={handleChange}
        fullWidth
      />
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
