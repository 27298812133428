import React from "react";
import { makeStyles } from "@material-ui/core";
import { parseISO, differenceInSeconds } from "date-fns";
import { useDispatch } from "react-redux";
import useFormatMessage from "hooks/useFormatMessage";
import clsx from "clsx";
import ActionsIntl from "./Actions.i";
import AbstractAction from "./AbstractAction";
import CircularCountDown from "./ActionCountDown/CircularCountDown";
import SubScreens from "./Common/SubScreens";
import { prepareChildren } from "./Common/utils";
import { wfSagaSetActionState, WF_SAGA_LOAD_CARD } from "redux/workflow/action";
import Title from "./Common/Title";

const useStyles = makeStyles({
  timers: { display: "flex", width: "100%", justifyContent: "space-around" },
  timer: { display: "flex" },
});

function distance(seconds) {
  let value = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  if (seconds > 0) {
    value.days = Math.floor(seconds / 60 / 60 / 24);
    value.hours = Math.floor((seconds - value.days * 60 * 60 * 24) / 60 / 60);
    value.minutes = Math.floor(
      (seconds - value.days * 60 * 60 * 24 - value.hours * 60 * 60) / 60
    );
    value.seconds = Math.floor(
      seconds -
        value.days * 60 * 60 * 24 -
        value.hours * 60 * 60 -
        value.minutes * 60
    );
  }
  return value;
}

const ActionCountDown = ({ config, data, action, children }) => {
  const timerRef = React.useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useFormatMessage();

  const target = React.useMemo(
    () => parseISO(config.date + "T" + config.time),
    [config]
  );

  const maxdays = React.useMemo(() => {
    const start = parseISO(config.start);
    const seconds = differenceInSeconds(target, start);
    return Math.floor(seconds / 60 / 60 / 24);
  }, [config, target]);

  const [seconds, setSeconds] = React.useState(() =>
    Math.max(differenceInSeconds(target, new Date()), 0)
  );
  const value = React.useMemo(() => distance(seconds), [seconds]);

  React.useEffect(() => {
    timerRef.current = setInterval(() => {
      setSeconds(Math.max(differenceInSeconds(target, new Date()), 0));
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [target]);

  React.useEffect(() => {
    if (seconds === 0) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      dispatch(
        wfSagaSetActionState(action.guid, {
          screen: "end",
          clearOn: WF_SAGA_LOAD_CARD,
          actionType: "COUNTDOWN",
        })
      );
    }
  }, [seconds, action.guid, dispatch]);

  const hideTimers = React.useMemo(
    () => (children?.end || []).length > 0,
    [children]
  );
  return (
    <>
      <Title config={config} data={data} />
      {!hideTimers && (
        <>
          <div className={clsx(classes.timers, "action-countdown-timers")}>
            {value.days > 0 && (
              <CircularCountDown
                value={value.days}
                max={maxdays}
                label={t(ActionsIntl.Day)}
                plural={t(ActionsIntl.Days)}
              />
            )}
            <CircularCountDown
              value={value.hours}
              max={24}
              label={t(ActionsIntl.Hour)}
              plural={t(ActionsIntl.Hours)}
            />
            <CircularCountDown
              value={value.minutes}
              max={60}
              label={t(ActionsIntl.Minute)}
              plural={t(ActionsIntl.Minutes)}
            />
            {value.days === 0 && (
              <CircularCountDown
                value={value.seconds}
                max={60}
                label={t(ActionsIntl.Second)}
                plural={t(ActionsIntl.Seconds)}
              />
            )}
          </div>
          <SubScreens actions={children} screens="elapsing" />
        </>
      )}
      <SubScreens actions={children} screens="end" />
    </>
  );
};

ActionCountDown.classNames = ["qrockme-action-countdown"];
ActionCountDown.prepareAction = function ({ children, ...rest }, state) {
  return {
    ...rest,
    children: prepareChildren(children, {
      onlyScreens: ["elapsing", state?.screen],
    }),
    state,
  };
};
ActionCountDown.shouldSkip = function (action) {
  if (!action.state?.screen) return;
  const screenChildren = (action.children[action.state.screen] || []).filter(
    action => action.type !== "CHECKPOINT"
  );
  if (screenChildren.length === 1) {
    const child = screenChildren[0];
    if (child.component && child.component.shouldSkip) {
      return child.component.shouldSkip(child);
    }
  }
};

Object.assign(ActionCountDown, AbstractAction);
export default ActionCountDown;
