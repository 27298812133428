import React from "react";
import { keyBy } from "lodash";

const useByGuid = value => {
  return React.useMemo(() => {
    if (!Array.isArray(value)) {
      return {};
    }
    return keyBy(value, "guid");
  }, [value]);
};

export default useByGuid;
