// TODO: return a value to handle success or error

export const isLocalStorageSupported = () => {
  return !!window.localStorage;
};

/**
 * Classe definition for User in local storage
 * @class User
 * @property {string} id
 * @property {string} username
 */

/**
 * return user in local storage
 * @returns {User}
 */
export const getUser = () => {
  const userFromLS = localStorage.getItem('user');
  return userFromLS ? JSON.parse(userFromLS) : [];
};

/**
 * set user in local storage
 * @property {User}
 */
export const setUser = user => {
  console.log('setUser: ', user);
  localStorage.setItem('user', JSON.stringify(user));
};

/**
 * del user in local storage
 * @property {User}
 */
export const delUser = user => {
  console.log('setUser: ', user);
  localStorage.removeItem('user');
};

/**
 * set locale
 * @property {string} locale
 */
export const setLocale = locale => {
  console.log('setLocale: ', locale);
  localStorage.setItem('locale', locale);
}

/**
 * return locale
 * @returns {string} locale
 */
export const getLocale = () => {
  return localStorage.getItem('locale');
}

/**
 * del user in local storage
 * @property {none}
 */
export const delLocale = () => {
  localStorage.removeItem('local');
};

/**
 * set locale
 * @property {string} locale
 */
export const setAPIlocale = locale => {
  console.log('setAPIlocale: ', locale);
  localStorage.setItem('APIlocale', locale);
}

/**
 * return locale
 * @returns {string} locale
 */
export const getAPIlocale = () => {
  return localStorage.getItem('APIlocale');
}

/**
 * del user in local storage
 * @property {none}
 */
export const delAPIlocale = () => {
  localStorage.removeItem('APIlocal');
};

export default {
  isLocalStorageSupported,
  getUser,
  setUser,
  delUser,
  setLocale,
  getLocale,
  delLocale,
  setAPIlocale,
  getAPIlocale,
  delAPIlocale,
};
