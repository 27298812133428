import ActionLink from "./ActionLink";
import ActionMedia from "./ActionMedia";
import ActionNext from "./ActionNext";
import ActionSearch from "./ActionSearch";
import ActionButton from "./ActionButton";
import ActionLogin from "./ActionLogin";
import ActionFavorites from "./ActionFavorites";

export const componentsMap = {
  LINK: ActionLink,
  "MULTI-MEDIA": ActionMedia,
  NEXT: ActionNext,
  SEARCH: ActionSearch,
  BUTTON: ActionButton,
  LOGIN: ActionLogin,
  FAVORITES: ActionFavorites,
};
