import React from "react";
import T from "prop-types";
import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";
import Input from "../Input/Input";
import { FieldError, FormInputContainer, TextError } from "./FormAdapters.s";
import useFormatMessage from "hooks/useFormatMessage";

const InputAdapter = ({ input, meta, onError, ...rest }) => {
  const theme = useTheme();
  const t = useFormatMessage();

  const translate = value => {
    if (typeof value === "string") {
      return value;
    }
    return t(value);
  };

  return (
    <FormInputContainer>
      <Input {...input} error={meta.error} touched={meta.touched} {...rest} />
      {meta.touched && meta.error && (
        <FieldError>
          <InfoIcon color={theme.colors.grey} />
          <TextError>{translate(meta.error)}</TextError>
        </FieldError>
      )}
    </FormInputContainer>
  );
};

InputAdapter.propTypes = {
  input: T.any,
  meta: T.any,
};

InputAdapter.defaultProps = {
  input: null,
  meta: null,
};

export default InputAdapter;
