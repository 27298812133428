import { scroller } from "react-scroll";

export default function (id, offset) {
  scroller.scrollTo(`action-title-${id}`, {
    containerId: "qrockme-scene",
    duration: 300,
    offset: offset || 0,
    delay: 0,
    smooth: "easeInOutQuart",
  });
}
