import React from "react";
import qs from "qs";
import { useSelector, useDispatch } from "react-redux";
import Loader from "common/components/Loader";
import FlipCard from "common/components/FlipCard";
import { CardWrapper, CardsContainer } from "./Workflow.s";
import { selectWorkflow, selectEntity } from "redux/workflow/selector";
import {
  wfSagaLoadCard,
  wfSubmitCard,
  wfSagaLoadCardChoices,
  wfSagaStartOrResume,
} from "redux/workflow/action";
import { useLocation, useHistory } from "react-router";
import Actions from "scenes/actions/Actions";
import { selectLangChoice } from "redux/app/selector";
import Dnd from "Dnd";
import Pwa from "scenes/projects/Pwa";

const Workflow = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { projects } = useSelector(selectEntity);
  const { loading, cards, card, actions } = useSelector(selectWorkflow);
  const currentLangChoice = useSelector(selectLangChoice);
  const [flippedId, setFlippedId] = React.useState(0);

  const handleFlipButtonClick = (e, flippedId) => {
    e.preventDefault();
    e.stopPropagation();
    setFlippedId(flippedId);
  };

  const handleCardClick = card => {
    history.replace("/_", { guid: card.guid, name: card.name });
  };

  const handleCardSubmit = (values, nextGuid, replace) => {
    values = Object.keys(values).map(value => {
      const actionId = Number(value.split("-")[1]);
      let data = values[value];
      if (data === "false" || data === "true") {
        data = !!data; // string->boolean
      } else if (typeof data !== "string") {
        data = JSON.stringify(data); // object->string
      }
      return { action_id: actionId, value: data };
    });
    dispatch(wfSubmitCard(values, nextGuid, Boolean(replace)));
  };

  React.useEffect(() => {
    if (location.state) {
      // Avancement normal du workflow
      const { guid, fork } = location.state;
      if (fork) {
        dispatch(wfSagaLoadCardChoices(guid));
      } else {
        dispatch(wfSagaLoadCard(guid));
      }
    } else {
      // Début, retour arrière ou retour SSO
      const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      dispatch(wfSagaStartOrResume(location.state, query));
    }
  }, [dispatch, location.state, location.search, currentLangChoice]); // Reload si l'utilisateur choisi une langue

  if (loading) {
    return <Loader />;
  }

  if (card) {
    return (
      <Dnd>
        {projects.length === 1 && <Pwa />}
        <Actions
          actions={actions}
          layout={card.layout}
          onSubmit={handleCardSubmit}
        />
      </Dnd>
    );
  } else {
    return (
      <>
        {projects.length === 1 && <Pwa />}
        <div className="qrockme-start-choice qrockme-column">
          <CardsContainer className={"items" + cards.length}>
            {cards.map(card => (
              <CardWrapper key={card.guid} className="qrockme-card">
                <FlipCard
                  data={card}
                  isFlipped={flippedId === card.guid}
                  onFlipButtonClick={handleFlipButtonClick}
                  onCardClick={handleCardClick}
                />
              </CardWrapper>
            ))}
          </CardsContainer>
          <br />
        </div>
      </>
    );
  }
};

export default Workflow;
