import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ImageLoader from "common/components/ImageLoader";
import { useActionImage } from "common/actions";

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    display: "flex",
    userSelect: "none",
  },
  image: {
    width: 24,
    height: 24,
    marginRight: 4,
    flexShrink: 0,
    flexGrow: 0,
  },
  responsive: {
    maxWidth: "100%",
    height: "auto",
  },
  text: { flexGrow: 1, userSelect: "none" },
});

const Title = ({
  config,
  data,
  children,
  className,
  component: Component = "div",
  ...rest
}) => {
  const showTitle = config.showTitle === undefined ? true : config.showTitle;
  const image = useActionImage(config, data, "titleImage");
  const classes = useStyles();

  if (!showTitle) return null;

  return (
    <Component
      {...rest}
      className={clsx(className, classes.root, "action-title")}
    >
      {image && (
        <div className={clsx(classes.image, "action-title-image")}>
          <ImageLoader alt={config.title} src={image} invisible responsive />
        </div>
      )}
      <div className={clsx(classes.text, "action-title-text")}>
        {config.title}
      </div>
      {children}
    </Component>
  );
};

export default Title;
