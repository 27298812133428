import React from "react";
import Action from "./Action";
import { Action as ActionType, MenuPosition } from "types";
import { componentsMap } from "./Actions.data";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectCurrent } from "redux/workflow/selector";
import { useLocation } from "react-router";

interface Props {
  actions: ActionType[];
  menuPosition: MenuPosition;
}

const isKeyOfComponentMap = (
  value: ActionType["type"]
): value is keyof typeof componentsMap => {
  return Object.keys(componentsMap).includes(value);
};

const ActionsRenderer: React.FC<Props> = ({ actions, ...others }) => {
  const current = useSelector(selectCurrent);
  const location = useLocation();

  return (
    <>
      {actions.map(action => {
        if (!isKeyOfComponentMap(action.type)) {
          return null;
        }
        // TODO : A typescript component type
        const component: any = componentsMap[action.type];
        return (
          <div
            className={clsx({
              [action.className]: true,
              "menu-active": component.isActive(action, { current, location }),
            })}
          >
            <Action
              {...others}
              {...action}
              action={action.action}
              config={action.config}
              component={component}
            />
          </div>
        );
      })}
    </>
  );
};

export default ActionsRenderer;
