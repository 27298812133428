import styled from "styled-components/macro";
import Button from "common/components/Button";
import T from "prop-types";

export const LoaderContainer = styled.div`
  width: ${props => (props.fullPage ? "100vw" : "100%")};
  height: ${props => (props.fullPage ? "100vh" : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
LoaderContainer.propTypes = {
  fullPage: T.bool,
};

export const StyledButton = styled(Button)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  cursor: pointer;
  margin: auto;
`;

export const AlertError = styled.div`
  background: rgba(
    ${props => props.theme.colors.rgb.primary.red},
    ${props => props.theme.colors.rgb.primary.green},
    ${props => props.theme.colors.rgb.primary.blue},
    0.14
  );
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const TextError = styled.span`
  margin-left: 12px;
  font-weight: 400;
  color: #565656;
`;

export const Errors = styled.div``;
