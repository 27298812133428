import { Action, ActionChildren, Layout } from "types";
import { difference } from "lodash";
import produce from "immer";

export function getGuid(value: any, obj: any, guids: string[]) {
  if (typeof value === "number" && guids && guids[value - 1]) {
    return guids[value - 1];
  } else if (obj && typeof obj === "object") {
    return obj.guid;
  }
}

type RecursiveSomePredicate = (action: Action) => boolean;
const recursiveSome =
  (predicate: RecursiveSomePredicate) => (action: Action) => {
    return (
      predicate(action) ||
      Object.values(action.children).some(childrenArray =>
        childrenArray.some(recursiveSome(predicate))
      )
    );
  };

export function someAction(
  actions: Action | Action[],
  predicate: RecursiveSomePredicate
) {
  if (!Array.isArray(actions)) {
    actions = [actions];
  }
  return actions.some(recursiveSome(predicate));
}

export const isNext: RecursiveSomePredicate = function isNext(action: Action) {
  return action.type === "NEXT";
};

export const isOnlyCheckPoints = function (actions: Action | Action[]) {
  if (!Array.isArray(actions)) {
    actions = [actions];
  }
  return actions.every(action => action.type === "CHECKPOINT");
};

export const isForm: RecursiveSomePredicate = function isForm(action: Action) {
  return (
    action.type === "WRITE_TEXT" ||
    action.type === "TAKE_PICS" ||
    action.type === "TOGGLE" ||
    action.type === "RANK" ||
    action.type === "REGISTER" ||
    action.type === "UNREGISTER" ||
    (action.type === "QCMQCU" && !action.config.quiz)
  );
};

interface PrepareChildrenOptions {
  onlyScreens?: string[];
  mapActions?: (action: Action) => Action;
}

export function prepareChildren(
  children: ActionChildren,
  options: PrepareChildrenOptions
) {
  options = options || {};
  const mapActions =
    typeof options.mapActions === "function" ? options.mapActions : undefined;
  return Object.entries(children).reduce((prev, [screen, actions]) => {
    if (
      options.onlyScreens &&
      Array.isArray(options.onlyScreens) &&
      options.onlyScreens.indexOf(screen) === -1
    ) {
      return prev; // Do not add this screen
    }
    return {
      ...prev,
      [screen]: mapActions ? actions.map(mapActions) : actions,
    };
  }, {});
}

export function produceIgnoreFormValidation(action: Action) {
  return produce(action, draft => {
    draft.config.ignoreFormValidation = true;
  });
}

export function prepareInteractiveActionChildren(
  children: ActionChildren,
  currentScreen: string
) {
  let mapActions;
  if (currentScreen === "info" || currentScreen === "retry") {
    // On info and retry screen, we can use button when form is invalid
    mapActions = produceIgnoreFormValidation;
  }
  return prepareChildren(children, {
    onlyScreens: [currentScreen],
    mapActions,
  });
}

const defaultLayout: Layout = {
  enable: true,
  sections: [
    {
      guid: "main",
      cssClass: "__default",
      cols: [
        {
          guid: "main-col",
          content: [],
          cssClass: "__default",
          width: 12,
        },
      ],
    },
  ],
};

export function settleLayout(
  layout: Layout | null | undefined,
  actions: Record<string, Action>
) {
  let settled: string[] = [];
  if (layout) {
    settled = layout.sections.reduce<string[]>((prev, section) => {
      return [...prev, ...section.cols.flatMap(col => col.content)];
    }, []);
  } else {
    layout = { ...defaultLayout };
  }
  const toSettle = difference(Object.keys(actions), settled);
  if (toSettle.length) {
    const mainIdx = layout.sections.findIndex(s => s.guid === "main");
    layout = produce(layout, draft => {
      draft.sections[mainIdx].cols[0].content.push(...toSettle);
    });
  }

  // Remove missing actions
  layout = produce(layout, draft => {
    for (let sIdx = 0; sIdx < draft.sections.length; sIdx++) {
      for (let cIdx = 0; cIdx < draft.sections[sIdx].cols.length; cIdx++) {
        const content = draft.sections[sIdx].cols[cIdx].content.filter(guid =>
          Boolean(actions[guid])
        );
        draft.sections[sIdx].cols[cIdx].content = content;
      }
    }
  });
  return layout;
}

export function isStyleVariant(
  cssClass: string | undefined,
  styleName: string
): boolean {
  return cssClass === styleName || (cssClass || "").endsWith(`-${styleName}`);
}
