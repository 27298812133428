import React from "react";
import MediasSlider from "./ActionMultiMedia/MediasSlider";
import MediasCard from "./ActionMultiMedia/MediasCard";
import MediasLine from "./ActionMultiMedia/MediasLine";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";
import { isStyleVariant } from "./Common/utils";

function fromUrlArray(medias) {
  return medias.map(m => {
    if (typeof m === "object") {
      return m;
    }
    return { url: m };
  });
}

const ActionMultiMedia = ({ config, data, action }) => {
  const Component = React.useMemo(() => {
    if (isStyleVariant(action.cssClass, "carte")) {
      return MediasCard;
    } else if (isStyleVariant(action.cssClass, "ligne")) {
      return MediasLine;
    } else {
      return MediasSlider;
    }
  }, [action.cssClass]);

  let { medias } = config;
  if (!medias.length) {
    return null;
  }
  // Keep this to handle old media format (url array)
  medias = fromUrlArray(medias);

  return (
    <>
      <Title config={config} data={data} />
      <Component config={config} medias={medias} />
    </>
  );
};

ActionMultiMedia.classNames = ["qrockme-action-multimedia"];

Object.assign(ActionMultiMedia, AbstractAction);

ActionMultiMedia.getClasses = function (action) {
  const classes = AbstractAction.getClasses.apply(this, [action]);
  const userClass = classes.pop();
  if (isStyleVariant(action.cssClass, "carte")) {
    classes.push("qrockme-action-multimedia-carte");
  } else if (isStyleVariant(action.cssClass, "ligne")) {
    classes.push("qrockme-action-multimedia-ligne");
  }
  classes.push(userClass); // Keep user class at end of array
  return classes;
};

export default ActionMultiMedia;
