export const WF_SET_ENTITY = "WF_SET_ENTITY";
export const WF_SET_CURRENT = "WF_SET_CURRENT";
export const WF_UPDATE_CURRENT = "WF_UPDATE_CURRENT";
export const WF_SET_QUERY = "WF_SET_QUERY";
export const WF_SET_LOADING = "WF_SET_LOADING";
export const WF_HISTORY_REMOVE_FUTURE = "WF_HISTORY_REMOVE_FUTURE";
export const WF_HISTORY_SET = "WF_HISTORY_SET";
export const WF_HISTORY_SKIP = "WF_HISTORY_SKIP";
export const WF_HISTORY_TRAVELING = "WF_HISTORY_TRAVELING";
export const WF_ERROR_CLEAR = "WF_ERROR_CLEAR";
export const WF_SET_ACTION_STATE = "WF_SET_ACTION_STATE";
export const WF_RESUMING = "WF_RESUMING";
export const WF_PWA_DECLINED = "WF_PWADECLINED";
export const WF_MENU_OPEN = "WF_MENU_OPEN";

export const WF_SAGA_SET_ENTITY_GUID = "WF_SAGA_SET_ENTITY_GUID";
export const WF_SAGA_SET_ENTITY_GUID_DONE = "WF_SAGA_SET_ENTITY_GUID_DONE";
export const WF_SAGA_SET_ENTITY_GUID_FAILED = "WF_SAGA_SET_ENTITY_GUID_FAILED";
export const WF_SAGA_LOAD_ENTITY = "WF_SAGA_LOAD_ENTITY";
export const WF_SAGA_LOAD_ENTITY_DONE = "WF_SAGA_LOAD_ENTITY_DONE";
export const WF_SAGA_LOAD_ENTITY_FAILED = "WF_SAGA_LOAD_ENTITY_FAILED";
export const WF_SAGA_START_PROJECT = "WF_SAGA_START_PROJECT";
export const WF_SAGA_START_PROJECT_DONE = "WF_SAGA_START_PROJECT_DONE";
export const WF_SAGA_START_PROJECT_FAILED = "WF_SAGA_START_PROJECT_FAILED";
export const WF_SAGA_LOAD_CARD = "WF_SAGA_LOAD_CARD";
export const WF_SAGA_LOAD_CARD_DONE = "WF_SAGA_LOAD_CARD_DONE";
export const WF_SAGA_LOAD_CARD_FAILED = "WF_SAGA_LOAD_CARD_FAILED";
export const WF_SAGA_PREPARE_ACTIONS_DONE = "WF_SAGA_PREPARE_ACTIONS_DONE";
export const WF_SAGA_PREPARE_ACTIONS_FAILED = "WF_SAGA_PREPARE_ACTIONS_FAILED";
export const WF_SAGA_LOAD_CARDCHOICES = "WF_SAGA_LOAD_CARDCHOICES";
export const WF_SAGA_LOAD_CARDCHOICES_DONE = "WF_SAGA_LOAD_CARDCHOICES_DONE";
export const WF_SAGA_LOAD_CARDCHOICES_FAILED =
  "WF_SAGA_LOAD_CARDCHOICES_FAILED";
export const WF_SAGA_SUBMIT_CARD = "WF_SAGA_SUBMIT_CARD";
export const WF_SAGA_SUBMIT_CARD_DONE = "WF_SAGA_SUBMIT_CARD_DONE";
export const WF_SAGA_SUBMIT_CARD_ABORT = "WF_SAGA_SUBMIT_CARD_ABORT";
export const WF_SAGA_SUBMIT_CARD_FAILED = "WF_SAGA_SUBMIT_CARD_FAILED";
export const WF_SAGA_SKIP_CARD = "WF_SAGA_SKIP_CARD";
export const WF_SAGA_SKIP_CARD_DONE = "WF_SAGA_SKIP_CARD_DONE";
export const WF_SAGA_SKIP_CARD_FAILED = "WF_SAGA_SKIP_CARD_FAILED";
export const WF_SAGA_CHECK_CARD_SKIP_DONE = "WF_SAGA_CHECK_CARD_SKIP_DONE";
export const WF_SAGA_CHECK_CARD_SKIP_FAILED = "WF_SAGA_CHECK_CARD_SKIP_FAILED";
export const WF_SAGA_START_OR_RESUME = "WF_SAGA_START_OR_RESUME";
export const WF_SAGA_START_OR_RESUME_DONE = "WF_SAGA_START_OR_RESUME_DONE";
export const WF_SAGA_SET_ACTION_STATE = "WF_SAGA_SET_ACTION_STATE";
export const WF_SAGA_SET_ACTION_STATE_DONE = "WF_SAGA_SET_ACTION_STATE_DONE";
export const WF_SAGA_SET_ACTION_STATE_FAILED =
  "WF_SAGA_SET_ACTION_STATE_FAILED";
export const WF_SAGA_SUBMIT_ACTION = "WF_SAGA_SUBMIT_ACTION";
export const WF_SAGA_SUBMIT_ACTION_DONE = "WF_SAGA_SUBMIT_ACTION_DONE";
export const WF_SAGA_SUBMIT_ACTION_FAILED = "WF_SAGA_SUBMIT_ACTION_FAILED";
export const WF_SAGA_UPDATE_ACTION = "WF_SAGA_UPDATE_ACTION";
export const WF_SAGA_UPDATE_ACTION_DONE = "WF_SAGA_UPDATE_ACTION_DONE";
export const WF_SAGA_UPDATE_ACTION_FAILED = "WF_SAGA_UPDATE_ACTION_FAILED";
export const WF_SAGA_PREPARE_UPDATED_ACTION_DONE =
  "WF_SAGA_PREPARE_UPDATED_ACTION_DONE";
export const WF_SAGA_PREPARE_UPDATED_ACTION_FAILED =
  "WF_SAGA_PREPARE_UPDATED_ACTION_FAILED";
export const WF_SAGA_CLEAR_STATES_DONE = "WF_SAGA_CLEAR_STATES_DONE";
export const WF_SAGA_CLEAR_STATES_FAILED = "WF_SAGA_CLEAR_STATES_FAILED";
export const WF_SAGA_GOTO_CARD = "WF_SAGA_GOTO_CARD";
export const WF_SAGA_GOTO_CARD_DONE = "WF_SAGA_GOTO_CARD_DONE";
export const WF_SAGA_GOTO_CARD_FAILED = "WF_SAGA_GOTO_CARD_FAILED";
export const WF_SAGA_GOTO_SEARCHRESULT = "WF_SAGA_GOTO_SEARCHRESULT";
export const WF_SAGA_GOTO_SEARCHRESULT_DONE = "WF_SAGA_GOTO_SEARCHRESULT_DONE";
export const WF_SAGA_GOTO_SEARCHRESULT_FAILED =
  "WF_SAGA_GOTO_SEARCHRESULT_FAILED";

export const wfSetCurrent = payload => {
  return {
    type: WF_SET_CURRENT,
    payload,
  };
};

export const wfUpdateCurrent = payload => {
  return {
    type: WF_UPDATE_CURRENT,
    payload,
  };
};

export const wfSetQuery = payload => {
  return {
    type: WF_SET_QUERY,
    payload,
  };
};

export const wfSetLoading = enable => {
  return {
    type: WF_SET_LOADING,
    payload: enable === undefined ? true : Boolean(enable),
  };
};

export const wfLoadEntity = guid => {
  return {
    type: WF_SAGA_LOAD_ENTITY,
    payload: guid,
  };
};

export const wfHistoryRemoveFuture = v => {
  return {
    type: WF_HISTORY_REMOVE_FUTURE,
    payload: v,
  };
};

export const wfHistorySet = history => {
  return {
    type: WF_HISTORY_SET,
    payload: history,
  };
};

export const wfHistorySkip = () => {
  return {
    type: WF_HISTORY_SKIP,
  };
};

export const wfStartHistoryTraveling = () => {
  return {
    type: WF_HISTORY_TRAVELING,
    payload: true,
  };
};

export const wfStartResuming = () => {
  return {
    type: WF_RESUMING,
    payload: true,
  };
};

export const wfPwaDeclined = () => {
  return {
    type: WF_PWA_DECLINED,
    payload: true,
  };
};

export const wfMenuOpen = position => {
  return {
    type: WF_MENU_OPEN,
    payload: position,
  };
};

export const wfErrorClear = () => {
  return {
    type: WF_ERROR_CLEAR,
    payload: true,
  };
};

export const wfSagaSetEntityGuid = (guid, disableResume) => {
  return {
    type: WF_SAGA_SET_ENTITY_GUID,
    payload: { guid, disableResume },
  };
};

export const wfSagaStartProject = project => {
  return {
    type: WF_SAGA_START_PROJECT,
    payload: project,
  };
};

export const wfSagaLoadCard = guid => {
  return {
    type: WF_SAGA_LOAD_CARD,
    payload: guid,
  };
};

export const wfSagaLoadCardChoices = guid => {
  return {
    type: WF_SAGA_LOAD_CARDCHOICES,
    payload: guid,
  };
};

export const wfSubmitCard = (values = {}, next, replace) => {
  return {
    type: WF_SAGA_SUBMIT_CARD,
    payload: { values, next, replace },
  };
};

export const wfSagaSkipCard = payload => {
  return {
    type: WF_SAGA_SKIP_CARD,
    payload,
  };
};

export const wfSagaStartOrResume = (guid, query) => {
  return {
    type: WF_SAGA_START_OR_RESUME,
    payload: { guid, query },
  };
};

export const wfSagaSetActionState = (guid, state) => {
  return {
    type: WF_SAGA_SET_ACTION_STATE,
    payload: { guid, state },
  };
};

export const wfSagaSubmitAction = guid => {
  return {
    type: WF_SAGA_SUBMIT_ACTION,
    payload: { guid },
  };
};

export const wfUpdateAction = (guid, data) => {
  return {
    type: WF_SAGA_UPDATE_ACTION,
    payload: { guid, data },
  };
};

export const wfSetActionState = (projectGuid, cardGuid, actionGuid, state) => {
  return {
    type: WF_SET_ACTION_STATE,
    payload: {
      projectGuid,
      cardGuid,
      actionGuid,
      state,
    },
  };
};

export const wfGotoSearchResult = ({ id, card, action }, fromHistory) => {
  return {
    type: WF_SAGA_GOTO_SEARCHRESULT,
    payload: { id, card, action, fromHistory },
  };
};

export const wfGotoCard = (guid, actionId) => {
  return {
    type: WF_SAGA_GOTO_CARD,
    payload: { guid, actionId },
  };
};
