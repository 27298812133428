import { componentsMap as cardComponentsMap } from "scenes/actions/Actions.data";
import { componentsMap as menuComponentsMap } from "menus/actions/Actions.data";
import { put, select, all, call } from "redux-saga/effects";
import {
  WF_SAGA_PREPARE_ACTIONS_DONE,
  WF_SAGA_PREPARE_ACTIONS_FAILED,
  wfSetActionState,
} from "redux/workflow/action";
import {
  selectCurrent,
  selectWorkflow,
  selectActionState,
} from "redux/workflow/selector";
import { selectLangCurrent } from "redux/app/selector";
import { selectUser } from "redux/auth/selector";
import { keyBy } from "lodash";
import produce from "immer";
import { settleLayout } from "scenes/actions/Common/utils";
import { prefsNotifications } from "redux/prefs/action";
import { getAction } from "connected-react-router";

function resolveComponent(action, isMenu) {
  const componentsMap = isMenu ? menuComponentsMap : cardComponentsMap;
  return componentsMap[action.type] &&
    typeof componentsMap[action.type].getComponent === "function"
    ? componentsMap[action.type].getComponent(action)
    : componentsMap[action.type];
}

function sortAction(a, b) {
  if (isNaN(a.order) || isNaN(b.order)) {
    return 0;
  }
  return a.order - b.order;
}

function extractData(action, isMenu) {
  const component = resolveComponent(action, isMenu);
  const classes = component ? component.getClasses(action) : [];
  const baseClass = isMenu ? "qrockme-menu-action" : "qrockme-action";
  const className =
    action.type === "MENU"
      ? classes.join(" ")
      : `${baseClass} ${classes.join(" ")}`;

  const children = {};
  for (const screen in action.children) {
    children[screen] = action.children[screen].map(a =>
      extractData(a, action.type === "MENU")
    );
  }

  const data = action.data || {};
  let key = `action-${action.id}`;
  if (data.recordId) {
    key += "|" + data.recordId;
  }

  return {
    key,
    type: action.type,
    guid: action.guid,
    config: action.config,
    data,
    component,
    action,
    className: className,
    parentId: action.parentId,
    screen: action.screen,
    children,
    layout: action.layout,
  };
}

const prepareNextCards = nextCards => (prev, cur) => {
  if (cur.type === "NEXT" && !nextCards[cur.config.nextCard]) {
    return prev; // remvove next action w/o card available
  } else if (cur.type === "NEXT") {
    return [
      ...prev,
      produce(cur, draft => {
        draft.config.nextCardName = nextCards[cur.config.nextCard].name;
        draft.config.nextCardDesc = nextCards[cur.config.nextCard].description;
        draft.config.nextCardImage = nextCards[cur.config.nextCard].image;
      }),
    ];
  } else {
    const children = {};
    for (const screen in cur.children) {
      children[screen] = cur.children[screen].reduce(
        prepareNextCards(nextCards, cur.type === "MENU"),
        []
      );
    }
    return [...prev, { ...cur, children }];
  }
};

function* loadState(projectId, cardId, actionId) {
  return yield select(selectActionState(projectId, cardId, actionId));
}

export function* updateStates(projectGuid, cardGuid, states) {
  return yield all(
    Object.entries(states).map(function* ([guid, state]) {
      yield put(wfSetActionState(projectGuid, cardGuid, guid, state));
    })
  );
}

const prepareAction = (states, lang) => action => {
  if (action.component) {
    if (action.component.prepareAction) {
      return action.component.prepareAction(action, states[action.guid], lang);
    } else {
      return action;
    }
  }
};

export default function* prepareActions() {
  try {
    const { project: projectId } = yield select(selectCurrent);
    const { card, nextCards, starting } = yield select(selectWorkflow);
    const routerAction = yield select(getAction);
    const lang = yield select(selectLangCurrent);
    const user = yield select(selectUser);

    // Split notifications to a map
    const { actions, notifications } = card.actions.reduce(
      (prev, action) => {
        if (action.type === "NOTIFICATION") {
          prev.notifications[action.guid] = {
            time: action.time,
            ...action.config,
          };
        } else {
          prev.actions.push(action);
        }
        return prev;
      },
      { actions: [], notifications: {} }
    );

    // Only supported by root actions
    const states = yield all(
      actions.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.guid]: loadState(projectId, card.guid, cur.guid),
        }),
        {}
      )
    );

    let sortedActions = [];
    if (card.layout?.enable) {
      const actionsByGuid = keyBy(actions, "guid");
      const layout = settleLayout(card.layout, actionsByGuid);
      layout.sections.forEach(s => {
        const sectionGuid = s.guid;
        s.cols.forEach(c => {
          const columnGuid = c.guid;
          c.content.forEach(actionGuid => {
            if (actionsByGuid[actionGuid]) {
              sortedActions.push({
                ...actionsByGuid[actionGuid],
                layout: { section: sectionGuid, column: columnGuid },
              });
            }
          });
        });
      });
    } else {
      sortedActions = actions.sort(sortAction);
    }

    const postPrepared = sortedActions
      .map(a => extractData(a, false))
      .reduce(prepareNextCards(nextCards), []);

    // Only supported by root actions
    const prepared = postPrepared
      .map(prepareAction(states, { lang, user, actions: postPrepared }))
      .filter(Boolean);

    // Only supported by root actions
    const updatedStates = prepared.reduce((prev, cur) => {
      if (!Object.is(states[cur.guid], cur.state)) {
        return { ...prev, [cur.guid]: cur.state };
      }
      return prev;
    }, {});

    yield call(updateStates, projectId, card.guid, updatedStates);

    const { cardActions, menus } = prepared.reduce(
      (prev, cur) => {
        if (cur.type === "MENU") {
          prev.menus[cur.config.position] = cur;
        } else {
          prev.cardActions.push(cur);
        }
        return prev;
      },
      {
        cardActions: [],
        menus: {},
      }
    );

    if (starting || routerAction === "REPLACE") {
      // Handle notifications at project start or only when user go forward
      yield put(prefsNotifications(projectId, notifications));
    }
    yield put({
      type: WF_SAGA_PREPARE_ACTIONS_DONE,
      payload: { actions: cardActions, menus },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: WF_SAGA_PREPARE_ACTIONS_FAILED, message: e.message });
  }
}
