import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CorrectionIcon from "../Common/CorrectionIcon";
import nl2br from "react-nl2br";
import clsx from "clsx";

const useStyles = makeStyles({
  proposal: {
    width: "100%",
    border: "1px solid #C7C7C7",
    background: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    padding: 0,
    overflow: "hidden",
    "& .action-proposal-check": {
      padding: 6,
      display: "flex",
      alignItems: "center",
    },
    "& .action-proposal-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 8,
      marginRight: 8,
      flexGrow: 1,
    },
    "& .action-correction-icon": {
      marginRight: 8,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
  },
});

const Proposal = ({ checked, disabled, label, correct, className }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.proposal]: true,
        "action-proposal": true,
        "action-proposal-checked": checked,
        "action-proposal-disabled": disabled,
        [className]: Boolean(className),
      })}
    >
      <div className="action-proposal-label">{nl2br(label)}</div>
      <CorrectionIcon correct={correct} />
    </div>
  );
};

Proposal.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  image: PropTypes.string,
  correct: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Proposal;
