import * as Api from "api";
import apiCall from "./apiCall";
import redirectToFallback from "./redirectToFallback";
import { put, call, select } from "redux-saga/effects";
import { setTitle, setLangAvailable, setLangCurrent } from "redux/app/action";
import { selectLangCurrent } from "redux/app/selector";
import { getShortBrowserLocale } from "detect-locale";
import { themeClearProject } from "redux/theme/action";
import {
  WF_SAGA_LOAD_ENTITY_DONE,
  WF_SAGA_LOAD_ENTITY_FAILED,
  wfSagaStartProject,
  wfLoadEntity,
} from "redux/workflow/action";
import { removeUserData, SET_USER_DATA } from "auth/store/actions";

function prepareLanguages(cards) {
  return cards.reduce((prev, cur) => {
    cur.languages.forEach(l => {
      Object.entries(l).forEach(([k, v]) => {
        prev[k] = v;
      });
    });
    return prev;
  }, {});
}

export default function* loadEntity({ payload: guid }) {
  try {
    const r = yield call(apiCall, Api.fetchEntity, guid, false);

    if (r.user.is_visitor) {
      yield put(removeUserData());
    } else {
      yield put({
        type: SET_USER_DATA,
        payload: r.user,
      });
    }

    if (r.cards.length === 0) {
      // Pas de projets, on redirige sur l'url fallback
      yield redirectToFallback(r.entity.fallback);
      return;
    } else if (r.cards.length === 1) {
      // Si un seule projet on le démarre
      yield put(wfSagaStartProject(r.cards[0]));
    } else {
      const langs = prepareLanguages(r.cards);
      yield put(setLangAvailable(langs));

      const lang = yield select(
        state => state.app.langChoice || getShortBrowserLocale()
      );
      const currentLang = yield select(selectLangCurrent);
      let changeLang;

      if (Object.keys(langs).includes(lang)) {
        // La langue choisie ou du navigateur est dispo
        changeLang = lang;
      } else {
        // La langue choisie ou du navigateur n'est dispo on utilise : en ou la premiere disponible
        if (Object.keys(langs).includes("en")) {
          changeLang = "en";
        } else {
          changeLang = Object.keys(langs)[0];
        }
      }

      if (changeLang && changeLang !== currentLang) {
        // Reload if lang changed
        yield put(setLangCurrent(changeLang));
        yield put(wfLoadEntity(guid));
        return;
      }

      yield put(themeClearProject());
      yield put(setTitle(r.entity.name));
    }
    yield put({
      type: WF_SAGA_LOAD_ENTITY_DONE,
      payload: {
        projects: r.cards,
        guid: r.entity.guid,
        name: r.entity.name,
        description: r.entity.description,
        pwa: r.entity.pwa,
        icon: r.entity.icon,
        company: r.company,
      },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_LOAD_ENTITY_FAILED, message: e.message });
  }
}
