import styled from "styled-components/macro";
import medias from "medias";

export const CardWrapper = styled.div`
   position: relative;
   .react-card-flip {
     position: absolute;
     left: 0;
     top: 0;
     padding: 8px 4px;
     width: 100%;
     height: 100%;
   }




`;

export const CardsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0;
   margin-left: auto;
   margin-right: auto;

   ${medias.xs`
      width: 100%;
      align-content: start;
      justify-content: space-around;
   `}

   ${medias.sm`
      width: 100%;
      height: auto;
      min-height: auto;
      align-items: center;
      justify-content: center;
   `}
   ${medias.md`
      width: 100%;
      height: auto;
      min-height: auto;
      align-items: center;
      justify-content: center;
   `}
   ${medias.lg`
      width: 100%;
      max-width: 1100px;
      height: auto;
      min-height: auto;
      align-items: center;
      justify-content: center;
   `}
`;