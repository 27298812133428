import React from "react";
import PropTypes from "prop-types";
import ResultsScreen from "../Common/ResultsScreen";
import Board from "./Board";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={state.correctCount}
      headerTotal={state.zones.length}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <Board
        src={config.image}
        alt={config.title}
        spots={state.spots}
        zones={state.zones}
        showSolution={showSolution}
      />
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
