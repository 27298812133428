import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { settleLayout } from "./Common/utils";
import { keyBy } from "lodash";
import { Action as ActionType, Layout } from "types";
import ActionsRenderer from "./ActionsRenderer";

const useStyles = makeStyles(theme => {
  return {
    section: {
      display: "flex",
      flexWrap: "wrap",
    },
    column: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-around",
      alignItems: "center",
      alignContent: "center",
      overflow: "hidden",
      flexBasis: "100%",
      "&.qrockme-empty-column": {
        padding: 0,
      },
      "&.qrockme-column-12": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "100%",
        },
      },
      "&.qrockme-column-11": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "91.666666667%",
        },
      },
      "&.qrockme-column-10": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "83.333333333%",
        },
      },
      "&.qrockme-column-9": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "75%",
        },
      },
      "&.qrockme-column-8": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "66.666666667%",
        },
      },
      "&.qrockme-column-7": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "58.333333333%",
        },
      },
      "&.qrockme-column-6": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "50%",
        },
      },
      "&.qrockme-column-5": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "41.666666667%",
        },
      },
      "&.qrockme-column-4": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "33.333333333%",
        },
      },
      "&.qrockme-column-3": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "25%",
        },
      },
      "&.qrockme-column-2": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "16.666666666%",
        },
      },
      "&.qrockme-column-1": {
        [theme.breakpoints.up("md")]: {
          flexBasis: "8.333333333%",
        },
      },
    },
  };
});

interface Props {
  actions: ActionType[];
  layout?: Layout;
}

const ActionsLayoutRenderer: React.FC<Props> = ({
  actions,
  layout,
  ...others
}) => {
  const classes = useStyles();
  const actionsByGuid = React.useMemo(
    () =>
      keyBy(
        actions.filter(a => a.component),
        "guid"
      ),
    [actions]
  );

  const sections = React.useMemo(() => {
    const settled = settleLayout(layout?.enable ? layout : null, actionsByGuid);
    // Remove empty sections
    return settled.sections.filter(s => !s.cols.every(c => !c.content.length));
  }, [layout, actionsByGuid]);

  return (
    <>
      {sections.map(s => (
        <div
          key={s.guid}
          className={clsx(
            classes.section,
            {
              "qrockme-nolayout": !layout?.enable,
              "qrockme-layout": layout?.enable,
            },
            "qrockme-section",
            `qrockme-section-${s.cols.length}`,
            `qrockme-section-${s.cssClass}`
          )}
        >
          {s.cols.map(c => {
            return (
              <div
                key={c.guid}
                className={clsx(
                  classes.column,
                  "qrockme-column",
                  `qrockme-column-${c.width}`,
                  `qrockme-column-${c.cssClass}`,
                  { "qrockme-empty-column": c.content.length === 0 }
                )}
              >
                <ActionsRenderer
                  actions={c.content
                    .map(guid => actionsByGuid[guid])
                    .filter(Boolean)}
                  {...others}
                />
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default ActionsLayoutRenderer;
