import React from "react";
import T from "prop-types";
import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";
import { FieldError, FormInputContainer, TextError } from "./FormAdapters.s";
import useFormatMessage from "hooks/useFormatMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const countryMap = {
  en: "uk",
};

function getCountry() {
  let country = navigator.language
    .substr(navigator.language.length - 2)
    .toLocaleLowerCase();
  if (countryMap[country]) {
    return countryMap[country];
  }
  return country;
}

const PhoneNumberAdapter = ({ input, meta, onError, ...rest }) => {
  const theme = useTheme();
  const t = useFormatMessage();

  const translate = value => {
    if (typeof value === "string") {
      return value;
    }
    return t(value);
  };

  const country = getCountry();

  return (
    <FormInputContainer>
      <PhoneInput
        country={country}
        {...input}
        containerStyle={{ textAlign: "center" }}
        inputStyle={{ paddingLeft: 48 }}
        inputProps={{ className: "qrockme-input" }}
        {...rest}
      />
      {meta.touched && meta.error && (
        <FieldError>
          <InfoIcon color={theme.colors.grey} />
          <TextError>{translate(meta.error)}</TextError>
        </FieldError>
      )}
    </FormInputContainer>
  );
};

PhoneNumberAdapter.propTypes = {
  input: T.any,
  meta: T.any,
};

PhoneNumberAdapter.defaultProps = {
  input: null,
  meta: null,
};

export default PhoneNumberAdapter;
