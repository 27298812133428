import React from "react";
import { makeStyles } from "@material-ui/core";
import { StyledButton } from "../Actions.s";
import { FormattedMessage } from "react-intl";
import { useDrag, useDrop } from "react-dnd";
import CommonIntl from "common/CommonTrad.i";
import clsx from "clsx";
import BarProposal from "../Common/BarProposal";
import { isPwaIOS } from "common/detect";

const useStyles = makeStyles(theme => ({
  content: {
    color: "#565656",
    marginLeft: -16,
    marginRight: -16,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  container: {
    display: "flex",
  },
  sticky: {
    position: "fixed",
    bottom: 44,
    left: 0,
    right: 0,
    marginLeft: 0,
    marginRight: 0,
    zIndex: 1000,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
      overflowY: "scroll",
    },
    [theme.breakpoints.up("md")]: {
      overflowY: "scroll",
    },
    [theme.breakpoints.down("sm") + " and (orientation: landscape)"]: {
      bottom: 0,
    },
  },
  stickyPwaIos: {
    bottom: 64,
  },
  scrollWrapper: {
    overflow: "auto",
    flexGrow: 1,
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.up("md")]: {
      maxHeight: 47 * 3,
    },
  },
  proposals: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: "99%",
    minHeight: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  proposal: {
    maxWidth: "100%",
    cursor: "grab",
    "&.dragging": {
      opacity: 0.5,
    },
  },
  button: {
    width: 115,
    transition: "width 0.5s",
    overflow: "hidden",
    flexShrink: 0,
    "& .qrockme-button": {
      minHeight: 35,
      maxHeight: 35,
      margin: "6px auto",
    },
  },
  buttonHidden: {
    width: "0 !important",
  },
}));

const dropFn = (onDrop, index) => (item, monitor) => {
  const didDrop = monitor.didDrop();
  if (didDrop) {
    return;
  }
  onDrop({ ...item, index });
};

const DraggableProposal = ({
  dndGroup,
  guid,
  index,
  label,
  onDrop,
  onDragging,
}) => {
  const ref = React.useRef();
  const width = ref.current ? ref.current.offsetWidth : 0;

  const [, drop] = useDrop({
    accept: dndGroup,
    drop: dropFn(onDrop, index),
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, guid, label, width },
    begin: () => {
      if (onDragging) {
        onDragging(true);
      }
    },
    end: () => {
      if (onDragging) {
        onDragging(false);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const classes = useStyles();
  drag(drop(ref));
  return (
    <BarProposal
      ref={ref}
      label={label}
      className={clsx(classes.proposal, { dragging: isDragging })}
    />
  );
};

const BarProposals = ({
  dndGroup,
  stuckBottom,
  proposals,
  onDrop,
  onSubmit,
  onDragging,
}) => {
  const [, drop] = useDrop({
    accept: dndGroup,
    drop: dropFn(onDrop),
  });
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.sticky]: stuckBottom,
        [classes.stickyPwaIos]: isPwaIOS(),
        [classes.content]: true,
      })}
    >
      <div
        className={clsx({
          "action-container": stuckBottom,
          [classes.container]: true,
          "action-proposalbar": true,
        })}
      >
        <div ref={drop} className={classes.scrollWrapper}>
          <div className={clsx(classes.proposals)}>
            {proposals.map(({ guid, label }, index) => (
              <DraggableProposal
                key={guid}
                dndGroup={dndGroup}
                guid={guid}
                label={label}
                index={index}
                onDrop={onDrop}
                onDragging={onDragging}
              />
            ))}
          </div>
        </div>
        <div
          className={clsx({
            [classes.button]: true,
            [classes.buttonHidden]: !onSubmit,
            "action-proposalbar-validate": true,
          })}
        >
          <StyledButton className="qrockme-button" onClick={onSubmit}>
            <FormattedMessage {...CommonIntl.Validate} />
          </StyledButton>
        </div>
      </div>
    </div>
  );
};

export default BarProposals;
