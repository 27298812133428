import React from "react";
import Proposal from "./Proposal";

const Board = React.forwardRef(
  ({ proposals, dndGroup, onDrop, onDragging, showSolution }, ref) => {
    const handleDrop = index => item => {
      onDrop(index, item);
    };

    return (
      <div ref={ref}>
        {proposals.map((p, i) => (
          <Proposal
            key={p.guid}
            dndGroup={dndGroup}
            onDrop={handleDrop(i)}
            onDragging={onDragging}
            index={i}
            showSolution={showSolution}
            {...p}
          />
        ))}
      </div>
    );
  }
);

export default Board;
