import { shuffle } from "lodash";
import ActionMagnetic from "../ActionMagnetic";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

function preparePoints(config) {
  const r = config.points.reduce(
    (prev, cur) => {
      const { proposals, ...rest } = cur;
      return {
        points: [...prev.points, { ...rest, answers: [] }],
        proposals: [...prev.proposals, ...proposals],
      };
    },
    { points: [], proposals: [] }
  );
  r.points.sort(function (a, b) {
    return a.y - b.y;
  });
  r.proposals = shuffle(r.proposals);
  return r;
}

export default function ({ config, children, data, ...rest }, state) {
  if (!state) {
    const { points, proposals } = preparePoints(config);
    state = { ...ActionMagnetic.initialState, points, proposals };
    if (data?.result_obj?.screen) {
      const pointsData = data?.result_obj?.points || [];
      state = {
        ...state,
        ...data.result_obj,
        correctCount: pointsData.reduce((prev, point) => {
          return prev + (point.correct ? 1 : 0);
        }, 0),
        points: state.points.map((point, idx) => {
          return { ...point, ...pointsData[idx] };
        }),
      };
    }
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      const { points, proposals } = preparePoints(config);
      state = {
        ...ActionMagnetic.initialState,
        points,
        proposals,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
