import React from "react";
import PropTypes from "prop-types";
import { Link as LinkIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import AbstractAction from "./AbstractAction";
import useCancelableRequest from "common/components/useCancelableRequest";
import { useActionImage } from "common/actions";
import clsx from "clsx";
import nl2br from "react-nl2br";
import ImageLoader from "common/components/ImageLoader";
import { isStyleVariant } from "./Common/utils";

const useStyles = makeStyles({
  root: {
    display: "flex",
    textAlign: "left",
    fontSize: 14,
    textDecoration: "none",
    alignItems: "center",
  },
  image: {
    width: "20%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    alignSelf: "stretch",
  },
  imageResponsive: {
    height: "auto",
    maxWidth: "100%",
    objectFit: "contain",
  },
  squareIcon: {
    "&:after": {
      content: '""',
      display: "block",
      paddingBottom: "100%",
    },
  },
});

const ActionLink = ({ action, config, data }) => {
  const image = useActionImage(config, data);
  const [result] = useCancelableRequest(image);
  const classes = useStyles();

  const isImageStyle = React.useMemo(
    () => isStyleVariant(action.cssClass, "image"),
    [action.cssClass]
  );

  const imageNode = React.useMemo(() => {
    if (isImageStyle) {
      if (image) {
        return (
          <ImageLoader
            src={image}
            className={clsx(classes.imageResponsive, "action-link-image")}
          />
        );
      } else {
        return (
          <div className={clsx(classes.squareIcon, "action-link-icon")}>
            <LinkIcon fontSize="inherit" />
          </div>
        );
      }
    } else if (image) {
      return (
        <div
          className={clsx(classes.image, "action-link-image")}
          style={{
            backgroundImage: `url('${result}')`,
          }}
        ></div>
      );
    }
    return null;
  }, [image, result, classes, isImageStyle]);

  // if the link comes from a column it may be empty
  if (!config.link) {
    return null;
  }

  return (
    <>
      <a
        className={clsx(classes.root, "action-link")}
        href={
          /^(tel|sms|geo|callto|WIFI|https?|mailto)/i.test(config.link) === true
            ? `${config.link}`
            : `https://${config.link}`
        }
        {...(config?.isInternal
          ? { target: "_self" }
          : {
              target: "_blank",
              rel: "noopener noreferrer",
            })}
      >
        {imageNode}
        <div className="action-link-text">{nl2br(config.title)}</div>
        {!isImageStyle && (
          <div className="action-link-icon">
            <LinkIcon />
          </div>
        )}
      </a>
    </>
  );
};

ActionLink.propTypes = {
  config: PropTypes.object.isRequired,
};

ActionLink.classNames = ["qrockme-action-link"];

Object.assign(ActionLink, AbstractAction);

ActionLink.getClasses = function (action) {
  const classes = AbstractAction.getClasses.apply(this, [action]);
  const userClass = classes.pop();
  if (isStyleVariant(action.cssClass, "image")) {
    classes.push("qrockme-action-link-image");
  }
  classes.push(userClass); // Keep user class at end of array
  return classes;
};

export default ActionLink;
