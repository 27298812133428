import React from "react";
import { makeStyles } from "@material-ui/core";
import { CallSplit as NextIcon } from "@material-ui/icons";
import { useActionNext } from "common/actions";
import { ImageLoader } from "common/components";
import nl2br from "react-nl2br";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    display: "flex",
    textAlign: "left",
    fontSize: 14,
    textDecoration: "none",
    alignItems: "center",
    cursor: "pointer",
  },
  image: {
    width: "20%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    alignSelf: "stretch",
  },
  imageResponsive: {
    height: "auto",
    maxWidth: "100%",
    objectFit: "contain",
  },
  squareIcon: {
    "&:after": {
      content: '""',
      display: "block",
      paddingBottom: "100%",
    },
  },
});

const Image = ({ config, data, onClick }) => {
  const { title, image, desc, additionals } = useActionNext(config, data);
  const classes = useStyles();

  const imageNode = React.useMemo(() => {
    if (image) {
      return (
        <ImageLoader
          src={image}
          className={clsx(classes.imageResponsive, "qrockme-image-image")}
        />
      );
    } else {
      return (
        <div className={clsx(classes.squareIcon, "qrockme-image-icon")}>
          <NextIcon fontSize="inherit" />
        </div>
      );
    }
  }, [image, classes]);
  return (
    <div className={clsx(classes.root, "qrockme-image")} onClick={onClick}>
      {imageNode}
      <div className="qrockme-image-text">{nl2br(title)}</div>
      <div className="qrockme-image-desc">{nl2br(desc)}</div>
      {additionals.length > 0 &&
        additionals.map(({ content }, idx) => {
          if (!content) return null;
          const className = [
            "qrockme-image-add",
            `qrockme-image-add-${idx + 1}`,
          ].join(" ");
          return (
            <div className={className} key={idx}>
              {nl2br(content)}
            </div>
          );
        })}
    </div>
  );
};

export default Image;
