import React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandIcon } from "@material-ui/icons";
import ActionsRenderer from "./ActionsRenderer";
import clsx from "clsx";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const useStyles = makeStyles({
  title: {
    padding: 0,
    marginBottom: 0,
    minHeight: "auto",
    "&.Mui-expanded": {
      minHeight: "auto",
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  details: {
    padding: 0,
  },
  expandIcon: {
    marginRight: 0,
    padding: 0,
  },
});

const ActionAccordion = ({ config, data, children }) => {
  const classes = useStyles();
  const actions = React.useMemo(() => children["content"] || [], [children]);
  return (
    <Accordion elevation={0} square>
      <Title
        component={AccordionSummary}
        expandIcon={<ExpandIcon />}
        className={clsx(classes.title, "action-accordion-title")}
        classes={{
          content: clsx(classes.content, "action-accordion-content"),
          expandIcon: clsx(classes.expandIcon, "action-accordion-expand-icon"),
        }}
        config={config}
        data={data}
      />
      <AccordionDetails className={clsx(classes.details, "action-screen")}>
        <div className="action-screen-content">
          <ActionsRenderer actions={actions} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ActionAccordion.classNames = ["qrockme-action-accordion"];

Object.assign(ActionAccordion, AbstractAction);
export default ActionAccordion;
