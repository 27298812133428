import { put, select, call } from "redux-saga/effects";
import {
  WF_SAGA_UPDATE_ACTION_DONE,
  WF_SAGA_UPDATE_ACTION_FAILED,
} from "../action";
import { selectCurrent } from "../selector";
import apiCall from "./apiCall";
import * as Api from "api";

export default function* updateAction({ payload: { guid, data } }) {
  try {
    const {
      entity: entityGuid,
      project: projectGuid,
      card: cardGuid,
      session,
    } = yield select(selectCurrent);
    const payload = yield call(
      apiCall,
      Api.updateActionData,
      entityGuid,
      projectGuid,
      cardGuid,
      guid,
      data,
      session
    );
    yield put({
      type: WF_SAGA_UPDATE_ACTION_DONE,
      payload,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: WF_SAGA_UPDATE_ACTION_FAILED, message: e.message });
  }
}
