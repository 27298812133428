import { createSelector } from "reselect";

export const selectToken = createSelector(
  (state) => state.app.token,
  (token) => token
);

export const selectLangChoice = createSelector(
  (state) => state.app.langChoice,
  (langChoice) => langChoice
);

export const selectLangCurrent = createSelector(
  (state) => state.app.langCurrent,
  (langCurrent) => langCurrent
);

export const selectLangAvailable = createSelector(
  (state) => state.app.langAvailable,
  (langAvailable) => langAvailable
);
