import React from "react";
import isVideo from "is-video";
import isImage from "is-image";
import isAudio from "libs/isAudio";
import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import nl2br from "react-nl2br";
import ImageLoader from "common/components/ImageLoader";
import PdfDownload from "./PdfDownload";

const useStyles = makeStyles({
  responsive: {
    maxWidth: "100%",
    height: "auto",
  },
  container: { justifyContent: "center" },
  card: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
});

function displayMedia(title, media, index, className) {
  let html = [];
  if (isVideo(media.url)) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    html.push(
      <video
        className={className}
        title={title + " #" + (index + 1)}
        key={url}
        controls
      >
        <source src={url} style={{ display: "block", lineHeight: "0" }} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );
  } else if (isImage(media.url)) {
    html.push(
      <ImageLoader
        key={media.url}
        className={className}
        alt={title + " #" + (index + 1)}
        src={media.url}
      />
    );
  } else if (isAudio(media.url)) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    html.push(
      <audio
        key={url}
        title={title + " #" + (index + 1)}
        style={{ margin: "12px auto" }}
        controls
      >
        <source src={url} />
        Sorry, your browser doesn't support embedded audios.
      </audio>
    );
  } else if (media.url.toLowerCase().endsWith(".pdf")) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    return (
      <PdfDownload
        key={url}
        url={url}
        filename={media.filename}
        title={media.title}
        desc={media.desc}
      />
    );
  }

  if (html) {
    if (media.title) {
      html.push(
        <p className="action-media-title" key="action-media-title">
          {nl2br(media.title)}
        </p>
      );
    }
    if (media.desc) {
      html.push(
        <p className="action-media-desc" key="action-media-desc">
          {nl2br(media.desc)}
        </p>
      );
    }
    if (media.additionals) {
      media.additionals.forEach(({ content }, idx) => {
        const classname = "action-media-add action-media-add-" + (idx + 1);
        html.push(<span className={classname}>{nl2br(content)}</span>);
      });
    }
  }
  return html;
}

const MediasCard = ({ config, medias }) => {
  const classes = useStyles();
  return (
    <Grid
      spacing={3}
      className={clsx(classes.container, "action-cardcontainer")}
      container
    >
      {medias.map((media, index) => {
        const xs = isAudio(media.url) ? 12 : 6;
        const md = isAudio(media.url) ? 6 : 3;
        return (
          <Grid item xs={xs} md={md} key={media.url}>
            <div className={clsx(classes.card, "action-card")}>
              {displayMedia(config.title, media, index, classes.responsive)}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MediasCard;
