import { THEME_SET_GLOBAL } from "./action";
import { THEME_SET_PROJECT } from "./action";

const INITIAL_STATE = {
  globalCss: "",
  globalLogo: "",
  globalLogoLeft: "",
  globalLogoRight: "assets/images/logo-qrockme.png",
  projectCss: "",
  projectLogo: "",
  projectLogoLeft: "",
  projectLogoRight: "",
};
const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case THEME_SET_GLOBAL:
      return {
        ...state,
        globalCss: action.payload.css || "",
        globalLogo: action.payload.logo || "",
        globalLogoLeft: action.payload.logoLeft || "",
        globalLogoRight: action.payload.logoRight || "",
      };
    case THEME_SET_PROJECT:
      return {
        ...state,
        projectCss: action.payload.css || "",
        projectLogo: action.payload.logo || "",
        projectLogoLeft: action.payload.logoLeft || "",
        projectLogoRight: action.payload.logoRight || "",
      };
    default:
      return state;
  }
};

export default themeReducer;
