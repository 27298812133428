import { defineMessages } from "react-intl";

export default defineMessages({
  Empty: {
    id: "Notifications.Empty",
    defaultMessage:
      "Vous n'avez pas encore de notifications, revenez plus tard.",
  },
  ThisWeek: {
    id: "Notifications.ThisWeek",
    defaultMessage: "Cette semaine",
  },
  Earlier: {
    id: "Notifications.Earlier",
    defaultMessage: "Plus tôt",
  },
});
