/* eslint-disable import/no-duplicates */
import * as React from "react";
import { useContext } from "react";
import { IntlProvider, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import translations from "i18n/locales";
import { selectLangCurrent } from "redux/app/selector";

export const IntlContext = React.createContext({});

// turn the old context into the new context
export const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

// the format message hook
export default () => {
  const intl = useContext(IntlContext);
  return intl.formatMessage;
};

export function withIntl(Component) {
  return function WrapperComponent(props) {
    let currentLang = useSelector(selectLangCurrent) || "en";
    let messages;
    if (translations[currentLang]) {
      messages = translations[currentLang];
    } else {
      messages = translations["en"];
      currentLang = "en";
    }
    return (
      <IntlProvider messages={messages} key={currentLang} locale={currentLang}>
        <InjectIntlContext>
          <Component {...props} />
        </InjectIntlContext>
      </IntlProvider>
    );
  };
}
