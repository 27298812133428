import React from "react";
import { Field, useField } from "react-final-form";
import { required, noValidation } from "common/components/rules";
import { FormField } from "./Actions.s";
import RankAdapter from "./ActionRank/RankAdapter";
import CollapseScreens from "./Common/CollapseScreens";
import SubScreens from "./Common/SubScreens";
import { getGuid, prepareChildren } from "./Common/utils";
import { wfSagaSetActionState } from "redux/workflow/action";
import { useDispatch } from "react-redux";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const ActionRank = ({ action, config, data, children }) => {
  const showLabel = Boolean(config.showLabel);
  const field = useField(`action-${action.id}`);
  const value = field.input.value;
  const dispatch = useDispatch();

  const values = React.useMemo(() => {
    return config.proposals.reduce(
      (prev, cur) => ({ ...prev, [cur.guid]: cur.value }),
      {}
    );
  }, [config]);

  const defaultValue = getGuid(
    data.result,
    data.result_obj,
    Object.keys(values)
  );
  const screen = React.useMemo(
    () => values[value || defaultValue],
    [values, value, defaultValue]
  );

  React.useEffect(() => {
    dispatch(wfSagaSetActionState(action.guid, { screen }));
  }, [dispatch, screen, action.guid]);

  return (
    <>
      <Title config={config} data={data} />
      <SubScreens actions={children} screens="info" />
      <FormField>
        <Field
          parse={value => value}
          className="action-rank-field"
          name={`action-${action.id}`}
          validate={config.required ? required : noValidation}
          component={RankAdapter}
          options={config.proposals}
          showLabel={showLabel}
          defaultValue={defaultValue}
        />
      </FormField>
      <CollapseScreens actions={children} screens={screen} />
    </>
  );
};

ActionRank.classNames = ["qrockme-action-rank"];

ActionRank.prepareAction = function ({ children, ...rest }, state) {
  return {
    ...rest,
    children: prepareChildren(children, {
      onlyScreens: ["info", state?.screen],
    }),
  };
};

Object.assign(ActionRank, AbstractAction);
export default ActionRank;
