import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { Favorite as FavoriteIcon } from "@material-ui/icons";
import FavoritesListIntl from "./FavoritesList.i";
import { useFavorites } from "./useFavorites";
import { useDispatch, useSelector } from "react-redux";
import { selectProjectCards } from "redux/workflow/selector";
import { wfGotoCard } from "redux/workflow/action";
import useFormatMessage from "hooks/useFormatMessage";
import clsx from "clsx";

export const FavoritesList = ({ category, sort, className, children }) => {
  const t = useFormatMessage();
  const projectCards = useSelector(selectProjectCards);
  const dispatch = useDispatch();
  const {
    favorites: favorites_1,
    add: add_1,
    remove: remove_1,
  } = useFavorites(1);
  const {
    favorites: favorites_2,
    add: add_2,
    remove: remove_2,
  } = useFavorites(2);
  const {
    favorites: favorites_3,
    add: add_3,
    remove: remove_3,
  } = useFavorites(3);
  const {
    favorites: favorites_4,
    add: add_4,
    remove: remove_4,
  } = useFavorites(4);
  const favoritesMap = React.useMemo(() => {
    let favsMap = {};
    if (category === 1 || category === 0) {
      favsMap = Object.entries(favorites_1).reduce((prev, [id, date]) => {
        if (prev[id]) {
          prev[id].from.push(1);
        } else {
          prev[id] = { id, date, from: [1] };
        }
        return prev;
      }, favsMap);
    }
    if (category === 2 || category === 0) {
      favsMap = Object.entries(favorites_2).reduce((prev, [id, date]) => {
        if (prev[id]) {
          prev[id].from.push(2);
        } else {
          prev[id] = { id, date, from: [2] };
        }
        return prev;
      }, favsMap);
    }
    if (category === 3 || category === 0) {
      favsMap = Object.entries(favorites_3).reduce((prev, [id, date]) => {
        if (prev[id]) {
          prev[id].from.push(3);
        } else {
          prev[id] = { id, date, from: [3] };
        }
        return prev;
      }, favsMap);
    }
    if (category === 4 || category === 0) {
      favsMap = Object.entries(favorites_4).reduce((prev, [id, date]) => {
        if (prev[id]) {
          prev[id].from.push(4);
        } else {
          prev[id] = { id, date, from: [4] };
        }
        return prev;
      }, favsMap);
    }
    return favsMap;
  }, [category, favorites_1, favorites_2, favorites_3, favorites_4]);

  const [initialFavorites] = React.useState(() => {
    const favsMap = Object.values(favoritesMap).reduce(
      (prev, { id, date, from }) => {
        if (projectCards[id]) {
          return {
            ...prev,
            [id]: {
              guid: id,
              date,
              from,
              name: projectCards[id].name,
              image: projectCards[id].image,
            },
          };
        }
        return prev;
      },
      {}
    );
    const compareFn =
      sort === "name"
        ? (a, b) => (a.name || "").localeCompare(b.name || "")
        : (a, b) => b.date.localeCompare(a.date);
    return Object.values(favsMap).sort(compareFn);
  }, []);

  const remove = React.useCallback(
    (guid, from) => {
      if (from.includes(1)) {
        remove_1(guid);
      }
      if (from.includes(2)) {
        remove_2(guid);
      }
      if (from.includes(3)) {
        remove_3(guid);
      }
      if (from.includes(4)) {
        remove_4(guid);
      }
    },
    [remove_1, remove_2, remove_3, remove_4]
  );

  const restore = React.useCallback(
    (guid, from) => {
      if (from.includes(1)) {
        add_1(guid);
      }
      if (from.includes(2)) {
        add_2(guid);
      }
      if (from.includes(3)) {
        add_3(guid);
      }
      if (from.includes(4)) {
        add_4(guid);
      }
    },
    [add_1, add_2, add_3, add_4]
  );

  const handleItemClick = React.useCallback(
    guid => {
      dispatch(wfGotoCard(guid));
    },
    [dispatch]
  );

  if (initialFavorites.length === 0) {
    return (
      children || (
        <div className="favorites-list-empty">{t(FavoritesListIntl.Empty)}</div>
      )
    );
  }

  return (
    <List className={clsx("favorites-list", className)}>
      {initialFavorites.map(({ guid, name, image, from }) => {
        const imageUrl =
          image && process.env.REACT_APP_STATIC_SERVER
            ? image.replace(
                "https://s3.eu-west-3.amazonaws.com/qrock.me/",
                process.env.REACT_APP_STATIC_SERVER
              )
            : image;
        return (
          <ListItem
            className={clsx({
              "favorites-list-item": true,
              "favorites-list-removed": !favoritesMap[guid],
            })}
            key={guid}
            onClick={() => handleItemClick(guid)}
            disableGutters
            button
          >
            {image && (
              <ListItemAvatar className="favorites-list-image">
                <Avatar alt={name} src={imageUrl} variant="square" />{" "}
              </ListItemAvatar>
            )}
            <ListItemText
              primary={name}
              classes={{
                primary: "favorites-list-title",
              }}
              disableTypography
            />
            <ListItemSecondaryAction>
              <IconButton
                size="small"
                className={clsx({
                  "favorites-list-icon": true,
                  "favorites-list-iconremoved": !favoritesMap[guid],
                })}
                onClick={() =>
                  favoritesMap[guid] ? remove(guid, from) : restore(guid, from)
                }
              >
                <FavoriteIcon color="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
