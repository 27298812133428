import React from "react";
import { Collapse } from "@material-ui/core";
import SubScreens from "./SubScreens";
import { isOnlyCheckPoints } from "./utils";

const CollapseScreens = ({ actions, screens }) => {
  if (screens === undefined) {
    screens = [];
  } else if (!Array.isArray(screens)) {
    screens = [screens];
  }
  const [height, setHeight] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [notFirstDisplay, setNotFirstDisplay] = React.useState(false);
  const childrenRef = React.useRef(null);

  const handleChange = React.useCallback(count => {
    setCount(count);
    if (count) {
      setHeight(childrenRef.current.offsetHeight);
    }
  }, []);

  const visibleActions = React.useMemo(() => {
    return screens.reduce(
      (prev, key) => [...prev, ...(actions[key] || [])],
      []
    );
  }, [actions, screens]);

  React.useEffect(() => {
    setNotFirstDisplay(true);
  }, []);

  if (isOnlyCheckPoints(visibleActions) || visibleActions.length === 0) {
    return <SubScreens actions={actions} screens={screens} />;
  }
  return (
    <Collapse in={true} appear={notFirstDisplay}>
      <div ref={childrenRef}>
        <SubScreens
          actions={actions}
          screens={screens}
          onChange={handleChange}
        />
      </div>
      {!count && <div style={{ height }}></div>}
    </Collapse>
  );
};

export default CollapseScreens;
