import { componentsMap } from "scenes/actions/Actions.data";
import { put, select } from "redux-saga/effects";
import {
  WF_SAGA_PREPARE_UPDATED_ACTION_DONE,
  WF_SAGA_PREPARE_UPDATED_ACTION_FAILED,
} from "redux/workflow/action";
import { selectWorkflow } from "redux/workflow/selector";
import produce from "immer";

function extractData(action) {
  const component =
    componentsMap[action.type] &&
    typeof componentsMap[action.type].getComponent === "function"
      ? componentsMap[action.type].getComponent(action)
      : componentsMap[action.type];
  const classes = component ? component.getClasses(action) : [];
  const className = `qrockme-action ${classes.join(" ")}`;

  const children = {};
  for (const screen in action.children) {
    children[screen] = action.children[screen].map(extractData);
  }

  const data = action.data || {};
  let key = `action-${action.id}`;
  if (data.recordId) {
    key += "|" + data.recordId;
  }

  return {
    key,
    type: action.type,
    guid: action.guid,
    config: action.config,
    data,
    component,
    action,
    className,
    parentId: action.parentId,
    screen: action.screen,
    children,
    layout: action.layout,
  };
}

const prepareNextCards = nextCards => (prev, cur) => {
  if (cur.type === "NEXT" && !nextCards[cur.config.nextCard]) {
    return prev; // remvove next action w/o card available
  } else if (cur.type === "NEXT") {
    return [
      ...prev,
      produce(cur, draft => {
        draft.config.nextCardName = nextCards[cur.config.nextCard].name;
        draft.config.nextCardDesc = nextCards[cur.config.nextCard].description;
        draft.config.nextCardImage = nextCards[cur.config.nextCard].image;
      }),
    ];
  } else {
    const children = {};
    for (const screen in cur.children) {
      children[screen] = cur.children[screen].reduce(
        prepareNextCards(nextCards),
        []
      );
    }
    return [...prev, { ...cur, children }];
  }
};

export default function* prepareUpdatedAction({ payload: { action } }) {
  try {
    const { nextCards } = yield select(selectWorkflow);

    const prepared = [action]
      .map(extractData)
      .reduce(prepareNextCards(nextCards), []);

    yield put({
      type: WF_SAGA_PREPARE_UPDATED_ACTION_DONE,
      payload: { action: prepared[0] },
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: WF_SAGA_PREPARE_UPDATED_ACTION_FAILED,
      message: e.message,
    });
  }
}
