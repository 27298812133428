import sendValues from "./sendValues";
import { push, replace } from "connected-react-router";
import { put, call, select } from "redux-saga/effects";
import { setTitle } from "redux/app/action";
import jwtService from "services/jwtService";
import {
  WF_SAGA_SUBMIT_CARD_FAILED,
  WF_SAGA_SUBMIT_CARD_DONE,
  WF_SAGA_SUBMIT_CARD_ABORT,
} from "redux/workflow/action";
import { setUserData } from "auth/store/actions/user.actions";
import { selectCurrent } from "redux/workflow/selector";
import { logoutUser } from "auth/store/actions";

export default function* submitCard({ payload: { values, next } }) {
  try {
    const {
      entity: entityGuid,
      project: projectGuid,
      card: cardGuid,
      sessionProject,
      sessionCard,
    } = yield select(selectCurrent);
    const {
      next: nextCards,
      errors,
      errorsData,
      token,
      session,
      sessionDebug,
    } = yield call(
      sendValues,
      entityGuid,
      projectGuid,
      cardGuid,
      sessionProject,
      sessionCard,
      values
    );

    if (sessionDebug) {
      console.log("SESSION", sessionDebug);
    }

    if (token) {
      if (token.token) {
        if (token.user) {
          yield call(jwtService.setSession, token.token, token.user);
          yield call(jwtService.signInWithToken);
        } else {
          yield put(logoutUser(token.token));
        }
      } else {
        yield call(jwtService.setUser, token.user);
      }
      if (token.user) {
        yield put(setUserData(token.user));
      }
    }

    if (errors && errors.length > 0) {
      yield put({
        type: WF_SAGA_SUBMIT_CARD_ABORT,
        payload: {
          errors,
          errorsData,
        },
      });
      return;
    } else if (nextCards.length === 0) {
      // Terminé
      yield put(setTitle(""));
      yield put(push("/"));
    } else if (nextCards.length === 1) {
      yield put(
        // Affiche carte suivante
        replace("/_", {
          guid: nextCards[0].guid,
          name: nextCards[0].name,
          session,
        })
      );
    } else {
      if (next) {
        // Si action next on affiche la carte choisie
        yield put(replace("/_", { guid: next, name: "?NEXT?", session }));
      } else {
        // Sinon on affiche le choix carte suivante
        yield put(replace("/_", { guid: cardGuid, fork: true, session }));
      }
    }
    yield put({
      type: WF_SAGA_SUBMIT_CARD_DONE,
      payload: {
        nextCards: [],
        cards: [],
        card: undefined,
      },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_SUBMIT_CARD_FAILED, message: e.message });
  }
}
