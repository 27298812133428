import * as Api from "api";
import apiCall from "./apiCall";
import { put, call, select } from "redux-saga/effects";
import { setTitle } from "redux/app/action";
import {
  WF_SAGA_LOAD_CARD_FAILED,
  WF_SAGA_LOAD_CARD_DONE,
} from "redux/workflow/action";
import {
  selectCurrent,
  selectHistory,
  selectQuery,
} from "redux/workflow/selector";
import produce from "immer";

export default function* loadCard({ payload: guid }) {
  try {
    const current = yield select(selectCurrent);
    const history = yield select(selectHistory);
    const query = yield select(selectQuery);
    const updatedQuery = produce(query, draft => {
      if (history[history.length - 2] && history[history.length - 2].guid) {
        draft["fromCard"] = history[history.length - 2].guid;
      } else {
        delete draft["fromCard"];
      }
    });

    const r = yield call(
      apiCall,
      Api.fetchCard,
      current.entity,
      current.project,
      guid,
      updatedQuery,
      current.session
    );

    if (r.sessionDebug) {
      console.log("SESSION", r.sessionDebug);
    }

    const card = r.current;

    // On range par guid les cartes suivante nécessaire aux actions next
    const nextCards = r.cards.reduce((prev, cur) => {
      prev[cur.guid] = cur;
      return prev;
    }, {});

    yield put(setTitle(card.showTitle ? card.name : ""));
    yield put({
      type: WF_SAGA_LOAD_CARD_DONE,
      payload: {
        nextCards,
        cards: [],
        card: card,
        session: r.session,
        actions: card.actions,
      },
    });
  } catch (e) {
    yield put({ type: WF_SAGA_LOAD_CARD_FAILED, message: e.message });
  }
}
