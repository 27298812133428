import React from "react";
import PropTypes from "prop-types";
import { keyBy } from "lodash";
import { useDispatch } from "react-redux";
import { makeStyles, Portal } from "@material-ui/core";
import { wfSagaSetActionState } from "redux/workflow/action";
import useInView from "react-cool-inview";
import { Preview } from "react-dnd-multi-backend";
import Board from "./Board";
import MainScreen from "../Common/MainScreen";
import ProposalsBar from "../Common/ProposalsBar";
import BarProposal from "../Common/BarProposal";
import populateLabels from "./populateLabels";
import clsx from "clsx";
import produce from "immer";

const useStyles = makeStyles({
  help: {
    textAlign: "left",
  },
});

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [dragging, setDragging] = React.useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const proposalsByGuid = React.useMemo(
    () => keyBy(config.proposals, "guid"),
    [config.proposals]
  );
  const proposals = React.useMemo(
    () => populateLabels(state.proposals, proposalsByGuid),
    [state.proposals, proposalsByGuid]
  );

  const values = React.useMemo(
    () =>
      state.values.map(guid => ({
        guid,
        label: proposalsByGuid[guid].value,
      })),
    [state.values, proposalsByGuid]
  );

  const handleProposalDrop = (to, { guid, from }) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          const valueIndex = draft.values.indexOf(guid);
          if (valueIndex > -1) {
            draft.values.splice(valueIndex, 1);
          }
          if (from !== undefined) {
            draft.proposals[from].answer = draft.proposals[to].answer;
          } else if (draft.proposals[to].answer) {
            draft.values.push(draft.proposals[to].answer);
          }
          draft.proposals[to].answer = guid;
        })
      )
    );
  };

  const handleBarDrop = ({ guid, from, index }) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          if (from !== undefined) {
            draft.proposals[from].answer = undefined;
            if (index !== undefined) {
              draft.values.splice(index, 0, guid);
            } else {
              draft.values.push(guid);
            }
          }
        })
      )
    );
  };

  const handleDragging = React.useCallback(
    dragging => setDragging(dragging),
    []
  );

  const generatePreview =
    actionGuid =>
    ({ itemType, item, style }) => {
      if (actionGuid !== itemType) return;
      return (
        <Portal>
          <div style={{ ...style, zIndex: 1001, width: item.width + 5 }}>
            <BarProposal label={item.label} />
          </div>
        </Portal>
      );
    };

  const fulfilled = state.proposals.every(p => !!p.answer);
  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      dragging={dragging}
    >
      <div className={clsx(classes.help, "action-help")}>
        <img alt="Aide" src="assets/images/help/matching.gif" />
      </div>
      <Board
        ref={ref}
        dndGroup={action.guid}
        proposals={proposals}
        onDrop={handleProposalDrop}
        onDragging={handleDragging}
      />
      <ProposalsBar
        dndGroup={action.guid}
        sticky={inView}
        proposals={values}
        onSubmit={fulfilled ? onSubmit : undefined}
        onDrop={handleBarDrop}
        onDragging={handleDragging}
      />
      <Preview>{generatePreview(action.guid)}</Preview>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
