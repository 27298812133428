import { put, select } from "redux-saga/effects";
import { wfLoadEntity } from "redux/workflow/action";
import { selectCurrent } from "redux/workflow/selector";

export default function* reloadProjects({ payload: { action, location } }) {
  if (
    location.pathname === "/projects" &&
    (action === "PUSH" || action === "REPLACE")
  ) {
    const current = yield select(selectCurrent);
    yield put(wfLoadEntity(current.entity));
  }
}
