import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AbstractAction from "./AbstractAction";
import clsx from "clsx";
import nl2br from "react-nl2br";
import ButtonImage from "common/components/ButtonImage";
import { useActionButton, useActionImage, LangList } from "common/actions";

const useStyles = makeStyles({
  root: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    "& .action-button-text": {
      flexGrow: 1,
    },
  },
});

const ActionButton = ({ config, data }) => {
  const classes = useStyles();
  const { title, type } = config;
  const image = useActionImage(config, data, "titleImage");

  const { icon, handler } = useActionButton(type, true);

  const handleClick = React.useCallback(() => {
    handler();
  }, [handler]);

  if (type === "lang") {
    return <LangList onSelect={handler} />;
  }

  return (
    <button
      type="button"
      className={clsx(classes.root, "action-button-button")}
      onClick={handleClick}
    >
      <ButtonImage
        image={image}
        icon={icon}
        classes={{
          image: "action-button-image",
          icon: "action-button-image",
        }}
      />
      <div className="action-button-text">{nl2br(title)}</div>
    </button>
  );
};

ActionButton.propTypes = {
  config: PropTypes.object.isRequired,
};

ActionButton.classNames = ["qrockme-action-button"];

Object.assign(ActionButton, AbstractAction);
export default ActionButton;
