import * as Api from "api";
import apiCall from "./apiCall";
import redirectToFallback from "./redirectToFallback";
import { push, replace } from "connected-react-router";
import { put, call, select } from "redux-saga/effects";
import { setTitle } from "redux/app/action";

import { themeSetGlobal, themeClearProject } from "redux/theme/action";
import {
  WF_SAGA_SET_ENTITY_GUID_FAILED,
  WF_SAGA_SET_ENTITY_GUID_DONE,
  wfSetCurrent,
  wfSagaStartProject,
  wfLoadEntity,
} from "redux/workflow/action";
import { selectCurrent, selectHistory } from "redux/workflow/selector";

export default function* setEntityGuid({ payload: { guid, disableResume } }) {
  try {
    // Clear search index cache
    if (window?.sessionStorage) {
      window.sessionStorage.clear();
    }

    const current = yield select(selectCurrent);
    const r = yield call(apiCall, Api.fetchEntity, guid, true);
    const { css, logo, logoLeft, logoRight } = r;
    yield put(themeSetGlobal({ css, logo, logoLeft, logoRight }));
    const project = r.cards.find(c => c.guid === current.project);
    if (
      !disableResume &&
      current.entity === guid && // Can resume on same entity
      current.card && // Can resume if user was on a card
      project && // Can resume if project still there
      project.canResume // Can resume if project resume is enabled
    ) {
      const history = yield select(selectHistory);
      if (history.length > 1 || r.cards.length > 1) {
        yield put(setTitle(""));
        yield put(push(`/resume/${guid}`));
        return;
      }
    }
    yield put(themeClearProject());
    yield put(wfSetCurrent({ entity: guid }));
    if (r.cards.length === 0) {
      // Pas de projets, on redirige sur l'url fallback
      yield redirectToFallback(r.entity.fallback);
    } else if (r.cards.length === 1) {
      // Si un seule projet on le démarre
      yield put(wfLoadEntity(guid)); // Populate projects
      yield put(wfSagaStartProject(r.cards[0]));
    } else {
      yield put(setTitle(r.entity.name));
      if (disableResume) {
        yield put(replace("/projects"));
      } else {
        yield put(push("/projects"));
      }
    }
    yield put({
      type: WF_SAGA_SET_ENTITY_GUID_DONE,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_SET_ENTITY_GUID_FAILED, message: e.message });
    yield put(setTitle(""));
    yield put(push("/", { error: true }));
  }
}
