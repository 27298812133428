import { defineMessages } from "react-intl";

export default defineMessages({
  NoResults: {
    id: "Search.NoResults",
    defaultMessage: "Aucun résultat",
  },
  ManyResults: {
    id: "Search.ManyResults",
    defaultMessage: "Plus de 100 résultats, veuillez préciser",
  },
  MoreChars: {
    id: "Search.MoreChars",
    defaultMessage: "Saisir au moins 3 caractères...",
  },
  Loading: {
    id: "Search.Loading",
    defaultMessage: "Chargement des données...",
  },
  LoadingError: {
    id: "Search.LoadingError",
    defaultMessage: "Erreur lors du chargement des données...",
  },
});
