import React from "react";
import { makeStyles } from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import nl2br from "react-nl2br";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    display: "flex",
    color: "inherit",
    textDecoration: "inherit",
    height: "98%",
    flexDirection: "column",
    justifyContent: "center",
  },
});

const PdfDownload = ({ url, title, filename, desc }) => {
  const classes = useStyles();
  return (
    <a
      className={clsx(classes.root, "qrockme-pdfdownload")}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {(title || filename) && (
        <div className="qrockme-pdfdownload-title">
          {nl2br(title || filename)}
        </div>
      )}
      {desc && <div className="qrockme-pdfdownload-desc">{nl2br(desc)}</div>}
      <div className="qrockme-pdfdownload-icon">
        <DownloadIcon fontSize="inherit" color="inherit" />
      </div>
    </a>
  );
};

export default PdfDownload;
