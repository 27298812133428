import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { VerticalMenu, HorizontalMenu } from "menus";
import { AppContainer } from "./App.s";
import Login from "./scenes/login/Login";
import Search from "./scenes/search/Search";
import Favorites from "./scenes/favorites/Favorites";
import QrCode from "./scenes/qrCode/QrCode";
import Entity from "./scenes/entity/Entity";
import Projects from "./scenes/projects/Projects";
import User from "./scenes/user/User";
import NotFound from "./scenes/notFound/NotFound";
import Home from "./scenes/home/Home";
import Workflow from "scenes/workflow/Workflow";
import Resume from "scenes/resume/Resume";
import ComingSoon from "./scenes/comingSoon/ComingSoon";
import AppContext from "./AppContext";
import { isPwa, isIos } from "common/detect";
import ScrollToTop from "./ScrollToTop";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { selectTheme } from "redux/theme/selector";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import Notifications from "scenes/notifications/Notifications";

const hideMenus = ["entity", "search", "resume", "favorites", "notifications"];

const useStyles = makeStyles(theme => ({
  appContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  sceneContainer: {
    flexDirection: "column",
    display: "flex",
    flexGrow: 1,
    width: "100%",
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useSelector(selectTheme);
  const containerRef = React.useRef();
  const currentRoute = useSelector(state => state.router.location.pathname);
  let routeFirstPart = currentRoute.split("/")[1];
  if (routeFirstPart === "_") {
    routeFirstPart = "workflow";
  }
  return (
    <div
      className={clsx(
        { "is-pwa": isPwa(), "is-ios": isIos() },
        classes.appContainer
      )}
    >
      <Helmet>
        <style type="text/css" id="css">
          {theme.css}
        </style>
      </Helmet>
      {!hideMenus.includes(routeFirstPart) && <VerticalMenu position="left" />}
      <div className={classes.sceneContainer}>
        {!hideMenus.includes(routeFirstPart) && (
          <HorizontalMenu position="top" />
        )}
        <AppContainer
          ref={containerRef}
          id="qrockme-scene"
          className={clsx(
            "qrockme-scene",
            routeFirstPart ? `qrockme-scene-${routeFirstPart}` : null
          )}
        >
          <AppContext.Provider value={{ containerRef: containerRef }}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/scan" component={QrCode} />
              <Route path="/entity/:entityGUID" component={Entity} />
              <Route path="/resume/:entityGUID" component={Resume} />
              <Route path="/projects" component={Projects} />
              <Route path="/_" component={Workflow} />
              <Route exact path="/account" component={Login} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/favorites" component={Favorites} />
              <Route exact path="/notifications" component={Notifications} />
              <Route path="/languages" component={ComingSoon} />
              <Route path="/user" component={User} />
              <Route path="/404" component={NotFound} />
              <Route path="*" render={() => <Redirect to="/404" />} />
            </Switch>
          </AppContext.Provider>
        </AppContainer>
        {!hideMenus.includes(routeFirstPart) && (
          <HorizontalMenu position="bottom" />
        )}
      </div>
      {!hideMenus.includes(routeFirstPart) && <VerticalMenu position="right" />}
    </div>
  );
};
