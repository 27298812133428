import React from "react";
import { List, makeStyles } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import NotificationsListIntl from "./NotificationsList.i";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrent } from "redux/workflow/selector";
import { wfGotoCard } from "redux/workflow/action";
import useFormatMessage from "hooks/useFormatMessage";
import clsx from "clsx";
import { makeSelectPrefsList } from "redux/prefs/selector";
import { NotificationsListItem } from "./NotificationsListItem";
import {
  prefsNotificationsView,
  prefsNotificationsRead,
} from "redux/prefs/action";
import { selectLangCurrent } from "redux/app/selector";

const useStyles = makeStyles({
  title: {
    textAlign: "left",
  },
  empty: {
    flexGrow: 1,
    maxWidth: 200,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  emptyIcon: {
    color: "#7F7F7F",
    fontSize: 100,
  },
});

function sortTime(a, b) {
  return b.time - a.time;
}

export const NotificationsList = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStyles();

  const lang = useSelector(selectLangCurrent) || "en";
  const { project } = useSelector(selectCurrent);
  const selectPrefsList = React.useMemo(makeSelectPrefsList, []);
  const notifications = useSelector(state =>
    selectPrefsList(state, project, "notifications")
  );

  const { week, earlier, hasNotViewed } = React.useMemo(() => {
    const earlierTs = Date.now() - 1000 * 60 * 60 * 24 * 7;
    const { week, earlier, hasNotViewed } = Object.entries(
      notifications
    ).reduce(
      (prev, [guid, n]) => {
        if (!n.view) {
          prev.hasNotViewed = true;
        }

        if (n.time < earlierTs) {
          prev.earlier.push({ ...n, guid });
        } else {
          prev.week.push({ ...n, guid });
        }

        return prev;
      },
      { week: [], earlier: [], hasNotViewed: false }
    );
    return {
      week: week.sort(sortTime),
      earlier: earlier.sort(sortTime),
      hasNotViewed,
    };
  }, [notifications]);

  React.useEffect(() => {
    if (hasNotViewed) {
      dispatch(prefsNotificationsView(project));
    }
  }, [dispatch, project, hasNotViewed]);

  const handleItemClick = React.useCallback(
    (guid, dest) => {
      dispatch(prefsNotificationsRead(project, guid));
      if (dest && dest !== "__") {
        dispatch(wfGotoCard(dest));
      }
    },
    [dispatch, project]
  );

  if (week.length === 0 && earlier.length === 0) {
    return (
      <div className={clsx(classes.empty, "notifications-list-empty")}>
        <div
          className={clsx(classes.emptyIcon, "notifications-list-empty-icon")}
        >
          <NotificationsIcon fontSize="inherit" color="inherit" />
        </div>
        <div className="notifications-list-empty-message">
          {t(NotificationsListIntl.Empty)}
        </div>
      </div>
    );
  }

  return (
    <>
      {week.length > 0 && (
        <>
          <h2 className={clsx(classes.title, "notifications-list-section")}>
            {t(NotificationsListIntl.ThisWeek)}
          </h2>
          <List className={clsx("notifications-list")}>
            {week.map(props => (
              <NotificationsListItem
                key={props.guid}
                {...props}
                lang={lang}
                onClick={handleItemClick}
              />
            ))}
          </List>
        </>
      )}
      {earlier.length > 0 && (
        <>
          <h2 className={clsx(classes.title, "notifications-list-section")}>
            {t(NotificationsListIntl.Earlier)}
          </h2>
          <List className={clsx("notifications-list")}>
            {earlier.map(props => (
              <NotificationsListItem
                key={props.guid}
                {...props}
                lang={lang}
                onClick={handleItemClick}
              />
            ))}
          </List>
        </>
      )}
    </>
  );
};
