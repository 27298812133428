import React from "react";
import InfoIcon from "common/icons/Info";
import Card from "common/components/Card";
import Truncate from "react-truncate";
import ReactCardFlip from "react-card-flip";
import FlashOnIcon from "common/icons/FlashOn";
import T from "prop-types";
import nl2br from "react-nl2br";
import ArrowDropDownCircleIcon from "common/icons/ArrowDropDownCircle";
import {
  CardContentFront,
  CardContentBack,
  IconWrapper,
  TitleWrapper,
  InfoWrapper,
  BackWrapper,
  DescriptionContainer,
  ImageContainer,
} from "./FlipCard.s";
import ImageLoader from "../ImageLoader";

const FlipCard = ({ isFlipped, data, onCardClick, onFlipButtonClick }) => {
  const renderIcon = data => {
    return data.icon ? (
      <IconWrapper>
        <FlashOnIcon style={{ fontSize: 52 }} />
      </IconWrapper>
    ) : null;
  };

  const renderInfo = data => {
    const className = data.image
      ? "card-icon-info withimage"
      : "card-icon-info";
    return data.description ? (
      <InfoWrapper className={className}>
        <InfoIcon onClick={e => onFlipButtonClick(e, data.guid)} />
      </InfoWrapper>
    ) : null;
  };

  const renderImage = data => {
    return data.image !== "null" && data.image ? (
      <ImageContainer fullHeight={!Boolean(data.name)} className="card-image">
        <ImageLoader height="100%" width="100%" alt="" src={data.image} cover />
      </ImageContainer>
    ) : null;
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <Card
        hover
        key="front"
        onClick={() => onCardClick(data)}
        className="card-content card-content-front"
      >
        <CardContentFront isImage={data.image}>
          {renderImage(data)}
          {renderInfo(data)}
          {renderIcon(data)}
          {data.name && (
            <TitleWrapper isImage={data.image} className="card-title">
              <span>{nl2br(data.name)}</span>
            </TitleWrapper>
          )}
        </CardContentFront>
      </Card>
      <Card key="back">
        <CardContentBack className="card-content card-content-back">
          <BackWrapper
            className="card-icon-back"
            onClick={e => onFlipButtonClick(e, null)}
          >
            <ArrowDropDownCircleIcon style={{ transform: "rotate(90deg)" }} />
          </BackWrapper>
          <DescriptionContainer>
            {typeof data.description !== undefined && (
              <Truncate lines={5}>{data.description}</Truncate>
            )}
          </DescriptionContainer>
        </CardContentBack>
      </Card>
    </ReactCardFlip>
  );
};

FlipCard.propTypes = {
  isFlipped: T.bool.isRequired,
  data: T.shape({
    guid: T.string.isRequired,
    name: T.string.isRequired,
    icon: T.string,
    description: T.string,
    image: T.string,
  }).isRequired,
  onCardClick: T.func.isRequired,
  onFlipButtonClick: T.func.isRequired,
};

export default FlipCard;
