export default function (points, labels) {
  return points.map(p => {
    return {
      ...p,
      answers: p.answers.map(guid => ({
        guid,
        label: labels[guid],
      })),
      proposals: (p.proposals || []).map(guid => ({
        guid,
        label: labels[guid],
      })),
    };
  });
}
