import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { Box } from '@rebass/grid';
import { Container } from './ComingSoon.s';

export default () => (
  <Container>
    <Box m={3}>
      <Heading textAlign='center'>Coming soon</Heading>
    </Box>
    <Box m={3}>
      <Text textAlign='center'>
        Cette fonctionnalité est en cours de développement
      </Text>
    </Box>
  </Container>
);
