export default {
  colors: {
    primary: '#512D6D',
    secondary: '#00A3E0',
    gray: '#00A3E0',
    grey: '#a6a5a5',
    darkGray: '#512D6D',
    lightGray: '#dcdfe6',
    white: '#ffffff',
    error: '#e53935',
    success: '#4caf50',
    gradient1: '#512D6D',
    gradient2: '#a6a5a5',
    rgb: {
      primary: {
        red: '255',
        green: '54',
        blue: '73',
      },
    },
  },
  fonts: {
    sans: 'Montserrat',
    mono: 'Montserrat',
  },
  fontSizes: {
    small: '12px',
    medium: '18px',
    button: '12px',
    large: '20px',
    title: '22px',
  },
  logoImg: {
    url: '',
  },
  constants: {
    menu: {
      height: '44px',
    },
    titlebar: {
      height: '100px',
    },
  },
};
