import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import appReducer from "redux/app/reducer";
import uploadPicsReducer from "common/components/MediaUpload/redux/reducers";
import userTagReducer from "scenes/user/redux/userTags/reducers";
import auth from "auth/store/reducers";
import themeReducer from "redux/theme/reducer";
import history from "common/history";
import workflowReducer from "redux/workflow/reducer";
import prefsReducer from "redux/prefs/reducer";

const rootReducer = combineReducers({
  app: appReducer,
  router: connectRouter(history),
  workflow: workflowReducer,
  uploadPics: uploadPicsReducer,
  tags: userTagReducer,
  theme: themeReducer,
  prefs: prefsReducer,
  auth,
});

export default rootReducer;
