import React from "react";
import {
  Favorite as FavoriteIcon,
  ArrowForward as ArrowIcon,
} from "@material-ui/icons";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";
import { useFavorites } from "common/components/Favorites";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrent, selectProjectCards } from "redux/workflow/selector";
import { makeSelectPrefsList } from "redux/prefs/selector";
import clsx from "clsx";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { wfGotoCard } from "redux/workflow/action";

const ActionHistory = ({ config, data, actionCount }) => {
  const dispatch = useDispatch();
  const { project, card } = useSelector(selectCurrent);
  const projectCards = useSelector(selectProjectCards);
  const selectPrefsList = React.useMemo(makeSelectPrefsList, []);
  const historyData = useSelector(state =>
    selectPrefsList(state, project, "history")
  );

  const history = React.useMemo(() => {
    const guids = Object.keys(historyData);
    const history = [];
    for (const guid of guids) {
      if (guid !== card && !config.exclude.includes(guid)) {
        const card = projectCards[guid];
        if (card) {
          history.push({ guid, name: card.name, image: card.image });
          if (config.limit && history.length >= config.limitCount) {
            break;
          }
        }
      }
    }
    return history;
  }, [
    historyData,
    projectCards,
    card,
    config.exclude,
    config.limit,
    config.limitCount,
  ]);

  const { favorites, add, remove } = useFavorites(config.favoriteCategory);

  const handleItemClick = React.useCallback(
    guid => {
      dispatch(wfGotoCard(guid));
    },
    [dispatch]
  );

  if (history.length === 0 && actionCount > 1) {
    return null;
  }

  return (
    <>
      <Title config={config} data={data} />
      <List className="history-list">
        {history.map(({ guid, name, image }) => {
          const imageUrl =
            image && process.env.REACT_APP_STATIC_SERVER
              ? image.replace(
                  "https://s3.eu-west-3.amazonaws.com/qrock.me/",
                  process.env.REACT_APP_STATIC_SERVER
                )
              : image;
          return (
            <ListItem
              className={clsx({
                "history-list-item": true,
                "history-list-removed": !favorites[guid],
              })}
              key={guid}
              onClick={() => handleItemClick(guid)}
              disableGutters
              button
            >
              {image && (
                <ListItemAvatar className="history-list-image">
                  <Avatar alt={name} src={imageUrl} variant="square" />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={name}
                classes={{
                  primary: "history-list-title",
                }}
                disableTypography
              />
              {config.favorite && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    className={clsx({
                      "history-list-icon": true,
                      "history-list-iconremoved": !favorites[guid],
                    })}
                    onClick={() => (favorites[guid] ? remove(guid) : add(guid))}
                  >
                    <FavoriteIcon color="inherit" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {!config.favorite && (
                <ListItemSecondaryAction>
                  <IconButton
                    className="history-list-icon"
                    onClick={() => handleItemClick(guid)}
                  >
                    <ArrowIcon color="inherit" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

ActionHistory.classNames = ["qrockme-action-history"];

Object.assign(ActionHistory, AbstractAction);
export default ActionHistory;
