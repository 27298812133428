import React from "react";
import { makeStyles } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import { Image } from "common/components";
import clsx from "clsx";

const useStyles = makeStyles({
  buttonImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  buttonIcon: {
    width: "100%",
    height: "100%",
  },
});

type Classes = "image" | "icon";

interface Props {
  image?: string;
  icon?: SvgIconComponent;
  classes?: Record<Classes, string>;
}

const ButtonImage: React.FC<Props> = React.memo(
  ({ image, icon: Icon, classes: propClasses }) => {
    const classes = useStyles();
    if (image) {
      return (
        <div className={clsx(classes.buttonImage, propClasses?.image)}>
          <Image src={image} fit />
        </div>
      );
    } else if (Icon) {
      return (
        <div className={clsx(classes.buttonImage, propClasses?.icon)}>
          <Icon fontSize="inherit" className={classes.buttonIcon} />
        </div>
      );
    }
    return null;
  }
);

export default ButtonImage;
