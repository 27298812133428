import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CommonIntl from "common/CommonTrad.i";
import { StyledButton, ButtonContainer } from "../Actions.s";
import {
  RateReview as ResultsIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { IconButton, Dialog, makeStyles } from "@material-ui/core";
import ActionsRenderer from "../ActionsRenderer";
import ResultsHeader from "./ResultsHeader";
import clsx from "clsx";
import { someAction, isNext, isOnlyCheckPoints } from "./utils";
import Title from "./Title";

const useStyles = makeStyles({
  dialog: { padding: 16 },
  dialogClose: { position: "absolute", right: 10, top: 10 },
  dialogPaper: {
    margin: 4,
    "& .action-mangetic-controls": {
      marginTop: -30,
      marginRight: 30,
    },
  },
  results: {
    "& .action-mangetic-controls": {
      marginTop: -25,
    },
  },
});

const ResultsScreen = ({
  actions,
  screen,
  config,
  data,
  headerCount,
  headerTotal,
  onSubmit,
  children,
}) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const classes = useStyles();

  const handleResultsClick = () => {
    setShowDialog(true);
  };

  const handleResultsClose = () => {
    setShowDialog(false);
  };

  const screenActions = actions[screen] || [];
  const hasNextActions = someAction(screenActions, isNext);
  const isEmpty =
    !Boolean(screenActions.length) || isOnlyCheckPoints(screenActions);

  return (
    <>
      <Title config={config} data={data}>
        {!isEmpty && (
          <IconButton
            size="small"
            color="secondary"
            onClick={handleResultsClick}
          >
            <ResultsIcon htmlColor="#ff3041" />
          </IconButton>
        )}
      </Title>
      <div className="action-content">
        <div
          className={clsx(
            classes.results,
            `action-screen action-screen-${screen} action-screen-results`,
            { "action-screen-results-empty": isEmpty }
          )}
        >
          <ActionsRenderer actions={screenActions} />
          {isEmpty && (
            <>
              <ResultsHeader count={headerCount} total={headerTotal} />
              {children}
            </>
          )}
        </div>
      </div>
      {onSubmit && !hasNextActions && (
        <ButtonContainer>
          <StyledButton className="qrockme-button" onClick={onSubmit}>
            <FormattedMessage {...CommonIntl.Retry} />
          </StyledButton>
        </ButtonContainer>
      )}
      {Boolean(screenActions.length) && (
        <Dialog
          open={showDialog}
          onClose={handleResultsClose}
          classes={{ paper: classes.dialogPaper }}
          maxWidth="sm"
          fullWidth
        >
          <IconButton
            className={classes.dialogClose}
            size="small"
            onClick={handleResultsClose}
          >
            <CloseIcon />
          </IconButton>
          <div
            className={clsx({
              [classes.dialog]: true,
              "action-screen-results": true,
            })}
          >
            <ResultsHeader count={headerCount} total={headerTotal} showEmpty />
            {children}
          </div>
        </Dialog>
      )}
    </>
  );
};

ResultsScreen.propTypes = {
  actions: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  headerCount: PropTypes.number,
  headerTotal: PropTypes.number,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default ResultsScreen;
