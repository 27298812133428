import {
  setDefaultSettings,
  setInitialSettings,
} from "storeLogin/actions/fuse";
import jwtService from "services/jwtService";
import { setToken } from "redux/app/action";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";

/**
 * Set User Data
 */
export function setUserData(user) {
  return dispatch => {
    /*
        Set User Settings
         */
    console.log("action setUserData user: ", user);
    if (user && user.length > 0) {
      dispatch(setDefaultSettings(user.data.settings));
    }
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser(token) {
  return dispatch => {
    const prom = jwtService.logout();
    dispatch(setInitialSettings());
    dispatch(setToken(token));
    dispatch({
      type: USER_LOGGED_OUT,
    });
    return prom;
  };
}
