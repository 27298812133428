import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { wfMenuOpen } from "redux/workflow/action";
import {
  selectCurrent,
  selectMenusState,
  selectWorkflow,
} from "redux/workflow/selector";

const otherSides = {
  left: "right",
  right: "left",
  top: "bottom",
  bottom: "top",
};

export function useMenu(position) {
  const dispatch = useDispatch();
  const { menus } = useSelector(selectWorkflow);
  const { card: currentCard } = useSelector(selectCurrent);
  const menuState = useSelector(selectMenusState);

  const menu = React.useMemo(() => menus?.[position], [menus, position]);

  const hidden = React.useMemo(
    () => menu && menu.config.hideOnCard === currentCard,
    [menu, currentCard]
  );

  const actions = React.useMemo(() => {
    if (menu === undefined || (menu && menu.config.type === "default")) return;
    if (menu === null || menu.config.type === "remove") {
      return [];
    }
    return menu.children.content || [];
  }, [menu]);

  const hasOtherSide = React.useMemo(() => {
    let otherSide = otherSides[position];
    return Boolean(menus?.[otherSide]?.children.content);
  }, [menus, position]);

  const openMenu = React.useCallback(
    position => {
      dispatch(wfMenuOpen(position));
    },
    [dispatch]
  );

  const closeMenu = React.useCallback(() => {
    dispatch(wfMenuOpen());
  }, [dispatch]);

  return {
    hidden,
    actions,
    hasOtherSide,
    className: menu?.className || "",
    titleImage: menu?.config.titleImage,
    currentOpenMenu: menuState.open,
    openMenu,
    closeMenu,
  };
}
