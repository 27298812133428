import { push } from "connected-react-router";
import { put } from "redux-saga/effects";
import { setTitle } from "redux/app/action";
import { themeSetProject } from "redux/theme/action";
import {
  WF_SAGA_START_PROJECT_DONE,
  WF_SAGA_START_PROJECT_FAILED,
  wfUpdateCurrent,
  wfHistorySet,
} from "redux/workflow/action";

export default function* startProject({ payload: project }) {
  try {
    yield put(wfUpdateCurrent({ project: project.guid, projectData: project }));
    const { css, logo, logoLeft, logoRight } = project;
    yield put(themeSetProject({ css, logo, logoLeft, logoRight }));
    yield put(setTitle(""));
    yield put(wfHistorySet([]));
    yield put(push("/_"));
    yield put({
      type: WF_SAGA_START_PROJECT_DONE,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_START_PROJECT_FAILED, message: e.message });
  }
}
