import rules from "./rules";

export default function (config, answer) {
  const parsed = parseInt(answer);
  if (isNaN(parsed)) {
    return { correct: false };
  }
  const correct = config.proposals.some(r => rules[r.operator].validate(parsed, r));
  return { correct };
}
