import styled from "styled-components/macro";

export const NavButton = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;
