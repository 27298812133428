import { put, select } from "redux-saga/effects";
import {
  WF_SAGA_CLEAR_STATES_DONE,
  WF_SAGA_CLEAR_STATES_FAILED,
} from "../action";
import { selectCurrent } from "../selector";

import produce from "immer";

const challengeTypes = [
  "HOTSPOT",
  "MAGNETIC",
  "MATCHING",
  "MISSING_WORDS",
  "NUMBER",
  "QCMQCU",
  "Q_TEXT",
  "SCAN",
  "SORT",
];

export default function* clearStates({ type, payload }) {
  try {
    const clearAllChallenges =
      type === "WF_SAGA_LOAD_CARD_DONE" &&
      payload.actions.some(
        action => action.type === "RESET" && action.config.all
      );
    const clearCardChallenges =
      type === "WF_SAGA_LOAD_CARD_DONE" &&
      payload.actions.some(
        action => action.type === "RESET" && action.config.card
      );
    const { project: projectGuid, card: cardGuid } = yield select(
      selectCurrent
    );
    const state = yield select(state => state.workflow.states);
    const newstate = produce(state, draft => {
      if (projectGuid && cardGuid) {
        if (draft[projectGuid] && draft[projectGuid][cardGuid]) {
          for (let aKey of Object.keys(draft[projectGuid][cardGuid])) {
            if (draft[projectGuid][cardGuid][aKey].clearOn === type) {
              delete draft[projectGuid][cardGuid][aKey];
            }
          }
        }
      } else {
        for (let pKey of Object.keys(draft)) {
          for (let cKey of Object.keys(draft[pKey])) {
            for (let aKey of Object.keys(draft[pKey][cKey])) {
              if (draft[pKey][cKey][aKey].clearOn === type) {
                delete draft[pKey][cKey][aKey];
              }
            }
          }
        }
      }
      if (clearAllChallenges) {
        for (let pKey of Object.keys(draft)) {
          for (let cKey of Object.keys(draft[pKey])) {
            for (let aKey of Object.keys(draft[pKey][cKey])) {
              if (challengeTypes.includes(draft[pKey][cKey][aKey].actionType)) {
                delete draft[pKey][cKey][aKey];
              }
            }
          }
        }
      } else if (clearCardChallenges) {
        for (let aKey of Object.keys(draft[projectGuid][cardGuid])) {
          if (
            challengeTypes.includes(
              draft[projectGuid][cardGuid][aKey].actionType
            )
          ) {
            delete draft[projectGuid][cardGuid][aKey];
          }
        }
      }
    });
    yield put({
      type: WF_SAGA_CLEAR_STATES_DONE,
      payload: newstate,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_CLEAR_STATES_FAILED, message: e.message });
  }
}
