export const PREFS_ADD = "PREFS_ADD";
export const PREFS_REMOVE = "PREFS_REMOVE";
export const PREFS_NOTIFICATIONS = "PREFS_NOTIFICATIONS";
export const PREFS_NOTIFICATIONS_VIEW = "PREFS_NOTIFICATIONS_VIEW";
export const PREFS_NOTIFICATIONS_READ = "PREFS_NOTIFICATIONS_READ";

export const prefsAdd = (nsKey, listKey, itemId, max) => {
  return {
    type: PREFS_ADD,
    payload: { nsKey, listKey, itemId, max },
  };
};

export const prefsRemove = (nsKey, listKey, itemId) => {
  return {
    type: PREFS_REMOVE,
    payload: { nsKey, listKey, itemId },
  };
};

export const prefsNotifications = (nsKey, notifications) => {
  return {
    type: PREFS_NOTIFICATIONS,
    payload: { nsKey, notifications },
  };
};

export const prefsNotificationsView = nsKey => {
  return {
    type: PREFS_NOTIFICATIONS_VIEW,
    payload: { nsKey },
  };
};

export const prefsNotificationsRead = (nsKey, guid) => {
  return {
    type: PREFS_NOTIFICATIONS_READ,
    payload: { nsKey, guid },
  };
};
