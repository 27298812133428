import React from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  IconButton,
  makeStyles,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import ActionsRenderer from "./ActionsRenderer";
import clsx from "clsx";
import {
  WF_SAGA_LOAD_CARD_DONE,
  wfSagaSubmitAction,
  wfSubmitCard,
} from "redux/workflow/action";
import AbstractAction from "./AbstractAction";

const useStyles = makeStyles({
  dialogClose: { position: "absolute", right: 0, top: 0 },
  dialogContent: { padding: 0 },
  dialogContainer: { height: "calc(100% - 64px)" },
});

const ActionPopup = ({ action, children, className }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(wfSagaSubmitAction(action.guid));
  };
  const classes = useStyles();
  const popupGuid = action.guid;

  const handleChildrenNextClick = React.useCallback(
    action => {
      dispatch(wfSagaSubmitAction(popupGuid));
      dispatch(
        wfSubmitCard(
          [{ action_id: action.action.id, value: JSON.stringify(action.data) }],
          action.config.nextCard
        )
      );
    },
    [dispatch, popupGuid]
  );

  const actions = React.useMemo(
    () =>
      (children["content"] || []).map(a => {
        if (a.type === "NEXT") {
          a.onClick = handleChildrenNextClick;
        }
        return a;
      }),
    [children, handleChildrenNextClick]
  );

  return (
    <>
      {Boolean(actions.length) && (
        <Dialog
          open={true}
          onClose={handleClose}
          classes={{ paper: className, container: classes.dialogContainer }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <IconButton
              className={clsx(classes.dialogClose, "action-close-button")}
              aria-label="Close dialog"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" color="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={clsx(classes.dialogContent, "action-screen")}
          >
            <div className="action-screen-content">
              <ActionsRenderer actions={actions} />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

ActionPopup.prepareAction = function (action, state) {
  state = state || {};
  if (
    !state.closed &&
    action.children["content"] &&
    action.children["content"].length > 0
  ) {
    return action;
  }
};

ActionPopup.onSubmit = function (action) {
  if (action.config.showOnce) {
    return { state: { closed: true } };
  } else {
    return {
      state: {
        closed: true,
        clearOn: WF_SAGA_LOAD_CARD_DONE,
        actionType: "POPUP",
      },
    };
  }
};

ActionPopup.classNames = ["qrockme-action-popup"];

Object.assign(ActionPopup, AbstractAction);
export default ActionPopup;
