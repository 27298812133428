import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { wfSagaSetActionState } from "redux/workflow/action";
import { Preview } from "react-dnd-multi-backend";
import Board from "./Board";
import MainScreen from "../Common/MainScreen";
import produce from "immer";
import { Portal } from "@material-ui/core";
import Spot from "./Spot";

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [dragging, setDragging] = React.useState();
  const dispatch = useDispatch();

  const handleSpotDrop = (item, x, y) => {
    dispatch(
      wfSagaSetActionState(
        action.guid,
        produce(draft => {
          if (item.index !== undefined && x !== undefined && y !== undefined) {
            draft.spots[item.index] = { x, y };
          } else if (x !== undefined && y !== undefined) {
            draft.spots.push({ x, y });
          } else if (item.index !== undefined) {
            draft.spots.splice(item.index, 1);
          }
        })
      )
    );
  };

  const handleDragging = React.useCallback(
    dragging => setDragging(dragging),
    []
  );

  const generatePreview =
    actionGuid =>
    ({ itemType, style }) => {
      if (actionGuid !== itemType) return;
      return (
        <Portal>
          <div style={{ ...style, zIndex: 1001 }}>
            <Spot />
          </div>
        </Portal>
      );
    };

  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      dragging={dragging}
      onSubmit={onSubmit}
    >
      <Board
        dndGroup={action.guid}
        src={config.image}
        alt={config.title}
        isMainScreen={true}
        spots={state.spots}
        maxCount={state.zones.length}
        onDrop={handleSpotDrop}
        onDragging={handleDragging}
      />
      <Preview>{generatePreview(action.guid)}</Preview>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
