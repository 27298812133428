import makeBasicAPIReducer from 'common/utils/makeBasicAPIReducer';
import { combineReducers } from 'redux';
import types from './types';

const fetchUserTagReducer = makeBasicAPIReducer(
  types.FETCH_USER_TAG_REQUEST,
  types.FETCH_USER_TAG_SUCCESS,
  types.FETCH_USER_TAG_FAILURE,
  []
);

const rootUserTagReducer = combineReducers({
  fetchUserTag: fetchUserTagReducer,
});

export default rootUserTagReducer;
