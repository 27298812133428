import React from "react";
import CorrectionIcon from "../Common/CorrectionIcon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    border: "none",
    display: "flex",
    width: "100%",
    padding: "4px 0 4px 20px",
    "& .action-label-text": {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .action-correction-icon": {
      padding: "0 10px",
      display: "flex",
      alignItems: "center",
    },
  },
});

const Result = ({ label, solution, correct }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="action-label-text">
        <div>{label}</div>
        {!correct && solution && (
          <div className="action-label-solution">{solution}</div>
        )}
      </div>
      <CorrectionIcon correct={correct} />
    </div>
  );
};

export default Result;
