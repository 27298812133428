import React from "react";
import {
  Description as PdfIcon,
  Image as ImageIcon,
  Theaters as VideoIcon,
  Audiotrack as AudioIcon,
} from "@material-ui/icons";
import isVideo from "is-video";
import isImage from "is-image";
import isAudio from "libs/isAudio";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";
import Image from "common/components/Image";
import { useActionImage } from "common/actions";

function fromUrlArray(medias) {
  return medias.map(m => {
    if (typeof m === "object") {
      return m;
    }
    return { url: m };
  });
}

function mediaType(url) {
  if (isVideo(url)) {
    return "video";
  } else if (isImage(url)) {
    return "image";
  } else if (isAudio(url)) {
    return "audio";
  } else if (url.toLowerCase().endsWith(".pdf")) {
    return "pdf";
  }
}

const typeIcons = {
  pdf: PdfIcon,
  image: ImageIcon,
  video: VideoIcon,
  audio: AudioIcon,
};

const ActionMedia = ({ config, data, menuPosition }) => {
  const title = config.showTitle
    ? config.title || config.nextCardName
    : undefined;
  const image = useActionImage(config, data, "titleImage");

  let { medias } = config;
  if (!medias.length) {
    return null;
  }

  medias = fromUrlArray(medias); // Keep this to handle old media format (url array)
  const url = medias[0].url;
  const type = mediaType(url);

  if (type === "image") {
    return (
      <div className="media-image">
        <Image alt={config.title} src={url} fit />
      </div>
    );
  }

  return (
    <MenuButton
      title={title}
      image={image}
      icon={!image ? typeIcons[type] : undefined}
      href={url}
      target="_blank"
    />
  );
};

ActionMedia.classNames = ["qrockme-menu-multimedia"];

Object.assign(ActionMedia, AbstractAction);

export default ActionMedia;
