import { isEqual } from "lodash";

export default function (config, state) {
  const sortedPoints = [...config.points].sort(function (a, b) {
    return a.y - b.y;
  });
  const points = sortedPoints.map((p, i) => {
    const answers = state.points[i] ? state.points[i].answers || [] : [];
    return { ...p, answers, correct: isEqual(p.proposals, answers) };
  });
  const correctCount = points.reduce(
    (prev, cur) => (cur.correct ? prev + 1 : prev),
    0
  );
  const correct = correctCount === config.points.length;
  return { correct, points, correctCount };
}
