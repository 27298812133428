import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import { withIntl } from "hooks/useFormatMessage";
import { useHistory, useLocation } from "react-router";
import {
  SearchField,
  SearchResults,
  useSearch,
} from "common/components/Search";
import { useDispatch } from "react-redux";
import { wfGotoSearchResult } from "redux/workflow/action";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
});

function Search() {
  const [text, setText] = React.useState("");
  const { state } = useLocation();
  const nav = useHistory();
  const { hint, history, results, search } = useSearch(state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = React.useCallback(
    value => {
      setText(value);
      search(value);
    },
    [search]
  );

  const handleResultClick = React.useCallback(
    ({ id, card, action }, fromHistory) => {
      dispatch(wfGotoSearchResult({ id, card, action }, Boolean(fromHistory)));
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, "search-field-container")}>
        <IconButton size="small" onClick={() => nav.goBack()}>
          <BackIcon />
        </IconButton>
        <SearchField
          placeholder={state.placeholder}
          image={state.titleImage}
          onChange={handleChange}
          value={text}
          autoFocus={true}
        />
      </div>
      {text.trim().length === 0 ? (
        <SearchResults results={history} onClick={handleResultClick} history />
      ) : (
        <>
          {hint && <div className="search-hint">{hint}</div>}
          <SearchResults results={results} onClick={handleResultClick} />
        </>
      )}
    </div>
  );
}

export default withIntl(Search);
