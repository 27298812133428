import React from "react";
import { List, ListItem, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectLangAvailable, selectLangChoice } from "redux/app/selector";
import ReactCountryFlag from "common/components/CountryFlag";
import clsx from "clsx";

const useStyles = makeStyles({
  root: { overflow: "visible" },
  button: { padding: "4px 8px" },
  arrow: {
    position: "absolute",
    marginLeft: -6,
    width: 12,
    height: 6,
    overflow: "hidden",
    "&:after": {
      content: '""',
      position: "absolute",
      width: 6,
      height: 6,
      left: "50%",
      transform: "translate(-50%,-50%) rotate(45deg)",
    },
  },
});
type OnSelectHandler = (lang: string) => void;

interface Props {
  onSelect: OnSelectHandler;
}

export const LangList: React.FC<Props> = ({ onSelect }) => {
  const langs = useSelector(selectLangAvailable);
  const current = useSelector(selectLangChoice);
  const classes = useStyles();

  const codes = React.useMemo(() => {
    return Object.keys(langs);
  }, [langs]);

  const handleClick = React.useCallback(
    lang => {
      onSelect(lang);
    },
    [onSelect]
  );

  return (
    <List className="lang-list">
      {codes.map(lang => (
        <ListItem
          key={lang}
          className={clsx(classes.button, "lang-item")}
          onClick={() => handleClick(lang)}
          disableGutters
          button
        >
          <ReactCountryFlag
            styleProps={{
              width: 38,
              height: 29,
              borderRadius: 8,
            }}
            className={current === lang ? "lang-active" : ""}
            code={lang}
            png
          />
        </ListItem>
      ))}
    </List>
  );
};
