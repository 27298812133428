import React, { useEffect } from "react";
import { FieldRenderProps } from "react-final-form";

import InfoIcon from "common/icons/Info";
import useTheme from "hooks/useTheme";

import {
  FieldError,
  FormInputContainer,
  TextError,
  DrawingContainer,
} from "./FormAdapters.s";
import DrawingInput from "../Drawing/DrawingInput";
import { convertImageToBase64, isValidURL } from "./DrawingInputAdapter.helper";

const DrawingInputAdapter: React.FC<
  FieldRenderProps<any, HTMLInputElement>
> = ({ input: { onChange, value, name }, label, meta, small, ...rest }) => {
  const theme = useTheme();
  // if the action is binded to a drawing, we save the base64 image
  // so we can restore it when the user clear the drawing
  const [initialBindedImage, setInitialBindedImage] = React.useState<
    string | null
  >(null);
  // canvas use base64 image
  // but api store it a a file on s3
  // so we need to convert it to base64
  useEffect(() => {
    try {
      const image = value[0].url;
      const imgUrl = process.env.REACT_APP_STATIC_SERVER
        ? image.replace(
            "https://s3.eu-west-3.amazonaws.com/qrock.me/",
            process.env.REACT_APP_STATIC_SERVER
          )
        : image;

      if (imgUrl && isValidURL(imgUrl)) {
        const loadImageBase64 = async () => {
          const base64 = await convertImageToBase64(imgUrl);
          setInitialBindedImage(base64);
          onChange(base64);
        };
        loadImageBase64();
      }
    } catch {}
  }, [value, onChange]);

  return (
    <FormInputContainer>
      {meta.touched && meta.error && (
        <FieldError>
          <InfoIcon color={theme.colors.grey} />
          <TextError>{meta.error}</TextError>
        </FieldError>
      )}
      <DrawingContainer {...rest}>
        <DrawingInput
          onChange={onChange}
          value={value}
          name={name}
          initialBindedImage={initialBindedImage}
        />
      </DrawingContainer>
    </FormInputContainer>
  );
};

export default DrawingInputAdapter;
