/* eslint-disable import/newline-after-import  */
/* eslint-disable import/first  */
global.Intl = require("intl");
window.Intl = require("intl");
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Root from "./Root";
import {store, persistor} from "./store";
import * as serviceWorker from "./serviceWorker";
import "./css/Init.css";
import "react-toggle/style.css";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Chargement...</div>} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
