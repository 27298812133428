import React from "react";
import { makeStyles } from "@material-ui/core";
import useResizeObserver from "use-resize-observer/polyfilled";
import Bucket from "./Bucket";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import Spot from "./Spot";
import DraggableSpot from "./DraggableSpot";
import Zone from "./Zone";
import useMath from "../Common/useMath";
import ImageLoader from "common/components/ImageLoader";

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    margin: "auto",
    paddingBottom: 8,
    userSelect: "none",
    "& *": {
      userSelect: "none",
    },
  },
  image: {
    "-webkit-transform": "translateZ(0)",
    maxWidth: "100%",
  },
  controls: {
    display: "flex",
  },
  help: {
    flexGrow: 1,
    textAlign: "left",
  },
});

const Board = ({
  src,
  alt,
  isMainScreen,
  spots,
  zones,
  maxCount,
  dndGroup,
  onDrop,
  onLoad,
  onDragging,
  showSolution,
}) => {
  const { ref, width, height } = useResizeObserver();
  const math = useMath(width, height);
  const [loaded, setLoaded] = React.useState(false);
  const [, drop] = useDrop({
    accept: dndGroup || "__disabled-drop",
    drop(item, monitor) {
      if (!onDrop) return;
      const offset = monitor.getSourceClientOffset();
      const bounding = ref.current.getBoundingClientRect();
      const { x, y } = math.toData(
        offset.x - bounding.x + 10,
        offset.y - bounding.y + 10
      );
      onDrop(item, x, y);
      return undefined;
    },
  });
  drop(ref);

  const classes = useStyles();

  const spotsWithPositions = React.useMemo(() => {
    if (!loaded) return [];
    return spots.map(p => {
      const { x, y } = math.toScreen(p.x, p.y);
      return {
        ...p,
        x: x - 10,
        y: y - 10,
      };
    });
  }, [math, loaded, spots]);

  const zonesWithPositions = React.useMemo(() => {
    if (!loaded || !zones) return [];
    return zones.map(z => {
      const { x, y } = math.toScreen(z.x, z.y);
      const { x: w, y: h } = math.toScreen(z.width, z.height);
      return {
        ...z,
        x,
        y,
        w,
        h,
      };
    });
  }, [math, loaded, zones]);

  const handleLoad = () => {
    setLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };

  const handleClick = e => {
    if (maxCount - spots.length <= 0 || !onDrop) return;
    const { x, y } = math.toData(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    onDrop({}, x, y);
  };

  const handleSpotDrop = (item, didDrop) => {
    if (!didDrop && onDrop) {
      onDrop(item);
    }
  };

  return (
    <>
      {!zones && (
        <div className={clsx(classes.controls, "action-mangetic-controls")}>
          <Bucket
            count={maxCount - spots.length}
            dndGroup={dndGroup}
            onDragging={onDragging}
          />
          <div className={clsx(classes.help, "action-help")}>
            {isMainScreen && (
              <img alt="Aide" src="assets/images/help/hotspot.gif" />
            )}
          </div>
        </div>
      )}
      <div
        ref={ref}
        className={clsx(classes.imageContainer)}
        onClick={handleClick}
      >
        <ImageLoader
          className={classes.image}
          src={src}
          alt={alt}
          onLoad={handleLoad}
        />
        {zones &&
          showSolution &&
          zonesWithPositions.map((z, i) => (
            <Zone
              key={i}
              style={{
                position: "absolute",
                left: z.x,
                top: z.y,
                width: z.w,
                height: z.h,
              }}
              className={clsx({
                "action-zone-correct": z.correct,
                "action-zone-wrong": !z.correct,
              })}
            />
          ))}
        {zones &&
          spotsWithPositions.map((s, i) => (
            <Spot
              key={i}
              index={i}
              style={{ position: "absolute", left: s.x, top: s.y }}
              className={clsx({
                "action-spot-correct": !!s.proposal,
                "action-spot-wrong": !s.proposal,
              })}
            />
          ))}
        {!zones &&
          spotsWithPositions.map((s, i) => (
            <DraggableSpot
              key={i}
              index={i}
              dndGroup={dndGroup}
              onDragging={onDragging}
              onDrop={handleSpotDrop}
              style={{ position: "absolute", left: s.x, top: s.y }}
            />
          ))}
      </div>
    </>
  );
};

export default Board;
