import React from "react";
import PropTypes from "prop-types";
import { wfSagaSetActionState } from "redux/workflow/action";
import { useDispatch } from "react-redux";
import produce from "immer";
import MainScreen from "../../Common/MainScreen";
import Proposal from "../Proposal";
import CollapseScreens from "scenes/actions/Common/CollapseScreens";
import useByGuid from "scenes/actions/Common/useByGuid";

const ScreenMain = ({ action, actions, config, data, state, children }) => {
  const dispatch = useDispatch();

  const handleProposalClick = (guid, screen) => () => {
    if (config.multiple) {
      const index = state.answers.findIndex(v => v === guid);
      dispatch(
        wfSagaSetActionState(
          action.guid,
          produce(draft => {
            if (index > -1) {
              draft.answers.splice(index, 1);
            } else {
              draft.answers.push(guid);
            }
          })
        )
      );
    } else {
      if (state.answers[0] === guid) {
        dispatch(
          wfSagaSetActionState(
            action.guid,
            produce(draft => {
              draft.screen = undefined;
              draft.answers = [];
            })
          )
        );
      } else {
        dispatch(
          wfSagaSetActionState(
            action.guid,
            produce(draft => {
              draft.screen = screen;
              draft.answers = [guid];
            })
          )
        );
      }
    }
  };

  const proposalsByGuid = useByGuid(config.proposals);

  return (
    <MainScreen data={data} actions={actions} screen={"info"} config={config}>
      {state.proposals.map(p => {
        const checked = state.answers.indexOf(p.guid) > -1;
        return (
          <Proposal
            key={p.guid}
            checked={checked}
            image={proposalsByGuid[p.guid].image}
            label={proposalsByGuid[p.guid].label}
            onClick={handleProposalClick(p.guid, p.valueSimple)}
          >
            {children && (
              <CollapseScreens
                actions={children}
                screens={checked ? p.valueSimple : ""}
              />
            )}
          </Proposal>
        );
      })}
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ScreenMain;
