import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { History as HistoryIcon } from "@material-ui/icons";
import { Result } from "./mapResults";
import clsx from "clsx";
import nl2br from "react-nl2br";

type ResultClickEvent = { id: string; card: string; action?: string };

interface Props {
  results: Result[];
  onClick: (ev: ResultClickEvent, history: boolean) => void;
  history?: boolean;
  className?: string;
}

export const SearchResults: React.FC<Props> = ({
  results,
  className,
  onClick,
  history,
}) => {
  if (!results.length) return null;
  const isHistory = Boolean(history);
  return (
    <List className={clsx("search-results", className)}>
      {results.map(({ id, label, parent, summary, image, card, action }) => {
        const imageUrl =
          image && process.env.REACT_APP_STATIC_SERVER
            ? image.replace(
                "https://s3.eu-west-3.amazonaws.com/qrock.me/",
                process.env.REACT_APP_STATIC_SERVER
              )
            : image;
        return (
          <ListItem
            className={clsx({
              "search-result": true,
              "search-history": isHistory,
            })}
            key={id}
            onClick={() => onClick({ id, card, action }, isHistory)}
            button
          >
            <ListItemAvatar className="search-result-image">
              {image ? (
                <Avatar alt={label} src={imageUrl} variant="square" />
              ) : (
                <div></div>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={label}
              secondaryTypographyProps={{ component: "div" }}
              secondary={
                <>
                  {parent && (
                    <div className="search-result-card">{nl2br(parent)}</div>
                  )}
                  {summary && (
                    <div className="search-result-summary">
                      {nl2br(summary)}
                    </div>
                  )}
                </>
              }
              classes={{
                primary: "search-result-title",
              }}
            />
            {isHistory && (
              <ListItemSecondaryAction>
                <HistoryIcon />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};
