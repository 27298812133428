import { replace } from "connected-react-router";
import { put, select } from "redux-saga/effects";
import {
  wfMenuOpen,
  wfSetLoading,
  WF_SAGA_GOTO_CARD_DONE,
  WF_SAGA_GOTO_CARD_FAILED,
} from "redux/workflow/action";
import { selectCurrent } from "../selector";

export default function* gotoCard({ payload: { guid, actionId } }) {
  try {
    const { session } = yield select(selectCurrent);
    yield put(wfSetLoading());
    yield put(wfMenuOpen());
    yield put(
      replace("/_", {
        guid,
        name: "?GOTO?",
        session,
        scrollTo: actionId,
      })
    );
    yield put({
      type: WF_SAGA_GOTO_CARD_DONE,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_GOTO_CARD_FAILED, message: e.message });
  }
}
