import styled from 'styled-components/macro';
import T from 'prop-types';

const Textarea = styled.textarea`
   resize: vertical;
  padding: 16px;
  box-sizing: border-box;
  transition: border-color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:required {
    box-shadow: none;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 1;
  }
  ::-moz-placeholder {
    opacity: 1;
  }
  :-ms-input-placeholder {
    opacity: 1;
  }
`;
Textarea.propTypes = {
  error: T.bool,
  readOnly: T.bool,
};

export { Textarea };
