import React from "react";
import AbstractAction from "./AbstractAction";
import { useDispatch } from "react-redux";
import { wfGotoCard } from "redux/workflow/action";
import { useActionNext } from "common/actions";
import MenuButton from "./Common/MenuButton";
import nl2br from "react-nl2br";

const ActionNext = ({ config, data }) => {
  const dispatch = useDispatch();

  const { image, title, showTitle, desc, additionals } = useActionNext(
    config,
    data
  );

  const handleClick = React.useCallback(() => {
    dispatch(wfGotoCard(config.nextCard));
  }, [dispatch, config.nextCard]);

  return (
    <MenuButton
      title={showTitle ? title : undefined}
      onClick={handleClick}
      image={image}
    >
      {desc && <div className="menu-next-desc">{nl2br(desc)}</div>}
      {additionals.length > 0 &&
        additionals.map(({ content }, idx) => {
          if (!content) return null;
          const className = [
            "menu-next-desc",
            "menu-next-add",
            `menu-next-add-${idx + 1}`,
          ].join(" ");
          return (
            <div className={className} key={idx}>
              {nl2br(content)}
            </div>
          );
        })}
    </MenuButton>
  );
};

ActionNext.classNames = ["qrockme-menu-next"];

Object.assign(ActionNext, AbstractAction);
ActionNext.isActive = function (action, { current, location }) {
  if (location.pathname !== "/_") return;
  return action.config.nextCard === current.card;
};

export default ActionNext;
