import React from "react";
import axios from "axios";
import qs from "qs";
import * as Api from "api";
import { Info as LoginErrorIcon, Close as CloseIcon } from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { useHistory, useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import InputAdapter from "common/components/formAdapters/InputAdapter";
import MaskedInputAdapter from "common/components/formAdapters/MaskedInputAdapter";
import Lock from "common/icons/Lock";
import Button from "common/components/Button";
import { required } from "common/components/rules";
import { withIntl } from "hooks/useFormatMessage";
import { useDispatch, useSelector } from "react-redux";
import jwtService from "services/jwtService";
import { setUserData } from "auth/store/actions/user.actions";
import { setToken } from "redux/app/action";
import { LOGIN_SUCCESS, LOGIN_ERROR } from "auth/store/actions/login.actions";
import {
  Container,
  ImageContainer,
  FormContainer,
  ButtonContainer,
  FormField,
  LoginError,
} from "./Login.s";
import ConnexionIntl from "./Login.i";
import {
  Box,
  IconButton,
  Button as MuiButton,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { selectEntity } from "redux/workflow/selector";

const useStyles = makeStyles({
  link: {
    fontSize: 13,
    color: "#919090",
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  hint: {
    fontSize: 13,
    color: "#919090",
  },
  error: {
    color: "red",
  },
});

function Login() {
  const { company } = useSelector(selectEntity);
  const [sso, setSso] = React.useState(company?.sso === 2);
  const [ssoError, setSsoError] = React.useState(false);
  const [error, setError] = React.useState();
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const defaultEmail =
    location.state && location.state.email ? location.state.email : "";

  function handleConnexionClick() {
    const event = document.createEvent("Event");
    event.initEvent("submit", false, true);
    document.getElementById("loginForm").dispatchEvent(event);
  }

  const { u: guid, s: secret } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  React.useEffect(() => {
    if (!(guid && secret)) return;
    Api.ssoConnect(guid, secret)
      .then(({ token: { token, user } }) => {
        dispatch(setToken(token));
        dispatch(setUserData(user));
        jwtService.setSession(token, user);
        history.replace(
          {
            pathname: "/user",
          },
          { resume: true }
        );
        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch(err => {
        history.replace({
          pathname: "/account",
          search: "",
        });
        console.error(err);
        setSsoError(true);
        setSso(true);
      });
  }, [location, dispatch, history, guid, secret]);

  function handleFormSubmit(model) {
    setSsoError(false);
    if (sso) {
      axios
        .post(`${process.env.REACT_APP_ROOT_API}auth/sso`, {
          email: model.email,
          target: "mobile",
        })
        .then(response => {
          window.location.replace(response.data.redirect);
        })
        .catch(() => {
          setSsoError(true);
        });

      return;
    }
    setError();
    jwtService
      .signInWithEmailAndPassword(model.email.trim(), model.password.trim())
      .then(({ user, token }) => {
        dispatch(setToken(token));
        dispatch(setUserData(user));
        dispatch({
          type: LOGIN_SUCCESS,
        });
        history.replace({
          pathname: "/user",
        });
      })
      .catch(error => {
        setError(
          error.response && error.response.data.error === "E_LOCKED"
            ? ConnexionIntl.AccountLocked
            : ConnexionIntl.LoginFailed
        );
        console.log("error", error);
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  }

  if (guid && secret) return null;

  return (
    <Container>
      <Box textAlign="right">
        <IconButton
          onClick={() => {
            if (location.state?.resume) {
              history.replace("/_?resume=1");
            } else {
              history.replace("/_");
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <ImageContainer className="qrockme-user-icon">
        <Lock style={{ fontSize: 70 }} />
      </ImageContainer>
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, pristine, invalid }) => (
          <React.Fragment>
            <FormContainer id="loginForm" onSubmit={handleSubmit}>
              {error && (
                <FormField>
                  <LoginError>
                    <LoginErrorIcon style={{ marginRight: 8 }} />
                    {t(error)}
                  </LoginError>
                </FormField>
              )}
              <FormField>
                <Field
                  name="email"
                  id="email"
                  defaultValue={defaultEmail}
                  component={MaskedInputAdapter}
                  placeholder={t(ConnexionIntl.EmailPlaceholder)}
                  validate={required}
                />
              </FormField>
              {sso && (
                <FormField
                  className={clsx(classes.hint, { [classes.error]: ssoError })}
                >
                  {ssoError ? (
                    <FormattedMessage {...ConnexionIntl.AuthSsoError} />
                  ) : (
                    <FormattedMessage {...ConnexionIntl.AuthSsoHint} />
                  )}
                </FormField>
              )}
              {!sso && (
                <FormField style={{ marginBottom: 0 }}>
                  <Field
                    name="password"
                    id="password"
                    component={InputAdapter}
                    placeholder={t(ConnexionIntl.PwdPlaceholder)}
                    validate={required}
                    type="password"
                  />
                </FormField>
              )}
              {!sso && (
                <FormField style={{ textAlign: "right" }}>
                  <MuiButton
                    className={classes.link}
                    variant="text"
                    href={`${process.env.REACT_APP_ROOT_ADMIN}auth/forgot-password?back=1`}
                  >
                    <FormattedMessage {...ConnexionIntl.Forgot} />
                  </MuiButton>
                </FormField>
              )}
              <ButtonContainer>
                <Button
                  type="button"
                  className="qrockme-button"
                  onClick={handleConnexionClick}
                  disabled={pristine || invalid}
                >
                  <FormattedMessage {...ConnexionIntl.Connexion} />
                </Button>
                {company.sso === 1 && (
                  <div>
                    <MuiButton
                      className={classes.link}
                      variant="text"
                      onClick={() => setSso(s => !s)}
                    >
                      {sso ? (
                        <FormattedMessage {...ConnexionIntl.AuthPasswordLink} />
                      ) : (
                        <FormattedMessage {...ConnexionIntl.AuthSsoLink} />
                      )}
                    </MuiButton>
                  </div>
                )}
              </ButtonContainer>
            </FormContainer>
          </React.Fragment>
        )}
      />
    </Container>
  );
}

export default withIntl(Login);
