import axios from "axios";
import jwtDecode from "jwt-decode";
import { setToken } from "redux/app/action";
import { utils, utilsLocalStorage } from "utils";

class jwtService extends utils.EventEmitter {
  // class jwtService {
  init() {
    console.log("jwtService init");
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          if (err.response !== undefined) {
            if (
              !err.config.url.endsWith("/auth/logout?from=mobile") &&
              err.response.status === 401 &&
              err.config &&
              !err.config.__isRetryRequest
            ) {
              // if you ever get an unauthorized response, logout the user
              this.emit("onAutoLogout", "Invalid access_token");
              this.setSession(null);
            }
          } else {
            this.emit("NetworkError404", "Network Error");
            this.setSession(null);
            console.log("network error : ", err);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    console.log("handleAuthentication: ");

    const access_token = this.getAccessToken();
    //console.log('access_token: ', access_token);

    if (!access_token) {
      this.emit("onNoAccessToken");
      //console.log('onNoAccessToken: ');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      console.log("onAutoLogin: ");
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
      //console.log('onAutoLogout: ');
    }
  };

  createUser = data => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_ROOT_API}auth/signin`, {
          email,
          password,
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.token, response.data.user);
            resolve({ user: response.data.user, token: response.data.token });
          } else {
            reject(response.data.error);
          }
        })
        .catch(reject);
    });
  };

  signInWithToken = () => {
    console.log("signInWithToken: ");
    return new Promise((resolve, reject) => {
      const config = {
        headers: { Authorization: `bearer ${this.getAccessToken()}` },
      };
      const bodyParameters = {
        key: "value",
      };
      // console.log('this.getAccessToken(): ', this.getAccessToken());
      axios
        .get(`${process.env.REACT_APP_ROOT_API}auth/checkme`, {
          bodyParameters,
          config,
        })
        .then(response => {
          //console.log('response: ', response);
          if (response.status === 204) {
            this.setSession(this.getAccessToken());
            const dataUser = utilsLocalStorage.getUser();
            console.log("signInWithToken dataUser: ", dataUser);
            resolve(dataUser);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  updateUserData = user => {
    return axios.post("/api/auth/user/update", {
      user,
    });
  };

  setSession = (access_token, user) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      if (user) {
        utilsLocalStorage.setUser(user);
      }
    } else {
      console.log("########### delete session", axios.defaults);
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
      setToken("");
    }
  };

  setUser = user => {
    utilsLocalStorage.setUser(user);
  };

  logout = () => {
    return axios
      .get(`${process.env.REACT_APP_ROOT_API}auth/logout?from=mobile`)
      .then(({ data }) => {
        this.setSession(null);
        utilsLocalStorage.delUser();
        return { redirect: data.redirect };
      })
      .catch(() => {
        this.setSession(null);
        utilsLocalStorage.delUser();
        return Promise.resolve({});
      });
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }
    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const instance = new jwtService();

export default instance;
