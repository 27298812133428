import ActionQText from "../ActionQText";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

export default function ({ config, children, data, ...rest }, state) {
  if (!state && data?.result_obj?.screen) {
    state = {
      ...ActionQText.initialState,
      ...data.result_obj,
      proposals: config.proposals,
      correct: data.result_obj.screen === "success",
    };
  } else if (!state) {
    state = state || { ...ActionQText.initialState };
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...ActionQText.initialState,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  const preparedChildren = prepareInteractiveActionChildren(
    children,
    state.screen
  );

  return {
    config,
    data,
    ...rest,
    children: preparedChildren,
    state,
  };
}
