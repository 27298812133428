import React from "react";
import { makeStyles } from "@material-ui/core";
import FlipCard from "common/components/FlipCard";
import clsx from "clsx";
import { useActionNext } from "common/actions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    "& .react-card-flip": {
      position: "absolute",
      left: 0,
      top: 0,
      padding: "8px 4px",
      width: "100%",
      height: "100%",
    },
  },
});

const Card = ({ config, data, disabled, onClick, actionKey }) => {
  const [flip, setFlip] = React.useState(false);
  const classes = useStyles();

  const handleFlipButtonClick = (e, flippedId) => {
    e.preventDefault();
    e.stopPropagation();
    setFlip(s => !s);
  };

  const { title, image, desc, additionals } = useActionNext(config, data);
  const flipdata = {
    guid: actionKey,
    name: [
      title,
      ...additionals.map(({ content }) => content).filter(Boolean),
    ].join("\n"),
    image,
    description: desc,
  };
  return (
    <div
      className={clsx(classes.root, "qrockme-card", {
        "qrockme-card-invalid": disabled,
      })}
    >
      <FlipCard
        data={flipdata}
        isFlipped={flip}
        onFlipButtonClick={handleFlipButtonClick}
        onCardClick={onClick}
      />
    </div>
  );
};

export default Card;
