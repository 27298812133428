export default `
.qrockme-titlebar {
  background: white;
  display: flex;
  flex-direction: column;
}

.qrockme-titlebar.title-logo-right-only,
.qrockme-titlebar.title-logo-left-only  {
  background: white;
  display: flex;
  flex-direction: row-reverse;
}

.qrockme-titlebar.title-logo-left-only {
  flex-direction: row;
}

.title-logo.title-logo-right-only .title,
.title-logo.title-logo-left-only .title {
  padding-top: 22px;
}

.qrockme-titlebar .title-logo {
  align-items: flex-start;
  flex-shrink: 0;
}

.qrockme-titlebar .title-logo-right {
  height: 85px;
  padding: 10px;
  flex-shrink: 1;
}

.qrockme-titlebar .title-logo-left {
  height: 85px;
  padding: 10px;
  flex-shrink: 1;
}

.qrockme-titlebar .title-logo-center {
  height: 85px;
  padding: 10px;
}

.title-logo.title-logo-right-only .title-logo-right,
.title-logo.title-logo-left-only .title-logo-left {
  flex-shrink: 0;
}

.qrockme-titlebar .title {
  flex-grow: 1;
  color: #565656;
  font-weight: bold;
  justify-content: center;
}

.qrockme-titlebar.title-logo-right-only .title,
.qrockme-titlebar.title-logo-left-only .title {
  padding-top: 22px;
}

.qrockme-scene {
  padding-top: 16px;
}

.qrockme-loader {
  color: #a6a5a5;
}

.qrockme-button {
  min-height: 39px;
  font-size: 12px;
  line-height: 1.6;
  min-width: 99px;
  text-transform: uppercase;
  color: white;
  background: #ff3041;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 #a6a5a5;
  text-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-appearance: none;
  margin-bottom: 16px;
  border: none;
  overflow: hidden;
}

.qrockme-button .qrockme-button-title {
  padding: 3px 10px;
}

.qrockme-button .qrockme-button-desc {
  padding-bottom: 3px;
  text-transform: none;
}

.qrockme-button .qrockme-button-add {
  padding-bottom: 3px;
  text-transform: none;
}

.qrockme-button .qrockme-button-image {
  width: 20%;
  background-size: contain;
  flex-shrink: 0;
}

.qrockme-button-small {
  min-height: auto;
  margin-bottom: 0;
}

.qrockme-button-active {
  box-shadow: none;
}

.qrockme-button-neutral {
  background: #00B0F0;
}

.qrockme-button-wrong {
  background: #FF0000;
}

.qrockme-button-correct {
  background: #00B050;
}

.qrockme-button .qrockme-loader {
  color: white;
}

.qrockme-icon-button {
  background: #D8D8D8;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0 2px 4px 0 #a6a5a5;
}

.qrockme-icon-button:hover {
  background: #c2c2c2;
}

.qrockme-icon-button-primary {
  background: #ff3041;
  color: #ffffff;
}

.qrockme-icon-button-primary:hover {
  background: #ff5966;
}

.qrockme-line {
  border-bottom: 1px solid #D8D8D8;
  min-height: 38px;
  padding: 3px 0;
}

.qrockme-line-disabled {
  cursor: default;
  opacity: 0.8;
}

.qrockme-line .qrockme-line-image {
  width: 46px;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(138 138 138 / 50%);
}

.qrockme-line .qrockme-line-arrow {
  color: #979797;
}

.qrockme-line .qrockme-line-title {
  padding: 2px 0;
}

.qrockme-line .qrockme-line-desc {
  padding: 2px 0;
  color: #979797;
  font-size: 11px;
}

.qrockme-line .qrockme-line-add {
  padding: 2px 0;
  color: #979797;
  font-size: 11px;
}

.qrockme-input {
  border-radius: 8px;
  border: 1px solid #565656;
  color: #565656;
  outline: none;
  width: 100%;
  min-width: 95%;
  padding: 10px;
  font-size: 18px;
}

.qrockme-cardlarge {
  border-radius: 8px;
  margin: 8px 4px;
  height: 200px;
  color: #FFF;
  box-shadow: 0 1px 4px 0 rgb(138 138 138 / 50%);
  background-color: #FF3041;
}

@media (min-width: 1280px) {
  .qrockme-action.qrockme-action-next-cardlarge {
    width: 50%;
  }
}

.qrockme-cardlarge-disabled {
  cursor: default;
  opacity: 0.7;
}

.qrockme-cardlarge-text {
  background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.1) 40%,rgba(0,0,0,0.8) 80%,rgba(0,0,0,0.9) 100%);
  padding: 16px;
}

.qrockme-cardlarge-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}

.qrockme-cardlarge-desc {
  margin-bottom: 4px;
}

.qrockme-cardlarge-add {
  margin-bottom: 4px;
}

.qrockme-user-icon {
  color: #FF3041;
}

.qrockme-user-tag {
  background-color: #f86874;
}


.qrockme-card .card-content {
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(138, 138, 138, 0.5);
  background-color: white;
  overflow: hidden;
}

.qrockme-card .card-title {
  font-size: 13px;
  letter-spacing: 0.02em;
}

.qrockme-card .card-icon-info {
  top: 10px;
  right: 10px;
  color: #b1b1b2;
}

.qrockme-card .card-icon-back {
  color: #ff3041;
}

.qrockme-card .card-icon-back svg {
  fill: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><linearGradient x1='0%' y1='0%' x2='0%' y2='100%' id='grad'><stop offset='0%' stop-color='%23ff2d40' /><stop offset='90%' stop-color='%23ffb659' /></linearGradient></svg>#grad")  #ff3041;
}

.qrockme-card .card-icon-info.withimage {
  filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .5));
  color: white;
}

/* SECTIONS & COLUMNS */
.qrockme-nolayout {
  width: 100%;
  margin: 0 auto;
}

.qrockme-column {
  padding-left: 16px;
  padding-right: 16px;
}

/* SM */
@media (min-width: 600px) {
  .qrockme-nolayout {
    width: 80vw;
  }
  .qrockme-layout.qrockme-section-1 .qrockme-action-next-button {
    width: 80vw;
    margin: auto;
  }
}

/* MD */
@media (min-width: 960px) {
  .qrockme-nolayout {
    width: 60vw;
  }
  .qrockme-layout.qrockme-section-1 .qrockme-action-next-button {
    width: 60vw;
    margin: auto;
  }
  .qrockme-column {
    padding: 10px;
  }
}

/* LG */
@media (min-width: 1280px) {
  .qrockme-nolayout {
    width: 50vw;
  }
  .qrockme-layout.qrockme-section-1 .qrockme-action-next-button {
    width: 50vw;
    margin: auto;
  }
}

.qrockme-default-submit-section {
  margin-top: -5px;
}

.qrockme-default-submit-section .qrockme-column {
  padding-top: 0;
  padding-bottom: 0;
}

/* COMPONENTS */

.qrockme-pdfviewer-document {
  height: 60vh;
  border: 1px solid #a6a5a5;
  border-radius: 4px;
}

.qrockme-pdfviewer-controls {
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  -webkit-box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  -moz-box-shadow: 0 30px 40px 0 rgba(16 36 94,0.20);
}

.qrockme-pdfviewer-controls button {
  background: transparent;
  margin: 4px;
  font-size: 16px;
  padding: 4px;
  min-width: 40px;
}

.qrockme-pdfviewer-controls  .qrockme-pdfviewer-controls-pages {
  min-width: 40px;
}

/* ACTIONS */

.qrockme-action {
  color: #565656;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.action-container {
  margin: 0 auto;
  width: 100%;
}

.action-group-label {
  color: #565656;
  margin-top: 10px;
  font-weight: bold;
  font-size: 150%;
}

.qrockme-action .action-container .action-screen  {
  border-radius: 8px;
  padding: 5px 8px;
}

.qrockme-action .action-container .action-screen.action-screen-info {
  background: #E2E2E2;
}

.qrockme-action .action-container .action-screen,
.qrockme-action .action-container .action-screen .action-screen-content  {
  display: flex;
  margin: 10px 0;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: space-around;
}

.qrockme-action .action-container .action-screen.action-screen-results  {
  border: 2px solid #C7C7C7;
  background: transparent;
}

.qrockme-action .action-container .action-screen.action-screen-results-empty {
  display: block;
}

.qrockme-action .action-container .action-screen .action-title  {
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.qrockme-action .action-container .action-screen .action-content  {
  margin: 0;
  font-size: 13px;
}

.action-screen .action-container {
  width: 100%;
}

.qrockme-action .action-title {
  font-size: 20px;
}

.qrockme-action .action-title:not(:first-child) {
  padding: 24px 0 0px 0;
}

.qrockme-action .action-link {
  overflow: hidden;
  color: #565656;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(124, 124, 124, 0.5);
}

.qrockme-action .action-link-text {
  padding: 10px;
}

.qrockme-action .action-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ff3041;
}

.qrockme-action-link .action-link-text {
  flex-grow: 1;
}

.qrockme-action-link-image {
  width: 33%;
  padding: 6px 12px;
}

/* MD */
@media (min-width: 960px) {
  .qrockme-action-link-image {
    width: 16%;
  }
}

.qrockme-action-link-image .action-container {
  height: 100%;
}

.qrockme-action-link-image .action-link {
  flex-direction: column;
  align-items: stretch;
  box-shadow: none;
  border-radius: 0;
  overflow: visible;
  height: 100%;
}

.qrockme-action-link-image .action-link-image {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(124 124 124 / 50%);
  flex-grow: 1;
}

.qrockme-action-link-image .action-link-icon {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(124 124 124 / 50%);
  flex-grow: 1;
  font-size: 3rem;
}

.qrockme-action-link .action-link-image + .action-link-text {
  text-align: center;
}

.qrockme-action-link-image .action-link-text {
  height: 36px;
  margin: 4px 0;
  padding: 0;
  text-align: center;
  overflow: hidden;
  display: block;
  flex-grow: 0;
}

.qrockme-action-next-card {
  position: relative;
  width: 50%;
  padding-bottom: 42.5%;
}

.qrockme-action-next-card .action-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* MD */
@media (min-width: 960px) {
  .qrockme-action-next-card {
    width: 33%;
    padding-bottom: 23%;
  }
}

.qrockme-action-next.qrockme-action-next-nav {
  width: calc(50% - 8px);
}

.qrockme-action-next-line.qrockme-action-next-nav {
  border-bottom: none;
}

.qrockme-action-next.qrockme-action-next-nav-prev {
  margin-right: 4px;
  float: left;
}

.qrockme-action-next-line.qrockme-action-next-nav-next .qrockme-line-text {
  text-align: right;
}

.qrockme-action-next.qrockme-action-next-nav-next {
  margin-left: 4px;
  float: right;
}

.qrockme-action-next-cardlarge { }

.qrockme-action-next-image { }

.qrockme-action-next-image .action-link-text {
  flex-grow: 1;
}

.qrockme-action-next-image {
  width: 33%;
  padding: 6px 12px;
}

/* MD */
@media (min-width: 960px) {
  .qrockme-action-next-image {
    width: 16%;
  }
}

.qrockme-action-next-image .action-container {
  height: 100%;
}

.qrockme-action-next-image .qrockme-image {
  flex-direction: column;
  align-items: stretch;
  box-shadow: none;
  border-radius: 0;
  overflow: visible;
  height: 100%;
}

.qrockme-action-next-image .qrockme-image-image {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(124 124 124 / 50%);
  flex-grow: 1;
}

.qrockme-action-next-image .qrockme-image-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ff3041;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(124 124 124 / 50%);
  flex-grow: 1;
  font-size: 3rem;
}

.qrockme-action-next-image .qrockme-image-image + .qrockme-image-text {
  text-align: center;
}

.qrockme-action-next-image .qrockme-image-text {
  height: 36px;
  margin: 4px 0;
  padding: 0;
  text-align: center;
  overflow: hidden;
  display: block;
  flex-grow: 0;
}

.qrockme-action-next-line {
  margin: 0 4px;
}

.qrockme-action-next .qrockme-button {
  width: 100%;
}

.qrockme-action-next .qrockme-button:disabled {
  background: #a6a5a5;
}

.qrockme-action-next:last-child .qrockme-button {
  margin-bottom: 0px;
}

.qrockme-action-takepics .image-frame {
  border-radius: 8px;
  color: #ff3041;
}

.qrockme-action-text .action-content {
  margin-top: 1em;
}

.qrockme-action-text .action-content FIGURE {
  margin: 4px 0;
  text-align: center;
}

.qrockme-action-text .action-content IMG {
  max-width: 100%;
  height: auto;
}

.qrockme-action-toggle .react-toggle {
  margin-right: 2px;
}

.qrockme-action-toggle .react-toggle--checked .react-toggle-track {
  border: 1px solid #ff3041;
}

.qrockme-action-toggle .react-toggle-thumb {
  background: #ff3041;
}

.qrockme-action-multimedia .action-title {
  margin-bottom: 8px;
}

.qrockme-pdfdownload {
  border-radius: 4px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.15);
  margin: 4px;
  padding: 4px;
}

.qrockme-pdfdownload .qrockme-pdfdownload-icon {
  color: #ff3041;
  font-size: 40px;
}

.qrockme-action-multimedia .action-media-title,
.qrockme-action-multimedia .qrockme-pdfdownload-title {
  font-size: 16px;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.qrockme-action-multimedia .action-media-desc,
.qrockme-action-multimedia .qrockme-pdfdownload-desc {
  color: #aeaeae;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.qrockme-action-multimedia .action-media-add,
.qrockme-action-multimedia .qrockme-pdfdownload-add {
  color: #aeaeae;
}

.qrockme-action-multimedia .slick-slide {
  height: 40vh !important;
}

.qrockme-action-multimedia .slick-next {
  right: -21px;
}

.qrockme-action-multimedia .slick-prev {
  left: -21px;
}


.qrockme-action-multimedia .slick-slide > div {
  width: 100%;
  height: 100%;
}

.qrockme-action-multimedia .slick-slide img,
.qrockme-action-multimedia .slick-slide video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.qrockme-action-multimedia-carrousel-pleine-largeur .media-content {
  margin-left: -16px;
  margin-right: -16px;
}

.qrockme-action-multimedia-carrousel-pleine-largeur .media-content .slick-prev {
  left: 17px;
  z-index: 10;
}

.qrockme-action-multimedia-carrousel-pleine-largeur .media-content .slick-prev:before {
  color: white !important;
  text-shadow: 0px 0px 5px black;
  -webkit-text-shadow: 0px 0px 5px black;
  -moz-text-shadow: 0px 0px 5px black;
}

.qrockme-action-multimedia-carrousel-pleine-largeur .media-content .slick-next {
  right: 17px;
  z-index: 10;
}

.qrockme-action-multimedia-carrousel-pleine-largeur .media-content .slick-next:before {
  color: white !important;
  text-shadow: 0px 0px 5px black;
  -webkit-text-shadow: 0px 0px 5px black;
  -moz-text-shadow: 0px 0px 5px black;
}

.qrockme-action-multimedia-carte .action-card {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.15);
}

.qrockme-action-multimedia-carte .action-card .qrockme-pdfdownload {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.qrockme-action-multimedia-ligne .action-media-ligne-item {
  margin: 10px 0;
}

.qrockme-action-multimedia-ligne .action-media-ligne-title {
  font-weight: bold;
  margin-right: 4px;
}

.qrockme-action-multimedia-ligne .action-media-ligne-desc {
  margin-right: 4px;
}

.qrockme-action-multimedia-ligne .action-media-ligne-add {
  margin-right: 4px;
}

.qrockme-action-multimedia-ligne .action-media-ligne-image-item .action-media-ligne-media,
.qrockme-action-multimedia-ligne .action-media-ligne-pdf-item .action-media-ligne-media {
  width: 40px;
}

.qrockme-action-multimedia-ligne .action-media-ligne-pdf-item .action-media-ligne-media  {
  color: #ff3041;
  font-size: 40px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -10px;
}

.qrockme-action-multimedia-ligne .action-media-ligne-text {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.qrockme-action-multimedia-ligne .action-media-ligne-video-item .action-media-ligne-media {
  width: 50%;
}

.qrockme-action-multimedia-ligne .action-media-ligne-audio-item {
  flex-wrap: wrap;
}

.qrockme-action-qcmqcu.qrockme-action-qcmqcusurvey .action-container .action-screen.action-screen-results {
  border-width: 0;
}

.qrockme-action-qcmqcu.qrockme-action-qcmqcusurvey .action-proposal-checked .action-proposal-button {
  background: #F2F2F2;
}

.qrockme-action-qcmqcu.qrockme-action-qcmqcusurvey .action-proposal .action-screen { /* Quand le écran est entre les propositions */
  margin-bottom: 0;
}

.qrockme-action-countdown .action-countdown-timers {
  margin-top: 12px;
}

.qrockme-action-countdown .action-countdown-timer {
  width: 30%;
}

.qrockme-action-countdown .action-countdown-label {
  margin-top: 12px;
  font-size: 18px;
}

.qrockme-action-countdown .action-countdown-value {
  font-size: 9vw;
}

.qrockme-action-countdown .action-countdown-track {
  color: #eeeeee;
  stroke-width: 2px;
}

.qrockme-action-countdown .action-countdown-circle {
  color: #ff3041;
  stroke-width: 2px;
  stroke-linecap: round;
}

.qrockme-action-accordion .action-container .action-screen {
  padding: 0;
}

.qrockme-action-accordion .action-accordion-title {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 8px;
}

.qrockme-action-search .action-search-results {
  border: 1px solid #565656;
  border-radius: 8px;
  margin-top: 8px;
  max-height: 60vh;
}

.qrockme-action-search .action-title {
  justify-content: center;
}

.qrockme-action-search .action-title-text {
  flex-grow: 0;
  font-size: 16px;
}

.search-result-title {
  font-weight: bold;
  text-transform: uppercase;
}

.search-result {
  align-items: start;
}

.search-result .search-result-card {
  color: #000;
  margin-bottom: 8px;
}

.search-result .search-result-summary {
  font-size: 12px;
  font-size: 12px;
  max-height: 102px;
  overflow: hidden;
  line-height: 17px;
}

.search-hint {
  padding: 8px;
}

.qrockme-action-button .action-button-button {
  border: 1px solid #ff3041;
  border-radius: 20px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  font-size: 12px;
}

.icon-badge {
  background-color: #ff3041;
}

.qrockme-action-button .action-button-image {
  height: 24px;
  margin: 6px 8px 6px 12px;
  flex: 0 0 24px;
}

.qrockme-action-button .action-button-text {
  margin: 6px 8px;
}

.qrockme-action-button .lang-list {
  background-color: rgba(8,8,8,.6);
  border-radius: 8px;
  box-shadow: 0 1px 8px rgb(166 165 165 / 60%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.qrockme-action-button .lang-list .lang-item {
  flex: 0 0;
}

.qrockme-action-button .lang-list .lang-active {
  box-shadow: 0 0 8px rgb(255 255 255 / 80%);
  border: solid 1px #512D6D;
}

.qrockme-action-login .action-login-error {
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  background-color: #ffe1e4;
  font-size: 12px;
  color: #a6a5a5;
}

.qrockme-action-favorites .qrockme-button {
  width: 100%;
  text-transform: none;
  background: white;
  color: #565656;
  font-size: 1rem;
}

.qrockme-action-favorites .qrockme-button-icon {
  color: #c1c1c1;
}

.qrockme-action-favorites .action-favorites-active .qrockme-button-icon {
  color: #ff3041;
}

.qrockme-action-favorites .favorites-list .favorites-list-icon {
  color: #757575;
}

.qrockme-action-favorites .favorites-list .favorites-list-icon.favorites-list-iconremoved {
  color: #c1c1c1;
}

.qrockme-action-favorites .favorites-list-empty {
  margin: 8px 0;
}

.qrockme-action-history .history-list .history-list-iconremoved {
  color: #c1c1c1;
}

/* CHALLENGES */
.action-proposals {
  margin-top: 16px;
}

.action-proposal {
  border-radius: 8px;
  border: 2px solid #C7C7C7;
  margin-bottom: 16px;
  color: #aeaeae;
  min-height: 40px;
  background-color: #FFFFFF;
}

.action-proposal-dnd-wrapper {
  padding-bottom: 10px;
}

.action-proposal-dnd-wrapper .action-proposal  {
  margin: 0;
}

.action-proposal .action-proposal-check {
  font-size: 28px;
}

.action-proposal-checked {
  border-color: #ff3041;
  color: #ff3041;
}

.action-proposal .action-proposal-image {
  width: 96px;
  min-height: 50px;
}

.action-proposal .action-proposal-label {
  color: #565656;
}

.action-proposalbar {
  background-color: #F1F1F1;
}

.action-proposalbar-proposal-over {
  background: #3080FF;
  color: #FFFFFF;
}

.action-proposal-checked .action-proposal-label {
  color: #ff3041;
}

.action-screen-results .action-proposal {
  margin: 8px 0;
  padding: 4px 0;
}

.action-screen-results .action-proposal-checked {
  border-color: #C7C7C7;
  color: #aeaeae;
}

.action-screen-results .action-proposal-checked .action-proposal-label {
  color: #565656;
}

.action-screen-results .action-proposal-correct {
  border: 3px dashed #AFD286;
}

.action-screen-results .action-proposal-checked.action-proposal-correct {
  border: 2px solid #AFD286;
  color: #AFD286;
}

.action-screen-results .action-proposal-checked.action-proposal-correct .action-proposal-label {
  color: #AFD286;
}

.action-screen-results .action-proposal-checked.action-proposal-correct .action-proposal-chip {
  background: #AFD286;
  color: #FFFFFF;
}

.action-screen-results .action-proposal-checked.action-proposal-wrong {
  border-color: #ff3041;
  color: #ff3041;
}

.action-screen-results .action-proposal-checked.action-proposal-wrong .action-proposal-label {
  color: #ff3041;
}

.action-screen-results .action-proposal-checked.action-proposal-wrong .action-proposal-chip {
  background: #ff3041;
  color: #FFFFFF;
}

.action-point {
  color: #FFFFFF;
}

.action-point .action-point-icon {
  background-color: #3080FF;
  padding: 5px;
  border-radius: 50%;
}

.action-point.action-point-hover .action-point-icon {
  background-color: #ff3041;
}

.action-point.action-point-wrong .action-point-icon {
  background-color: #CC3A3A;
  cursor: pointer;
}

.action-point.action-point-correct .action-point-icon {
  background-color: #69A533;
  cursor: pointer;
}

.action-point .action-point-arrow {
  border-top-color: #3080FF;
}

.action-point.action-point-hover .action-point-arrow {
  border-top-color: #ff3041;
}

.action-point.action-point-wrong .action-point-arrow {
  border-top-color: #CC3A3A;
}

.action-point.action-point-correct .action-point-arrow {
  border-top-color: #69A533;
}

.action-point-label {
  color: #FFFFFF;
  background-color: #3080FF;
  font-size: 12px;
  border-radius: 6px;
}

.action-point-label.tiny {
  color: #3080FF;
  font-weight: bold;
  border: 4px solid #3080FF;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 30px;
  min-height: 30px;
}

.action-point.action-point-hover .action-point-label {
  background-color: #ff3041;
}

.action-point.action-point-wrong .action-point-label {
  background-color: #CC3A3A;
}

.action-point.action-point-wrong .action-point-label.active {
  background-color: #FFFFFF;
  color: #CC3A3A;
  border: 2px solid #CC3A3A;
  padding-left: 8px;
  padding-right: 8px;
}

.action-point.action-point-wrong .action-point-label .action-point-solution {
  color: #69A533;
}

.action-point.action-point-correct .action-point-label {
  background-color: #69A533;
}

.action-point.action-point-correct .action-point-label.active {
  background-color: #FFFFFF;
  color: #69A533;
  border: 2px solid #69A533;
  padding-left: 8px;
  padding-right: 8px;
}

.action-spot.action-spot-wrong {
  border-color: #CC3A3A;
}

.action-spot.action-spot-wrong .action-spot-dot {
  background: #CC3A3A;
}

.action-spot.action-spot-correct {
  border-color: #69A533;
}

.action-spot.action-spot-correct .action-spot-dot {
  background: #69A533;
}

.action-zone.action-zone-wrong {
  background: rgba(204,58,58,0.75);
  border: 2px solid #CC3A3A;
}

.action-zone.action-zone-correct {
  background: rgba(105,165,51,0.75);
  border: 2px solid #69A533;
}

.action-label-correct {
  color: #69A533;
}

.action-label-wrong {
  color: #CC3A3A;
}

.action-label-solution {
  color: #69A533;
}

.action-word-correct {
  color: #69A533;
  border-color: #69A533;
}

.action-word-wrong {
  color: #CC3A3A;
  border-color: #CC3A3A;
}

.action-word-solution {
  color: #69A533;
  border-color: #69A533;
}

.action-matching-answer {
  background-color: #f7f7f7;
  border: 2px dashed #C7C7C7;
}

.action-matching-answer.action-matching-answer-filled {
  color: #565656;
  border: 2px solid #C7C7C7;
  background: #E3E3E3;
}

.action-matching-answer.action-matching-answer-correct {
  color: #69A533;
}

.action-matching-answer.action-matching-answer-wrong {
  color: #CC3A3A;
}

.qrockme-action-popup .action-screen-content {
  margin: 10px;
}

.qrockme-action-toggle .action-toggle-label {
  color: #555;
  font-family: Arial;
  font-size: 18px;
  text-align: justify;
  margin-right: 16px;
}

.qrockme-action-rank .action-title {
  margin-bottom: 16px;
}

.qrockme-action-rank .action-rank-label {
  color: #565656;
  font-size: 14px;
}

.qrockme-action-rank .action-rank-icons {
  font-size: 42px;
  color: #BDBDBD;
}

.qrockme-action-rank  .action-rank-icon {
  cursor: pointer;
}

.qrockme-action-rank .action-rank-icon:hover {
  transform: scale(1.2);
}

.qrockme-action-rank .action-container .action-screen.action-screen-results {
  border-width: 0;
}

.qrockme-action-dynlist-__default .action-container .action-screen {
  border: 2px solid #C7C7C7;
}

.qrockme-action-dynlist .qrockme-action-next-card .action-group-label  {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.qrockme-action-dynlist-pleine-page .action-container .action-screen {
  padding: 0;
}

.qrockme-action-dynlist .action-filters-modal {
  background: #FFFFFF;
  border-radius: 16px 16px 0 0;
  padding-top: 8px;
}

.qrockme-action-dynlist .action-filters-title {
  margin-bottom: 0;
}

.qrockme-action-dynlist .action-filters-chip {
  margin: 2px;
  color: #FFFFFF;
  background: #AFABAB;
  max-width: 95%;
}

.qrockme-action-dynlist .action-filters-chip-checked {
  background: #ff3041;
}

.qrockme-action-dynlist .action-filters-chip-empty {
  font-style: italic;
}

.qrockme-action-dynlist .action-filters-chip-checked .action-filters-chip-icon {
  color: #ff3041;
  background: #FFFFFF;
  border-radius: 50%;
}

.qrockme-action-dynlist .action-filters-modal-header {
  text-align: right;
  padding: 0 8px;
  height: 32px;
  margin-top: -32px;
  display: flex;
}

.qrockme-action-dynlist .action-filters-modal-header .action-filters-modal-close-button {
  text-transform: none;
  font-weight: bold;
  color: #d9d7d7;
  padding: 0;

}

.qrockme-action-dynlist .action-filters-modal-header .qrockme-icon-button {
  position: relative;
  top: -16px;
}

.qrockme-action-dynlist .action-filters-modal-content {
  max-height: 70vh;
  overflow-y: auto;
  padding-bottom: 20px;
}

.qrockme-action-dynlist .action-filters-modal-content h2:first-child {
  margin-top: 0;
}

.qrockme-scene-projects .qrockme-card,
.qrockme-start-choice .qrockme-card {
  width: 50%;
  height: 0;
  padding-bottom: 42.5%;
  margin: 0;
  color: #565656;
  cursor: pointer;
}

/* MD */
@media (min-width: 960px) {
  .qrockme-scene-projects .qrockme-card,
  .qrockme-start-choice .qrockme-card {
    width: 33%;
    padding-bottom: 23%;
 }
}

/* LG */
@media (min-width: 1280px) {
  .qrockme-scene-projects .qrockme-card,
  .qrockme-start-choice .qrockme-card {
    width: 30%;
    padding-bottom: 23%;
 }
}

.qrockme-scene-search {
  color: #000;
  padding: 0;
  align-items: center;
}

.qrockme-scene-search .search-field-container {
  padding: 16px 8px;
  background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,153,76,1) 74%, rgba(255,48,65,1) 100%);
}

.qrockme-scene-search .qrockme-input {
  background: white;
}

.qrockme-scene-favorites {
  color: #565656;
  padding: 8px;
}

.qrockme-scene-favorites .favorites-header {
  font-size: 18px;
}

.qrockme-scene-favorites .favorites-list .favorites-list-iconremoved {
  color: #c1c1c1;
}

.qrockme-scene-notifications {
  color: #565656;
}

.qrockme-scene-notifications .notifications-header {
  font-size: 18px;
  padding: 0 8px;
}

.qrockme-scene-notifications .notifications-list-section {
  font-size: 18px;
  margin: 12px 8px 4px 8px;
}

.qrockme-scene-notifications .notifications-list-item {
  padding: 0 8px;
}

.qrockme-scene-notifications .notifications-list-image {
  align-self: flex-start;
  margin-top: 8px;
}

.qrockme-scene-notifications .notifications-list-title {
  font-weight: bold;
}

.qrockme-scene-notifications .notifications-list-desc {
  color: #565656;
}

.qrockme-scene-notifications .notifications-list-add {
  color: #565656;
}

.qrockme-scene-notifications .notifications-list-time {
  color: #888;
}

.qrockme-scene-notifications .notifications-list-item-unread {
  background-color: #E8F4FD;
}

/* LANGUAGE LIST */
.lang-menu {
  background-color: rgba(8,8,8,.6);
  border-radius: 8px;
  box-shadow: 0 1px 8px rgb(166 165 165 / 60%);
}

.lang-menu-arrow::after {
  background-color: rgba(8,8,8,.6);
  box-shadow: 0 1px 8px rgb(166 165 165 / 60%);
}

.lang-menu .lang-active {
  box-shadow: 0 0 8px rgb(255 255 255 / 80%);
  border: solid 1px #512D6D;
}

/* MENUS */

.qrockme-menu-horizontal {
  display: flex;
  flex: 0 0 44px;
  height: 44px;
  background: white;
  color: #a6a5a5;
  box-shadow: 0 0px 4px 0 rgb(203 203 203 / 50%);
}

.qrockme-menu-cardtitle {
  font-size: 22px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin: 8px;
  color: #565656;
  font-weight: bold;
}

.is-pwa.is-ios .qrockme-menu-bottom {
  flex: 0 0 64px;
  height: 64px;
  padding-bottom: 20px;
}

.qrockme-menu-horizontal .qrockme-menu-action {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  position: relative;
}

.qrockme-menu-horizontal .qrockme-menu-action::before {
  display: block;
  background: transparent;
  height: 3px;
  width: 100%;
  content: " ";
  left: 0;
  position: absolute;
}

.qrockme-menu-bottom .qrockme-menu-action::before {
  top: 0;
}

.qrockme-menu-top .qrockme-menu-action::before {
  bottom: 0;
}

.qrockme-menu-horizontal .qrockme-menu-action.menu-active::before {
  background: linear-gradient(to right, #ffb659, #ff2d40 230%);
}

.qrockme-menu-action {
  color: #565656;
}

.menu-button .menu-button-image.menu-button-icon {
  color: #a6a5a5;
}

.menu-active .menu-button .menu-button-image.menu-button-icon {
  color: #ff3041;
}

.qrockme-menu-horizontal .menu-button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
}

.qrockme-menu-horizontal .menu-button .menu-button-image {
  flex-grow: 1;
  margin: 2px;
}

.qrockme-menu-horizontal .menu-button .menu-button-content {
  font-size: 13px;
  line-height: 20px;
  max-height: 100%;
  text-align: center;
}

.qrockme-menu-horizontal .menu-button .menu-button-image.menu-button-icon {
  max-height: 22px;
}

.qrockme-menu-vertical .menu-button .menu-button-image.menu-button-icon {
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(to right, #676b6e, #5f5f5f);
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 2%);
  padding: 8px;
}

.qrockme-menu-horizontal .menu-button .menu-button-arrow {
  display: none;
}

.qrockme-menu-horizontal .menu-button .menu-button-image+.menu-button-content {
  height: 20px;
}

/* MD+ */
@media (min-width: 960px) {
  .qrockme-menu-horizontal .menu-button {
    flex-direction: row;
    align-items: center;
  }

  .qrockme-menu-horizontal .menu-button .menu-button-image+.menu-button-content {
    height: auto;
    text-align: left;
  }

  .qrockme-menu-horizontal .menu-button .menu-button-image {
    flex-grow: 0;
    width: 44px;
    height: 44px;
    margin-right: 6px;
  }
}

.qrockme-menu-vertical {
  width: 83%;
  max-width: 350px;
  text-align: left;
}

.qrockme-menu-static {
  padding-top: 44px;
  box-shadow: 0 0px 4px 0 rgb(203 203 203 / 50%);
}

.qrockme-menu-static.qrockme-menu-left {
  margin-right: 2px;
}

.qrockme-menu-static.qrockme-menu-right {
  margin-left: 2px;
}

.qrockme-action-menudrawer-button {
  width: 44px;
  height: 44px;
}

.menudrawer-placeholder {
  width: 44px;
  height: 44px;
}

.title-logo-right+.menudrawer-placeholder {
  display: none;
}

.qrockme-menu-drawer {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,.8);
}

.qrockme-menu-vertical .qrockme-menu-action {
  margin: 8px 0;
}

.qrockme-menu-vertical .qrockme-menu-action:first-child {
  margin-top: 0;
}

.qrockme-menu-vertical-close {
  text-align: right;
  padding: 8px 8px 0 8px;
  color: rgba(0, 0, 0, 0.54);
}

.qrockme-menu-vertical .menu-button {
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.qrockme-menu-vertical .menu-button .menu-button-image {
  height: 39px;
  margin-right: 8px;
  margin-left: 0px;
  flex: 0 0 39px;
}

.qrockme-menu-vertical .menu-button .menu-button-content {
  flex-grow: 1;
}

.qrockme-menu-vertical .menu-button .menu-button-arrow {
  font-size: 20px;
  opacity: .8;
}

.qrockme-menu-right .menu-button {
  flex-direction: row-reverse;
}

.qrockme-menu-right .menu-button .menu-button-image {
  margin-left: 8px;
  margin-right: 0px;
}

.qrockme-menu-right .menu-button .menu-button-content {
  text-align: right;
}

.qrockme-menu-right .menu-button .menu-button-arrow {
  transform: rotate(180deg);
}

.is-pwa.is-ios .qrockme-bottomnav {
  height: 64px;
}

.qrockme-bottomnav {
  height: 44px;
  background: white;
  color: #a6a5a5;
  box-shadow: 0 0px 4px 0 rgb(203 203 203 / 50%);
}

.qrockme-bottomnav .active .activeMarker {
  background: linear-gradient(to right, #ffb659, #ff2d40 230%);
}

.qrockme-bottomnav .bottomnav-item.active {
  color: #ff3041;
}

.qrockme-bottomnav .bottomnav-item-home {
  background: white;
  margin-top: -20px;
  z-index: 1000;
  width: 52px;
  height: 52px;
  box-shadow: 0 0 6px 0 rgba(134, 134, 134, 0.5);
  border-radius: 100px;
  font-size: 28px;
}

.qrockme-bottomnav .bottomnav-item-home svg {
  fill: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><linearGradient id='grad' x1='88.9273356%' y1='100%' x2='11.0726644%' y2='0%'><stop offset='0%' stop-color='%23FFB659'/><stop offset='100%' stop-color='%23FF2D40'/></linearGradient></svg>#grad")  #ff3041;
}

.qrockme-menu-action.menu-active {
  color: #ff3041;
}

/* MENU ACTIONS */
.qrockme-menu-vertical .menu-button {
  min-height: 39px;
}

.qrockme-menu-multimedia .media-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.qrockme-menu-search .search-input {
  padding: 0 8px;
}

.qrockme-menu-vertical .qrockme-menu-button-type-lang  {
  padding: 0 8px;
}

.qrockme-menu-next .menu-next-desc {
 font-weight: normal;
}

.qrockme-menu-vertical .qrockme-menu-button .lang-list {
  background-color: rgba(8,8,8,.6);
  border-radius: 8px;
  box-shadow: 0 1px 8px rgb(166 165 165 / 60%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.qrockme-menu-vertical .qrockme-menu-button .lang-list .lang-item {
  flex: 0 0;
}

.qrockme-menu-vertical .qrockme-menu-button .lang-list .lang-active {
  box-shadow: 0 0 8px rgb(255 255 255 / 80%);
  border: solid 1px #512D6D;
}

.menu-button.menu-favorites-active .menu-button-image.menu-button-icon {
  color: #ff3041;
}

.action-drawing-canvas {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #ffeff1;
}

.action-drawing-delete-icon {
  top: 0;
  right: 0;
  padding: 5px;
}
`;
