import AbstractAction from "./AbstractAction";

const ActionMenu = () => {
  return null;
};

ActionMenu.classNames = ["qrockme-action-menu"];

Object.assign(ActionMenu, AbstractAction);
export default ActionMenu;
