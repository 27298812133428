import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { CropFree as ScanIcon } from "@material-ui/icons";
import useInView from "react-cool-inview";
import { useDispatch } from "react-redux";
import { wfSagaSetActionState } from "redux/workflow/action";
import MainScreen from "../Common/MainScreen";
import produce from "immer";
import clsx from "clsx";
import CatchQrCode from "scenes/qrCode/CatchQrCode";

const useStyles = makeStyles({
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  square: {
    width: "100%",
    position: "relative",
    backgroundColor: "#D8D8D8",
    "&:after": {
      content: '""',
      display: "block",
      paddingBottom: "100%",
    },
  },
  squarePlaceholder: {
    overflow: "hidden",
    position: "absolute",
    color: "white",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 500ms",
    opacity: 1,
    "&.active": {
      opacity: 0,
    },
  },
  squareStream: {
    overflow: "hidden",
    position: "absolute",
    color: "white",
    opacity: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 500ms",
    "&.active": {
      opacity: 1,
    },
  },
});

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const [scanning, setScanning] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ref, inView } = useInView({
    threshold: 0.75,
  });

  const handleScan = React.useCallback(
    value => {
      const i = value.lastIndexOf("/");
      const guid = i > -1 ? value.substr(i + 1) : "";
      dispatch(
        wfSagaSetActionState(
          action.guid,
          produce(draft => {
            draft.answer = guid;
          })
        )
      );
      onSubmit();
    },
    [dispatch, action.guid, onSubmit]
  );

  React.useEffect(() => {
    if (!inView) {
      const timeout = setTimeout(() => setScanning(false), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inView]);

  const handlePlaying = React.useCallback(() => setScanning(true), []);

  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
    >
      <div ref={ref} className={classes.square}>
        <div
          className={clsx(classes.squarePlaceholder, {
            active: inView && scanning,
          })}
        >
          <ScanIcon
            fontSize="inherit"
            style={{ width: "90%", height: "90%" }}
          />
        </div>
        <div
          className={clsx(classes.squareStream, {
            active: inView && scanning,
          })}
        >
          {(inView || scanning) && (
            <CatchQrCode onDecode={handleScan} onPlaying={handlePlaying} />
          )}
        </div>
      </div>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenMain;
