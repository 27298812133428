import React from "react";
import T from "prop-types";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { withIntl } from "hooks/useFormatMessage";
import { Form } from "react-final-form";
import CommonIntl from "common/CommonTrad.i";
import { Container, FormContainer, BottomSpacer } from "./Actions.s";
import ActionsLayoutRenderer from "./ActionsLayoutRenderer";
import { someAction, isNext, isForm } from "./Common/utils";
import clsx from "clsx";

const useStyles = makeStyles({
  submitButton: {
    cursor: "pointer",
  },
});

const Actions = ({ actions, layout, onSubmit }) => {
  const classes = useStyles();

  const handleSubmit = values => {
    const { clickedCategory, replace, ...actionValues } = values;
    const fields = actions.reduce((prev, cur) => {
      prev.push(cur.key);
      if (cur.children && typeof cur.children === "object") {
        Object.values(cur.children)
          .flat()
          .forEach(child => {
            prev.push(child.key.split("|")[0]);
            if (Array.isArray(child.actions)) {
              child.actions.forEach(a => prev.push(a.key.split("|")[0]));
            }
          });
      }
      if (Array.isArray(cur.actions)) {
        cur.actions.forEach(a => prev.push(a.key));
      }
      return prev;
    }, []);
    const filteredValues = fields.reduce((prev, cur) => {
      if (actionValues.hasOwnProperty(cur)) {
        prev[cur] = actionValues[cur];
      }
      return prev;
    }, {});
    onSubmit(filteredValues, clickedCategory, replace);
  };

  const hasNextActions = someAction(actions, isNext);
  const hasFormActions = someAction(actions, isForm);

  return (
    <Container
      className="qrockme-actions"
      style={actions.length === 0 ? { display: "none" } : { display: "flex" }}
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, invalid, form }) => (
          <React.Fragment>
            <FormContainer id="actionsForm" onSubmit={handleSubmit}>
              <ActionsLayoutRenderer actions={actions} layout={layout} />
              <input style={{ visibility: "hidden", position: "absolute" }} />
              {!hasNextActions && hasFormActions && (
                <div
                  className={clsx(
                    {
                      "qrockme-nolayout": !layout?.enable,
                    },
                    "qrockme-section",
                    "qrockme-default-submit-section"
                  )}
                >
                  <div className="qrockme-column qrockme-column-__default">
                    <div className="action-container">
                      <div className="qrockme-action-next qrockme-action-next-button qrockme-action-next-__default">
                        <button
                          className={clsx(
                            classes.submitButton,
                            "qrockme-button"
                          )}
                          disabled={invalid}
                          type="button"
                          onClick={() => form.submit()}
                        >
                          <div className="qrockme-button-text">
                            <div className="qrockme-button-title">
                              <FormattedMessage {...CommonIntl.Validate} />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <BottomSpacer />
            </FormContainer>
          </React.Fragment>
        )}
      />
    </Container>
  );
  // }
};

Actions.propTypes = {
  actions: T.array.isRequired,
  onSubmit: T.func.isRequired,
};

export default withIntl(Actions);
