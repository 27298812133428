import { defineMessages } from "react-intl";

export default defineMessages({
  TextareaPlaceholder: {
    id: "Action.TextareaPlaceholder",
    defaultMessage: "Écrire un texte",
  },
  PhonePlaceholder: {
    id: "Action.PhonePlaceholder",
    defaultMessage: "Saisir un numéro de téléphone",
  },
  EmailPlaceholder: {
    id: "Action.EmailPlaceholder",
    defaultMessage: "Saisir un email",
  },
  NumberPlaceholder: {
    id: "Action.NumberPlaceholder",
    defaultMessage: "Saisir un nombre",
  },
  DatePlaceholder: {
    id: "Action.DatePlaceholder",
    defaultMessage: "Saisir une date",
  },
  AnswerPlaceholder: {
    id: "Action.AnswerPlaceholder",
    defaultMessage: "Entrez votre réponse ici...",
  },
  FirstnamePlaceholder: {
    id: "Action.FirstnamePlaceholder",
    defaultMessage: "Prénom",
  },
  LastnamePlaceholder: {
    id: "Action.LastnamePlaceholder",
    defaultMessage: "Nom",
  },
  RegisterEmailPlaceholder: {
    id: "Action.EmailPlaceholder",
    defaultMessage: "Email",
  },
  RegisterDontHaveEmail: {
    id: "Action.RegisterDontHaveEmail",
    defaultMessage: "Je n'ai pas d'email",
  },
  RegisterHaveEmail: {
    id: "Action.RegisterHaveEmail",
    defaultMessage: "J'ai un email",
  },
  ScanError: {
    id: "Action.ScanError",
    defaultMessage: "Le QR Code scanné n'est pas celui attendu",
  },
  FilterEmptyElement: {
    id: "Action.FilterEmptyElement",
    defaultMessage: "Éléments vides",
  },
  FilterTrueElement: {
    id: "Action.FilterTrueElement",
    defaultMessage: "Vrai",
  },
  FilterFalseElement: {
    id: "Action.FilterFalseElement",
    defaultMessage: "Faux",
  },
  FilterNullElement: {
    id: "Action.FilterNullElement",
    defaultMessage: "Indéterminé",
  },
  Day: {
    id: "Action.Day",
    defaultMessage: "Jour",
  },
  Days: {
    id: "Action.Days",
    defaultMessage: "Jours",
  },
  Hour: {
    id: "Action.Hour",
    defaultMessage: "Heure",
  },
  Hours: {
    id: "Action.Hours",
    defaultMessage: "Heures",
  },
  Minute: {
    id: "Action.Minute",
    defaultMessage: "Minute",
  },
  Minutes: {
    id: "Action.Minutes",
    defaultMessage: "Minutes",
  },
  Second: {
    id: "Action.Second",
    defaultMessage: "Seconde",
  },
  Seconds: {
    id: "Action.Seconds",
    defaultMessage: "Secondes",
  },
});
