import { useField } from "react-final-form";
import AbstractAction from "./AbstractAction";

const ActionCheckPoint = ({ action }) => {
  useField(`action-${action.id}`, { initialValue: "-" });
  return null;
};

ActionCheckPoint.classNames = ["qrockme-action-checkpoint"];

Object.assign(ActionCheckPoint, AbstractAction);
export default ActionCheckPoint;
