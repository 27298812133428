import React from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";

function is_touch_device1() {
  return "ontouchstart" in window;
}

function oncontextmenu(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}

const Dnd = ({ children }) => {
  const options =
    window && is_touch_device1()
      ? { delayTouchStart: 200 }
      : { enableTouchEvents: false, enableMouseEvents: true };

  React.useEffect(() => {
    if (window && is_touch_device1()) {
      window.addEventListener("contextmenu", oncontextmenu);
      return () => {
        window.removeEventListener("contextmenu", oncontextmenu);
      };
    }
  }, []);

  return (
    <DndProvider
      options={{
        backends: [
          {
            backend: TouchBackend,
            preview: true,
            options: options,
          },
        ],
      }}
    >
      {children}
    </DndProvider>
  );
};

export default Dnd;
