import extractWords from "./extractWords";

export default function (config, state) {
  const words = extractWords(config.html).reduce(
    (prev, cur) => ({ ...prev, [cur.guid]: cur.label }),
    {}
  );

  const answers = Object.entries(state.answers).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: { ...v, correct: words[k] === v.label },
    }),
    {}
  );

  const correctCount = Object.entries(answers).reduce(
    (prev, [, v]) => (v.correct ? prev + 1 : prev),
    0
  );
  const correct = correctCount === Object.keys(state.answers).length;
  return { correct, answers, correctCount };
}
