const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export default (REQUEST, SUCCESS, FAILURE, RESET) => {
  if (RESET) {
    return (state, action) => {
      if (!state) {
        state = initialState;
      }
      switch (action.type) {
        case REQUEST:
          return { ...state, isLoading: true, error: null };
        case SUCCESS: {
          return {
            ...state,
            isLoading: false,
            data: action.payload,
            error: null,
          };
        }
        case FAILURE:
          return { ...state, isLoading: false, error: action.payload };
        case RESET:
          return initialState;
        default:
          return state;
      }
    };
  }
  return (state, action) => {
    if (!state) {
      state = initialState;
    }
    switch (action.type) {
      case REQUEST:
        return { ...state, isLoading: true, error: null };
      case SUCCESS: {
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          error: null,
        };
      }
      case FAILURE:
        return { ...state, isLoading: false, error: action.payload };
      default:
        return state;
    }
  };
};
