import React from "react";
import AppContext from "AppContext";
import { makeStyles } from "@material-ui/core";
import Sticky from "common/components/Sticky";
import BarProposals from "../Common/BarProposals";

const useStyles = makeStyles({
  root: {
    minHeight: 47,
    userSelect: "none",
    "& *": {
      userSelect: "none",
    },
  },
});

const ProposalsBar = props => {
  const classes = useStyles();
  const { containerRef } = React.useContext(AppContext);
  if (!containerRef.current) return null;

  return (
    <Sticky
      scrollTarget={containerRef.current}
      className={classes.root}
      sides={{ bottom: props.sticky ? 0 : undefined }}
    >
      <BarProposals {...props} />
    </Sticky>
  );
};

export default ProposalsBar;
