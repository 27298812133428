import initialState from "./initialState";
import { shuffle } from "lodash";
import produce from "immer";
import { prepareInteractiveActionChildren } from "scenes/actions/Common/utils";
import checkResults from "./checkResults";

export default function ({ config, children, data, ...rest }, state) {
  if (!state) {
    state = state || { ...initialState };
    if (data?.result_obj?.screen) {
      const answers = data.result_obj.answers || [];
      state = {
        ...state,
        answers,
        trial: 0,
        ...checkResults(
          config.proposals,
          { answers, trial: 0 },
          config.attempts,
          config.multiple
        ),
      };
    }
  }
  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...initialState,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  if (!state.proposals) {
    state = produce(state, draft => {
      if (config.random) {
        draft.proposals = shuffle(config.proposals);
      } else {
        draft.proposals = config.proposals;
      }
    });
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
