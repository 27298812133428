import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  responsive: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "100%",
  },
  fit: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
});

interface Props {
  src?: string;
  alt?: string;
  className?: string;
  responsive?: boolean;
  fit?: boolean;
}

const Image: React.FC<Props> = ({
  src,
  alt,
  className,
  responsive,
  fit,
  ...props
}) => {
  const classes = useStyles();

  const url = React.useMemo(() => {
    if (!src) return;
    return process.env.REACT_APP_STATIC_SERVER
      ? src.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : src;
  }, [src]);

  if (!url) return null;

  return (
    <>
      <img
        alt={alt}
        src={url}
        className={clsx(className, {
          [classes.responsive]: responsive,
          [classes.fit]: fit,
        })}
        {...props}
      />
    </>
  );
};

export default Image;
