/**
 * rewrite https://github.com/danalloway/react-country-flag convert SVG to PNG
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};

var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var CountryFlag = function (_Component) {
  inherits(CountryFlag, _Component);

  function CountryFlag() {
    classCallCheck(this, CountryFlag);
    return possibleConstructorReturn(this, (CountryFlag.__proto__ || Object.getPrototypeOf(CountryFlag)).apply(this, arguments));
  }

  createClass(CountryFlag, [{
    key: "render",
    value: function render() {
      var _props = this.props,
        cdnUrl = _props.cdnUrl,
        className = _props.className,
        code = _props.code,
        styleProps = _props.styleProps,
        png = _props.png,
        title = _props.title;


      if (!code) {
        return null;
      }

      var flagUrl = "" + cdnUrl + code.toLowerCase() + ".png";
      var emoji = code.toUpperCase().replace(/./g, function (char) {
        return String.fromCodePoint(char.charCodeAt(0) + 127397);
      });
      return png ? React.createElement("span", {
        "aria-label": code,
        className: className,
        role: "img",
        style: _extends({
          position: "relative",
          display: "block",
          width: "1.3333333333em",
          height: "1em",
          backgroundImage: "url(" + flagUrl + ")",
          backgroundPosition: "50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          fontSize: "1em",
          lineHeight: "1em",
          verticalAlign: "middle",
        }, styleProps),
        title: title || code
      }) : React.createElement(
        "span",
        {
          "aria-label": code,
          className: className,
          role: "img",
          style: _extends({
            verticalAlign: "middle"
          }, styleProps),
        },
        emoji
      );
    }
  }]);
  return CountryFlag;
}(Component);

CountryFlag.propTypes = {
  cdnUrl: PropTypes.string,
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  styleProps: PropTypes.object,
  png: PropTypes.bool,
  title: PropTypes.string
};
CountryFlag.defaultProps = {
  cdnUrl: "/assets/images/flags/4x3/",
  png: false
};

export default CountryFlag;
