import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => {
  return (
    <SvgIcon {...props}>
      <defs>
        <linearGradient x1='0%' y1='0%' x2='0%' y2='100%' id='grad1'>
          <stop offset='0%' stopColor='#ff2d40' />
          <stop offset='90%' stopColor='#ffb659' />
        </linearGradient>
      </defs>
      <path fill='url(#grad1)' d='M7 2v11h3v9l7-12h-4l4-8z' />
    </SvgIcon>
  );
};
