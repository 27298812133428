import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-react-router";
import history from "common/history";
import { Auth } from "./auth";
import styledTheme from "./themes/styledTheme";
import App from "./App";
import {
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from "@material-ui/styles";
import { create } from "jss";
import { SearchContextProvider } from "common/components/Search";

const generateClassName = createGenerateClassName();

library.add(faBars);

export default () => {
  const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins],
    insertionPoint: document.getElementById("jss-insertion-point"),
  });

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <SearchContextProvider>
        <Auth>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={styledTheme}>
              <App />
            </ThemeProvider>
          </ConnectedRouter>
        </Auth>
      </SearchContextProvider>
    </StylesProvider>
  );
};
