import ActionMatching from "../ActionMatching";
import checkResults from "./checkResults";

export default function (action, state) {
  if (state.screen === "retry") {
    // Submit on retry screen do a restart
    return {
      state: {
        ...ActionMatching.initialState,
        trial: state.trial,
        proposals: state.proposals.map(function (p) {
          const { correct, ...rest } = p;
          return rest;
        }),
        values: state.values,
      },
    };
  } else if (state.screen === "info") {
    // Submit on info screen, we need to check answers
    const { attempts } = action.config;
    const { trial } = state;

    const { correct, proposals, correctCount } = checkResults(
      action.config,
      state
    );
    let newstate = { ...state, trial: trial + 1 };
    if (correct) {
      newstate = {
        ...newstate,
        screen: "success",
        correct,
        proposals,
        correctCount,
      };
    } else if (attempts === 0 || trial + 1 < attempts) {
      // Unlimited attempts or trial left
      newstate = { ...newstate, screen: "retry", proposals, correctCount };
    } else {
      newstate = {
        ...newstate,
        screen: "fail",
        correct,
        proposals,
        correctCount,
      };
    }

    let value;
    if (newstate.screen === "success" || newstate.screen === "fail") {
      value = {
        screen: newstate.screen,
        trial: newstate.trial,
        proposals: proposals.map(p => ({
          correct: p.correct,
          answer: p.answer,
          guid: p.guid,
        })),
      };
    }
    return { state: newstate, value };
  }
}
