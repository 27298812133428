import React from "react";
import { LangList, useActionButton, useActionImage } from "common/actions";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";
import LangMenu from "./ActionButton/LangMenu";

const ActionButton = ({ config, data, menuPosition }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const image = useActionImage(config, data, "titleImage");
  const showTitle = config.showTitle === undefined ? true : config.showTitle; // On old or never edited LINK showTitle is undefined
  const title = showTitle ? config.title : undefined;
  const { type } = config;

  const { icon, handler } = useActionButton(type);

  const handleClick = React.useCallback(
    event => {
      if (type === "lang") {
        setAnchorEl(event.currentTarget);
      } else {
        handler();
      }
    },
    [handler, type]
  );

  if (
    type === "lang" &&
    (menuPosition === "left" || menuPosition === "right")
  ) {
    return <LangList onSelect={handler} />;
  }

  return (
    <>
      <MenuButton
        title={title}
        image={image}
        icon={icon}
        onClick={handleClick}
      />
      {type === "lang" && (
        <LangMenu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl()}
          direction={menuPosition === "top" ? "down" : "up"}
        />
      )}
    </>
  );
};

ActionButton.classNames = ["qrockme-menu-button"];

Object.assign(ActionButton, AbstractAction);

ActionButton.getClasses = function (action) {
  const classes = AbstractAction.getClasses.bind(this)(action);
  classes.push("qrockme-menu-button-type-" + action.config.type);
  return classes;
};

ActionButton.isActive = function (action, { location }) {
  return action.config.type === "scan" && location.pathname === "/scan";
};

export default ActionButton;
