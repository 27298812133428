import {
  PREFS_ADD,
  PREFS_REMOVE,
  PREFS_NOTIFICATIONS,
  PREFS_NOTIFICATIONS_VIEW,
  PREFS_NOTIFICATIONS_READ,
} from "./action";

import produce from "immer";
import { difference } from "lodash";

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case PREFS_ADD: {
      const { nsKey, listKey, itemId } = payload;
      return produce(state, draft => {
        if (!draft[nsKey]) {
          draft[nsKey] = {};
        }
        const store = draft[nsKey];
        const map = store[listKey] || {};

        delete map[itemId];
        store[listKey] = { [itemId]: new Date().toISOString(), ...map };
      });
    }
    case PREFS_REMOVE: {
      const { nsKey, listKey, itemId } = payload;
      if (!state[nsKey]) {
        return state;
      }
      if (!state[nsKey][listKey]) {
        return state;
      }

      return produce(state, draft => {
        delete draft[nsKey][listKey][itemId];
      });
    }
    case PREFS_NOTIFICATIONS: {
      const { nsKey, notifications } = payload;
      const currentKeys = Object.keys(state[nsKey]?.["notifications"] || {});
      const keys = Object.keys(notifications);
      const removeKeys = difference(currentKeys, keys);

      return produce(state, draft => {
        if (!draft[nsKey]) {
          draft[nsKey] = {};
        }
        const store = draft[nsKey];
        if (!store["notifications"]) {
          store["notifications"] = {};
        }
        const map = store["notifications"];

        // Remove deleted actions
        removeKeys.forEach(k => delete map[k]);

        Object.entries(notifications).forEach(([k, v]) => {
          const type = v.type;
          if (type) {
            // Update notification if api send data
            delete v.type;
            delete v.conds;
            if (!map[k] || type === "card") {
              map[k] = v;
            } else {
              map[k] = { ...v, view: map[k].view, read: map[k].read };
            }
          }
        });
      });
    }
    case PREFS_NOTIFICATIONS_VIEW: {
      const { nsKey } = payload;
      if (!state[nsKey]?.["notifications"]) {
        return state;
      }
      return produce(state, draft => {
        const map = draft[nsKey]["notifications"];

        for (const guid in map) {
          map[guid].view = true;
        }
      });
    }
    case PREFS_NOTIFICATIONS_READ: {
      const { nsKey, guid } = payload;
      if (!state[nsKey]?.["notifications"]) {
        return state;
      }
      return produce(state, draft => {
        draft[nsKey]["notifications"][guid].read = true;
      });
    }
    default:
      return state;
  }
};

export default reducer;
