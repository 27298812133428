import React from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

async function fetchUrl(url, signal, onProgress) {
  const response = await fetch(url, { method: "get", signal: signal });
  if (!response.ok) {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
  const reader = response.body.getReader();
  const total = +response.headers.get("Content-Length");
  let loaded = 0;
  let chunks = [];
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value);
    loaded += value.length;
    if (onProgress) {
      onProgress({ total, loaded });
    }
  }

  return new Blob(chunks, {
    type: response.headers.get("Content-Type"),
  });
}

const useCancelableRequest = url => {
  url = typeof url === "object" && url?.url ? url.url : url;
  const isMounted = React.useRef(true);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState();
  const [result, setResult] = React.useState("");

  const handleProgress = React.useCallback(e => {
    setProgress(Math.round((e.loaded / e.total) * 100));
  }, []);

  useDeepCompareEffect(() => {
    if (!url) return;
    const proxyUrl = process.env.REACT_APP_STATIC_SERVER
      ? url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : url;
    setError();
    setResult("");
    setProgress(0);
    const cancel = new AbortController();
    fetchUrl(proxyUrl, cancel.signal, handleProgress)
      .then(r => {
        if (!isMounted.current) return;
        setProgress(100);
        setResult(URL.createObjectURL(r));
      })
      .catch(err => {
        console.error("Request failure", err);
        if (!isMounted.current) return;
        setProgress(100);
        setError("Erreur de chargement : " + err.message);
      });
    return () => {
      isMounted.current = false;
      cancel.abort();
    };
  }, [url, handleProgress]);

  return [result, progress, error];
};

export default useCancelableRequest;
