import { shuffle } from "lodash";
import ActionMatching from "../ActionMatching";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

function prepareProposals(config) {
  let proposals = Object.values(config.proposals).map(({ guid }) => ({ guid }));
  if (config.random) {
    proposals = shuffle(proposals);
  }
  const values = shuffle(Object.values(config.proposals).map(p => p.guid));
  return { proposals, values };
}

export default function ({ config, children, data, ...rest }, state) {
  if (!state) {
    const { proposals, values } = prepareProposals(config);
    state = { ...ActionMatching.initialState, proposals, values };
    if (data?.result_obj?.screen) {
      state = {
        ...state,
        ...data.result_obj,
        correctCount: (data?.result_obj?.proposals || []).reduce(
          (prev, prop) => {
            return prev + (prop.correct ? 1 : 0);
          },
          0
        ),
      };
    }
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      const { proposals, values } = prepareProposals(config);
      state = {
        ...ActionMatching.initialState,
        proposals,
        values,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
