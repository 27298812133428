import React from "react";
import { useActionNext } from "common/actions";
import NextButton from "common/components/NextButton";

const Default = ({ config, data, disabled, onClick }) => {
  const { title, image, desc, additionals } = useActionNext(config, data);
  const invert = data?.modelNav === "next";
  const props = {
    title,
    image,
    desc,
    additionals,
    invert,
    disabled,
    onClick,
  };
  return <NextButton {...props} />;
};

export default Default;
