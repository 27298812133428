import styled from 'styled-components/macro';
import T from 'prop-types';

export const Label = styled.div`
  margin-bottom: 8px;
`;

export const CharactersMax = styled.span`
  position: absolute;
  top: 0;
  right: 0;
`;

const Input = styled.input`
  box-sizing: border-box;
  transition: border-color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 1;
  }
  ::-moz-placeholder {
    opacity: 1;
  }
  :-ms-input-placeholder {
    opacity: 1;
  }
`;
Input.propTypes = {
  error: T.bool,
  readOnly: T.bool,
};

export { Input };

export const CharactersMaxContainer = styled.input`
  width: 100%;
  position: relative;
`;
