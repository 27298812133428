import styled from "styled-components";

export const DrawingInputContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const DrawingClearContainer = styled.div`
  position: absolute;
`;
