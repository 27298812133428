import produce from "immer";
function intersect(spot, zone) {
  return (
    spot.x > zone.x &&
    spot.x < zone.x + zone.width &&
    spot.y > zone.y &&
    spot.y < zone.y + zone.height
  );
}

export default function (config, state) {
  const { spots, zones } = state.spots.reduce(
    (prev, cur) => {
      const index = prev.zones.findIndex(z => intersect(cur, z) && !z.correct);
      if (index > -1) {
        return produce(prev, draft => {
          draft.spots.push({ ...cur, proposal: prev.zones[index].proposal });
          draft.zones[index].correct = true;
        });
      } else {
        return produce(prev, draft => {
          draft.spots.push(cur);
        });
      }
    },
    {
      spots: [],
      zones: [...config.zones],
    }
  );
  const correctCount = spots.reduce(
    (prev, cur) => (cur.proposal ? prev + 1 : prev),
    0
  );
  const correct = correctCount === config.zones.length;
  return { correct, spots, zones, correctCount };
}
