import React from "react";

interface AdditionalElement {
  content: string;
  guid: string;
}

interface MediaElement {
  url: string;
}

interface NextActionConfig {
  title: string;
  showTitle?: boolean;
  desc: string;
  image?: string;
  nextCardName: string;
  nextCardImage?: string;
  nextCardDesc: string;
  additionals: AdditionalElement[];
}

interface NextActionData {
  image?: MediaElement[];
  modelNav: "next" | "prev";
}

interface Return {
  title: string;
  showTitle: boolean;
  desc: string;
  image?: string;
  additionals: AdditionalElement[];
}

export function useActionNext(
  config: NextActionConfig,
  data: NextActionData
): Return {
  return React.useMemo<Return>(() => {
    const showTitle = config.showTitle === undefined ? true : config.showTitle; // On old or never edited NEXT showTitle is undefined
    const values =
      config.image || (data.image && data.image[0]) || config.title
        ? {
            title: config.title || config.nextCardName,
            showTitle,
            image:
              data.image && data.image[0] ? data.image[0].url : config.image,
            desc: config.desc,
            additionals: config.additionals || [],
          }
        : {
            title: config.nextCardName,
            showTitle,
            image: config.nextCardImage,
            desc: config.nextCardDesc,
            additionals: config.additionals || [],
          };
    if (data.modelNav && !config.title) {
      values.title = "";
    }
    return values;
  }, [config, data]);
}
