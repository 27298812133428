import React from "react";
import PropTypes from "prop-types";
import { StyledButton, ButtonContainer } from "../Actions.s";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import CommonIntl from "common/CommonTrad.i";
import clsx from "clsx";
import SubScreens from "./SubScreens";
import Title from "./Title";

const useStyles = makeStyles({
  dragging: { cursor: "grabbing", "& *": { cursor: "grabbing" } },
});

const MainScreen = ({
  actions,
  screen,
  config,
  data,
  onSubmit,
  children,
  className,
  dragging,
}) => {
  const classes = useStyles();

  return (
    <>
      <Title config={config} data={data} />
      <div
        className={clsx(
          "action-content",
          { [classes.dragging]: dragging },
          className
        )}
      >
        <SubScreens actions={actions} screens={screen} />
        <div className="action-proposals">{children}</div>
        {onSubmit && (
          <ButtonContainer>
            <StyledButton
              type="button"
              className="qrockme-button"
              onClick={onSubmit}
            >
              <FormattedMessage {...CommonIntl.Validate} />
            </StyledButton>
          </ButtonContainer>
        )}
      </div>
    </>
  );
};

MainScreen.propTypes = {
  actions: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default MainScreen;
