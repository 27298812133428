import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { wfSagaSetActionState } from "redux/workflow/action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MainScreen from "../Common/MainScreen";
import Proposal from "./Proposal";
import produce from "immer";
import useByGuid from "../Common/useByGuid";

const useStyles = makeStyles({
  draggable: { "&:focus": { outline: "none" } },
});

const ScreenMain = ({ action, children, config, data, state, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDragEnd = React.useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      dispatch(
        wfSagaSetActionState(
          action.guid,
          produce(draft => {
            const proposal = draft.proposals.splice(result.source.index, 1);
            draft.proposals.splice(result.destination.index, 0, proposal[0]);
          })
        )
      );
    },
    [dispatch, action.guid]
  );

  const proposalsByGuid = useByGuid(config.proposals);
  return (
    <MainScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      onSubmit={onSubmit}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`action-${action.id}`}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {state.proposals.map((p, i) => (
                <Draggable key={p.guid} draggableId={p.guid} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                      className={classes.draggable}
                    >
                      <div className="action-proposal-dnd-wrapper">
                        <Proposal
                          key={p.guid}
                          highlighted={snapshot.isDragging}
                          image={proposalsByGuid[p.guid].image}
                          label={proposalsByGuid[p.guid].label}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </MainScreen>
  );
};

ScreenMain.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default ScreenMain;
