import { createSelector } from "reselect";

export const selectEntity = createSelector(
  state => state.workflow.entity,
  entity => entity
);

export const selectWorkflow = createSelector(
  state => state.workflow.workflow,
  workflow => workflow
);

export const selectCurrent = createSelector(
  state => state.workflow.current,
  current => current
);

export const selectProjectCards = createSelector(
  state => state.workflow.current.projectCards,
  projectCards => projectCards
);

export const selectQuery = createSelector(
  state => state.workflow.query,
  query => query || {}
);

export const selectHistory = createSelector(
  state => state.workflow.history,
  history => history
);

export const selectResuming = createSelector(
  state => state.workflow.resuming,
  resuming => resuming
);

export const selectPwaDeclined = createSelector(
  state => state.workflow.pwaDeclined,
  pwaDeclined => pwaDeclined
);

export const selectMenusState = createSelector(
  state => state.workflow.menusState,
  menusState => menusState || {}
);

export const selectAction = actionGuid =>
  createSelector(
    [state => state.workflow.workflow.actions, () => actionGuid],
    (actions, guid) => actions.find(action => action.guid === guid)
  );

export const selectActionState = (projectGuid, cardGuid, actionId) =>
  createSelector(
    state => {
      if (state.workflow.states) {
        if (state.workflow.states[projectGuid]) {
          if (state.workflow.states[projectGuid][cardGuid]) {
            if (state.workflow.states[projectGuid][cardGuid][actionId])
              return state.workflow.states[projectGuid][cardGuid][actionId];
          }
        }
      }
    },
    actionState => actionState
  );

export const selectSearchHistory = projectGuid =>
  createSelector(
    state => state.workflow.searchHistory,
    searchHistory => (searchHistory ? searchHistory[projectGuid] || [] : [])
  );
