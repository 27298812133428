const rules = {
  eq: {
    validate: (answer, { value }) => answer === value,
    toString: ({ value }) => `= ${value}`,
  },
  neq: {
    validate: (answer, { value }) => answer !== value,
    toString: ({ value }) => `<> ${value}`,
  },
  lte: {
    validate: (answer, { value }) => answer <= value,
    toString: ({ value }) => `<= ${value}`,
  },
  gte: {
    validate: (answer, { value }) => answer >= value,
    toString: ({ value }) => `>= ${value}`,
  },
  lt: {
    validate: (answer, { value }) => answer < value,
    toString: ({ value }) => `< ${value}`,
  },
  gt: {
    validate: (answer, { value }) => answer > value,
    toString: ({ value }) => `> ${value}`,
  },
  bw: {
    validate: (answer, { min, max }) => answer >= min && answer <= max,
    toString: ({ min, max }) => `[${min}-${max}]`,
  },
  bwex: {
    validate: (answer, { min, max }) => answer < min || answer > max,
    toString: ({ min, max }) => `]${min}-${max}[`,
  },
};

export default rules;
