import React from "react";
import SignatureCanvas from "react-signature-canvas";

const Drawing = React.forwardRef(({ onDrawingEnd, onReadyToLoadData }, ref) => {
  // react-signature-canvas does not support responsive canvas
  // so we need to set the canvas width manually
  const drawingContainerElement = document.getElementById(
    "action-drawing-canvas"
  );
  const containerWidth = drawingContainerElement?.offsetWidth;

  const handleOnEnd = () => {
    onDrawingEnd();
  };

  React.useEffect(() => {
    if (ref?.current) {
      // we pass to the parent component the canvas width
      // so it can draw the image with the correct size
      onReadyToLoadData(containerWidth);
    }
  }, [containerWidth, onReadyToLoadData, ref]);

  if (!containerWidth) return null;

  return (
    <SignatureCanvas
      ref={ref}
      onEnd={handleOnEnd}
      style={{ width: "100%", height: "auto" }}
      canvasProps={{
        height: 150,
        width: containerWidth,
      }}
    />
  );
});

export default Drawing;
