import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffeff1;
  flex-wrap: wrap;
`;

export const CardWrapper = styled.div`
  width: 31.3%;
  height: 90px;
  display: flex;
  padding: 1%;
`;

export const CardsContainer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const CardContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const PdfFileName = styled.div`
  height: 30px;
  overflow: hidden;
`;

export const DeleteWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
