import React from "react";
import { Favorite as FavoriteIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";
import { useActionImage } from "common/actions";
import { useFavorites } from "common/components/Favorites";
import { useSelector } from "react-redux";
import { selectCurrent } from "redux/workflow/selector";
import clsx from "clsx";

const ActionFavorites = ({ config, data }) => {
  const { card } = useSelector(selectCurrent);
  const nav = useHistory();
  const { isCurrentCardFavorite, add, remove } = useFavorites(
    config.type === "list" ? config.showCategory : config.addCategory
  );
  const title = config.showTitle ? config.title : undefined;
  const image = useActionImage(config, data, "titleImage");

  const handleClick = React.useCallback(() => {
    if (config.type === "add") {
      if (isCurrentCardFavorite) {
        remove(card);
      } else {
        add(card);
      }
    } else {
      nav.push("/favorites", { ...config });
    }
  }, [nav, isCurrentCardFavorite, add, remove, card, config]);

  return (
    <>
      {config.type === "add" && (
        <MenuButton
          title={title}
          image={image}
          icon={!image ? FavoriteIcon : undefined}
          className={clsx({ "menu-favorites-active": isCurrentCardFavorite })}
          onClick={handleClick}
        />
      )}
      {config.type === "list" && (
        <MenuButton
          title={title}
          image={image}
          icon={!image ? FavoriteIcon : undefined}
          onClick={handleClick}
        />
      )}
    </>
  );
};

ActionFavorites.classNames = ["qrockme-menu-favorites"];

Object.assign(ActionFavorites, AbstractAction);

export default ActionFavorites;
