import initialState from "./initialState";
import checkResults from "./checkResults";

export default function (action, state) {
  if (state.screen === "retry") {
    // Submit on retry screen do a restart
    return {
      state: {
        ...initialState,
        trial: state.trial,
      },
    };
  } else if (state.screen === "info") {
    // Submit on info screen, we need to check answers
    let newstate = checkResults(
      action.config.proposals,
      state,
      action.config.attempts,
      action.config.multiple
    );
    newstate = { ...state, ...newstate, trial: state.trial + 1 };

    const { screen, trial, answers } = newstate;
    let value;
    if (screen === "neutral" || screen === "success" || screen === "fail") {
      value = { screen, trial, answers };
    }
    return { state: newstate, value };
  }
}
