import { WF_SAGA_SET_ENTITY_GUID_DONE } from "redux/workflow/action";

const initialState = {
  answers: [],
  trial: 0,
  screen: "info",
  clearOn: WF_SAGA_SET_ENTITY_GUID_DONE,
  actionType: "QCMQCU",
};

export default initialState;
