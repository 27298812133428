import React from "react";
import { Star as ChoiceIcon } from "@material-ui/icons";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import nl2br from "react-nl2br";

const useStyles = makeStyles({
  icons: {
    display: "inline-flex",
    flexFlow: "row wrap",
    justifyContent: "center",
  },
  break: {
    flexBasis: "100%",
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  icon: {
    display: "flex",
  },
});

const choiceBreak = 5;

const RankAdapter = ({
  input: { onChange, value },
  className,
  options,
  showLabel,
}) => {
  const classes = useStyles();
  const valueIndex = options.findIndex(v => v.guid === value);
  const color = valueIndex > -1 ? options[valueIndex].color : undefined;
  const label = valueIndex > -1 ? options[valueIndex].label : undefined;

  const handleClick = guid => () => {
    if (guid !== value) {
      onChange(guid);
    } else {
      onChange("");
    }
  };

  return (
    <div className={clsx(className)}>
      <div className={clsx(classes.icons, "action-rank-icons")}>
        {options.map((option, index) => {
          const empty = valueIndex < index;
          const filled = valueIndex >= index;
          const pressed = valueIndex === index;
          return (
            <React.Fragment key={option.guid}>
              <span
                className={clsx({
                  [classes.icon]: true,
                  "action-rank-icon": true,
                  "action-rank-empty": empty,
                  "action-rank-filled": filled,
                })}
                aria-label={option.label}
                role="button"
                aria-pressed={pressed}
                onClick={handleClick(option.guid)}
              >
                <ChoiceIcon
                  fontSize="inherit"
                  htmlColor={filled ? color : undefined}
                />
              </span>
              {(index + 1) % choiceBreak === 0 && (
                <div className={classes.break}></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      {showLabel && label && (
        <p className="action-rank-label">{nl2br(label)}</p>
      )}
    </div>
  );
};

export default RankAdapter;
