import React from "react";
import { useHistory } from "react-router";
import { useActionImage } from "common/actions";
import ConnectIcon from "@material-ui/icons/Person";
import ConnectedIcon from "common/icons/IconCnx";
import AbstractAction from "./AbstractAction";
import MenuButton from "./Common/MenuButton";
import { useDispatch, useSelector } from "react-redux";
import { wfMenuOpen } from "redux/workflow/action";

const ActionLogin = ({ config, data }) => {
  const user = useSelector(({ auth }) => auth.user);
  const nav = useHistory();
  const dispatch = useDispatch();

  const title = config.showTitle
    ? config.title || config.nextCardName
    : undefined;
  const image = useActionImage(config, data, "titleImage");

  const Icon = user.id ? ConnectedIcon : ConnectIcon;
  const route = user.id ? "/user" : "/account";
  const className = user.id ? "menu-login-connected" : "menu-login-connect";

  const handleClick = React.useCallback(() => {
    dispatch(wfMenuOpen());
    nav.replace(route, { ...config, resume: true });
  }, [nav, config, dispatch, route]);

  return (
    <MenuButton
      className={className}
      title={title}
      image={image}
      icon={!image ? Icon : undefined}
      onClick={handleClick}
    />
  );
};

ActionLogin.classNames = ["qrockme-menu-login"];

Object.assign(ActionLogin, AbstractAction);
ActionLogin.isActive = function (action, { location }) {
  return location.pathname === "/account" || location.pathname === "/user";
};

export default ActionLogin;
