import React from "react";
import { makeStyles } from "@material-ui/core";
import ActionsRenderer from "./actions/ActionsRenderer";
import { useMenu } from "./useMenu";
import DefaultMenu from "./DefaultMenu";
import TitleBar from "./TitleBar";
import DrawerMenu from "./DrawerMenu";
import { useSelector } from "react-redux";
import { selectWorkflow } from "redux/workflow/selector";
import clsx from "clsx";

const useStyles = makeStyles(theme => {
  return {
    landscape: {
      [theme.breakpoints.down("sm") + " and (orientation: landscape)"]: {
        display: "none !important",
      },
    },
  };
});

const Menu = ({ position }) => {
  const classes = useStyles();
  const title = useSelector(state => state.app.title);
  const { loading } = useSelector(selectWorkflow);
  const { hidden, actions, className } = useMenu(position);
  if (actions) {
    const direction =
      position === "bottom" || position === "top" ? "horizontal" : "vertical";
    return (
      <>
        {!hidden && actions.length > 0 && (
          <div
            className={clsx(
              classes.landscape,
              `qrockme-menu-${direction} qrockme-menu-${position} ${className}`
            )}
          >
            {position === "top" && <DrawerMenu position="left" />}
            <ActionsRenderer actions={actions} menuPosition={position} />
            {position === "top" && <DrawerMenu position="right" />}
          </div>
        )}
        {position === "top" && title && !loading && (
          <div className={clsx(classes.landscape, "qrockme-menu-cardtitle")}>
            {title}
          </div>
        )}
      </>
    );
  } else {
    if (hidden) {
      return null;
    }
    if (position === "bottom") {
      return <DefaultMenu />;
    } else if (position === "top") {
      return <TitleBar />;
    }
  }
};

export default Menu;
