import types from "./types";

const INITIAL_STATE = {
  images: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPLOAD_PIC_REQUEST: {
      const { name, id, file } = action.payload;
      const images = state.images[name] || {};
      const newImages = { ...images };
      newImages[id] = {
        isLoading: true,
        error: null,
        local: file,
        upload: null,
      };
      return { images: { ...state.images, [name]: newImages } };
    }
    case types.UPLOAD_PIC_SUCCESS: {
      const { name, id, url } = action.payload;
      const images = state.images[name] || {};
      const newImages = { ...images };
      const imageToUpdate = newImages[id];
      newImages[id] = {
        ...imageToUpdate,
        isLoading: false,
        error: null,
        upload: { url, filename: imageToUpdate.local.name },
      };
      return { images: { ...state.images, [name]: newImages } };
    }
    case types.UPLOAD_PIC_FAILURE: {
      const { name, id, error } = action.payload;
      const images = state.images[name] || {};
      const newImages = { ...images };
      const imageToUpdate = newImages[id];
      newImages[id] = {
        ...imageToUpdate,
        isLoading: false,
        error: error,
      };
      return { images: { ...state.images, [name]: newImages } };
    }
    case types.CLEAR_UPLOAD_PIC: {
      const { name } = action.payload;
      const images = { ...state.images };
      delete images[name];
      return { images: images };
    }
    case types.REMOVE_UPLOADED_PIC: {
      const { name, id } = action.payload;
      const images = state.images[name] || {};
      const newImages = { ...images };
      delete newImages[id];
      return { images: { ...state.images, [name]: newImages } };
    }
    case types.INIT_UPLOADED_PIC: {
      const { name, urls } = action.payload;
      const newImages = urls.reduce(
        (prev, cur, index) => ({
          ...prev,
          [`image-${index}`]: {
            isLoading: false,
            error: null,
            local: {},
            upload: cur,
          },
        }),
        {}
      );
      return { images: { ...state.images, [name]: newImages } };
    }
    default:
      return state;
  }
};
