var path = require("path");
var audioExtensions = require("audio-extensions");
var exts = Object.create(null);

audioExtensions.forEach(function (el) {
  exts[el] = true;
});

module.exports = function (filepath) {
  return path.extname(filepath).slice(1).toLowerCase() in exts;
};
