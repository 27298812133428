import React from "react";
import { makeStyles } from "@material-ui/core";
import { useDrop, useDrag } from "react-dnd";
import clsx from "clsx";
import CorrectionIcon from "../Common/CorrectionIcon";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F1F1F1",
    borderRadius: 6,
    top: 15,
    padding: "4px 10px",
    border: "1px solid #E3E3E3",
    "&.action-word-empty": {
      borderRadius: 0,
      color: "#888888",
      width: 150,
      display: "inline-block",
      textAlign: "center",
      border: "none",
      fontSize: 18,
      position: "relative",
      backgroundColor: "#FFFFFF",
      transition: "background-color 0.5s ease",
    },
    "&.action-word-over .action-word-placeholder": {
      backgroundColor: "#F1F1F1",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    "&.action-word-filled": {
      cursor: "grab",
    },
    "&.action-word-correction": {
      display: "inline-flex",
      lineHeight: "1em",
      textAlign: "center",
      alignItems: "center",
      position: "relative",
      top: "5px",
    },
    "& .action-correction-icon": {
      paddingRight: 6,
      display: "flex",
      alignItems: "center",
      fontSize: 16,
    },
    "& .action-word-placeholder": {
      position: "relative",
      borderBottomStyle: "dashed",
      borderBottomWidth: 1,
      borderColor: "#888888",
      display: "block",
      marginBottom: 10,
      height: 32,
    },
  },
  icon: {
    position: "absolute",
    bottom: 2,
    left: 60,
    color: "#ff3041",
  },
});

const Proposal = ({ guid, answer, dndGroup, onDrop, onDragging, solution }) => {
  const ref = React.useRef();
  const [width, setWidth] = React.useState(0);
  const classes = useStyles();
  const correct = answer ? answer.correct : undefined;

  React.useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth + 22 : 0);
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: dndGroup || "__disabled-drop",
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [, drag] = useDrag({
    item: {
      type: dndGroup || "__disabled-drag",
      guid: answer ? answer.guid : "",
      from: guid,
      label: answer ? answer.label : "",
      width,
    },
    begin: () => {
      if (onDragging) {
        onDragging(true);
      }
    },
    end: () => {
      if (onDragging) {
        onDragging(false);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  drop(ref);
  if (answer) {
    drag(ref);
  }

  return (
    <span
      ref={ref}
      className={clsx(classes.root, {
        "action-word-over": isOver,
        "action-word-empty": !answer,
        "action-word-filled": !!answer && !!dndGroup,
        "action-word-correction": correct !== undefined,
        "action-word-correct": correct,
        "action-word-wrong": correct !== undefined && !correct,
      })}
    >
      {!answer && <span className="action-word-placeholder"></span>}
      <CorrectionIcon correct={correct} />
      {answer && (
        <span>
          {answer.label}
          {correct !== undefined && !correct && (
            <>
              <br />
              <span className="action-label-solution">{solution}</span>
            </>
          )}
        </span>
      )}
    </span>
  );
};

export default Proposal;
