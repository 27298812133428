import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { withIntl } from "hooks/useFormatMessage";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { wfPwaDeclined } from "redux/workflow/action";
import { selectPwaDeclined } from "redux/workflow/selector";
import { isIOS } from "react-device-detect";
import { isPwaIOS } from "common/detect";
import PwaDialogIOSIntl from "./PwaDialogIOS.i";

const useStyles = makeStyles({
  dialogClose: { position: "absolute", right: 0, top: 0 },
  icon: { borderRadius: 10 },
  name: {
    fontSize: 18,
    fontWeight: "bold",
  },
  hint: { fontSize: 16, display: "inline-flex", alignItems: "center" },
  shareIcon: {
    marginRight: 8,
    marginTop: -6,
  },
  addIcon: {
    marginLeft: 8,
  },
  text: { lineHeight: 1.8 },
});

const shareIcon = "/assets/images/ios-share.png";
const addIcon = "/assets/images/ios-add.png";

const PwaDialogIOS = ({ name, icon }) => {
  const declined = useSelector(selectPwaDeclined);
  const dispatch = useDispatch();
  const classes = useStyles();
  if (!isIOS || isPwaIOS()) return null;

  const handleClose = () => {
    dispatch(wfPwaDeclined());
  };

  return (
    <Dialog open={!declined} classes={{ paper: "qrockme-action-popup" }}>
      <DialogTitle>
        <IconButton
          className={clsx(classes.dialogClose, "action-close-button")}
          aria-label="Close dialog"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="action-screen">
        <p>
          <img
            alt={name}
            className={classes.icon}
            src={icon}
            width="64"
            height="64"
          />
        </p>
        <p className={classes.name}>{name}</p>
        <p className={classes.hint}>
          <img
            className={classes.shareIcon}
            src={shareIcon}
            alt="Share"
            width="24"
            height="24"
          />
          <span>
            <FormattedMessage {...PwaDialogIOSIntl.HomeScreen} />
          </span>
          <img
            className={classes.addIcon}
            src={addIcon}
            alt="Share"
            width="24"
            height="24"
          />
        </p>
        <p className={classes.text}>
          <FormattedMessage {...PwaDialogIOSIntl.Text} />
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default withIntl(PwaDialogIOS);
