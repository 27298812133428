import { isIOS } from "react-device-detect";

export function isPwa() {
  return (
    window.navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches
  );
}

export function isIos() {
  return isIOS;
}

export function isPwaIOS() {
  return isIOS && isPwa();
}
