import React from "react";

const useMath = (width, height) => {
  return React.useMemo(() => {
    const toScreenX = value => {
      return (value / 1000) * width;
    };
    const toScreenY = value => {
      return (value / 1000) * height;
    };
    const toScreen = (x, y) => {
      return { x: toScreenX(x), y: toScreenY(y) };
    };
    const toDataX = value => {
      return Math.round((value / width) * 1000);
    };
    const toDataY = value => {
      return Math.round((value / height) * 1000);
    };
    const toData = (x, y) => {
      return { x: toDataX(x), y: toDataY(y) };
    };

    return {
      toScreenX,
      toScreenY,
      toScreen,
      toDataX,
      toDataY,
      toData,
    };
  }, [width, height]);
};

export default useMath;
