import React from "react";
import Slider from "react-slick";
import isVideo from "is-video";
import isImage from "is-image";
import isAudio from "libs/isAudio";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import nl2br from "react-nl2br";
import ImageLoader from "common/components/ImageLoader";
import PdfViewerLazy from "./PdfViewerLazy";
import PdfDownload from "./PdfDownload";

const useStyles = makeStyles({
  responsive: {
    maxWidth: "100%",
    height: "auto",
  },
  fit: {
    width: "100%",
    display: "inline-block",
  },
  slider: {
    margin: "0 20px 30px 20px",
  },
});

function displayMedia(title, media, index, className, solo) {
  let html = null;
  if (isVideo(media.url)) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    html = (
      <video className={className} title={title + " #" + (index + 1)} controls>
        <source src={url} style={{ display: "block", lineHeight: "0" }} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );
  } else if (isImage(media.url)) {
    html = (
      <ImageLoader
        className={className}
        alt={title + " #" + (index + 1)}
        src={media.url}
      />
    );
  } else if (isAudio(media.url)) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    html = (
      <div
        className={className}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <audio title={title + " #" + (index + 1)} controls>
          <source src={url} />
          Sorry, your browser doesn't support embedded audios.
        </audio>
      </div>
    );
  } else if (media.url.toLowerCase().endsWith(".pdf")) {
    const url = process.env.REACT_APP_STATIC_SERVER
      ? media.url.replace(
          "https://s3.eu-west-3.amazonaws.com/qrock.me/",
          process.env.REACT_APP_STATIC_SERVER
        )
      : media.url;
    if (solo) {
      return <PdfViewerLazy key={media.url} url={url} />;
    } else {
      return (
        <PdfDownload
          key={media.url}
          url={url}
          filename={media.filename}
          title={media.title}
          desc={media.desc}
        />
      );
    }
  }
  if (html) {
    html = (
      <React.Fragment key={media.url}>
        <div style={{ width: "100%", display: "inline-block" }}>{html}</div>
        {media.title && (
          <p className="qrockme-action-multimedia-title">
            {nl2br(media.title)}
          </p>
        )}
        {media.desc && (
          <p className="qrockme-action-multimedia-desc">{nl2br(media.desc)}</p>
        )}
        {media.additionals &&
          media.additionals.map(({ content }, idx) => {
            const classname =
              "action-media-add action-media-add-" + (idx + 1);
            return <div className={classname}>{nl2br(content)}</div>;
          })}
      </React.Fragment>
    );
  }
  return html;
}

const MediasSlider = ({ config, medias }) => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  if (medias.length === 1) {
    return (
      <div className="media-content">
        {displayMedia(config.title, medias[0], 0, classes.responsive, true)}
      </div>
    );
  } else {
    return (
      <Slider
        data-testid="slider"
        {...settings}
        className={clsx(classes.slider, "media-content")}
      >
        {medias.map((media, index) =>
          displayMedia(config.title, media, index, classes.fit, false)
        )}
      </Slider>
    );
  }
};

export default MediasSlider;
