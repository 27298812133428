export interface Result {
  id: string;
  label: string;
  parent?: string;
  summary?: string;
  image?: string;
  card: string;
  action?: string;
}

type Ref = Omit<Result, "id">;
type Refs = Record<string, Ref>;

function mapResults(ids: string[], refs: Refs): Result[] {
  return ids.reduce<Result[]>((prev, cur) => {
    const ref = refs[cur];
    if (ref) {
      return [...prev, { ...ref, id: cur }];
    }
    return prev;
  }, []);
}

export default mapResults;
