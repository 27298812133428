import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Check as HeaderIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    minHeight: 24,
  },
  headerText: {
    marginLeft: 8,
  },
});

const ResultsHeader = ({ count, total, showEmpty }) => {
  const classes = useStyles();
  if (!Boolean(total) && !showEmpty) return null;
  return (
    <div className={clsx(classes.header, "action-results-header")}>
      {Boolean(total) && (
        <>
          <HeaderIcon />
          <span className={classes.headerText}>
            {count} / {total}
          </span>
        </>
      )}
    </div>
  );
};

ResultsHeader.propTypes = {
  count: PropTypes.number,
  total: PropTypes.number,
  showEmpty: PropTypes.bool,
};

export default ResultsHeader;
