import * as Api from "api";
import apiCall from "./apiCall";
import { push } from "connected-react-router";
import { put, call, select } from "redux-saga/effects";
import { setLangAvailable, setLangCurrent } from "redux/app/action";
import { getShortBrowserLocale } from "detect-locale";
import { themeSetProject } from "redux/theme/action";
import {
  wfStartHistoryTraveling,
  wfUpdateCurrent,
  wfHistorySet,
} from "redux/workflow/action";
import { selectCurrent } from "redux/workflow/selector";

function prepareLanguages(cards) {
  return cards.reduce((prev, cur) => {
    cur.languages.forEach(l => {
      Object.entries(l).forEach(([k, v]) => {
        prev[k] = v;
      });
    });
    return prev;
  }, {});
}

export default function* startWorkFlow() {
  const current = yield select(selectCurrent);
  const r = yield call(
    apiCall,
    Api.fetchProject,
    current.entity,
    current.project
  );
  const { css, logo, logoLeft, logoRight } = current.projectData;
  yield put(themeSetProject({ css, logo, logoLeft, logoRight }));
  const langs = prepareLanguages([current.projectData]);
  yield put(setLangAvailable(langs));

  const lang = yield select(
    state => state.app.langChoice || getShortBrowserLocale()
  );

  if (Object.keys(langs).includes(lang)) {
    // La langue choisie ou du navigateur est dispo
    yield put(setLangCurrent(lang));
    // La langue choisie et du navigateur ne sont pas dispo, on utilise l'anglais si dispo
  } else if (Object.keys(langs).includes("en")) {
    yield put(setLangCurrent("en"));
  } else {
    // La langue choisie, du navigateur et l'anglais ne sont pas dispo, on utilise celle par défaut du projet
    yield put(setLangCurrent(current.projectData.lang));
  }

  let start;
  if (r.cards.length > 1) {
    start = { guid: "", fork: true };
  } else {
    start = { guid: r.cards[0].guid, name: r.cards[0].name };
  }
  yield put(
    wfUpdateCurrent({
      projectData: undefined,
      projectCards: r.projectCards,
      hasNotification: Boolean(r.hasNotification),
    })
  );
  yield put(wfHistorySet([start]));
  yield put(wfStartHistoryTraveling()); // Prevent push next nav to history stack
  yield put(push("/_", start));
}
