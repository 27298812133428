import React from "react";
import PicsAdapter from "common/components/formAdapters/PicsAdapter";
import { Field } from "react-final-form";
import { required, noValidation } from "common/components/rules";
import { FormField } from "./Actions.s";
import AbstractAction from "./AbstractAction";
import Title from "./Common/Title";

const ActionTakePics = ({ action, config, data, actionCount }) => {
  return (
    <FormField key={`action-${action.id}`}>
      <Title config={config} data={data} />
      <Field
        className="action-field"
        name={`action-${action.id}`}
        component={PicsAdapter}
        validate={config.required ? required : noValidation}
        small={actionCount > 1}
        initialValue={data.result}
      />
    </FormField>
  );
};

ActionTakePics.classNames = ["qrockme-action-takepics"];

Object.assign(ActionTakePics, AbstractAction);
export default ActionTakePics;
