import React from "react";
import { makeStyles, Chip, Avatar } from "@material-ui/core";
import { Check as IncludeIcon, Close as ExcludeIcon } from "@material-ui/icons";
import ActionsIntl from "../Actions.i";
import useFormatMessage from "hooks/useFormatMessage";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    paddingRight: 16,
    "&.action-filters-chip .MuiChip-icon": {
      transition: "opacity 500ms",
      opacity: 0,
    },
    "&.action-filters-chip-checked .MuiChip-icon": {
      opacity: 1,
    },
  },
});

const FilterChip = ({ name, value, image, operator, checked, onClick }) => {
  const classes = useStyles();
  const t = useFormatMessage();

  const handleClick = React.useCallback(
    e => {
      if (onClick) {
        e.target.name = value;
        e.target.checked = !checked;
        onClick(e);
      }
    },
    [value, checked, onClick]
  );

  const label = React.useMemo(() => {
    let label = name || t(ActionsIntl.FilterEmptyElement);
    if (name === true) {
      label = t(ActionsIntl.FilterTrueElement);
    } else if (name === false) {
      label = t(ActionsIntl.FilterFalseElement);
    } else if (name === null) {
      label = t(ActionsIntl.FilterNullElement);
    }
    return label;
  }, [name, t]);

  const iconProps = React.useMemo(() => {
    if (!image || checked) {
      if (operator === "ne") {
        return { icon: <ExcludeIcon className="action-filters-chip-icon" /> };
      } else {
        return { icon: <IncludeIcon className="action-filters-chip-icon" /> };
      }
    }
    return { avatar: <Avatar alt="" src={image} /> };
  }, [image, checked, operator]);

  return (
    <Chip
      className={clsx(classes.root, "action-filters-chip", {
        "action-filters-chip-checked": checked,
        "action-filters-chip-empty": name === "",
      })}
      size="small"
      label={label}
      onClick={handleClick}
      {...iconProps}
    />
  );
};

export default FilterChip;
