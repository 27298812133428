import submitCard from "./submitCard";
import submitAction from "./submitAction";
import { put, call } from "redux-saga/effects";
import {
  wfHistorySkip,
  wfSetLoading,
  wfSetActionState,
  WF_SAGA_SKIP_CARD_DONE,
  WF_SAGA_SKIP_CARD_FAILED,
} from "redux/workflow/action";

export default function* skipCard({
  payload: {
    projectGuid,
    cardGuid,
    actionGuid,
    state,
    history,
    next,
    actionSubmit,
    values,
  },
}) {
  try {
    yield put(wfSetLoading());
    if (state && actionGuid) {
      // Action ask a state update
      yield put(wfSetActionState(projectGuid, cardGuid, actionGuid, state));
    }
    if (!history) {
      // Action ask to remove card from history
      yield put(wfHistorySkip());
    }

    if (actionSubmit) {
      yield call(submitAction, { payload: { guid: actionGuid, single: true } });
    }
    yield call(submitCard, {
      payload: { guid: cardGuid, values, next: next },
    });

    yield put({
      type: WF_SAGA_SKIP_CARD_DONE,
    });
  } catch (e) {
    yield put({ type: WF_SAGA_SKIP_CARD_FAILED, message: e.message });
  }
}
