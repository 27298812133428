import { isEqual } from "lodash";

export default function (config, proposals) {
  const main = config.proposals.map(p => p.guid);
  const solutions = [main, ...config.alts];
  const proposed = proposals.map(p => p.guid);
  const correct = solutions.some(s => isEqual(s, proposed));
  if (correct) {
    const solution = proposals.map((p, i) => ({
      ...p,
      correct: true,
    }));
    return { correct, solution, correctCount: proposed.length };
  } else {
    const solution = proposals.map((p, i) => ({
      ...p,
      solution: main.indexOf(p.guid) + 1,
      correct: main[i] === p.guid,
    }));
    const correctCount = solution.reduce(
      (prev, cur) => (cur.correct ? prev + 1 : prev),
      0
    );
    return { correct, solution, correctCount };
  }
}
