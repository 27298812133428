import React from "react";
import {
  CircularProgress,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import useCancelableRequest from "./useCancelableRequest";
import clsx from "clsx";

const useStyles = makeStyles({
  loading: {
    position: "relative",
    width: "100%",
    paddingBottom: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
    animation: "$imageLoadingPulse 1.5s ease-in-out 0.5s infinite",
    color: "#a6a5a5",
  },
  loadingCover: {
    paddingBottom: 0,
    height: "100%",
  },
  loadingContent: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    animation: "$loaderAppear 4s",
  },
  error: {
    animation: "none",
  },
  hiddenImage: {
    display: "none !important",
  },
  "@keyframes loaderAppear": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes imageLoadingPulse": {
    "0%": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "50%": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    "100%": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  responsive: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "100%",
  },
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ImageLoader = ({
  src,
  alt,
  onLoad,
  className,
  cover,
  invisible,
  responsive,
  ...props
}) => {
  const previousProgress = React.useRef(0);
  const [result, progress, error] = useCancelableRequest(src);
  const classes = useStyles();

  React.useEffect(() => {
    if (onLoad && previousProgress.current < 100 && progress >= 100) {
      onLoad();
    }
    previousProgress.current = progress;
  }, [progress, onLoad]);
  return (
    <>
      {!invisible && (progress < 100 || error) && (
        <div
          className={clsx(classes.loading, {
            [classes.loadingCover]: cover,
            [classes.error]: Boolean(error),
          })}
        >
          <div className={classes.loadingContent}>
            {error || (
              <CircularProgressWithLabel
                color="inherit"
                thickness={5}
                value={progress}
              />
            )}
          </div>
        </div>
      )}
      <img
        alt={alt}
        src={result || ""}
        className={clsx(className, {
          [classes.hiddenImage]: !result,
          [classes.responsive]: responsive,
        })}
        {...props}
      />
    </>
  );
};

export default ImageLoader;
