import React from "react";
import PropTypes from "prop-types";
import { keyBy } from "lodash";
import ResultsScreen from "../Common/ResultsScreen";
import Board from "./Board";
import populateLabels from "./populateLabels";

const ScreenResults = ({ children, config, data, state, onSubmit }) => {
  // Do not display solution if user can try again
  const showSolution = state.screen !== "retry";

  const proposalsByGuid = React.useMemo(
    () => keyBy(config.proposals, "guid"),
    [config.proposals]
  );
  const proposals = React.useMemo(
    () => populateLabels(state.proposals, proposalsByGuid),
    [state.proposals, proposalsByGuid]
  );

  return (
    <ResultsScreen
      actions={children}
      screen={state.screen}
      config={config}
      data={data}
      headerCount={state.correctCount}
      headerTotal={state.proposals.length}
      onSubmit={!showSolution ? onSubmit : undefined}
    >
      <Board proposals={proposals} showSolution={showSolution} />
    </ResultsScreen>
  );
};

ScreenResults.propTypes = {
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScreenResults;
