export const THEME_SET_GLOBAL = "THEME_SET_GLOBAL";
export const THEME_SET_PROJECT = "THEME_SET_PROJECT";

export const themeSetGlobal = ({ css, logo, logoLeft, logoRight }) => {
  return {
    type: THEME_SET_GLOBAL,
    payload: { css, logo, logoLeft, logoRight },
  };
};

export const themeSetProject = ({ css, logo, logoLeft, logoRight }) => {
  return {
    type: THEME_SET_PROJECT,
    payload: { css, logo, logoLeft, logoRight },
  };
};

export const themeClearProject = () => {
  return {
    type: THEME_SET_PROJECT,
    payload: {},
  };
};
