import ActionNumber from "../ActionNumber";
import produce from "immer";
import { prepareInteractiveActionChildren } from "../Common/utils";

export default function ({ config, children, data, ...rest }, state) {
  if (!state && data?.result_obj?.screen) {
    state = {
      ...ActionNumber.initialState,
      ...data.result_obj,
      proposals: config.proposals,
      correct: data.result_obj.screen === "success",
    };
  } else if (!state) {
    state = state || { ...ActionNumber.initialState };
  }

  // If we leave card on retry screen, we go to info screen on next display
  if (state.screen === "retry") {
    if (state.goInfoNextTime) {
      state = {
        ...ActionNumber.initialState,
        trial: state.trial,
      };
    } else {
      state = produce(state, draft => {
        draft.goInfoNextTime = true;
      });
    }
  }

  return {
    config,
    data,
    ...rest,
    children: prepareInteractiveActionChildren(children, state.screen),
    state,
  };
}
