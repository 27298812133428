import React from "react";
import { withRouter } from "react-router-dom";
import {
  isIOS,
  // isBrowser,
  isMobile,
  // mobileVendor
} from "react-device-detect";
class ScrollToTop extends React.Component {

  componentDidUpdate(prevProps) {

    let el;
    if (isIOS && isMobile) {
      // console.log('ios mobile');

      if ((this.props.location.pathname !== prevProps.location.pathname) && prevProps.location.pathname !== '/scan') {
        el = window.document.querySelector('div[class*="Apps__AppContainer"]');
        el.scrollTop = el.scrollHeight;
        el.scrollTop = 0;
      }
    } else {
      // console.log('NOT ios mobile');
      // el = window.document.querySelector('div[class*="Apps__AppContainer"]');
      // el.scrollTop();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);