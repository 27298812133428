import React from "react";
import { useMenu } from "./useMenu";
import ActionsRenderer from "./actions/ActionsRenderer";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  menu: {
    flex: "0 0 350px",
    overflow: "auto",
  },
  breakpoint: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  breakpointSecondary: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));

const VerticalMenu = ({ position }) => {
  const classes = useStyles();
  const { hidden, actions, hasOtherSide, className } = useMenu(position);

  if (hidden || !actions || actions.length === 0) {
    return null;
  }

  const breakpointClass = clsx({
    [classes.breakpoint]:
      position === "left" || (position === "right" && !hasOtherSide),
    [classes.breakpointSecondary]: position === "right" && hasOtherSide,
  });

  return (
    <div
      className={clsx(
        classes.menu,
        breakpointClass,
        `qrockme-menu-vertical qrockme-menu-${position} qrockme-menu-static ${className}`
      )}
    >
      <ActionsRenderer actions={actions} menuPosition={position} />
    </div>
  );
};

export default VerticalMenu;
