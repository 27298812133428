import React from "react";
import { makeStyles } from "@material-ui/core";
import { NavigateNext as ArrowIcon } from "@material-ui/icons";
import nl2br from "react-nl2br";
import clsx from "clsx";
import { ButtonImage } from "common/components";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    margin: 0,
    padding: 0,
    textAlign: "left",
    textDecoration: "none",
  },
  icon: {
    width: "100%",
    height: "100%",
  },
  buttonImage: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const MenuButton = ({
  title,
  image,
  icon,
  onClick,
  component = "button",
  className,
  children,
  ...rest
}) => {
  const classes = useStyles();

  let ComponentProp = component;
  if (ComponentProp === "button" && rest.href) {
    ComponentProp = "a";
  }

  const buttonProps = {};
  if (ComponentProp === "button") {
    ComponentProp = "div";
    buttonProps.role = "button";
  } else {
    buttonProps.role = "button";
  }

  return (
    <ComponentProp
      className={clsx(classes.root, "menu-button", className)}
      onClick={onClick}
      {...buttonProps}
      {...rest}
    >
      <ButtonImage
        image={image}
        icon={icon}
        classes={{
          image: clsx(classes.buttonImage, "menu-button-image"),
          icon: "menu-button-image menu-button-icon",
        }}
      />
      {(title || children) && (
        <div className="menu-button-content">
          {title && <div className="menu-button-title">{nl2br(title)}</div>}
          {children}
        </div>
      )}
      <div className="menu-button-arrow">
        <ArrowIcon fontSize="inherit" />
      </div>
    </ComponentProp>
  );
};

export default MenuButton;
