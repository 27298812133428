import types from "./types";

export const clearUploadPic = name => {
  return {
    type: types.CLEAR_UPLOAD_PIC,
    payload: { name },
  };
};

export const removeUploadedPic = (name, id) => {
  return {
    type: types.REMOVE_UPLOADED_PIC,
    payload: { name, id },
  };
};

export const initUploadedPic = (name, urls) => {
  return {
    type: types.INIT_UPLOADED_PIC,
    payload: { name, urls },
  };
};
