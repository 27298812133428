import React, { Component } from "react";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

class Sticky extends Component {
  state = {
    height: 0,
    width: 0,
    stuckBottom: false,
    stuckLeft: false,
    stuckRight: false,
    stuckTop: false,
  };

  componentDidMount() {
    this.addEvents();
    this.handleScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTarget !== this.props.scrollTarget) {
      this.removeEvents();
      this.addEvents();
    }
    if (!isEqual(prevProps.sides, this.props.sides)) {
      this.debouncedScroll();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  frameId = 0;

  stickyDiv = React.createRef();

  handleScroll = () => {
    const { sides } = this.props;
    const stickyDiv = this.stickyDiv.current || null;
    const scrollTarget = this.props.scrollTarget || window;

    this.frameId = 0;

    if (!stickyDiv) {
      return;
    }

    let scrollRect = {
      // scrollTarget is the window
      height: scrollTarget.innerHeight,
      width: scrollTarget.innerWidth,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      x: scrollTarget.scrollX,
      y: scrollTarget.scrollY,
    };

    if (scrollTarget.getBoundingClientRect) {
      // scrollTarget is not the window
      scrollRect = scrollTarget.getBoundingClientRect();
    }

    let stickyRect = stickyDiv.getBoundingClientRect();

    if (!this.state.height || !this.state.width) {
      this.setState({
        height: stickyRect.height,
        width: stickyRect.height,
      });
    }

    stickyRect = {
      // Apparently you can't spread the results of a bounding client rectangle
      height: this.state.height || stickyRect.height,
      width: this.state.width || stickyRect.width,
      x: stickyRect.x,
      y: stickyRect.y,
    };

    const newstate = {
      stuckBottom: false,
      stuckTop: false,
      stuckLeft: false,
      stuckRight: false,
    };

    if (typeof sides.bottom === "number") {
      if (
        stickyRect.y + stickyRect.height >
        scrollRect.height + scrollRect.top - sides.bottom
      ) {
        newstate.stuckBottom = true;
      }
    }

    if (typeof sides.top === "number") {
      if (stickyRect.y < scrollRect.top + sides.top) {
        newstate.stuckTop = true;
      }
    }

    if (typeof sides.left === "number") {
      if (stickyRect.x < scrollRect.left + sides.left) {
        newstate.stuckLeft = true;
      }
    }

    if (typeof sides.right === "number") {
      if (
        stickyRect.x + stickyRect.width >
        scrollRect.width + scrollRect.left - sides.right
      ) {
        newstate.stuckRight = true;
      }
    }
    this.setState(s => ({ ...s, ...newstate }));
  };

  debouncedScroll = () => {
    if (!this.frameId) {
      const frameId = requestAnimationFrame(this.handleScroll);
      this.frameId = frameId;
    }
  };

  addEvents() {
    const scrollTarget = this.props.scrollTarget || window;

    if (scrollTarget && this.stickyDiv.current) {
      scrollTarget.addEventListener("scroll", this.debouncedScroll);
    }
    window.addEventListener("resize", this.debouncedScroll);
    window.addEventListener("load", this.debouncedScroll);
  }

  removeEvents() {
    const scrollTarget = this.props.scrollTarget || window;

    if (scrollTarget) {
      scrollTarget.removeEventListener("scroll", this.debouncedScroll);
    }
    window.removeEventListener("resize", this.debouncedScroll);
    window.removeEventListener("load", this.debouncedScroll);

    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
    }
  }

  render() {
    const { children } = this.props;
    const { stuckBottom, stuckLeft, stuckRight, stuckTop } = this.state;

    const childrenWithStuckProps = React.Children.map(children, child => {
      return React.cloneElement(child, {
        ...child.props,
        stuckBottom,
        stuckLeft,
        stuckRight,
        stuckTop,
      });
    });

    return (
      <div ref={this.stickyDiv} className={this.props.className}>
        {childrenWithStuckProps}
      </div>
    );
  }
}

Sticky.propTypes = {
  children: PropTypes.node.isRequired,
  scrollTarget: PropTypes.object,
  sides: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
};

Sticky.defaultProps = {
  scrollTarget: null,
  sides: {
    top: 0,
  },
};

export default Sticky;
