import { WF_SAGA_LOAD_CARD_DONE } from "redux/workflow/action";

const initialState = {
  answers: [],
  screen: undefined,
  clearOn: WF_SAGA_LOAD_CARD_DONE,
  actionType: "QCMQCU",
};

export default initialState;
