import React from "react";
import PropTypes from "prop-types";

const Action = ({ action, config, component, ...others }) => {
  const ComponentProp = component;
  return (
    <div className="action-container" name={`action-title-${action.id}`}>
      <ComponentProp
        action={action}
        config={config}
        data={action.data}
        {...others}
      />
    </div>
  );
};

Action.propTypes = {
  action: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default Action;
