import React from "react";
import { makeStyles } from "@material-ui/core";
import extractWords from "./extractWords";
import Proposal from "./Proposal";

const useStyles = makeStyles({
  line: {
    textAlign: "justify",
    lineHeight: "3em",
  },
});

const Board = React.forwardRef(
  ({ html, answers, dndGroup, onDrop, onDragging, showSolution }, ref) => {
    const classes = useStyles();
    const [lines, setLines] = React.useState([]);
    const rawTextRef = React.useRef();

    const handleDrop = React.useCallback(
      index => item => {
        onDrop(index, item);
      },
      [onDrop]
    );

    React.useEffect(() => {
      const nodes = [].slice.call(rawTextRef.current.childNodes);
      const lines = nodes.map(n => n.innerHTML).filter(html => Boolean(html));
      const words = lines.map(extractWords);
      const final = lines.map((value, index) => {
        if (words[index].length === 0) {
          return <span dangerouslySetInnerHTML={{ __html: value }}></span>;
        } else {
          let pos = 0;
          const parts = words[index].reduce((prev, word, i) => {
            const html = value.substr(pos, word.index - pos);
            pos = word.index + word.rawlabel.length + 70; // tag + guid
            return [
              ...prev,
              <span key={i} dangerouslySetInnerHTML={{ __html: html }} />,
              <Proposal
                key={word.guid}
                guid={word.guid}
                dndGroup={dndGroup}
                onDrop={handleDrop(word.guid)}
                onDragging={onDragging}
                answer={answers[word.guid]}
                solution={showSolution ? word.label : undefined}
              />,
            ];
          }, []);
          if (pos < value.length) {
            parts.push(
              <span
                key="end"
                dangerouslySetInnerHTML={{ __html: value.substr(pos) }}
              />
            );
          }
          return parts;
        }
      });
      setLines(final);
    }, [html, answers, showSolution, dndGroup, handleDrop, onDragging]);

    return (
      <>
        <div
          ref={rawTextRef}
          style={{ display: "none" }}
          dangerouslySetInnerHTML={{ __html: `${html}` }}
        />
        <div ref={ref} className="action-word-text">
          {lines.map((html, i) => (
            <p key={i} className={classes.line}>
              {html}
            </p>
          ))}
        </div>
      </>
    );
  }
);

export default Board;
