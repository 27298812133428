import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    background: "rgba(22,112,255)",
    borderRadius: 6,
  },
});

const Zone = React.forwardRef(({ className, style }, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      className={clsx(classes.root, "action-zone", className)}
      style={style}
    ></div>
  );
});

export default Zone;
